import { FC, useRef, useState } from "react";
import { LoaderWrapper, Spinner } from "@amenda-components/App";
import {
  useAppStore,
  useAttachmentStore,
  usePreserveScrollPosition,
} from "@amenda-domains/mutations";

import { GalleryCard } from "@amenda-components/Gallery";
import { MediaGalleryProps } from "./common";
import { PaddingRow } from "@amenda-components/PageBuilder/PaddingRow";
import clsx from "clsx";
import { getPadding } from "@amenda-components/Shared/reactTableHelpers";
import { isEmpty } from "lodash";
import { useInView } from "react-intersection-observer";
import { useRunOnMountOnly } from "@amenda-utils";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";

type Props = Pick<
  MediaGalleryProps,
  | "pagination"
  | "canUpload"
  | "attachments"
  | "rootRoute"
  | "fetchPaginatedAttachments"
>;

export const GalleryGridView: FC<Props> = ({
  pagination,
  attachments,
  rootRoute,
  fetchPaginatedAttachments,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const virtualizer = useVirtualizer({
    lanes: 6,
    overscan: 6,
    count: attachments.length,
    getScrollElement: () => wrapperRef.current,
    estimateSize: () => 1,
  });
  const setIsScrolling = useAppStore((state) => state.setIsScrolling);

  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();
  const { paddingBottom, paddingTop } = getPadding(virtualRows, totalSize);
  const { ref } = useInView({
    threshold: 0.5,
    root: containerRef.current,
    onChange(inView, entry) {
      if (inView && !isFetching && fetchPaginatedAttachments) {
        fetchPaginatedAttachments();
      }
    },
  });
  const { handleScroll: handleScrolling } = usePreserveScrollPosition({
    scrollRef: containerRef,
    canScrollTo: !!wrapperRef?.current,
  });

  let scrollTimeout: any = null;
  const isLoadingInVirtualizer =
    isEmpty(virtualizer.getVirtualItems()) && !isEmpty(attachments);

  const handleScroll = () => {
    handleScrolling();
    setIsScrolling(true);
    // Clear the timeout if scrolling continues
    clearTimeout(scrollTimeout);
    // Set a timeout to reset the isScrolling state after scrolling stops
    scrollTimeout = setTimeout(() => setIsScrolling(false), 200);
  };

  useRunOnMountOnly(() => {
    setKey((prev) => prev + 1);
  });

  return (
    <div
      key={key}
      ref={containerRef}
      className="relative h-[calc(100vh-96px)] w-full overflow-y-auto overscroll-y-contain"
      onScroll={handleScroll}
    >
      {isSearching || isLoadingInVirtualizer ? (
        <LoaderWrapper />
      ) : isFetching && isEmpty(attachments) ? (
        <LoaderWrapper className="h-44" />
      ) : null}
      {containerRef?.current && !isSearching && attachments.length > 0 && (
        <div
          ref={wrapperRef}
          className={clsx(
            "grid-cols relative grid w-full auto-rows-min gap-2 transition-all duration-500 md:gap-2 lg:gap-2 xxl:gap-8",
            {
              "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6":
                !sidebarOpen,
              "grid-cols-2 md:grid-cols-2 lg:grid-cols-4": sidebarOpen,
            },
          )}
          style={{
            height: containerRef.current.clientHeight,
          }}
        >
          {totalSize >= 12 && (
            <div className="col-span-full">
              <PaddingRow padding={paddingTop} />
            </div>
          )}
          {virtualizer.getVirtualItems().map((virtualRow, i) => {
            const attachment = attachments[virtualRow.index];
            return (
              <GalleryCard
                key={attachment.id}
                attachment={attachment}
                rootRoute={rootRoute}
              />
            );
          })}
          <div className="col-span-full">
            {pagination?.hasNext && (
              <div className="flex w-full items-center justify-center space-x-2">
                <Spinner spinnerSize="xs" variant="default" />
                <span className="text-sm">{t("Loading more photos...")}</span>
              </div>
            )}
            <div ref={ref} className="h-8 w-full" />
            {totalSize >= 12 && <PaddingRow padding={paddingBottom} />}
          </div>
        </div>
      )}
    </div>
  );
};
