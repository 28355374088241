import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { FC, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  isDragValid,
  isFormBuilderDragValid,
} from "./FormBuilderDndComponents";
import { MainContainer, SidebarContainer } from "@amenda-components/Shared";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  useAppStore,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { FormBuilderCategorySidebar } from "./FormBuilderCategorySidebar";
import { FormBuilderComponentEditor } from "./FormBuilderComponentEditor";
import { FormBuilderComponentsSidebar } from "./FormBuilderComponentsSidebar";
import { FormBuilderCreateFormModal } from "./FormBuilderCreateFormModal";
import { FormBuilderRightSidebar } from "./FormBuilderRightSidebar";
import { FormBuilderSectionsSidebar } from "./FormBuilderSectionsSidebar";
import { GripVerticalIcon } from "lucide-react";
import { IconButtonBase } from "@amenda-components/App/IconButton";
import { availableLayouts } from "@amenda-types";
import clsx from "clsx";

const FormBuilderLeftSidebar: FC = () => {
  const selectedCategory = useAppStore(
    (state) => state.formBuilderState.selectedCategory,
  );
  const isEditingForm = useAppStore(
    (state) => state.formBuilderState.isEditingForm,
  );

  if (isEditingForm) {
    return (
      <SidebarContainer className="w-72 border-r bg-gray-50 pt-4">
        <FormBuilderComponentsSidebar />
      </SidebarContainer>
    );
  } else if (selectedCategory) {
    return (
      <SidebarContainer className="w-72 border-r bg-gray-50 pt-4">
        <FormBuilderSectionsSidebar />
      </SidebarContainer>
    );
  }
  return (
    <SidebarContainer className="w-72 border-r bg-gray-50 pt-4">
      <FormBuilderCategorySidebar />
    </SidebarContainer>
  );
};

interface FormBuilderDragOverlayProps {
  component: Record<string, any>;
}

export const FormBuilderDragOverlay: FC<FormBuilderDragOverlayProps> = ({
  component,
}) => {
  const label = component.properties?.label ?? component.label ?? component.id;

  return (
    <FormBuilderDragOverlayItem
      className={clsx(
        "z-40 w-full cursor-pointer rounded-sm bg-gray-800 px-1 py-2 text-sm text-white shadow-lg outline-none",
        {
          "h-48 w-96": [
            availableLayouts.twoThirdsColumnContainer,
            availableLayouts.fullColumnContainer,
            availableLayouts.nestedFormContainer,
          ].includes(component.layout),
        },
      )}
    >
      <div className="flex w-full items-center">
        <div>
          <IconButtonBase size="sm" className="cursor-grab p-1">
            <GripVerticalIcon className="h-5 w-5" />
          </IconButtonBase>
        </div>
        <span className="ml-2 truncate">{label}</span>
      </div>
    </FormBuilderDragOverlayItem>
  );
};

export const FormBuilder: FC = () => {
  const { updateForm } = useUpdateForm();
  const [activeComponent, setActiveComponent] = useState<Record<string, any>>(
    {},
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState.selectedForm,
  );
  const originalForms = useProjectStore((state) => state.originalForms);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const handleNewComponentOnDragEnd = useAppStore(
    (state) => state.handleNewComponentOnDragEnd,
  );
  const upsertComponentDragNDrop = useAppStore(
    (state) => state.upsertComponentDragNDrop,
  );
  const upsertLayoutDragNDrop = useAppStore(
    (state) => state.upsertLayoutDragNDrop,
  );

  const transformComponents = (
    updatedComponentIds: string[],
    parentId?: string,
  ) => {
    const components = selectedForm?.components ?? [];
    const updatedComponents: any[] = [];
    const componentsById: Record<string, any> = {};
    const componentsByIndex: Record<string, number> = {};
    const originalComponents =
      (selectedForm &&
        originalForms.find((form) => form.id === selectedForm.id)
          ?.components) ??
      [];
    const originalComponentsById: Record<string, any> = {};

    originalComponents.forEach((c) => {
      originalComponentsById[c.componentId] = c;
    });

    components.forEach((c, i) => {
      componentsById[c.id] = c;
      componentsByIndex[c.id] = i;
    });

    updatedComponentIds.forEach((id, i) => {
      if (
        componentsById[id].order !== i ||
        componentsById[id].parentId !== parentId
      ) {
        updatedComponents.push({
          ...(originalComponentsById[id] ?? {}),
          order: i,
          parentId: parentId ?? componentsById[id].parentId,
        });
      }
    });

    return updatedComponents;
  };

  const handleNewComponentDragEnd = async (
    active: Active,
    over: Over | null,
  ) => {
    const activeComponent = active.data.current?.component;
    const overComponent = over?.data?.current?.component;

    if (
      !overComponent ||
      !Boolean(overComponent?.parentId) ||
      Boolean(overComponent?.fromSidebar)
    ) {
      handleNewComponentOnDragEnd(activeComponent);
    } else if (activeComponent.parentId === overComponent.parentId) {
      const componentIds: any[] = active.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const oldIndex = componentIds.indexOf(active.id);
      const updatedComponentIds = arrayMove(componentIds, oldIndex, newIndex);
      handleNewComponentOnDragEnd(activeComponent, updatedComponentIds);
    }
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    const activeComponent = active.data.current?.component;
    const overComponent = over.data.current?.component;

    if (!isFormBuilderDragValid(activeComponent, overComponent)) {
      return;
    }

    updateFormBuilderState("isSortingComponent", true);
    if (activeComponent.parentId === overComponent.parentId) {
      const componentIds: any[] = active.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const oldIndex = componentIds.indexOf(active.id);
      const updatedComponentIds = arrayMove(componentIds, oldIndex, newIndex);
      const updatedComponents = transformComponents(updatedComponentIds);

      await updateForm({
        id: selectedForm?.id,
        components: updatedComponents,
      });
    } else {
      const componentIds: any[] = over.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const allComponentIds = [...componentIds, active.id];
      const updatedComponentIds = arrayMove(
        allComponentIds,
        allComponentIds.length - 1,
        newIndex,
      );
      const updatedComponents = transformComponents(
        updatedComponentIds,
        over.data.current?.component?.parentId,
      );

      await updateForm({
        id: selectedForm?.id,
        components: updatedComponents,
      });
    }
    updateFormBuilderState("isSortingComponent", false);
  };

  return (
    <div className="border-1 mt-4 flex h-[calc(100vh-12rem)] w-full rounded-md border border-gray-100">
      <FormBuilderCreateFormModal />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={(event) => {
          const { active } = event;

          setActiveComponent(active.data.current?.component ?? {});
        }}
        onDragOver={(e) => {
          const { over, active } = e;

          if (over?.data?.current?.component?.fromSidebar) {
            return;
          }
          if (
            active?.data?.current?.component?.component &&
            over?.data?.current?.component?.component
          ) {
            upsertComponentDragNDrop({
              component: active.data.current.component,
              order: over.data.current.component.order,
              parentId: over.data.current.component.parentId,
            });
          } else if (
            active?.data?.current?.component?.component &&
            over?.data?.current?.component?.layout
          ) {
            upsertComponentDragNDrop({
              component: active.data.current.component,
              parentId: over.data.current.component.id,
              order: over.data.current.component.order + 1,
            });
          } else if (
            active?.data?.current?.component?.layout &&
            over?.data?.current?.component?.layout
          ) {
            upsertLayoutDragNDrop(
              active.data.current.component,
              over.data.current.component,
            );
          }
        }}
        onDragEnd={(event) => {
          const { active, over } = event;

          setActiveComponent({});
          if (
            Boolean(active.data?.current?.component?.fromSidebar) ||
            Boolean(over?.data?.current?.component?.fromSidebar)
          ) {
            handleNewComponentDragEnd(active, over);
          } else if (over && isDragValid(active, over)) {
            handleDragEnd(active, over);
          }
        }}
      >
        <FormBuilderLeftSidebar />
        <MainContainer>
          <FormBuilderComponentEditor />
        </MainContainer>
        <DragOverlay>
          {activeComponent && (
            <FormBuilderDragOverlay component={activeComponent} />
          )}
        </DragOverlay>
      </DndContext>
      <FormBuilderRightSidebar />
    </div>
  );
};
