import { AllowedTimelineTypes, FormTab, TabItem } from "@amenda-types";
import {
  EditProjectMedia,
  ProjectFormsWrapper,
} from "@amenda-components/Projects";
import { FC, ReactNode } from "react";

import { CreateOrEditProjectCostGroups } from "@amenda-components/CostGroup/CreateOrEditProjectCostGroups";
import { EditProjectConstructionDetails } from "./EditProjectConstructionDetails";
import { Timeline } from "@amenda-components/Timeline";
import { isEmpty } from "lodash";
import { staticProjectLabels } from "@amenda-components/Projects/common";

interface Props {
  defaultTab: string;
  projectId?: string;
  forms: FormTab[];
  availableTabs: TabItem[];
}

interface ShowCurrentTab extends Omit<Props, "defaultTab" | "availableTabs"> {
  tab: TabItem;
}

const showCurrentTab = ({ tab, projectId, forms }: ShowCurrentTab) => {
  const tabComponents: Record<string, ReactNode> = {
    [staticProjectLabels.constructionDetails.value]: (
      <EditProjectConstructionDetails projectId={projectId} />
    ),
    [staticProjectLabels.attachments.value]: (
      <div className="amenda-form pt-5">
        <EditProjectMedia projectId={projectId} />
      </div>
    ),
    [staticProjectLabels.costGroups.value]: (
      <CreateOrEditProjectCostGroups projectId={projectId} />
    ),
    [staticProjectLabels.activity.value]: (
      <Timeline type={AllowedTimelineTypes.Project} resourceId={projectId} />
    ),
  };
  forms.forEach((f) => {
    tabComponents[f.id] = <ProjectFormsWrapper form={f} />;
  });

  return tabComponents[tab.value];
};

export const ProjectFormTabs: FC<Props> = ({
  projectId,
  defaultTab,
  forms,
  availableTabs,
}) => {
  const foundIndex = availableTabs.findIndex((tab) => tab.value === defaultTab);
  const defaultTabIndex = foundIndex >= 0 ? foundIndex : 0;

  if (isEmpty(forms)) {
    return null;
  }
  return (
    <div className="pt-4">
      {showCurrentTab({
        projectId,
        forms,
        tab: availableTabs[defaultTabIndex],
      })}
    </div>
  );
};
