import { FC, ReactNode } from "react";
import {
  useAttachmentCreatedSubscription,
  useAttachmentDeletedSubscription,
  useAttachmentUpdatedSubscription,
  useCollectionCreatedSubscription,
  useCollectionDeletedSubscription,
  useCollectionUpdatedSubscription,
  useContactCreatedSubscription,
  useContactDeletedSubscription,
  useContactUpdatedSubscription,
  useFormViewCreatedSubscription,
  useFormViewDeletedSubscription,
  useFormViewUpdatedSubscription,
  useMaterialCreatedSubscription,
  useMaterialDeletedSubscription,
  useMaterialUpdatedSubscription,
  useProjectCreatedSubscription,
  useProjectDeletedSubscription,
  useProjectUpdatedSubscription,
  useTimelineActivityCreatedSubscription,
  useTimelineActivityDeletedSubscription,
  useTimelineActivityUpdatedSubscription,
  useUserCreatedSubscription,
  useUserDeletedSubscription,
  useUserUpdatedSubscription,
} from "@amenda-domains/queries";
import { useTenantStore, useUsersStore } from "@amenda-domains/mutations";

interface Props {
  children: ReactNode;
}

type SubscriptionsProps = Props & {
  ownerId: string;
  tenantId: string;
};

const Subscriptions: FC<SubscriptionsProps> = ({
  ownerId,
  tenantId,
  children,
}) => {
  useUserCreatedSubscription(ownerId, tenantId);
  useUserUpdatedSubscription(ownerId, tenantId);
  useUserDeletedSubscription(ownerId, tenantId);
  useContactCreatedSubscription(ownerId, tenantId);
  useContactUpdatedSubscription(ownerId, tenantId);
  useContactDeletedSubscription(ownerId, tenantId);
  useProjectCreatedSubscription(ownerId, tenantId);
  useProjectUpdatedSubscription(ownerId, tenantId);
  useProjectDeletedSubscription(ownerId, tenantId);
  useMaterialCreatedSubscription(ownerId, tenantId);
  useMaterialUpdatedSubscription(ownerId, tenantId);
  useMaterialDeletedSubscription(ownerId, tenantId);
  useAttachmentCreatedSubscription(ownerId, tenantId);
  useAttachmentUpdatedSubscription(ownerId, tenantId);
  useAttachmentDeletedSubscription(ownerId, tenantId);
  useCollectionCreatedSubscription(ownerId, tenantId);
  useCollectionUpdatedSubscription(ownerId, tenantId);
  useCollectionDeletedSubscription(ownerId, tenantId);
  useFormViewCreatedSubscription(ownerId, tenantId);
  useFormViewDeletedSubscription(ownerId, tenantId);
  useFormViewUpdatedSubscription(ownerId, tenantId);
  useTimelineActivityCreatedSubscription(ownerId, tenantId);
  useTimelineActivityUpdatedSubscription(ownerId, tenantId);
  useTimelineActivityDeletedSubscription(ownerId, tenantId);

  return <>{children}</>;
};

// ensures subscriptions are mounted regardless of route
export const SubscriptionWrapper: FC<Props> = ({ children }) => {
  const ownerId = useUsersStore((state) => state.currentUser?.id);
  const tenantId = useTenantStore((state) => state.primaryTenant?.tenantId);

  if (ownerId && tenantId) {
    return (
      <Subscriptions ownerId={ownerId} tenantId={tenantId}>
        {children}
      </Subscriptions>
    );
  }
  return <>{children}</>;
};
