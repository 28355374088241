import { BoltIcon } from "lucide-react";
import { FC } from "react";
import { FormBuilderLayoutSortDroppable } from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { LayoutProps } from "./common";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export const RootContainerLayout: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  config,
}) => {
  const { t } = useTranslation();
  return (
    <FormBuilderLayoutSortDroppable
      config={config}
      isFormBuilder={isFormBuilder}
      className={clsx("group relative space-y-2", {
        "min-h-full py-10": isFormBuilder,
        "flex w-full items-center justify-center":
          isFormBuilder && isEmpty(config?.components),
      })}
    >
      {children}
      {isFormBuilder && isEmpty(config?.components) && (
        <div className="flex w-full flex-col items-center">
          <BoltIcon className="h-10 w-10 stroke-[2] text-gray-800" />
          <p className="mt-2 text-center text-sm text-gray-800">
            {t("Begin with adding a subsection")}
          </p>
        </div>
      )}
    </FormBuilderLayoutSortDroppable>
  );
};
