import {
  Client,
  Exchange,
  RequestPolicy,
  fetchExchange,
  subscriptionExchange,
} from "urql";

import { abortExchange } from "./abortExchange";
import { asyncHeadersExchange } from "./asyncHeaderExchange";
import { createClient as createWSClient } from "graphql-ws";
import { devtoolsExchange } from "@urql/devtools";
import { errorExchange } from "./errorExchange";
import introspectedSchema from "./introspectionSchema.json";
import { keys } from "./keys";
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { offlineExchange } from "@urql/exchange-graphcache";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { resolvers } from "./resolvers";
import { updates } from "./cacheUpdates";

const subscriptionClient = () => {
  const url = process.env.REACT_APP_BE_SUBSCRIPTION_URL || "";
  const wsClient = createWSClient({
    url,
  });

  return wsClient;
};

const dbVersion = "0.0.3";
export const storage = makeDefaultStorage({
  idbName: `amenda-cache-v[${dbVersion}]`, // The name of the IndexedDB database
  maxAge: 1, // The maximum age of the persisted data in days
});

const cache = offlineExchange({
  keys,
  storage,
  updates,
  resolvers,
  schema: introspectedSchema,
});
const requestPolicy = requestPolicyExchange({
  ttl: 3 * 60 * 1000, // 3 minutes.
  // An optional function that allows you to specify whether an operation should be upgraded.
  shouldUpgrade: (operation) => {
    const upgradeableOperations: RequestPolicy[] = [
      "cache-and-network",
      "network-only",
    ];

    return upgradeableOperations.includes(operation.context.requestPolicy);
  },
}) as Exchange;

export const client = new Client({
  url: process.env.REACT_APP_BE_URL || "",
  exchanges: [
    devtoolsExchange,
    requestPolicy,
    cache,
    asyncHeadersExchange,
    abortExchange,
    errorExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(request) {
        const input = { ...request, query: request.query || "" };

        return {
          subscribe(sink) {
            const unsubscribe = subscriptionClient().subscribe(input, sink);
            return { unsubscribe };
          },
        };
      },
    }),
  ],
});
