import {
  AllowedFilename,
  FormComponentTypes,
  ResizeMode,
} from "@amenda-constants";
import { Control, useController, useWatch } from "react-hook-form";
import { useProjectStore, useTenantStore } from "@amenda-domains/mutations";

import { FC } from "react";
import { SingleRadioButton } from "@amenda-components/FormComponents";
import { Tooltip } from "@amenda-components/App";
import clsx from "clsx";
import { isEqual } from "lodash";
import { isOriginalDownloadFormat } from "./common";
import { useTranslation } from "react-i18next";

interface FormatsFieldProps {
  control: Control<any>;
}

const FormatsField: FC<FormatsFieldProps> = ({ control }) => {
  const { t } = useTranslation();
  const downloadFormats: any[] =
    useTenantStore((state) => state.primaryTenant?.settings?.downloadFormats) ??
    [];
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: "downloadFormat",
  });

  const handleChange = (format: Record<string, any>) => {
    onChange(format);
  };

  return (
    <>
      <div className="grid w-full grid-cols-2 gap-4">
        <div className="amenda-component-label">{t("Name")}</div>
        <div className="amenda-component-label">{t("Dimensions")}</div>
      </div>
      <div className="space-y-1">
        {downloadFormats.map((format) => {
          return (
            <div
              key={format.name}
              className="grid w-full grid-cols-2 items-center gap-4"
            >
              <div className="amenda-component-label">
                <SingleRadioButton
                  id={format.name}
                  label={format.name}
                  checked={isEqual(format, value)}
                  onChange={() => handleChange(format)}
                />
              </div>
              <div className="amenda-component-label">
                {!isOriginalDownloadFormat(format) && (
                  <div className="flex items-center space-x-2 text-center">
                    <span className="leading-none">
                      {format.dimensions.width}
                    </span>
                    <span className="leading-none">x</span>
                    <span className="leading-none">
                      {format.dimensions.height}
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

interface FileNameProps {
  control: Control<any>;
}

const FileName: FC<FileNameProps> = ({ control }) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: "filename",
  });

  const fileNames = [
    { label: "Original", value: AllowedFilename.Original },
    { label: "Amenda Dateiname", value: AllowedFilename.AmendaFilename },
  ];

  return (
    <div className="flex w-full space-x-2">
      {fileNames.map((fileName) => (
        <button
          type="button"
          key={fileName.value}
          className={clsx(
            "border-300 rounded-sm border px-4 py-3 text-gray-900",
            {
              "bg-white": value !== fileName.value,
              "bg-gray-100": value === fileName.value,
            },
          )}
          onClick={() => onChange(fileName.value)}
        >
          <span>{fileName.label}</span>
        </button>
      ))}
    </div>
  );
};

interface MetaDataProps {
  control: Control<any>;
}

export const preStripMetadata = "amendaPreStripMetadata";

const MetaData: FC<MetaDataProps> = ({ control }) => {
  const { t } = useTranslation();
  const {
    field: { value: values, onChange },
  } = useController({
    control,
    name: "metadata",
    defaultValue: [],
  });
  const attachmentFormComponents = useProjectStore(
    (state) => state.attachmentFormComponents,
  );

  const components = (attachmentFormComponents ?? [])
    .filter(
      (c) =>
        c.component &&
        ![FormComponentTypes.Save, FormComponentTypes.Hidden].includes(
          c.component,
        ),
    )
    .map((c) => ({
      value: c.id,
      label: c?.properties?.label,
    }));

  const handleClick = (value: string) => {
    let updatedValues = values.includes(value)
      ? values.filter((v: any) => v !== value)
      : [...values, value];

    onChange(updatedValues);
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-wrap">
        {components.map((component) => (
          <button
            key={component.value}
            type="button"
            className={clsx(
              "border-300 mb-2 mr-2 flex-col rounded-sm border px-6 py-3 text-gray-900",
              {
                "bg-white": !values.includes(component.value),
                "bg-gray-100": values.includes(component.value),
              },
            )}
            onClick={() => handleClick(component.value)}
          >
            <div>{component.label}</div>
          </button>
        ))}
      </div>
      <div className="relative flex items-center py-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-1 flex-shrink rounded-sm bg-gray-100 px-2 text-sm text-gray-900">
          {t("Advanced")}
        </span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <div className="flex w-full justify-center">
        <button
          type="button"
          className={clsx(
            "border-300 w-64 flex-col rounded-sm border px-4 py-3 text-gray-900",
            {
              "bg-white": !values.includes(preStripMetadata),
              "bg-gray-100": values.includes(preStripMetadata),
            },
          )}
          onClick={() => handleClick(preStripMetadata)}
        >
          <div>{t("Pre-Strip Metadata from files")}</div>
          <div className="text-xs">
            {t(
              "Removes metadata from each file before adding any new metadata selected above",
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

interface ResizeImagesProps {
  control: Control<any>;
}

const ResizeImages: FC<ResizeImagesProps> = ({ control }) => {
  const { t } = useTranslation();
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: "fit",
    defaultValue: ResizeMode.Cover,
  });
  const downloadFormat = useWatch({
    control,
    name: "downloadFormat",
  });

  const objectFit = [
    {
      value: ResizeMode.Contain,
      label: "Contain",
      tooltip:
        "Preserving aspect ratio, contain within both provided dimensions using 'letterboxing' where necessary. A white background will be added to fill the remaining space",
      png: "/images/image_contain.png",
    },
    {
      value: ResizeMode.Cover,
      label: "Cover",
      tooltip:
        "Preserving aspect ratio, attempt to ensure the image covers both provided dimensions by cropping/clipping to fit",
      png: "/images/image_cover.png",
    },
    {
      value: ResizeMode.Fill,
      label: "Fill",
      tooltip:
        "Ignore the aspect ratio of the input and stretch to both provided dimensions",
      png: "/images/image_fill.png",
    },
    {
      value: ResizeMode.Inside,
      label: "Inside",
      tooltip:
        "Preserving aspect ratio, resize the image to be as large as possible while ensuring its dimensions are less than or equal to both those specified",
      png: "/images/image_inside.png",
    },
    {
      value: ResizeMode.Outside,
      label: "Outside",
      tooltip:
        "Preserving aspect ratio, resize the image to be as small as possible while ensuring its dimensions are greater than or equal to both those specified",
      png: "/images/image_outside.png",
    },
  ];
  const hasOriginal =
    downloadFormat && isOriginalDownloadFormat(downloadFormat);
  const handleClick = (value: string) => {
    onChange(value);
  };

  return (
    <div className="relative flex w-full flex-wrap">
      <div className="relative mb-4 w-full">
        {hasOriginal && (
          <>
            <div className="absolute h-full w-full bg-white/50 backdrop-sepia-0" />
            <span className="z-10 text-xs text-gray-900">
              {t(
                "You are downloading the original files, in this case no fit parameter can be applied",
              )}
            </span>
          </>
        )}
      </div>
      {objectFit.map((fit) => (
        <Tooltip
          key={fit.value}
          id={fit.value}
          message={fit.tooltip}
          position="top"
          positionStrategy="fixed"
          style={{
            maxWidth: "300px",
          }}
        >
          <button
            type="button"
            className={clsx(
              "border-300 mb-2 mr-2 w-24 flex-col items-center rounded-sm border py-4 text-gray-900",
              {
                "cursor-not-allowed": hasOriginal,
                "bg-white": value !== fit.value,
                "bg-gray-100": value === fit.value,
              },
            )}
            disabled={hasOriginal}
            onClick={() => handleClick(fit.value)}
          >
            <div className="flex w-full items-center justify-center">
              <img src={fit.png} alt={fit.label} className="h-12 w-12" />
            </div>
            <div>{t(fit.label)}</div>
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

interface ShowDownloadAttachmentFormProps {
  control: Control<any>;
  currentStep: number;
}

export const showDownloadAttachmentForm = ({
  control,
  currentStep,
}: ShowDownloadAttachmentFormProps) => {
  switch (currentStep) {
    case 1:
      return <FormatsField control={control} />;
    case 2:
      return <ResizeImages control={control} />;
    case 3:
      return <FileName control={control} />;
    case 4:
      return <MetaData control={control} />;
    default:
      return <div />;
  }
};
