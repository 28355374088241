import {
  AllowedContactType,
  AvailablePermissions,
  ComponentValidationType,
  Conditions,
  FormBuilderComponent,
  FormTab,
  GeneralPermissionTypes,
  NestedPageComponentProps,
  Option,
  PageComponentProps,
  SimilaritySearchConfigColumns,
  TabItemLink,
  availableLayouts,
  permissionHeaders,
} from "@amenda-types";
import {
  AvailableForms,
  CostGroupComponentStaticId,
  CostGroupPermissionPath,
  FormCategories,
  FormComponentTypes,
  GeneralAccessTypes,
  PermissionComponentKey,
  RouteBasedPermissionKey,
} from "@amenda-constants";
import {
  customMerge,
  generateReadableId,
  sanitizeData,
  sanitizeNestedData,
} from "@amenda-utils";
import { get, isDate, isEmpty, isNil, snakeCase } from "lodash";

import { ResourceSharingPermissionTypes } from "@amenda-components/Shared/common";
import set from "lodash/set";

export const getGeneralPermissionsName = (
  tab: GeneralPermissionKeys,
  value: string,
) => {
  return `permissions.${RouteBasedPermissionKey}.${tab}.${value}`;
};

export const getCostGroupPermissionsName = () => {
  return `permissions.${CostGroupPermissionPath}`;
};

export const getFromGeneralPermissions = (
  permissions: Record<string, any>,
  path: GeneralPermissionKeys,
) => {
  return get(permissions, `${RouteBasedPermissionKey}.${path}`, {});
};

export const getPermissionName = (component: PageComponentProps) => {
  return `permissions.${component.formId}.${component.id}`;
};

export enum GeneralPermissionKeys {
  Media = "media",
  Projects = "projects",
  UnitPrice = "unitPrice",
  Contacts = "contacts",
  Admin = "administration",
  ConstructionDetails = "constructionDetails",
}

export const rolesCreationTabs: TabItemLink[] = [
  {
    label: "Projects",
    path: GeneralPermissionKeys.Projects,
  },
  {
    label: "Media",
    path: GeneralPermissionKeys.Media,
  },
  {
    label: "Unit price",
    path: GeneralPermissionKeys.UnitPrice,
  },
  {
    label: "Contacts",
    path: GeneralPermissionKeys.Contacts,
  },
  {
    label: "Construction Details",
    path: GeneralPermissionKeys.ConstructionDetails,
  },
  {
    label: "Administration",
    path: GeneralPermissionKeys.Admin,
  },
];

export enum UserStatus {
  Active = "active",
  Disabled = "disabled",
}

export const getUserStatuses = (user: any): Option[] => [
  { value: UserStatus.Active, label: "Active" },
  {
    value: UserStatus.Disabled,
    label: "Disabled",
    unavailable: Boolean(user?.isTenantAdmin),
  },
];

export const processUserStatus = (status?: string) => {
  if (status) {
    return {
      isActive: status.includes(UserStatus.Active),
    };
  }
  return {};
};

export const getUserStatus = (user?: Record<string, boolean>) => {
  const status = Boolean(user?.isActive)
    ? UserStatus.Active
    : UserStatus.Disabled;

  return status;
};

export const getProjectComponents = (
  components: NestedPageComponentProps[],
) => {
  return [
    ...components,
    {
      component: CostGroupComponentStaticId,
      properties: {
        label: "Kosten",
      },
    },
  ];
};

const isConditionComponent = (componentType: FormComponentTypes) => {
  return [
    FormComponentTypes.Select,
    FormComponentTypes.MultiSelect,
    FormComponentTypes.RadioButton,
    FormComponentTypes.Checkbox,
    FormComponentTypes.Badges,
    FormComponentTypes.Switch,
    FormComponentTypes.Input,
    FormComponentTypes.DatePicker,
  ].includes(componentType);
};

export const getConditionComponents = (
  components: any[] = [],
  component?: any,
) => {
  let conditionComponents = components.filter(
    (c) => Boolean(c.component) && isConditionComponent(c.component),
  );
  if (component) {
    conditionComponents = conditionComponents.filter(
      (c) => c.id !== component?.id,
    );
  }
  return conditionComponents;
};

export enum PermissionComponents {
  GeneralAccess = "generalAccess",
}

export const generalPermissions = {
  [GeneralPermissionKeys.UnitPrice]: [
    {
      label: "Create",
      value: GeneralPermissionTypes.Create,
      components: [
        {
          defaultValue: GeneralAccessTypes.Everyone,
          label: "Default access to new unit prices",
          id: PermissionComponents.GeneralAccess,
        },
      ],
    },
    {
      label: "Delete",
      value: GeneralPermissionTypes.Delete,
    },
    {
      label: "Edit",
      value: GeneralPermissionTypes.Edit,
    },
    {
      label: "Activity",
      value: GeneralPermissionTypes.Activity,
    },
    {
      label: "Archive Unit Prices",
      value: GeneralPermissionTypes.Archive,
    },
    {
      label: "Share",
      value: GeneralPermissionTypes.Share,
    },
  ],
  [GeneralPermissionKeys.Contacts]: [
    {
      label: "Create",
      value: GeneralPermissionTypes.Create,
      components: [
        {
          defaultValue: GeneralAccessTypes.Everyone,
          label: "Default access to new contacts",
          id: PermissionComponents.GeneralAccess,
        },
      ],
    },
    {
      label: "Delete",
      value: GeneralPermissionTypes.Delete,
    },
    {
      label: "Edit",
      value: GeneralPermissionTypes.Edit,
    },
    {
      label: "Activity",
      value: GeneralPermissionTypes.Activity,
    },
    {
      label: "Share",
      value: GeneralPermissionTypes.Share,
    },
  ],
  [GeneralPermissionKeys.Projects]: [
    {
      label: "Create",
      value: GeneralPermissionTypes.Create,
      components: [
        {
          defaultValue: GeneralAccessTypes.Everyone,
          label: "Default access to new projects",
          id: PermissionComponents.GeneralAccess,
        },
      ],
    },
    {
      label: "Delete",
      value: GeneralPermissionTypes.Delete,
    },
    {
      label: "Edit",
      value: GeneralPermissionTypes.Edit,
    },
    {
      label: "Share",
      value: GeneralPermissionTypes.Share,
    },
    {
      label: "Activity",
      value: GeneralPermissionTypes.Activity,
    },
  ],
  [GeneralPermissionKeys.Media]: [
    {
      label: "Upload",
      value: GeneralPermissionTypes.Create,
      components: [
        {
          defaultValue: GeneralAccessTypes.Everyone,
          label: "Default access to new attachments",
          id: PermissionComponents.GeneralAccess,
        },
      ],
    },
    {
      label: "Delete",
      value: GeneralPermissionTypes.Delete,
    },
    {
      label: "Edit",
      value: GeneralPermissionTypes.Edit,
    },
    {
      label: "Move",
      value: GeneralPermissionTypes.Move,
    },
    {
      label: "Download",
      value: GeneralPermissionTypes.Download,
    },
    {
      label: "Activity",
      value: GeneralPermissionTypes.Activity,
    },
    {
      label: "Face detection labelling",
      value: GeneralPermissionTypes.CanDetectFaces,
    },
    {
      label: "Share",
      value: GeneralPermissionTypes.Share,
    },
  ],
  [GeneralPermissionKeys.ConstructionDetails]: [
    {
      label: "Upload",
      value: GeneralPermissionTypes.Create,
      components: [
        {
          defaultValue: GeneralAccessTypes.Everyone,
          label: "Default access to new details",
          id: PermissionComponents.GeneralAccess,
        },
      ],
    },
    {
      label: "Delete",
      value: GeneralPermissionTypes.Delete,
    },
    {
      label: "Edit",
      value: GeneralPermissionTypes.Edit,
    },
    {
      label: "Download",
      value: GeneralPermissionTypes.Download,
    },
    {
      label: "Share",
      value: GeneralPermissionTypes.Share,
    },
  ],
  [GeneralPermissionKeys.Admin]: [
    {
      label: "Full access",
      value: GeneralPermissionTypes.FullAccess,
    },
  ],
};

const setGeneralPermissions = (defaultValues: any) => {
  Object.keys(generalPermissions).forEach((key) => {
    const tab = key as GeneralPermissionKeys;
    const values = generalPermissions[tab];

    values.forEach(({ value }) => {
      set(defaultValues, getGeneralPermissionsName(tab, value), false);
    });
  });
};

const setSharingPermissions = (defaultValues: any) => {
  set(
    defaultValues,
    getGeneralPermissionsName(
      GeneralPermissionKeys.Projects,
      PermissionComponentKey,
    ),
    GeneralAccessTypes.Limited,
  );
};

const setDefaultComponentPermissions = (
  formsByType: Record<string, FormTab[]>,
  defaultValues: any,
) => {
  const components: any[] = [];
  const availableForms: FormTab[] = [];

  set(
    defaultValues,
    getCostGroupPermissionsName(),
    AvailablePermissions.Restricted,
  );

  Object.keys(formsByType)
    .filter(
      (key) =>
        ![
          AvailableForms.CostGroupsCost,
          AvailableForms.CostGroupsQuantity,
        ].includes(key as any),
    )
    .forEach((key) => availableForms.push(...formsByType[key]));

  availableForms.forEach((form) => {
    components.push(...form.components);
  });

  components.forEach((component) => {
    set(
      defaultValues,
      getPermissionName(component),
      AvailablePermissions.Restricted,
    );
  });
};

export const getPermissionsDefaultValues = (
  formsByType: Record<string, FormTab[]> = {},
) => {
  const defaultValues: {
    permissions: Record<string, boolean>;
  } = { permissions: {} };

  setDefaultComponentPermissions(formsByType, defaultValues);
  setGeneralPermissions(defaultValues);
  setSharingPermissions(defaultValues);

  return defaultValues;
};

export const permissionColumns = permissionHeaders.map(({ label }) => ({
  label,
  id: label,
}));

export const getRegionalFactorYears = () => {
  const oldestYear = 2005;
  const currentYear = new Date().getFullYear();

  // generate the years between oldestYear and currentYear
  const years = Array.from(
    { length: currentYear - oldestYear + 1 },
    (_, i) => oldestYear + i,
  );
  return years.reverse();
};

export const getSimilaritySearchConfigName = (
  component: PageComponentProps,
  suffix: SimilaritySearchConfigColumns,
) => {
  return `${component.formId}.${component.id}.${suffix}`;
};

const updateGeneralPermissions = (
  generalPermissionTypes: Record<GeneralPermissionTypes, boolean>,
  denyPermissions?: GeneralPermissionTypes[],
) => {
  const permissions: Record<GeneralPermissionTypes, boolean> = {
    ...generalPermissionTypes,
  };

  Object.keys(generalPermissionTypes).forEach((key) => {
    if (
      !isEmpty(denyPermissions) &&
      denyPermissions?.includes(key as GeneralPermissionTypes)
    ) {
      permissions[key as GeneralPermissionTypes] = false;
    }
  });

  return permissions;
};

export const processGeneralAccessToResource = ({
  ownerId,
  shareType,
  shareTo,
  currentUserId,
  generalPermissions,
}: {
  ownerId: string;
  currentUserId: string;
  shareTo?: any[];
  shareType: GeneralAccessTypes;
  generalPermissions: Record<GeneralPermissionTypes, boolean>;
}) => {
  if (shareType === GeneralAccessTypes.Everyone) {
    return generalPermissions;
  }
  if (ownerId === currentUserId) {
    return generalPermissions;
  }

  const foundContact = shareTo?.find(({ id }) => id === currentUserId);
  if (foundContact?.role === ResourceSharingPermissionTypes.Admin) {
    return updateGeneralPermissions(generalPermissions, [
      GeneralPermissionTypes.Delete,
    ]);
  }
  if (foundContact?.role === ResourceSharingPermissionTypes.Edit) {
    return updateGeneralPermissions(generalPermissions, [
      GeneralPermissionTypes.Delete,
      GeneralPermissionTypes.Share,
    ]);
  }
  return updateGeneralPermissions(generalPermissions, [
    GeneralPermissionTypes.Delete,
    GeneralPermissionTypes.Share,
    GeneralPermissionTypes.Edit,
    GeneralPermissionTypes.Move,
  ]);
};

export const getCategorySidebar = (withContactForms = false) => {
  const categories = [
    {
      label: "Projects",
      value: FormCategories.Projects,
    },
    {
      label: "Media",
      value: FormCategories.Media,
    },
    {
      label: "Unit price",
      value: FormCategories.UnitPrice,
    },
    {
      label: "Construction Details",
      value: FormCategories.ConstructionDetails,
    },
    {
      label: "Cost groups",
      value: FormCategories.CostGroups,
    },
  ];

  if (withContactForms) {
    categories.push(
      ...[
        {
          label: "Office",
          value: FormCategories.OfficeContacts,
        },
        {
          label: "Persons",
          value: FormCategories.PersonsContacts,
        },
        {
          label: "Company",
          value: FormCategories.CompanyContacts,
        },
        {
          label: "Other",
          value: FormCategories.Contacts,
        },
      ],
    );
  }

  return categories;
};

export const transformFormBuilderComponents = (
  component?: PageComponentProps,
) => {
  const values: Record<string, any> = {
    label: component?.properties?.label ?? "",
    description: component?.properties?.description ?? "",
    placeholder: component?.properties?.placeholder,
    isSearchable: component?.isSearchable,
    isGroupable: component?.isGroupable,
    isBulkEditable: component?.isBulkEditable,
    required: Array.isArray(component?.validation?.properties)
      ? undefined
      : Boolean(component?.validation?.properties?.required),
    url: Array.isArray(component?.validation?.properties)
      ? undefined
      : Boolean(component?.validation?.properties?.url),
    integer: Array.isArray(component?.validation?.properties)
      ? undefined
      : Boolean(component?.validation?.properties?.integer),
    positive: Array.isArray(component?.validation?.properties)
      ? undefined
      : Boolean(component?.validation?.properties?.positive),
    negative: Array.isArray(component?.validation?.properties)
      ? undefined
      : Boolean(component?.validation?.properties?.negative),
    max: Array.isArray(component?.validation?.properties)
      ? undefined
      : component?.validation?.properties?.max,
    min: Array.isArray(component?.validation?.properties)
      ? undefined
      : component?.validation?.properties?.min,
    layout: component?.layout,
    options: component?.properties?.options,
    roles: component?.properties?.roles,
    contactType: component?.properties?.contactType,
    isMulti: component?.properties?.isMulti,
    isNested: component?.properties?.isNested,
    canSelectParents: component?.properties?.canSelectParents,
    horizontal:
      component?.component === FormComponentTypes.RadioButton
        ? Boolean(component?.properties?.horizontal)
        : undefined,
    showLabel:
      component?.component === FormComponentTypes.Switch
        ? Boolean(component?.properties?.showLabel)
        : undefined,
    labelRight: component?.properties?.labelRight,
    labelTop: component?.properties?.labelTop,
    rows: component?.properties?.rows,
    maxLength: component?.properties?.maxLength,
    startAddOnText: component?.properties?.startAddOnText,
    endAddOnText: component?.properties?.endAddOnText,
    type:
      component?.component === FormComponentTypes.Input
        ? (component?.properties?.type ?? "text")
        : component?.properties?.type,
    referenceQuantity: component?.properties?.referenceQuantity,
    className: component?.properties?.className,
    hasHeaderProperties: !isEmpty(component?.headerProperties),
    headerLayout: component?.headerProperties?.layout,
    titleClassName: component?.headerProperties?.properties?.titleClassName,
    descriptionClassName:
      component?.headerProperties?.properties?.descriptionClassName,
    withTitle: component?.headerProperties?.properties?.withTitle,
    displayConditions: component?.display,
    hasConditionalValidations: Array.isArray(component?.validation?.properties),
    conditionalValidations: Array.isArray(component?.validation?.properties)
      ? component?.validation?.properties
      : [],
  };

  return sanitizeData(values);
};

const getValidationType = (
  componentType?: FormComponentTypes,
  values?: Record<string, any>,
) => {
  switch (componentType) {
    case FormComponentTypes.Input:
      return values?.type === "number"
        ? ComponentValidationType.Number
        : values?.type === "email"
          ? ComponentValidationType.Email
          : ComponentValidationType.String;
    case FormComponentTypes.DatePickerRange:
      return ComponentValidationType.DateRange;
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.Badges:
    case FormComponentTypes.Keyword:
    case FormComponentTypes.Checkbox:
    case FormComponentTypes.LabelledInput:
    case FormComponentTypes.LabelledContactInputs:
      return ComponentValidationType.Array;
    case FormComponentTypes.Switch:
      return ComponentValidationType.Boolean;
    case FormComponentTypes.AddressSearch:
    case FormComponentTypes.SearchAndSelectProjects:
    case FormComponentTypes.SearchAndSelect:
    case FormComponentTypes.MaterialCostInput:
    case FormComponentTypes.MaterialPercentageValues:
      return ComponentValidationType.Any;
    case FormComponentTypes.RegionalSelect:
    case FormComponentTypes.Textarea:
    case FormComponentTypes.DatePicker:
    case FormComponentTypes.RadioButton:
      return ComponentValidationType.String;
    case FormComponentTypes.Select:
      return Boolean(values?.isNested)
        ? ComponentValidationType.Any
        : ComponentValidationType.String;
    case FormComponentTypes.SelectCostGroups:
      return Boolean(values?.isMulti)
        ? ComponentValidationType.Array
        : ComponentValidationType.String;
    default:
      return undefined;
  }
};

export const getValidationTypeFromComponent = (
  values: Record<string, any>,
  component?: FormBuilderComponent,
) => {
  if (component?.component === FormComponentTypes.SelectCostGroups) {
    return getValidationType(component.component as any, values);
  }
  if (isEmpty(values?.type) && !isEmpty(component?.validation?.type)) {
    return component?.validation?.type;
  }
  if (!component?.component) {
    return undefined;
  }
  return getValidationType(component.component as any, values);
};

export const transformFormBuilderComponent = ({
  values,
  isNewComponent = false,
  component = {},
}: {
  isNewComponent?: boolean;
  values: Record<string, any>;
  component?: Record<string, any>;
}) => {
  const {
    options,
    placeholder,
    label,
    description,
    contactType,
    isMulti,
    isNested,
    canSelectParents,
    horizontal,
    rows,
    maxLength,
    startAddOnText,
    endAddOnText,
    type,
    referenceQuantity,
    integer,
    negative,
    positive,
    required,
    url,
    min,
    max,
    labelRight,
    labelTop,
    roles,
    className,
    headerLayout,
    titleClassName,
    descriptionClassName,
    hasHeaderProperties,
    withTitle,
    displayConditions,
    conditionalValidations,
    hasConditionalValidations,
    ...rest
  } = values;
  const componentId = component?.componentId
    ? component.componentId
    : generateReadableId(label);

  const updatedComponent: any = {
    ...component,
    componentId,
    ...rest,
  };

  if (!isEmpty(displayConditions)) {
    updatedComponent.display = displayConditions;
  } else {
    delete updatedComponent["display"];
  }

  if (hasHeaderProperties) {
    updatedComponent.headerProperties = customMerge(
      updatedComponent?.headerProperties || {},
      {
        layout: headerLayout,
        properties: {
          titleClassName,
          descriptionClassName,
          withTitle,
        },
      },
    );
  } else {
    updatedComponent.headerProperties = {};
  }

  updatedComponent.properties = customMerge(
    updatedComponent?.properties || {},
    {
      className,
      roles,
      options,
      placeholder,
      label,
      description,
      contactType,
      isMulti,
      isNested,
      canSelectParents,
      horizontal,
      rows,
      startAddOnText,
      endAddOnText,
      type,
      labelRight,
      labelTop,
      referenceQuantity,
      maxLength:
        component?.component === FormComponentTypes.Input ? max : undefined,
    },
  );

  if (isNewComponent) {
    updatedComponent.properties = customMerge(updatedComponent.properties, {
      contactType: [
        FormComponentTypes.SearchAndSelect,
        FormComponentTypes.LabelledContactInputs,
      ].includes(updatedComponent.component)
        ? AllowedContactType.person
        : contactType,
    });
  }
  if (hasConditionalValidations) {
    updatedComponent.validation = {
      ...(updatedComponent.validation || {}),
      properties: conditionalValidations,
    };
  } else {
    updatedComponent.validation = customMerge(
      updatedComponent?.validation ?? {},
      {
        componentId,
        type: getValidationTypeFromComponent(values, updatedComponent),
        properties: {
          label,
          max,
          min,
          url,
          integer,
          positive,
          negative,
          required,
        },
      },
    );
  }

  return sanitizeNestedData(updatedComponent, true);
};

export const getComponentIdsToRemove = (component?: any) => {
  const componentIds: string[] = [];
  let stack: any[] = [];

  if (component) {
    stack.push(component);
  }

  while (stack.length > 0) {
    const component = stack.pop();

    if (component?.id) {
      componentIds.push(component.id);
    }
    if (component?.components) {
      stack.push(...component.components);
    }
  }

  return componentIds;
};

export const getLayoutOptions = (layout?: any) => {
  return [
    {
      value: availableLayouts.fullColumn,
      label: "1/1 Spalte",
    },
    {
      value: availableLayouts.halfColumn,
      label: "1/2 Spalte",
    },
    {
      value: availableLayouts.oneThirdColumn,
      label: "1/3 Spalte",
    },
    {
      value: availableLayouts.twoThirdColumn,
      label: "2/3 Spalte",
    },
  ];
};

export const getComponentOptions = () => {
  return [
    {
      value: FormComponentTypes.Input,
      label: "Text Field",
    },
    {
      value: FormComponentTypes.Checkbox,
      label: "Checkbox",
    },
    {
      value: FormComponentTypes.Select,
      label: "Single Select",
    },
    {
      value: FormComponentTypes.RadioButton,
      label: "Radio Button",
    },
    {
      value: FormComponentTypes.Switch,
      label: "Switch",
    },
    {
      value: FormComponentTypes.Badges,
      label: "Badges",
    },
    {
      value: FormComponentTypes.DatePicker,
      label: "Date Picker",
    },
    {
      value: FormComponentTypes.MultiSelect,
      label: "Multi Select",
    },
    {
      value: FormComponentTypes.Textarea,
      label: "Textarea",
    },
    {
      value: FormComponentTypes.DatePickerRange,
      label: "Date Picker Range",
    },
    {
      value: FormComponentTypes.Keyword,
      label: "Keyword",
    },
    {
      value: FormComponentTypes.Hidden,
      label: "Hidden",
    },
    {
      value: FormComponentTypes.AddressSearch,
      label: "Address Search",
    },
    {
      value: FormComponentTypes.RegionalSelect,
      label: "Regions Search",
    },
    {
      value: FormComponentTypes.LabelledInput,
      label: "Labelled Input",
    },
    {
      value: FormComponentTypes.SearchAndSelectProjects,
      label: "Projects Search",
    },
    {
      value: FormComponentTypes.SearchAndSelect,
      label: "Contacts Search",
    },
    {
      value: FormComponentTypes.MaterialCostInput,
      label: "Material Cost",
    },
    {
      value: FormComponentTypes.MaterialPercentageValues,
      label: "Material Percentage Values",
    },
    {
      value: FormComponentTypes.LabelledContactInputs,
      label: "Contacts Labelled Input",
    },
    {
      value: FormComponentTypes.Title,
      label: "Title",
    },
    {
      value: FormComponentTypes.ColoredSelect,
      label: "Select with Colored Badges",
    },
  ];
};

const getCellDataType = (dataRows: any[], index: number) => {
  let type;
  let i = 0;

  while (i < dataRows.length) {
    const rows = dataRows[i];
    const value = rows[index];

    type = typeof value;

    if (!isNil(value) && type !== undefined) {
      if (type === "object") {
        type = Array.isArray(value) ? "array" : isDate(value) ? "date" : type;
      }
      break;
    }
    i += 1;
  }

  return type;
};

export const getConfigFromExcel = (dataRows: any[][]) => {
  const slicedData = dataRows.slice(0, 10);
  const columns: Record<string, any> = {};

  slicedData[0].forEach((id, i) => {
    if (!columns[id]) {
      columns[id] = {
        id,
        label: id,
        rowIndex: i,
        type: getCellDataType(dataRows.slice(1, 11), i),
      };
    } else {
      columns.isArray = true;
    }
  });

  return columns;
};

export const getPossibleComponentTypes = (config: Record<string, any>) => {
  let updateConfig = { ...config };

  Object.keys(updateConfig).forEach((key) => {
    const column = updateConfig[key];
    if (column.isArray || column.type === "array") {
      const possibleComponents = getComponentOptions().filter((o) =>
        [
          FormComponentTypes.MultiSelect,
          FormComponentTypes.Keyword,
          FormComponentTypes.Badges,
          FormComponentTypes.Select,
          FormComponentTypes.RadioButton,
          FormComponentTypes.Checkbox,
        ].includes(o.value),
      );

      updateConfig[key] = {
        ...column,
        isRequired: false,
        type: "array",
        possibleComponents,
        selectedComponent: possibleComponents[0].value,
      };
    } else if (column.type === "number") {
      const possibleComponents = getComponentOptions().filter((o) =>
        [FormComponentTypes.Input].includes(o.value),
      );

      updateConfig[key] = {
        ...column,
        isRequired: false,
        possibleComponents,
        selectedComponent: possibleComponents[0].value,
      };
    } else if (column.type === "date") {
      const possibleComponents = getComponentOptions().filter((o) =>
        [
          FormComponentTypes.DatePicker,
          FormComponentTypes.DatePickerRange,
        ].includes(o.value),
      );

      updateConfig[key] = {
        ...column,
        isRequired: false,
        possibleComponents,
        selectedComponent: possibleComponents[0].value,
      };
    } else if (column.type === "boolean") {
      const possibleComponents = getComponentOptions().filter((o) =>
        [FormComponentTypes.Switch].includes(o.value),
      );

      updateConfig[key] = {
        ...column,
        isRequired: false,
        possibleComponents,
        selectedComponent: possibleComponents[0].value,
      };
    } else if (column.type === "string") {
      const possibleComponents = getComponentOptions().filter((o) =>
        [
          FormComponentTypes.Input,
          FormComponentTypes.Textarea,
          FormComponentTypes.Select,
          FormComponentTypes.RadioButton,
          FormComponentTypes.AddressSearch,
          FormComponentTypes.RegionalSelect,
        ].includes(o.value),
      );

      updateConfig[key] = {
        ...column,
        isRequired: false,
        possibleComponents,
        selectedComponent: possibleComponents[0].value,
      };
    } else {
      const possibleComponents = getComponentOptions();

      updateConfig[key] = {
        ...column,
        isRequired: false,
        possibleComponents,
        selectedComponent: possibleComponents[0].value,
      };
    }
  });

  return updateConfig;
};

const getOptionsFromData = (
  componentType: any,
  index: number,
  dataRows: any[],
) => {
  const selectedRows = dataRows.slice(0, 20);

  if (
    [
      FormComponentTypes.MultiSelect,
      FormComponentTypes.Keyword,
      FormComponentTypes.Badges,
      FormComponentTypes.Select,
      FormComponentTypes.RadioButton,
      FormComponentTypes.Checkbox,
    ].includes(componentType)
  ) {
    let labels: string[] = [];

    selectedRows.forEach((row) => {
      const value = row[index];
      if (value && !labels.includes(value)) {
        labels.push(value);
      }
    });

    const options = labels.map((label) => ({
      value: snakeCase(label),
      label,
    }));

    return { options };
  }
  return {};
};

export const getComponentsFromConfig = ({
  componentsConfig,
  dataRows,
  category,
  formName,
}: {
  componentsConfig: Record<string, any>;
  dataRows: any[];
  category?: FormCategories;
  formName?: string;
}) => {
  const initialComponentId = generateReadableId("Initial Component");
  const layout =
    category === FormCategories.Projects
      ? availableLayouts.twoThirdsColumnContainer
      : availableLayouts.fullColumnContainer;
  const label =
    category === FormCategories.Projects
      ? "Two columns grid"
      : "Full column grid";
  const parentId = generateReadableId(label);

  const components: any[] = [
    {
      componentId: initialComponentId,
      parentId: null,
      order: 0,
      layout: availableLayouts.rootContainer,
      properties: { label: formName ?? "Initial Info" },
    },
    {
      componentId: parentId,
      parentId: initialComponentId,
      order: 0,
      layout,
      properties: { label },
    },
  ];

  Object.keys(componentsConfig).forEach((key, i) => {
    const component = componentsConfig[key];
    const componentId = generateReadableId(component?.properties?.label);
    const formComponent: any = {
      componentId,
      parentId,
      order: i,
      layout: availableLayouts.fullColumn,
      properties: {
        label: component.label,
        ...getOptionsFromData(
          component.selectedComponent,
          component.rowIndex,
          dataRows,
        ),
      },
      validation: {
        componentId,
        type: component.type,
        properties: { label: component.label, required: component.isRequired },
      },
      component: component.selectedComponent,
    };

    if (
      component.selectedComponent === FormComponentTypes.Input &&
      component.type === "number"
    ) {
      formComponent.properties.type = "number";
    }
    components.push(formComponent);
  });

  return components;
};

export enum FormBuilderComponentTabs {
  Details = "details",
  Options = "options",
  Validation = "validation",
  Settings = "componentLayoutSettings",
  Components = "componentLayoutComponents",
  Header = "componentHeaderProperties",
  Display = "displayConditions",
}

export const getFormBuilderComponentTabs = ({
  component,
  parentComponent,
  isCreatingComponent = false,
}: {
  component?: Record<string, any>;
  parentComponent?: PageComponentProps;
  isCreatingComponent?: boolean;
}) => {
  return [
    { label: "Details", value: FormBuilderComponentTabs.Details },
    {
      label: "Options",
      value: FormBuilderComponentTabs.Options,
      disabled:
        !Boolean(component?.component) ||
        ![
          FormComponentTypes.Keyword,
          FormComponentTypes.LabelledContactInputs,
          FormComponentTypes.Select,
          FormComponentTypes.Badges,
          FormComponentTypes.MultiSelect,
          FormComponentTypes.SearchAndSelect,
          FormComponentTypes.Checkbox,
          FormComponentTypes.RadioButton,
          FormComponentTypes.LabelledInput,
        ].includes(component?.component),
    },
    {
      label: "Validation",
      value: FormBuilderComponentTabs.Validation,
      disabled: !Boolean(component?.component),
    },
    {
      label: "Readonly header settings",
      value: FormBuilderComponentTabs.Header,
      disabled:
        !Boolean(component?.component) ||
        [
          FormComponentTypes.CalculatorTextField,
          FormComponentTypes.Image,
          FormComponentTypes.Title,
          FormComponentTypes.LabelledContactInputs,
          FormComponentTypes.LabelledInput,
          FormComponentTypes.SelectCostGroups,
          FormComponentTypes.SearchAndSelectProjects,
          FormComponentTypes.MaterialPhoto,
          FormComponentTypes.Save,
          FormComponentTypes.MaterialCostInput,
          FormComponentTypes.MaterialPercentageValues,
          FormComponentTypes.SearchAndSelect,
        ].includes(component?.component),
    },
    {
      label: "Components",
      value: FormBuilderComponentTabs.Components,
      disabled:
        !isCreatingComponent ||
        parentComponent?.layout !== availableLayouts.rootContainer,
    },
    {
      label: "Settings",
      value: FormBuilderComponentTabs.Settings,
      disabled:
        isCreatingComponent ||
        ![
          availableLayouts.twoThirdsColumnContainer,
          availableLayouts.fullColumnContainer,
          availableLayouts.nestedFormContainer,
        ].includes(component?.layout),
    },
    {
      label: "Display conditions",
      value: FormBuilderComponentTabs.Display,
      disabled: !Boolean(component?.component),
    },
  ];
};

const getClonedComponent = ({
  component,
  componentId,
  parentId: parentComponentId,
}: {
  component: PageComponentProps;
  componentId: string;
  parentId: string;
}) => {
  const { id, formId, parentId, components, ...rest } = component;

  return {
    ...rest,
    componentId,
    parentId: parentComponentId,
  };
};

export const transformFormBuilderClonedComponents = (
  parentComponent: any,
  components: any[],
) => {
  const transformedComponents: any[] = [];
  const parentId = generateReadableId(parentComponent?.properties?.label);

  transformedComponents.push(
    getClonedComponent({
      component: parentComponent,
      componentId: parentId,
      parentId: parentComponent?.parentId,
    }),
  );

  components.forEach((c) => {
    const componentId = generateReadableId(c?.properties?.label);

    transformedComponents.push(
      getClonedComponent({
        parentId,
        componentId,
        component: c,
      }),
    );
  });

  return transformedComponents;
};

export const generateLayoutComponents = (
  componentTypes: FormComponentTypes[],
  parentId: string,
) => {
  return componentTypes.map((type, i) => {
    const label = `Component ${i + 1}`;
    const componentId = generateReadableId(label);
    const properties: any = {
      label,
    };

    if (
      [
        FormComponentTypes.SearchAndSelect,
        FormComponentTypes.LabelledContactInputs,
      ].includes(type)
    ) {
      properties.contactType = AllowedContactType.person;
    }
    if (
      [
        FormComponentTypes.Select,
        FormComponentTypes.MultiSelect,
        FormComponentTypes.RadioButton,
        FormComponentTypes.Checkbox,
        FormComponentTypes.Badges,
      ].includes(type)
    ) {
      properties.options = [];
    }

    return {
      componentId,
      parentId,
      order: i,
      layout: availableLayouts.halfColumn,
      component: type,
      properties,
      validation: {
        componentId,
        type: getValidationType(type),
        properties: {
          label,
        },
      },
    };
  });
};

export const getConditionOptions = (component?: PageComponentProps) => {
  let options = [
    {
      value: Conditions.IsEqual,
      label: "Is equal?",
    },
    {
      value: Conditions.Includes,
      label: "Includes",
    },
  ];

  if (component?.component === FormComponentTypes.Input) {
    options = [
      ...options,
      {
        value: Conditions.GreaterThan,
        label: "Greater than?",
      },
      {
        value: Conditions.LessThan,
        label: "Less than?",
      },
    ];
  }
  return options;
};

export const getCategory = (
  availableForms: FormTab[],
  selectedCategory?: FormCategories,
) => {
  const form = availableForms[0];
  let category = form?.category;
  if (category) {
    return category;
  }

  Object.values(AvailableForms).forEach((value) => {
    if (selectedCategory && value.includes(selectedCategory)) {
      category = value;
    }
  });
  return category;
};

export const contactPermissionTabs = [
  {
    value: AllowedContactType.person,
    label: "Persons",
  },
  {
    value: AllowedContactType.company,
    label: "Companies",
  },
  {
    value: AllowedContactType.office,
    label: "Office",
  },
];
