import { FC } from "react";

interface Props {
  currentCount?: number;
  totalCount?: number;
  hasSearch?: boolean;
}

export const ResourceCounter: FC<Props> = ({
  totalCount,
  currentCount = 0,
  hasSearch = false,
}) => {
  if (!totalCount) {
    return null;
  }
  if (hasSearch) {
    return (
      <div className="text-sm text-gray-700">
        <span>
          {currentCount}/{totalCount} gefundene Einträge
        </span>
      </div>
    );
  }
  return (
    <div className="text-sm text-gray-700">
      <span>{totalCount} Einträge</span>
    </div>
  );
};
