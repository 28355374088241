import {
  AllowedAttachmentType,
  AppRoutes,
  AvailableImageVariants,
  RoundedVariants,
} from "@amenda-types";
import { FC, useEffect, useState } from "react";
import { MainContainer, SidebarContainer } from "@amenda-components/Shared";
import {
  convertExtractedImages,
  isEditingConstructionDetails,
} from "./helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ConstructionDetailActionBar } from "./ConstructionDetailActionBar";
import { ConstructionDetailsForm } from "./ConstructionDetailsForm";
import { Image } from "@amenda-components/App";
import { ThumbnailSliderView } from "@amenda-components/FileUploads";
import { useAttachmentStore } from "@amenda-domains/mutations";
import { useGetAttachment } from "@amenda-domains/queries";

export const ViewConstructionDetails: FC = () => {
  const { pathname } = useLocation();
  const { constructionDetailId } = useParams<{
    constructionDetailId: string;
  }>();
  const navigate = useNavigate();
  const selectedAttachment = useAttachmentStore(
    (state) => state.selectedAttachment,
  );
  const { getAttachment, loading } = useGetAttachment();
  const [selectedUrl, setSelectedUrl] = useState<string>("");
  const isEditing = isEditingConstructionDetails(pathname);
  const setAttachmentProject = useAttachmentStore(
    (state) => state.setAttachmentProject,
  );
  const goBackRoute = useAttachmentStore(
    (state) => state.attachmentProject?.goBackRoute,
  );

  const handleClose = () => {
    setAttachmentProject({});
    navigate(goBackRoute ?? AppRoutes.ConstructionDetails);
  };

  useEffect(() => {
    if (selectedAttachment?.url) {
      setSelectedUrl(selectedAttachment.url);
    }

    return () => {
      setSelectedUrl("");
    };
  }, [selectedAttachment?.url]);

  useEffect(() => {
    if (constructionDetailId) {
      getAttachment({
        id: constructionDetailId,
        type: AllowedAttachmentType.pdf,
        selectedAttachmentFields: {
          metadata: 1,
          url: 1,
          name: 1,
          originalFilename: 1,
        },
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [constructionDetailId, getAttachment]);

  return (
    <div className="flex h-full w-full">
      <MainContainer className="px-4 pb-2 lg:pb-4 lg:pr-12">
        <span className="text-lg">
          {selectedAttachment?.formValues?.name ??
            selectedAttachment?.originalFilename}
        </span>
        <div className="grid h-full w-full gap-2 pt-4 md:grid-cols-7 lg:grid-cols-5 xl:grid-cols-7">
          <div className="col-span-1 hidden h-full w-full bg-gray-100 px-0.5 py-2 md:block lg:py-6">
            <ThumbnailSliderView
              vertical={true}
              files={convertExtractedImages(
                selectedAttachment?.extractedImages,
              )}
              onClick={(file) => {
                setSelectedUrl(file?.url);
              }}
            />
          </div>
          <div className="overflow-y-contain h-full overflow-y-auto bg-gray-100 px-3 py-4 md:col-span-6 lg:col-span-4 lg:py-12 xl:col-span-6">
            <Image
              isLoading={loading}
              showLoader={true}
              url={selectedUrl}
              size="h-full"
              magicSize="fullscreen"
              background="bg-gray-100"
              lazyLoad={false}
              variant={AvailableImageVariants.contain}
              rounded={RoundedVariants.none}
              enableHighlighting={false}
            />
          </div>
          <div className="col-span-1 block h-min w-full bg-gray-100 px-0.5 py-2 md:hidden lg:py-6">
            <ThumbnailSliderView
              files={convertExtractedImages(
                selectedAttachment?.extractedImages,
              )}
              onClick={(file) => {
                setSelectedUrl(file?.url);
              }}
            />
          </div>
        </div>
      </MainContainer>
      <SidebarContainer className="bg-gray-100 p-2 lg:w-[25vw] xl:p-4">
        <div className="relative h-full w-full overflow-y-auto overscroll-y-contain bg-white">
          <ConstructionDetailActionBar
            isEditing={isEditing}
            handleClose={handleClose}
          />
          <ConstructionDetailsForm
            className="px-4 pb-6 pt-4"
            readOnly={!isEditing}
            handleClose={handleClose}
          />
        </div>
      </SidebarContainer>
    </div>
  );
};
