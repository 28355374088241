import { AvailableNotificationTypes } from "@amenda-types";
import { FC, useEffect, useState } from "react";
import {
  useAppStore,
  useTenantStore,
  useUpdateTenant,
} from "@amenda-domains/mutations";

import { AdminSettingsLayout } from "@amenda-components/Settings";
import { TenantSAMLFormWrapper } from "@amenda-components/Tenant";
import { useGetCurrentTenant } from "@amenda-domains/queries";

export const SAMLConfigurationPage: FC = () => {
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const { getCurrentTenant } = useGetCurrentTenant();
  const { updateTenant } = useUpdateTenant();
  const [loading, setLoading] = useState(false);
  const showNotification = useAppStore((state) => state.showNotification);

  const handleUpdateTenant = async (input: any) => {
    await updateTenant({
      input,
    });
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);

    await handleUpdateTenant({
      tenantConfig: {
        samlConfig: data,
      },
    });
    showNotification(
      AvailableNotificationTypes.Success,
      "SAML configuration updated",
    );
    setLoading(false);
  };

  useEffect(() => {
    getCurrentTenant({
      requestPolicy: "cache-and-network",
    });
  }, [getCurrentTenant]);

  return (
    <AdminSettingsLayout>
      <TenantSAMLFormWrapper
        tenant={primaryTenant}
        loading={loading}
        onFormSubmit={handleSubmit}
      />
    </AdminSettingsLayout>
  );
};
