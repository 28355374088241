import { LoaderWrapper } from "@amenda-components/App";
import { useSignInWithMicrosoft } from "@amenda-domains/mutations";

import { FC, useEffect, useRef } from "react";
import { MicrosoftLoginButton } from "@amenda-components/Auth/MicrosoftLoginButton";

export const SAMLLoginForm: FC = () => {
  const { signInWithSAML, handlePopupSignIn, loader } =
    useSignInWithMicrosoft();
  const domain = window.location.hostname;
  const useDebouncedEffect = (
    callback: () => void,
    delay: number,
    deps: any[],
  ) => {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    useEffect(() => {
      const handler = setTimeout(() => callbackRef.current(), delay);
      return () => clearTimeout(handler);
    }, [delay]);
  };

  useDebouncedEffect(
    () => {
      if (domain) {
        handlePopupSignIn(domain, signInWithSAML);
      }
    },
    0,
    [],
  );

  if (loader) {
    return <LoaderWrapper />;
  }

  return (
    <div className="flex justify-center">
      <MicrosoftLoginButton
        onClick={() => handlePopupSignIn(domain, signInWithSAML)}
      />
    </div>
  );
};
