import {
  Control,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import {
  useAppStore,
  useCreateFormView,
  useProjectStore,
  useUpdateFormView,
} from "@amenda-domains/mutations";

import { Button } from "@amenda-components/App/Button";
import { FC } from "react";
import { FormBuilderFormViewsForm } from "./FormBuilderFormViewsForm";
import { SidebarContainer } from "@amenda-components/Shared";
import { devConsole } from "@amenda-utils";
import { translateFormViewData } from "@amenda-components/Projects/common";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control;
  reset: UseFormReset<any>;
  setValue: UseFormSetValue<any>;
  handleSubmit: UseFormHandleSubmit<any>;
}

export const FormBuilderFormViewLeftSidebar: FC<Props> = ({
  control,
  reset,
  setValue,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const { updateFormView, loading: isUpdating } = useUpdateFormView();
  const { createFormView, loading: isCreating } = useCreateFormView();
  const setFormViewStatus = useProjectStore((state) => state.setFormViewStatus);
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const clearFormBuilderState = useAppStore(
    (state) => state.clearFormBuilderState,
  );
  const setSelectedFormView = useProjectStore(
    (state) => state.setSelectedFormView,
  );

  const handleClose = () => {
    reset();
    setFormViewStatus("view");
    clearFormBuilderState();
  };

  const onSubmit = async (data: any) => {
    const formView = translateFormViewData(data);

    if (selectedFormView?.id) {
      const updatedFormView = await updateFormView({
        input: {
          _id: selectedFormView.id,
          ...formView,
        },
      });
      setSelectedFormView(updatedFormView);
      setFormViewStatus("view");
    } else {
      await createFormView({
        input: {
          ...formView,
        },
      });
    }
  };

  const handleSave = () => {
    handleSubmit(onSubmit, (e) => {
      devConsole?.warn("amenda:handle save error", e);
    })();
  };

  return (
    <SidebarContainer className="relative w-72 border-r pt-4">
      <div className="w-full overflow-y-auto overscroll-y-contain px-4 pb-10">
        <span className="amenda-settings-heading">
          {t("Ansicht bearbeiten")}
        </span>
        <FormBuilderFormViewsForm control={control} setValue={setValue} />
      </div>
      <div className="absolute bottom-0 z-10 flex w-full items-center space-x-2 border-t bg-white px-4 py-2">
        <Button
          size="md"
          className="flex-1 items-center justify-center"
          onClick={handleClose}
        >
          <span>{t("Cancel")}</span>
        </Button>
        <Button
          variant="primary"
          size="md"
          loading={isUpdating || isCreating}
          className="flex-1 items-center justify-center"
          onClick={handleSave}
        >
          <span>{t("Save")}</span>
        </Button>
      </div>
    </SidebarContainer>
  );
};
