import { FC, useEffect, useMemo } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  ProjectLayout,
  ProjectSectionSidebar,
  ProjectSectionSidebarMobile,
  ReadOnlyProjectHeader,
  ReadOnlyProjectTabs,
} from "@amenda-components/Projects";
import {
  useAppStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { FaceAPIProvider } from "@amenda-context";
import { FormBuilderFormViewsEditor } from "@amenda-components/Settings/FormBuilder/FormBuilderFormViewsEditor";
import { MainContainer } from "@amenda-components/Shared";
import { MoveAttachmentModal } from "@amenda-components/Gallery";
import clsx from "clsx";
import { useGetProject } from "@amenda-domains/queries";
import { useParams } from "react-router";

export const ProjectPage: FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const { getProject } = useGetProject();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues,
  );
  const projectForms = useProjectStore((state) => state.projectForms);
  const availableForms = useMemo(() => projectForms || [], [projectForms]);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const clearFormBuilderState = useAppStore(
    (state) => state.clearFormBuilderState,
  );

  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const resourceId = projectId || selectedProject?.id;
  const formViewStatus = useProjectStore((state) => state.formViewStatus);

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
        redirectWhenEmpty: true,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [projectId, getProject]);

  useEffect(() => {
    return () => {
      clearProjectValues();
      clearFormBuilderState();
    };
  }, [clearProjectValues, clearFormBuilderState]);

  if (formViewStatus !== "view") {
    return <FormBuilderFormViewsEditor />;
  }
  return (
    <FaceAPIProvider>
      <ProjectLayout className="px-1 lg:pt-0" title={selectedProject?.name}>
        <MoveAttachmentModal />
        <div className="flex h-full w-full flex-row">
          <ProjectSectionSidebar
            showFormViews={true}
            resourceId={resourceId}
            availableForms={availableForms}
            projectsGeneralPermissions={projectsGeneralPermissions}
          />
          <ProjectSectionSidebarMobile
            showFormViews={true}
            resourceId={resourceId}
            availableForms={availableForms}
            projectsGeneralPermissions={projectsGeneralPermissions}
          />
          <div
            className={clsx(
              "h-screen w-full overflow-y-auto overscroll-y-contain",
              {
                "pr-4": formViewStatus === "view",
              },
            )}
          >
            <MainContainer className="pt-0">
              <ReadOnlyProjectHeader />
              <div className="h-auto w-full">
                <ReadOnlyProjectTabs
                  availableForms={availableForms}
                  projectsGeneralPermissions={projectsGeneralPermissions}
                />
              </div>
            </MainContainer>
          </div>
        </div>
      </ProjectLayout>
    </FaceAPIProvider>
  );
};
