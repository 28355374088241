import { FC } from "react";
import clsx from "clsx";

interface Props {
  className?: string;
  project?: Record<string, any>;
  textClassName?: string;
}

export const ProjectFallbackThumbnail: FC<Props> = ({
  project,
  className,
  textClassName = "text-[40px]",
}) => {
  return (
    <div className={clsx("relative w-full", className)}>
      <div className="absolute right-0 top-0 flex h-full w-full items-center justify-center bg-gray-50">
        <span
          className={clsx(
            "truncate px-2 py-1.5 text-center leading-none text-gray-300",
            textClassName,
          )}
        >
          {project?.number}
        </span>
      </div>
    </div>
  );
};
