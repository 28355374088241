import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { FormTab, TabItem, availableLayouts } from "@amenda-types";
import { addQueryToPath, getStaticProjectTabs } from "./common";
import { useLocation, useNavigate } from "react-router-dom";

import { ChevronDownIcon } from "lucide-react";
import { Link } from "@amenda-components/App";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { getQueryParams } from "@amenda-utils";
import isEmpty from "lodash/isEmpty";
import { sortBy } from "lodash";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  resourceId?: string;
  availableForms: FormTab[];
  projectsGeneralPermissions: any;
}

type CustomTabItem = TabItem & {
  order?: number;
  isStatic?: boolean;
  children?: CustomTabItem[];
};

interface ProjectSectionSidebarBaseProps extends Pick<Props, "resourceId"> {
  tabs: CustomTabItem[];
}

export const ProjectSectionSidebarBase: FC<ProjectSectionSidebarBaseProps> = ({
  tabs,
  resourceId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [expandedSection, setExpandedSection] = useState<
    Record<string, boolean>
  >({});
  const setOpenProjectFormSection = useProjectStore(
    (state) => state.setOpenProjectFormSection,
  );
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const isFetchingProject = useProjectStore((state) => state.isFetchingProject);

  const foundIndex = tabs.findIndex(
    (tab) => tab.value === getQueryParams(search)?.tab,
  );
  const defaultTabIndex = foundIndex >= 0 ? foundIndex : 0;

  const handleChange = (tab: CustomTabItem) => {
    if (tab.children && tab.children.length > 1) {
      setExpandedSection((prev) => ({
        ...prev,
        [tab.value]: !Boolean(prev[tab.value]),
      }));
    }
    setOpenProjectFormSection(false);
    navigate(addQueryToPath(pathname, { tab: tab.value }), { replace: true });
  };

  const handleChangeComponent = (tab: TabItem, childId: string) => {
    setOpenProjectFormSection(false);
    navigate(addQueryToPath(pathname, { tab: tab.value }), { replace: true });
    setTimeout(() => {
      document.getElementById(childId)?.scrollIntoView({
        behavior: "smooth",
      });
    }, 300);
  };

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    },
    [],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.7, // Trigger when 70% of the target is visible
    });

    if (!isFetchingProject && defaultTabIndex >= 0) {
      tabs.forEach((tab) => {
        if (tab.children && tab.children.length > 1) {
          tab.children.forEach((child) => {
            const element = document.getElementById(child.value);
            if (element) observer.observe(element);
          });
        }
      });
    }
    return () => observer.disconnect();
  }, [isFetchingProject, defaultTabIndex, tabs, handleIntersection]);

  return (
    <>
      <div
        className={clsx("flex flex-col space-y-2 px-4", {
          "pt-6": !!resourceId,
        })}
      >
        {tabs.map((tab, i) => (
          <Fragment key={tab.value}>
            <Link
              variant="secondaryAlt"
              className={clsx(
                "flex w-full items-center rounded-sm pr-2 text-left",
                {
                  "bg-gray-900 text-white": defaultTabIndex === i,
                },
              )}
              onClick={() => handleChange(tab)}
              disabled={!resourceId ? tab.order !== 0 : false}
            >
              {Boolean(tab.isStatic) ? t(tab.label) : tab.label}
              {tab.children && tab.children.length > 1 && (
                <ChevronDownIcon
                  className={clsx(
                    "ml-auto h-5 w-5 transition-transform delay-75 duration-200 ease-in-out",
                    {
                      "rotate-180": Boolean(expandedSection[tab.value]),
                    },
                  )}
                />
              )}
            </Link>
            {tab.children && tab.children.length > 1 && (
              <Transition
                as="div"
                className="flex flex-col space-y-2"
                show={Boolean(expandedSection[tab.value])}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <>
                  {tab.children.map((tc) => (
                    <Link
                      key={tc.value}
                      variant="secondaryAlt"
                      className={clsx(
                        "flex w-full items-center rounded-sm pl-4 text-left transition delay-150 duration-300 ease-in-out",
                        {
                          "text-gray-900": activeSection === tc.value,
                        },
                      )}
                      disabled={!resourceId ? tab.order !== 0 : false}
                      onClick={() => {
                        handleChangeComponent(tab, tc.value);
                      }}
                    >
                      {tc.label}
                    </Link>
                  ))}
                </>
              </Transition>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};

export const ProjectSectionSidebarContent: FC<Props> = ({
  resourceId,
  availableForms,
  projectsGeneralPermissions,
}) => {
  const selectedFormView = useProjectStore((state) => state.selectedFormView);

  const projectTabs = useMemo(() => {
    let tabs: CustomTabItem[] = [];

    (availableForms ?? []).forEach((form) => {
      let children = (form.components ?? [])
        .filter((c) =>
          [
            availableLayouts.twoThirdsColumnContainer,
            availableLayouts.nestedFormContainer,
            availableLayouts.fullColumnContainer,
          ].includes(c.layout),
        )
        .map((c) => ({
          value: c?.id,
          order: c?.order,
          label: c?.properties?.label,
        }));
      children = sortBy(children, "order");

      tabs = [
        ...tabs,
        {
          children,
          label: form.name,
          value: form.id,
          order: form.order,
        },
      ];
    });

    tabs = [...tabs, ...getStaticProjectTabs(projectsGeneralPermissions)];
    return tabs;
  }, [availableForms, projectsGeneralPermissions]);

  const formViewTabs = useMemo(() => {
    let tabs: CustomTabItem[] = [];

    (selectedFormView?.sections ?? []).forEach((section: any) => {
      let children = (section?.components ?? [])
        .filter((c: any) =>
          [
            availableLayouts.twoThirdsColumnContainer,
            availableLayouts.nestedFormContainer,
            availableLayouts.fullColumnContainer,
          ].includes(c.layout),
        )
        .map((c: any) => ({
          value: c.id,
          order: c.order,
          label: c.label,
        }));
      children = sortBy(children, "order");

      tabs = [
        ...tabs,
        {
          children,
          value: section.id,
          label: section.label,
        },
      ];
    });

    return tabs;
  }, [selectedFormView?.sections]);

  return (
    <ProjectSectionSidebarBase
      resourceId={resourceId}
      tabs={isEmpty(selectedFormView) ? projectTabs : formViewTabs}
    />
  );
};
