import { Control, Controller } from "react-hook-form";
import { SingleSelect, TextField } from "@amenda-components/FormComponents";

import { AvailableForms } from "@amenda-constants/forms";
import { FC } from "react";
import { getComponentsFromForms } from "@amenda-utils/data";
import { useProjectStore } from "@amenda-domains/mutations/projects";

interface Props {
  control: Control<any>;
}

export const FormBuilderCostGroupCostComponentForm: FC<Props> = ({
  control,
}) => {
  const forms = useProjectStore((state) => state.forms);

  const components = getComponentsFromForms(
    forms?.[AvailableForms.CostGroupsQuantity] ?? [],
  ).filter((c) => Boolean(c?.properties));

  return (
    <div className="flex flex-col space-y-2 px-2 pt-4">
      <Controller
        name="label"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            id="label"
            label="Label"
            error={error?.message}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="endAddOnText"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            id="endAddOnText"
            label="Add on text on the right"
            error={error?.message}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="referenceQuantity"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <SingleSelect
              id="referenceQuantity"
              label="Reference Quantity"
              isClearable={false}
              error={error?.message}
              value={value}
              hasMenuOverflow={true}
              getOptionLabel={(component) => component?.properties?.label}
              getOptionValue={(component) => component?.id}
              onChange={onChange}
              options={components}
            />
          );
        }}
      />
    </div>
  );
};
