import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { IconButtonBase } from "@amenda-components/App/IconButton";
import { LayoutProps } from "@amenda-components/PageLayouts/common";
import { XIcon } from "lucide-react";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

type Props = Pick<LayoutProps, "config" | "globalProps"> & {
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

interface FormViewActionAndLabelsProps extends Props {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const FormViewActionAndLabels: FC<FormViewActionAndLabelsProps> = ({
  isEdit,
  config,
  control,
  setValue,
  setIsEdit,
}) => {
  const { t } = useTranslation();
  const sections = useWatch({
    control,
    name: "sections",
    defaultValue: [],
  });
  const selectedForm = useAppStore(
    (state) => state.formBuilderState.selectedForm,
  );
  const removeFormViewComponent = useAppStore(
    (state) => state.removeFormViewComponent,
  );
  const updateFormViewComponentHeaders = useAppStore(
    (state) => state.updateFormViewComponentHeaders,
  );
  const [label, setLabel] = useState(config?.properties?.label || "");
  const [description, setDescription] = useState(
    config?.properties?.description || "",
  );
  const ref = useRef<HTMLInputElement>(null);

  const activeIndex = sections.findIndex((s: any) => s.id === selectedForm?.id);

  const handleDeleteComponent = () => {
    if (config?.id && activeIndex !== -1) {
      removeFormViewComponent(config.id, (components) => {
        setValue(`sections.${activeIndex}.components`, components);
      });
    }
  };

  const handleUpdateComponent = () => {
    if (config?.id && activeIndex !== -1) {
      updateFormViewComponentHeaders({
        componentId: config.id,
        properties: {
          label,
          description,
        },
        callback: (components) => {
          setValue(`sections.${activeIndex}.components`, components);
        },
      });
    }
    setIsEdit(false);
  };

  useEffect(() => {
    if (isEdit && ref.current) {
      ref.current.focus();
    }
  }, [isEdit]);

  return (
    <>
      <IconButtonBase
        size="xss"
        className="invisible absolute right-0 top-0 mr-2 mt-2 h-5 w-5 group-hover/full-col-grid:visible group-hover/two-col-full-width:visible"
        variant="primary"
        onClick={handleDeleteComponent}
      >
        <XIcon className="h-4 w-4" />
      </IconButtonBase>
      {isEdit && (
        <div
          className="flex w-full flex-col"
          onPointerLeave={handleUpdateComponent}
        >
          <input
            ref={ref}
            type="text"
            value={label}
            placeholder={t("Label")}
            className="text-md min-w-10 grow border-0 py-0 pl-0 pr-2 leading-[inherit] focus:outline-none focus:ring-0"
            style={{ width: `${label.length + 1}ch` }}
            onChange={(e) => setLabel(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent form submission
              }
            }}
          />
          <input
            type="text"
            value={description}
            placeholder={t("Description")}
            className="mt-1 min-w-24 grow border-0 py-0 pl-0 pr-2 text-sm leading-[inherit] text-gray-600 focus:outline-none focus:ring-0"
            style={{ width: `${description.length + 1}ch` }}
            onChange={(e) => setDescription(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent form submission
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export const FormBuilderFormViewSectionHeader: FC<Props> = ({
  globalProps,
  ...rest
}) => {
  const isFormView = Boolean(globalProps?.isFormView);

  if (isFormView && globalProps?.control && globalProps?.setValue) {
    return (
      <FormViewActionAndLabels
        {...rest}
        control={globalProps?.control}
        setValue={globalProps?.setValue}
      />
    );
  }
  return null;
};
