import {
  AllowedAttachmentType,
  AllowedCollectionType,
  AppRoutes,
  GalleryView,
  ProjectView,
} from "@amenda-types";
import { FC, useEffect, useRef } from "react";
import {
  FormAutoSaveProvider,
  FormAutoSaveSubmitProps,
} from "@amenda-components/PageBuilder";
import {
  LargerScreensSidebar,
  SidebarFilters,
  SmallerScreensSidebar,
} from "@amenda-components/Shared";
import { PaginationLimit, sidebarFiltersSchema } from "@amenda-constants";
import {
  ProjectTabRoutes,
  getProjectArgs,
} from "@amenda-components/Projects/common";
import {
  isAttachmentOrProjectCollection,
  isSearchFilterEmpty,
  transformFilters,
} from "./common";
import {
  useAppStore,
  useAttachmentStore,
  useFormStore,
  useProjectStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";
import {
  useGetAllAttachments,
  useGetAllProjects,
  useSearchAttachments,
  useSearchProjects,
} from "@amenda-domains/queries";
import { useLocation, useParams } from "react-router-dom";

import { getQueryParams } from "@amenda-utils";
import isEmpty from "lodash/isEmpty";

const FiltersWrapper: FC = () => {
  const { pathname, search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();
  const { searchProjects } = useSearchProjects();
  const { getAllProjects } = useGetAllProjects();
  const { searchAttachments } = useSearchAttachments();
  const { getAllAttachments } = useGetAllAttachments();
  const setIsSearching = useProjectStore((state) => state.setIsSearching);
  const setIsSearchingAttachments = useAttachmentStore(
    (state) => state.setIsSearching,
  );
  const { sidebarFilters, searchTerm, setSidebarFilters, clearSidebarFilters } =
    useSidebarFiltersWithPath();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const similarProjectIds = useProjectStore(
    (state) => state.similarityResourceIds,
  );
  const clearAttachments = useAttachmentStore(
    (state) => state.clearAttachments,
  );
  const clearProjects = useProjectStore((state) => state.clearProjects);
  const projectView = useProjectStore((state) => state.projectView);
  const attachmentView = useAttachmentStore(
    (state) => state.selectedGalleryView,
  );

  const activeProjectTab = getQueryParams(search)?.tab;
  const selectedCollection =
    selectedCollectionByType?.[
      pathname.includes(AppRoutes.Attachments)
        ? AllowedCollectionType.Attachments
        : AllowedCollectionType.Projects
    ];
  const isCollection = isAttachmentOrProjectCollection(pathname);
  const isProject =
    pathname.includes(AppRoutes.Projects) &&
    ProjectTabRoutes.Attachments !== activeProjectTab;

  const searchInProjects = async (filters: any) => {
    setIsSearching(true);
    await searchProjects({
      isCollection,
      searchTerm,
      filters,
      resourceIds: selectedCollection?.resourceIds,
      isTableView: projectView === ProjectView.Table,
    });
    setIsSearching(false);
  };

  const searchInAttachments = async (searchFilters: any) => {
    let filters = { ...searchFilters, type: AllowedAttachmentType.image };
    if (activeProjectTab === ProjectTabRoutes.Attachments && projectId) {
      filters = {
        ...filters,
        "formValues.project": projectId,
      };
    }

    setIsSearchingAttachments(true);
    await searchAttachments({
      filters,
      isCollection,
      searchTerm,
      resourceIds: selectedCollection?.resourceIds,
      isTableView: attachmentView === GalleryView.Table,
    });
    setIsSearchingAttachments(false);
  };

  const getAllAttachmentsAsync = async () => {
    const args: any = {
      isTableView: attachmentView === GalleryView.Table,
      attachmentType: AllowedAttachmentType.image,
      limit: PaginationLimit.attachments,
      context: {
        requestPolicy: "cache-and-network",
      },
    };
    if (isCollection) {
      args.ids = selectedCollection?.resourceIds;
    }

    await getAllAttachments(args);
  };

  const getAllProjectsAsync = async () => {
    await getAllProjects({
      ...getProjectArgs({
        isCollection,
        similarProjectIds,
        collectionProjectIds: selectedCollection?.resourceIds,
      }),
      isTableView: projectView === ProjectView.Table,
      limit: PaginationLimit.projects,
      context: {
        requestPolicy: "cache-and-network",
      },
    });
  };

  const handleGetAll = async () => {
    const callFunc = isProject ? getAllProjectsAsync : getAllAttachmentsAsync;
    await callFunc();
  };

  const handleSearch = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    const runSearch = isProject ? searchInProjects : searchInAttachments;
    const clearAll = isProject ? clearProjects : clearAttachments;

    setSidebarFilters(sanitizedData);
    clearAll();
    if (isEmpty(searchTerm) && isSearchFilterEmpty(sanitizedData)) {
      await handleGetAll();
    } else {
      await runSearch(transformFilters(sanitizedData));
    }
  };

  const handleReset = async () => {
    const clearAll = isProject ? clearProjects : clearAttachments;

    clearSidebarFilters();
    clearAll();
    await handleGetAll();
  };

  return (
    <FormAutoSaveProvider
      ignoreResourceIdAlways={true}
      values={sidebarFilters}
      inputSchema={sidebarFiltersSchema}
      onSubmit={handleSearch}
    >
      <div className="relative w-full">
        <SidebarFilters
          isProject={isProject}
          isCollection={isCollection}
          handleReset={handleReset}
        />
      </div>
    </FormAutoSaveProvider>
  );
};

export const SidebarFiltersWrapper: FC = () => {
  const ref = useRef<string>();
  const { pathname, search } = useLocation();
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const { sidebarFilters, setSidebarFilters } = useSidebarFiltersWithPath();

  const hasActiveFilters = !isSearchFilterEmpty(sidebarFilters);

  // orchestrate clearing and closing of the filter sidebar
  useEffect(() => {
    const processProjectRoutes = () => {
      const activeProjectTab = getQueryParams(search)?.tab;

      if (pathname.startsWith(AppRoutes.Projects)) {
        if (ref.current === AppRoutes.Attachments) {
          setSidebarFilters({});
          setOpenSidebar(false);
        } else if (
          pathname !== AppRoutes.Projects &&
          !pathname.startsWith(AppRoutes.ProjectsCollection) &&
          ProjectTabRoutes.Attachments !== activeProjectTab
        ) {
          setOpenSidebar(false);
        } else if (hasActiveFilters) {
          setOpenSidebar(true);
        }
        ref.current = AppRoutes.Projects;
      }
    };

    processProjectRoutes();
  }, [search, pathname, hasActiveFilters, setSidebarFilters, setOpenSidebar]);

  useEffect(() => {
    const processAttachmentsRoute = () => {
      if (pathname.startsWith(AppRoutes.Attachments)) {
        if (ref.current === AppRoutes.Projects) {
          setSidebarFilters({});
          setOpenSidebar(false);
        } else if (
          pathname !== AppRoutes.Attachments &&
          !pathname.startsWith(AppRoutes.AttachmentsCollection)
        ) {
          setOpenSidebar(false);
        } else if (hasActiveFilters) {
          setOpenSidebar(true);
        }
        ref.current = AppRoutes.Attachments;
      }
    };

    processAttachmentsRoute();
  }, [pathname, setOpenSidebar, hasActiveFilters, setSidebarFilters]);

  useEffect(() => {
    const closeSidebarForOtherRoutes = () => {
      if (
        !pathname.startsWith(AppRoutes.Projects) &&
        !pathname.startsWith(AppRoutes.Attachments)
      ) {
        setOpenSidebar(false);
      }
    };

    closeSidebarForOtherRoutes();
  }, [pathname, setOpenSidebar]);

  return (
    <>
      <LargerScreensSidebar>
        <FiltersWrapper />
      </LargerScreensSidebar>
      <SmallerScreensSidebar>
        <FiltersWrapper />
      </SmallerScreensSidebar>
    </>
  );
};
