import { AllowedCollectionType, GeneralPermissionTypes } from "@amenda-types";
import { AvailableForms, PaginationLimit } from "@amenda-constants";
import { Chip, IconButton } from "@amenda-components/App";
import { FC, useRef } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { GroupByDropdown, ResourceCounter } from "@amenda-components/Shared";
import { debounce, isEmpty } from "lodash";
import {
  useFormStore,
  useMaterialsStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";
import {
  useGetAllMaterials,
  useSearchMaterials,
} from "@amenda-domains/queries";

import { MaterialsActionBar } from "./MaterialsActionBar";
import { MaterialsCollectionDropdown } from "./MaterialsCollectionDropdown";
import { MaterialsPageProps } from "./common";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { SearchField } from "@amenda-components/SearchComponents";
import { abortRequest } from "@amenda-domains/abortExchange";
import { getGroupableFromForms } from "@amenda-utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const MaterialListHeader: FC<MaterialsPageProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const clearSelectedMaterial = useMaterialsStore(
    (state) => state.clearSelectedMaterial,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { getAllMaterials } = useGetAllMaterials();
  const { searchMaterials } = useSearchMaterials();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const forms = useProjectStore((state) => state.forms) ?? {};
  const groupComponents = useMaterialsStore(
    (state) => state.groupingComponents,
  );
  const toggleGroupingComponent = useMaterialsStore(
    (state) => state.toggleGroupingComponent,
  );
  const searchTerm = useMaterialsStore((state) => state.searchTerm);
  const setSearchTerm = useMaterialsStore((state) => state.setSearchTerm);
  const pagination = useMaterialsStore((state) => state.pagination);
  const materials = useMaterialsStore((state) => state.materials);

  const groupByComponentIds = groupComponents.map((c) => c.id);
  const materialForms = forms[AvailableForms.Materials] ?? [];
  const selectedCollection =
    selectedCollectionByType[AllowedCollectionType.Materials];
  const components = getGroupableFromForms(materialForms);
  const unitPriceGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );

  const debounceSearch = useRef(
    debounce(
      async (
        searchTerm: string,
        ids?: string[],
        groupByComponentIds?: string[],
      ) => {
        const { isCollection } = props;
        setSearchTerm(searchTerm);

        let args: any = {
          isCollection,
          autoSelect: true,
        };

        if (!isEmpty(searchTerm) || !isEmpty(groupByComponentIds)) {
          args = {
            ...args,
            searchTerm,
            groupByComponentIds,
          };

          if (!isEmpty(ids)) {
            args.match = {
              isDeleted: false,
              _id: {
                $in: ids,
              },
            };
          }
          await searchMaterials(args);
        } else {
          args = {
            ...args,
            isDeleted: false,
            limit: PaginationLimit.materials,
            context: {
              requestPolicy: "network-only",
            },
          };

          if (!isEmpty(ids)) {
            args.ids = ids;
          }
          abortRequest("searchMaterials");
          await getAllMaterials(args);
        }
      },
      600,
    ),
  ).current;

  const handleClick = () => {
    clearSelectedMaterial();
    navigate("/einheitspreise/neu");
  };

  const runSearch = async (
    searchTerm: string,
    groupByComponentIds?: string[],
  ) => {
    await debounceSearch(
      searchTerm,
      selectedCollection?.resourceIds,
      groupByComponentIds,
    );
  };

  const handleSearchTerm = async (searchTerm: string) => {
    await runSearch(searchTerm, groupByComponentIds);
  };

  const handleGrouping =
    (component: any, remove = false, close?: () => void) =>
    async () => {
      const keys = remove
        ? groupByComponentIds.filter((id) => id !== component.id)
        : [...groupByComponentIds, component.id];

      toggleGroupingComponent(component);
      await runSearch(searchTerm, keys);
      close?.();
    };

  return (
    <div className="h-[220px] w-full px-4 py-6 lg:h-[260px]">
      <MaterialsCollectionDropdown />
      <div className="w-full">
        <GroupByDropdown
          components={components}
          selectedComponents={groupComponents}
          handleGrouping={handleGrouping}
        />
        {groupComponents.length > 0 && (
          <div className="flex w-full flex-wrap items-center pt-2">
            {groupComponents.map((component) => (
              <Chip
                key={component.id}
                className="mb-1 mr-1 bg-gray-900 text-xs text-white"
                label={component.properties?.label}
                onDelete={handleGrouping(component, true)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="mt-2 flex space-x-4">
        <div className="min-w-0 flex-1">
          <label htmlFor="search" className="sr-only">
            {t("Search")}
          </label>
          <div className="relative mt-[10px] rounded-md">
            <SearchField
              variant="slim"
              isClearable={true}
              defaultValue={searchTerm}
              placeHolder="Search materials"
              onSearch={handleSearchTerm}
            />
          </div>
        </div>
        {unitPriceGeneralPermissions[GeneralPermissionTypes.Create] && (
          <div className="flex">
            <IconButton
              label="Add Material"
              className=""
              iconSize={3}
              Icon={PlusCircleIcon}
              onClick={handleClick}
            />
          </div>
        )}
      </div>
      <MaterialsActionBar {...props} />
      <ResourceCounter
        currentCount={materials.length}
        totalCount={pagination?.docsCount}
        hasSearch={!isEmpty(searchTerm) || !isEmpty(groupByComponentIds)}
      />
    </div>
  );
};
