import { FileJson2Icon, LayoutTemplateIcon, PlusIcon } from "lucide-react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import { FC } from "react";
import { FormCategories } from "@amenda-constants";
import { ReactSVG } from "react-svg";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const FormBuilderCreateFormButton: FC = () => {
  const { t } = useTranslation();
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const { selectedCategory } = formBuilderState ?? {};

  const handleOpenCreateForm =
    (source: "blankSlate" | "json" | "xlsx") => () => {
      updateFormBuilderState("createNewFormModal", {
        isOpen: true,
        source,
      });
    };

  if (
    selectedCategory === FormCategories.CostGroups ||
    selectedCategory === FormCategories.Contacts
  ) {
    return null;
  }
  return (
    <Menu as="div" className="relative inline-block">
      <MenuButton className="inline-flex w-full justify-center rounded-sm bg-gray-800 px-4 py-2 text-sm text-white hover:bg-black/90 focus:outline-none">
        <span>{t("New form")}</span>
        <PlusIcon className="ml-2 h-5 w-5" />
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom start"
        className="w-64 rounded-sm bg-white text-gray-800 shadow-lg transition duration-200 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <MenuItem>
          <button
            onClick={handleOpenCreateForm("blankSlate")}
            className="flex w-full items-center rounded-sm px-2 py-2 text-sm hover:bg-gray-800 hover:text-white"
          >
            <LayoutTemplateIcon className="mr-2 h-5 w-5" />
            {t("Start from blank form")}
          </button>
        </MenuItem>
        <MenuItem>
          <button
            onClick={handleOpenCreateForm("xlsx")}
            className="flex w-full items-center rounded-sm fill-gray-800 stroke-gray-800 px-2 py-2 text-sm hover:bg-gray-800 hover:fill-white hover:stroke-white hover:text-white"
          >
            <ReactSVG src="/svg/excel.svg" className="mr-2 h-5 w-5" />
            {t("Create from Excel")}
          </button>
        </MenuItem>
        <MenuItem>
          <button
            onClick={handleOpenCreateForm("json")}
            className="flex w-full items-center rounded-sm px-2 py-2 text-sm hover:bg-gray-800 hover:text-white"
          >
            <FileJson2Icon className="mr-2 h-5 w-5" />
            {t("Create from JSON")}
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};
