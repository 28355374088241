import {
  AcceptedShareIcon,
  DeleteModal,
  SharingPermissionsDropdown,
} from "@amenda-components/Shared";
import { BuildingIcon, TrashIcon } from "lucide-react";
import { Button, Modal, Tooltip } from "@amenda-components/App";
import {
  Control,
  Controller,
  UseFormSetValue,
  useController,
} from "react-hook-form";
import { FC, useState } from "react";
import { FullColumn, GridWrapper } from "@amenda-components/PageLayouts";
import {
  ResourceSharingPermissionTypes,
  checkCollectionPermissions,
} from "@amenda-components/Shared/common";
import { TextArea, TextField } from "@amenda-components/FormComponents";
import {
  useAppStore,
  useProjectStore,
  useUpdateFormView,
  useUsersStore,
} from "@amenda-domains/mutations";

import { CollectionContactsShare } from "@amenda-components/Shared/CollectionContactsShare";
import { GeneralAccessTypes } from "@amenda-constants";
import { useTranslation } from "react-i18next";

interface ShareButtonProps {
  disabled: boolean;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const DeleteButton: FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const { updateFormView, loading } = useUpdateFormView();
  const setSelectedFormView = useProjectStore(
    (state) => state.setSelectedFormView,
  );
  const clearFormBuilderState = useAppStore(
    (state) => state.clearFormBuilderState,
  );
  const setFormViewStatus = useProjectStore((state) => state.setFormViewStatus);

  const handleDelete = async () => {
    await updateFormView({
      input: {
        _id: selectedFormView.id,
        isDeleted: true,
      },
    });
    setFormViewStatus("view");
    clearFormBuilderState();
    setSelectedFormView({});
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="dangerAlt" onClick={() => setIsOpen(true)}>
        <div className="flex items-center">
          <TrashIcon className="h-5 w-5" />
          <span className="ml-1">{t("Delete")}</span>
        </div>
      </Button>
      <DeleteModal
        loading={loading}
        openModal={isOpen}
        title={t("Delete Form View")}
        description={t("Are you sure you want to delete this form view?")}
        handleDelete={handleDelete}
        handleCloseModal={() => setIsOpen(false)}
      />
    </>
  );
};

const ShareButton = ({ disabled, control, setValue }: ShareButtonProps) => {
  const { t } = useTranslation();
  const currentUser = useUsersStore((state) => state.currentUser);
  const {
    field: { value: shareType, onChange: setShareType },
  } = useController({
    control,
    disabled,
    name: "shareType",
  });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Tooltip
        id="share"
        className="w-fit"
        position="bottom"
        positionStrategy="fixed"
        message={
          shareType === GeneralAccessTypes.Everyone
            ? "Shared with Organization"
            : "Share the view with"
        }
      >
        <Button
          disabled={disabled}
          variant="outline"
          onClick={() => setIsOpen(true)}
        >
          <div className="flex items-center">
            <AcceptedShareIcon value={shareType}>
              <BuildingIcon className="h-5 w-5" />
            </AcceptedShareIcon>
            <span className="ml-1">{t("Share")}</span>
          </div>
        </Button>
      </Tooltip>
      <Modal
        title="Share view with"
        isOpen={isOpen}
        withCancel={false}
        closeModalFromTitle={true}
        isElevated={true}
        size="sm"
        contentClassName="space-y-2 pb-6"
        onClose={() => setIsOpen(false)}
      >
        {shareType !== GeneralAccessTypes.Everyone && (
          <div className="w-full flex-col">
            <label htmlFor="users" className="amenda-component-label">
              {t("Share with Contacts")}
            </label>
            <CollectionContactsShare
              id="users"
              control={control}
              ignoreStylesOverride={true}
              ownerId={currentUser?.id}
              setValue={setValue}
            />
          </div>
        )}
        <SharingPermissionsDropdown
          className="text-md"
          label="General Access"
          labelClassName="amenda-component-label"
          hasMenuOverflow={true}
          selectedOption={shareType}
          onChange={setShareType}
        />
      </Modal>
    </div>
  );
};

interface Props {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

export const CreateProjectFormViews: FC<Props> = ({ control, setValue }) => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const selectedFormView = useProjectStore((state) => state.selectedFormView);

  const disabled =
    selectedFormView?.id &&
    !checkCollectionPermissions({
      currentUser,
      collection: selectedFormView,
      permission: ResourceSharingPermissionTypes.Admin,
    });

  return (
    <div className="w-full pt-4">
      <GridWrapper className="gap-y-2">
        <FullColumn>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="name"
                  label="Name"
                  maxLength={25}
                  value={value}
                  error={error?.message}
                  disabled={disabled}
                  onChange={onChange}
                />
              );
            }}
          />
        </FullColumn>
        <FullColumn>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextArea
                  id="description"
                  label="Description"
                  value={value}
                  error={error?.message}
                  disabled={disabled}
                  onChange={onChange}
                />
              );
            }}
          />
        </FullColumn>
        <FullColumn>
          <div className="flex w-full items-center justify-between">
            <ShareButton
              control={control}
              disabled={disabled}
              setValue={setValue}
            />
            {checkCollectionPermissions({
              currentUser,
              collection: selectedFormView,
              permission: ResourceSharingPermissionTypes.Admin,
            }) && <DeleteButton />}
          </div>
        </FullColumn>
      </GridWrapper>
    </div>
  );
};
