import { Control, UseFormSetValue } from "react-hook-form";

import { CreateProjectFormViews } from "@amenda-components/Projects/CreateProjectFormViews";
import { FC } from "react";
import { ProjectFormSections } from "@amenda-components/Projects/ProjectFormSections";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control;
  setValue: UseFormSetValue<any>;
}

export const FormBuilderFormViewsForm: FC<Props> = ({ control, setValue }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full py-4">
      <CreateProjectFormViews control={control} setValue={setValue} />
      <h3 className="amenda-heading pt-8">{t("Abschnitte bearbeiten")}</h3>
      <ProjectFormSections control={control} setValue={setValue} />
    </div>
  );
};
