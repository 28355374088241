import { HelperMessage, LoaderWrapper } from "@amenda-components/App";

import { FC } from "react";
import { isEmpty } from "lodash";
import { useGetProjectDesign } from "@amenda-domains/queries/designs";

export const ProjectDesignListView: FC<{
  designs: any[];
  loading: boolean;
  handleSelectDesign?: (design: any) => void;
}> = ({ designs, loading, handleSelectDesign }) => {
  const { getProjectDesign, loading: isLoadingDesignDetails } =
    useGetProjectDesign();

  const handleFetchDesignDetails = (id: string) => {
    if (handleSelectDesign) {
      getProjectDesign({
        id,
        callback: handleSelectDesign,
      });
    }
  };
  return (
    <>
      {(loading || isLoadingDesignDetails) && <LoaderWrapper />}
      {!(loading || isLoadingDesignDetails) && isEmpty(designs) && (
        <div className="flex h-full w-full items-center justify-center">
          <HelperMessage message="No designs found" />
        </div>
      )}

      {!(loading || isLoadingDesignDetails) && !isEmpty(designs) && (
        <ul className="flex items-end gap-4 py-3">
          {designs.map((design: any) => (
            <li
              key={design.id}
              onClick={() => handleFetchDesignDetails(design.id)}
            >
              <img
                id={design?.id}
                className="w-40 pb-10/12"
                src={design?.previewUrl}
                alt=""
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
