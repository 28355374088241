import { ChangeEvent, FC, useEffect, useState } from "react";
import { IconButtonBase, SaveIndicator } from "@amenda-components/App";
import { formatNumbersAlt, reverseNumberFormatting } from "@amenda-utils";
import {
  useProjectStore,
  useUpdateRegionalFactor,
} from "@amenda-domains/mutations";

import { NumericFormat } from "react-number-format";
import { PaginationLimit } from "@amenda-constants";
import { PencilIcon } from "lucide-react";
import { ReactTable } from "@amenda-components/Shared";
import { ReactTableKeys } from "@amenda-types";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { getRegionalFactorYears } from "../common";
import isNil from "lodash/isNil";
import { useGetAllRegionalFactors } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface TableEditableCellProps {
  year: number;
  regionalFactor?: Record<string, any>;
  updateRegionalFactor: (args: any) => Promise<void>;
}

const TableEditableCell: FC<TableEditableCellProps> = ({
  year,
  regionalFactor,
  updateRegionalFactor,
}) => {
  const [editable, setEditable] = useState(false);

  const defaultValue =
    isNil(regionalFactor?.[String(year)]) ||
    Number.isNaN(regionalFactor?.[String(year)])
      ? undefined
      : Number(regionalFactor?.[String(year)]);
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(reverseNumberFormatting(e.target.value));
  };

  const handlePointerLeave = async () => {
    if (editable && regionalFactor && value !== defaultValue) {
      let updatedRegionalFactor = {
        _id: regionalFactor.id,
        city: regionalFactor.city,
        factors: {
          [String(year)]: value,
        },
      };

      setEditable(false);
      await updateRegionalFactor({
        input: updatedRegionalFactor,
      });
    }
  };

  if (editable) {
    return (
      <TableCellWrapper
        className="relative px-1 py-0.5"
        onPointerLeave={handlePointerLeave}
      >
        <NumericFormat
          className="h-full w-full text-sm"
          value={value}
          onChange={handleChange}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={3}
        />
      </TableCellWrapper>
    );
  }
  return (
    <TableCellWrapper className="group/tcw relative">
      {isNil(value) ? "_" : formatNumbersAlt({ value, fixedTo: 3 })}
      <IconButtonBase
        size="xss"
        className="invisible absolute right-0 group-hover/tcw:visible"
        onClick={() => setEditable(true)}
      >
        <PencilIcon className="h-4 w-4" />
      </IconButtonBase>{" "}
    </TableCellWrapper>
  );
};

const SavingRegionalFactor = () => {
  const loading = useProjectStore((state) => state.isUpdatingRegionalFactor);

  return <SaveIndicator isSubmitting={loading} />;
};

export const RegionalFactorsTable: FC = () => {
  const { t } = useTranslation();
  const { getAllRegionalFactors, loading } = useGetAllRegionalFactors();
  const regionalFactors = useProjectStore((state) => state.regionalFactors);
  const regionalFactorsPagination = useProjectStore(
    (state) => state.regionalFactorsPagination,
  );
  const { updateRegionalFactor } = useUpdateRegionalFactor();

  const regionalFactorYears = getRegionalFactorYears();

  const fetchNextPage = async () => {
    if (regionalFactorsPagination?.hasNext) {
      await getAllRegionalFactors({
        isTableView: true,
        limit: PaginationLimit.regionalFactors,
        next: regionalFactorsPagination?.next,
        selectRegionalFactorFields: {
          city: 1,
          factors: 1,
        },
      });
    }
  };

  const handleFilter = async (columnSorting: any) => {
    await getAllRegionalFactors({
      columnSorting,
      isTableView: true,
      limit: PaginationLimit.regionalFactors,
      selectRegionalFactorFields: {
        city: 1,
        factors: 1,
      },
    });
  };

  useEffect(() => {
    getAllRegionalFactors({
      isTableView: true,
      limit: PaginationLimit.regionalFactors,
      selectRegionalFactorFields: {
        city: 1,
        factors: 1,
      },
    });
  }, [getAllRegionalFactors]);

  return (
    <ReactTable
      data={regionalFactors}
      isLoading={loading}
      isFullWidth
      defaultPinnedColumns={{
        left: ["city"],
      }}
      tableId={ReactTableKeys.RegionalFactors}
      pagination={regionalFactorsPagination}
      containerClass="h-[calc(100vh-15rem)] w-full"
      tableConfigChildren={<SavingRegionalFactor />}
      fetchNextPage={fetchNextPage}
      handleFilter={handleFilter}
      columns={[
        {
          id: "city",
          minSize: 20,
          header: t("City"),
          meta: {
            label: t("City"),
          },
          accessorKey: "city",
          cell: ({ row }: any) => {
            const regionalFactor = row?.original;

            return (
              <TableCellWrapper isText={true}>
                {regionalFactor?.city}
              </TableCellWrapper>
            );
          },
        },
        ...regionalFactorYears.map((year) => ({
          id: String(year),
          minSize: 20,
          header: String(year),
          meta: {
            label: String(year),
            isNumber: true,
          },
          accessorKey: String(year),
          cell: ({ row }: any) => {
            const regionalFactor = row?.original;

            return (
              <TableEditableCell
                year={year}
                regionalFactor={regionalFactor}
                updateRegionalFactor={updateRegionalFactor}
              />
            );
          },
        })),
      ]}
    />
  );
};
