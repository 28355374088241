import { PROJECT_DESIGN_FRAGMENT } from "@amenda-domains/fragments/designs";
import { AllowedDesignType, Pagination } from "@amenda-types";
import { gql, useMutation } from "urql";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type State = {
  designs: any[];
  templates: any[];
  selectedProjectDesign: Record<string, any>;
  openTemplateDesignModal: boolean;
  templatesPagination: Pagination;
  designsPagination: Pagination;
};

type Actions = {
  setProjectDesigns: (
    type: AllowedDesignType,
    props: Pagination & {
      designs: any[];
    },
  ) => void;
  setSelectedProjectDesign: (
    selectedProjectDesign: Record<string, any>,
  ) => void;
  clearSelectedDesignData: () => void;
  setTemplateDesignModal: (openTemplateDesignModal: boolean) => void;
  clearPagination: () => void;
};
const persistedStateVersion = 0.2;

export const useProjectDesignsStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      designs: [],
      templates: [],
      templatesPagination: {},
      designsPagination: {},
      selectedProjectDesign: {},
      openTemplateDesignModal: false,
      setProjectDesigns: (type, { designs, ...pagination }) =>
        set((state) => {
          if (type === AllowedDesignType.custom) {
            state.designs = designs || [];
            const docsCount =
              pagination?.docsCount ?? get().designsPagination.docsCount ?? 0;
            state.designsPagination = {
              docsCount,
              ...pagination,
            };
          } else if (type === AllowedDesignType.template) {
            state.templates = designs || [];
            const docsCount =
              pagination?.docsCount ?? get().templatesPagination.docsCount ?? 0;
            state.templatesPagination = {
              docsCount,
              ...pagination,
            };
          }
        }),
      setSelectedProjectDesign: (selectedProjectDesign) =>
        set((state) => {
          state.selectedProjectDesign = selectedProjectDesign;
        }),
      setTemplateDesignModal: (openTemplateDesignModal) =>
        set((state) => {
          state.openTemplateDesignModal = openTemplateDesignModal;
        }),
      clearSelectedDesignData: () =>
        set((state) => {
          state.selectedProjectDesign = {};
          state.openTemplateDesignModal = false;
        }),
      clearPagination() {
        set((state) => {
          const designsDocsCount = get().designsPagination.docsCount ?? 0;
          const templatesDocsCount = get().templatesPagination.docsCount ?? 0;

          state.designsPagination = {
            docsCount: designsDocsCount,
          };
          state.templatesPagination = {
            docsCount: templatesDocsCount,
          };
        });
      },
    })),
    {
      name: "projects-designs-storage",
      version: persistedStateVersion,
      storage: createJSONStorage(() => sessionStorage),
      //   partialize: (state) => ({

      //   }),
    },
  ),
);

const CREATE_PROJECT_DESIGN = gql`
  ${PROJECT_DESIGN_FRAGMENT}
  mutation CreateProjectDesign($input: ProjectDesignInput!) {
    createProjectDesign(input: $input) {
      ...ProjectDesignFragment
    }
  }
`;

const UPDATE_PROJECT_DESIGN = gql`
  ${PROJECT_DESIGN_FRAGMENT}
  mutation UpdateProjectDesign($input: ProjectDesignUpdateInput!) {
    updateProjectDesign(input: $input) {
      ...ProjectDesignFragment
    }
  }
`;

export const useCreateProjectDesign = () => {
  const setSelectedProjectDesign = useProjectDesignsStore(
    (state) => state.setSelectedProjectDesign,
  );
  const [upsertResult, callCreateProjectDesign] = useMutation(
    CREATE_PROJECT_DESIGN,
  );

  const createProjectDesign = (variables: Record<string, any>) => {
    return callCreateProjectDesign(variables).then(({ data }) => {
      const projectDesign = data?.createProjectDesign ?? {};
      setSelectedProjectDesign(projectDesign);
      return projectDesign;
    });
  };

  const { fetching } = upsertResult;

  return {
    createProjectDesign,
    createProjectDesignLoader: fetching,
  };
};

export const useUpdateProjectDesign = () => {
  const setSelectedProjectDesign = useProjectDesignsStore(
    (state) => state.setSelectedProjectDesign,
  );
  const [upsertResult, callUpdateProject] = useMutation(UPDATE_PROJECT_DESIGN);

  const updateProjectDesign = (variables: Record<string, any>) => {
    return callUpdateProject(variables).then(({ data }) => {
      if (data?.updateProjectDesign) {
        setSelectedProjectDesign(data.updateProjectDesign);
      }
      return data?.updateProjectDesign;
    });
  };

  const { fetching } = upsertResult;

  return {
    updateProjectDesign,
    updateProjectDesignLoader: fetching,
  };
};
