import { FC, useEffect, useMemo } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { MainContainer, SidebarToggle } from "@amenda-components/Shared";
import {
  ProjectActionBar,
  ProjectFormTabs,
  ProjectSectionSidebar,
  ProjectSectionSidebarMobile,
} from "@amenda-components/Projects";
import {
  useAppStore,
  useProjectStore,
  useSettingsStore,
} from "@amenda-domains/mutations";

import { TabItem } from "@amenda-types";
import { getFormViewComponents } from "@amenda-constants/template";
import { getQueryParams } from "@amenda-utils";
import { getStaticProjectTabs } from "./common";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  projectId?: string;
}

export const CreateOrEditProject: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const projectForms = useProjectStore((state) => state.projectForms);
  const isFetchingProject = useProjectStore((state) => state.isFetchingProject);
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const projectFormComponents = useProjectStore(
    (state) => state.projectFormComponents,
  );

  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const availableForms = useMemo(() => projectForms || [], [projectForms]);
  const projectTabs = useMemo(() => {
    const tabs: TabItem[] =
      availableForms?.map((form) => ({
        label: form.name,
        value: form.id,
      })) || [];

    tabs.push(...getStaticProjectTabs(projectsGeneralPermissions));

    return tabs;
  }, [availableForms, projectsGeneralPermissions]);

  const formViewTabs = useMemo(() => {
    const tabs: TabItem[] =
      selectedFormView?.sections?.map((s: any) => ({
        value: s.id,
        label: s.label,
      })) || [];

    return tabs;
  }, [selectedFormView]);

  const query = getQueryParams(search);
  const resourceId = projectId || selectedProject?.id;
  const formViewForms = (selectedFormView?.sections ?? []).map((s: any) => ({
    id: s.id,
    name: s.label,
    order: s.order,
    components: getFormViewComponents(projectFormComponents, s.components),
  }));
  const availableTabs = isEmpty(selectedFormView) ? projectTabs : formViewTabs;
  const forms = isEmpty(selectedFormView) ? availableForms : formViewForms;
  const defaultTab = query.tab || availableTabs[0].value;

  useEffect(() => {
    if (sidebarOpen) {
      setOpenSidebar(false);
    }
  }, [sidebarOpen, setOpenSidebar]);

  return (
    <div className="flex h-full w-full flex-row">
      <ProjectSectionSidebar
        resourceId={resourceId}
        availableForms={availableForms}
        projectsGeneralPermissions={projectsGeneralPermissions}
      />
      <ProjectSectionSidebarMobile
        resourceId={resourceId}
        availableForms={availableForms}
        projectsGeneralPermissions={projectsGeneralPermissions}
      />
      <div className="h-screen w-full overflow-y-auto overscroll-y-contain pr-4">
        <MainContainer className="pt-6">
          {!isFetchingProject && (
            <div className="sticky top-0 z-30 w-full bg-white">
              <div className="flex w-full items-center justify-between">
                <div className="flex grow items-center">
                  <SidebarToggle />
                  <h3 className="amenda-page-title truncate">
                    {selectedProject?.name || t("New Project")}
                  </h3>
                </div>
                <div className="flex justify-end">
                  <ProjectActionBar isEditing={true} />
                </div>
              </div>
            </div>
          )}
          <div className="h-auto w-full">
            <ProjectFormTabs
              defaultTab={defaultTab}
              projectId={resourceId}
              forms={forms}
              availableTabs={availableTabs}
            />
          </div>
        </MainContainer>
      </div>
    </div>
  );
};
