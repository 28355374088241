import {
  ATTACHMENT_FRAGMENT,
  SUBSCRIPTION_ATTACHMENT_FRAGMENT,
} from "@amenda-domains/fragments/attachments";
import {
  AllowedAttachmentType,
  ComponentValidationType,
  FormTab,
  ImageAttachmentProps,
  PageComponentProps,
  PaginationQueryProps,
  ReactTableKeys,
} from "@amenda-types";
import { FormComponentTypes, SearchCollections } from "@amenda-constants";
import {
  devConsole,
  formatDate,
  formatNumbers,
  generateColumnSorting,
  getComponentsById,
  getComponentsFromForms,
  getSelectableFields,
  mergeJson,
} from "@amenda-utils";
import { gql, useClient, useSubscription } from "urql";
import { isEmpty, isNil, isString } from "lodash";
import {
  useAppStore,
  useAttachmentStore,
  useProjectStore,
} from "@amenda-domains/mutations";
import { useCallback, useState } from "react";

import { addMatchForCollection } from "@amenda-components/Shared/common";
import { processMagicLinks } from "@amenda-utils";
import stringTemplate from "json-templater/string";

const SEARCH_ATTACHMENTS = gql`
  ${ATTACHMENT_FRAGMENT}
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    search(
      input: {
        limit: $limit
        searchTerm: $searchTerm
        collections: $collections
        filters: $filters
        aggregations: $aggregations
      }
    ) {
      attachments {
        ...AttachmentFragment
      }
    }
  }
`;

export const GET_ALL_ATTACHMENTS = gql`
  ${ATTACHMENT_FRAGMENT}
  query GetAllAttachments(
    $type: AllowedAttachmentType!
    $isDeleted: Boolean
    $selectAttachmentFields: Object
    $sort: Object
    $formValues: Object
    $ids: [ID!]
    $limit: Int
    $next: String
    $previous: String
  ) {
    getAllAttachments(
      ids: $ids
      type: $type
      formValues: $formValues
      isDeleted: $isDeleted
      selectAttachmentFields: $selectAttachmentFields
      sort: $sort
      limit: $limit
      next: $next
      previous: $previous
    ) {
      docsCount
      hasNext
      hasPrevious
      previous
      next
      filteredDocsCount
      attachments {
        ...AttachmentFragment
      }
    }
  }
`;

const GET_ATTACHMENTS_BY_IDS = gql`
  ${ATTACHMENT_FRAGMENT}
  query (
    $type: AllowedAttachmentType
    $attachmentIds: [String!]!
    $isDeleted: Boolean
  ) {
    attachments: getAttachmentsByIds(
      type: $type
      attachmentIds: $attachmentIds
      isDeleted: $isDeleted
    ) {
      ...AttachmentFragment
    }
  }
`;

const ATTACHMENT_CREATED_SUBSCRIPTION = gql`
  subscription AttachmentCreated($ownerId: String!, $tenantId: String!) {
    attachmentCreated(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
    }
  }
`;

const ATTACHMENT_UPDATED_SUBSCRIPTION = gql`
  ${SUBSCRIPTION_ATTACHMENT_FRAGMENT}
  subscription AttachmentUpdated($ownerId: String!, $tenantId: String!) {
    attachmentUpdated(ownerId: $ownerId, tenantId: $tenantId) {
      ...SubscriptionAttachmentFragment
    }
  }
`;

const ATTACHMENT_DELETED_SUBSCRIPTION = gql`
  subscription AttachmentDeleted($ownerId: String!, $tenantId: String!) {
    attachmentDeleted(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
      isDeleted
    }
  }
`;

const GET_ATTACHMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  query GetAttachment(
    $id: ID
    $type: AllowedAttachmentType!
    $selectAttachmentFields: Object
    $isDeleted: Boolean
    $ownerId: String
  ) {
    attachment: getAttachment(
      _id: $id
      type: $type
      isDeleted: $isDeleted
      ownerId: $ownerId
      selectAttachmentFields: $selectAttachmentFields
    ) {
      ...AttachmentFragment
      originalFilename
      description
      metadata
    }
  }
`;

const processAttachmentImages = async (
  attachments: Record<string, any>,
  params: Record<string, any>,
) => {
  let updatedImages = {};
  Object.keys(attachments).forEach((key) => {
    const src = attachments[key]?.properties?.src;
    updatedImages = {
      ...updatedImages,
      [key]: {
        ...attachments[key],
        properties: {
          ...attachments[key]?.properties,
          src: processMagicLinks(src, params),
        },
      },
    };
  });
  return updatedImages;
};

const replaceCurlyBraces = (text: string): string => {
  return text.replace(/{{[^}]+}}/g, " — ");
};

const processComponentProperties = (properties: any, data: any) => {
  let resolvedProperties = {
    ...(properties ?? {}),
  };
  Object.keys(resolvedProperties)
    .filter((key) =>
      ["title", "companyName", "content", "description"].includes(key),
    )
    .forEach((key) => {
      if (isString(properties[key])) {
        let result = stringTemplate(properties[key], {
          ...data,
        });

        resolvedProperties = {
          ...resolvedProperties,
          [key]: replaceCurlyBraces(result),
        };
      } else {
        resolvedProperties = {
          ...resolvedProperties,
          [key]: properties[key],
        };
      }
    });
  return resolvedProperties;
};

const processTemplateComponents = (
  components: PageComponentProps[],
  data: any,
) => {
  return components.map(({ properties, ...rest }) => {
    return {
      ...rest,
      properties: processComponentProperties(properties, data),
    };
  });
};

interface ProcessTemplateDataArgs {
  projectForms?: FormTab[];
  imageParams?: any;
  selectedProject?: any;
  templateValues?: Record<string, ImageAttachmentProps>;
  templateStructure?: any[];
  processMagicLinks?: boolean;
}

const formatAndFlattenProjectFormValues = (
  selectedProject: any,
  componentsById: Record<string, any>,
) => {
  const { formValues, ...rest } = selectedProject;
  const flattenedProject = {
    ...rest,
  };

  if (formValues) {
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      const component = componentsById[key];
      if (!component) {
        flattenedProject[key] = value;
        return;
      }
      if (component?.validation?.type === ComponentValidationType.Number) {
        flattenedProject[key] = value ? formatNumbers(value) : value;
      } else if (component.component === FormComponentTypes.DatePicker) {
        flattenedProject[key] = value ? formatDate(value) : value;
      } else if (component.component === FormComponentTypes.DatePickerRange) {
        flattenedProject[key] = value
          ? {
              to: value?.to ? formatDate(value.to) : value?.to,
              from: value?.from ? formatDate(value.from) : value?.from,
            }
          : value;
      } else {
        flattenedProject[key] = value;
      }
    });
  }

  return flattenedProject;
};

export const processTemplateData = async ({
  projectForms,
  selectedProject,
  templateStructure,
  imageParams = {},
  templateValues = {},
  processMagicLinks = false,
}: ProcessTemplateDataArgs) => {
  let processedTemplate: any = [];

  if (!selectedProject || !templateStructure || !projectForms) {
    return processedTemplate;
  }
  const components = getComponentsFromForms(projectForms);
  const componentsById = getComponentsById(components);
  const flattenedProject = formatAndFlattenProjectFormValues(
    selectedProject,
    componentsById,
  );

  processedTemplate = processTemplateComponents(templateStructure, {
    project: flattenedProject,
  });
  if (processMagicLinks) {
    const data = await processAttachmentImages(templateValues, imageParams);
    processedTemplate = mergeJson(processedTemplate, data, "id");
  } else {
    processedTemplate = mergeJson(processedTemplate, templateValues, "id");
  }
  return processedTemplate;
};

export const useAttachmentCreatedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  useSubscription(
    {
      query: ATTACHMENT_CREATED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {},
  );
};

export const useAttachmentUpdatedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  const updateAttachments = useAttachmentStore(
    (state) => state.updateAttachments,
  );
  const updateSelectedAttachmentImages = useAttachmentStore(
    (state) => state.updateSelectedAttachmentImages,
  );
  const updateBulkEditorAttachment = useAttachmentStore(
    (state) => state.updateBulkEditorAttachment,
  );

  useSubscription(
    {
      query: ATTACHMENT_UPDATED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    (messages, response) => {
      const attachment = response?.attachmentUpdated;

      if (attachment) {
        updateAttachments(attachment);
        updateSelectedAttachmentImages(attachment);
        updateBulkEditorAttachment(attachment);
      }
    },
  );
};

export const useAttachmentDeletedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  useSubscription(
    {
      query: ATTACHMENT_DELETED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {},
  );
};

interface GetAllAttachmentArgs extends PaginationQueryProps {
  attachmentType: string;
  ids?: string[];
  isCollection?: boolean;
  formValues?: Record<string, any>;
  context?: Record<string, any>;
  isTableView?: boolean;
  columnSorting?: any;
  callback?: (data: any) => void;
}

export const useGetAllAttachments = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setAttachments = useAttachmentStore((state) => state.setAttachments);
  const setAttachmentLoader = useAttachmentStore(
    (state) => state.setAttachmentLoader,
  );
  const components = useProjectStore((state) => state.attachmentFormComponents);
  const values = getSelectableFields({
    components,
    componentTypes: [FormComponentTypes.Keyword],
  });
  const stringifiedValues = JSON.stringify(values);

  const getAllAttachments = useCallback(
    async ({
      attachmentType,
      formValues,
      ids,
      limit,
      next,
      isCollection,
      context = {},
      columnSorting = {},
      isTableView = false,
      callback,
    }: GetAllAttachmentArgs) => {
      const values = JSON.parse(stringifiedValues);
      const sortProps = isTableView
        ? generateColumnSorting({
            ...columnSorting,
            defaultSorting: {
              sort: { createdAt: -1 },
            },
            getPrefix: () => "formValues",
          })
        : {
            sort: { createdAt: -1 },
          };
      let variables: any = {
        ...sortProps,
        ids,
        limit,
        formValues,
        type: attachmentType,
        isDeleted: false,
        selectAttachmentFields: {
          filename: 1,
          url: 1,
          type: 1,
          name: 1,
          createdAt: 1,
          description: 1,
          isDeleted: 1,
          metadata: 1,
          termsOfUse: 1,
          dimensions: 1,
          originalFilename: 1,
          ownerId: 1,
          share: 1,
          ...(isTableView
            ? {
                formValues: 1,
              }
            : {
                formValues: {
                  name: 1,
                  description: 1,
                  rateDetail: 1,
                  constructionDate: 1,
                  ...values,
                },
              }),
        },
      };
      if (!isNil(next)) {
        variables.next = next;
      }

      const isFetching = !isCollection || (isCollection && !!next);

      setLoading(true);
      setAttachmentLoader(true, isFetching);

      const { data } = await client.query(
        GET_ALL_ATTACHMENTS,
        variables,
        context,
      );

      if (data?.getAllAttachments) {
        callback
          ? callback(data.getAllAttachments.attachments)
          : setAttachments(data.getAllAttachments);
      }
      setLoading(false);
      setAttachmentLoader(false, isFetching);

      return data?.attachments;
    },
    [client, stringifiedValues, setAttachments, setAttachmentLoader],
  );

  return {
    loading,
    getAllAttachments,
  };
};

interface GetAttachmentArgs {
  id: string;
  type: AllowedAttachmentType;
  selectedAttachmentFields?: Record<string, any>;
  attachmentFields?: Record<string, any>;
  context?: Record<string, any>;
  callback?: (attachment: any) => void;
}

export const useGetAttachment = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );

  const getAttachment = useCallback(
    async ({ callback, context, ...variables }: GetAttachmentArgs) => {
      setLoading(true);

      const response = await client
        .query(GET_ATTACHMENT, variables, context)
        .toPromise();

      const attachment = response?.data?.attachment;
      callback ? callback(attachment) : setSelectedAttachment(attachment);

      setLoading(false);
    },
    [client, setSelectedAttachment],
  );
  return {
    loading,
    getAttachment,
  };
};

export const useGetAttachmentsByIds = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getAttachmentsByIds = useCallback(
    async (variables: Record<string, any>) => {
      setLoading(true);

      const { data } = await client
        .query(GET_ATTACHMENTS_BY_IDS, variables)
        .toPromise();

      if (data?.attachments) {
        return data.attachments;
      }
      setLoading(false);
    },
    [client],
  );

  return {
    loading,
    getAttachmentsByIds,
  };
};

interface SearchAttachmentsArgs {
  filters?: Record<string, any>;
  isCollection?: boolean;
  resourceIds?: string[];
  searchTerm?: string;
  isTableView?: boolean;
  columnSorting?: any;
  callback?: (attachments: any[]) => void;
}

export const useSearchAttachments = () => {
  const client = useClient();
  const setAttachments = useAttachmentStore((state) => state.setAttachments);
  const [loading, setLoading] = useState(false);
  const components = useProjectStore((state) => state.attachmentFormComponents);
  const setColumnSorting = useAppStore((state) => state.setColumnSorting);

  const values = getSelectableFields({
    components,
    prefix: "formValues",
    componentTypes: [FormComponentTypes.Keyword],
  });
  const stringifiedValues = JSON.stringify(values);

  const searchAttachments = useCallback(
    async ({
      isCollection,
      resourceIds,
      searchTerm,
      isTableView,
      columnSorting,
      filters = {},
      callback,
    }: SearchAttachmentsArgs) => {
      const searchAggregations = [
        {
          $search: {
            index: "autocomplete",
            compound: {
              should: [
                {
                  autocomplete: {
                    query: searchTerm,
                    path: "name",
                  },
                },
                {
                  autocomplete: {
                    query: searchTerm,
                    path: "originalFilename",
                  },
                },
              ],
            },
          },
        },
        {
          $addFields: {
            score: {
              $meta: "searchScore",
            },
          },
        },
        {
          $setWindowFields: {
            output: {
              maxScore: {
                $max: "$score",
              },
            },
          },
        },
        {
          $addFields: {
            normalizedScore: {
              $divide: ["$score", "$maxScore"],
            },
          },
        },
      ];
      let allAggregations: any[] = [];
      const values = JSON.parse(stringifiedValues);
      const sorting = generateColumnSorting({
        ...columnSorting,
        useCaseInsensitiveSort: false,
        getPrefix: () => "formValues",
      });
      let sortAggregations =
        isTableView && sorting?.sort
          ? [
              {
                $sort: sorting.sort,
              },
            ]
          : [];
      if (isTableView && !sorting?.sort && filters.type) {
        const tableId =
          filters.type === AllowedAttachmentType.pdf
            ? ReactTableKeys.ConstructionDetails
            : ReactTableKeys.Gallery;
        setColumnSorting({
          tableId,
          columnSorting: {} as any,
        });
      }

      if (searchTerm) {
        allAggregations = searchAggregations;
      }
      let matchAggregations: any[] = [
        {
          $match: {
            isDeleted: false,
          },
        },
      ];
      if (!isEmpty(filters)) {
        matchAggregations = [
          {
            $match: {
              ...filters,
              isDeleted: false,
            },
          },
        ];
      }
      if (isCollection) {
        matchAggregations = [
          {
            $match: {
              ...filters,
              ...addMatchForCollection(resourceIds ?? []),
              isDeleted: false,
            },
          },
        ];
      }

      try {
        setLoading(true);
        const response = await client
          .query(
            SEARCH_ATTACHMENTS,
            {
              collections: [SearchCollections.Attachments],
              aggregations: {
                [SearchCollections.Attachments]: [
                  ...allAggregations,
                  ...matchAggregations,
                  ...sortAggregations,
                  {
                    $project: {
                      filename: 1,
                      url: 1,
                      type: 1,
                      name: 1,
                      createdAt: 1,
                      description: 1,
                      isDeleted: 1,
                      metadata: 1,
                      termsOfUse: 1,
                      dimensions: 1,
                      originalFilename: 1,
                      ownerId: 1,
                      share: 1,
                      ...(isTableView
                        ? {
                            formValues: 1,
                          }
                        : {
                            "formValues.name": 1,
                            "formValues.description": 1,
                            "formValues.rateDetail": 1,
                            "formValues.constructionDate": 1,
                            "formValues.slider": 1,
                            ...values,
                          }),
                    },
                  },
                ],
              },
            },
            {
              customKey: "searchAttachments",
              requestPolicy: "network-only",
            },
          )
          .toPromise();

        const { data } = response;

        callback
          ? callback(data?.search?.attachments ?? [])
          : setAttachments(data?.search);
        setLoading(false);
      } catch (err) {
        devConsole?.error("amenda:something went wrong", err);
      }
    },
    [client, stringifiedValues, setAttachments, setColumnSorting],
  );

  return {
    loading,
    searchAttachments,
  };
};
