import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { GeneralPermissionTypes, HoverVariants } from "@amenda-types";
import { HoverSelector, Image, Tag, Tags } from "@amenda-components/App";
import {
  MediaGalleryProps,
  getGalleryCardKeywordIds,
  getKeywordsFromSidebarFilters,
} from "./common";
import {
  useAppStore,
  useAttachmentStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import DragInfoModal from "./DragInfoModal";
import GalleryDragHandle from "./GalleryDragHandle";
import { ReadOnlyKeywordsBase } from "@amenda-components/Shared/ReadOnlyKeywords";
import clsx from "clsx";
import { getComponentsFromForms } from "@amenda-utils";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

interface TagsAndNameProps {
  attachment: any;
}

const TagsAndName: FC<TagsAndNameProps> = ({ attachment }) => {
  const { ref, inView } = useInView();
  const forms = useProjectStore((state) => state.forms);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { sidebarFilters } = useSidebarFiltersWithPath();
  const isScrolling = useAppStore((state) => state.isScrolling);

  const availableForms = forms?.[AvailableForms.Gallery];
  const components = getComponentsFromForms(availableForms);
  const { keywordIds, keywordComponentIds } = getGalleryCardKeywordIds({
    permissions,
    components,
    attachment,
  });
  const filterKeywords = getKeywordsFromSidebarFilters(
    sidebarFilters,
    keywordComponentIds,
  );

  return (
    <>
      <div ref={ref} />
      <div
        className={clsx("absolute inset-0 bg-black/15 p-2", {
          "hidden group-hover:block": isSearchFilterEmpty(sidebarFilters),
        })}
      >
        <div className="relative h-full w-full">
          {inView && !isScrolling && (
            <ReadOnlyKeywordsBase keywords={keywordIds}>
              {(loading, selectedKeywords) => (
                <div className="absolute bottom-0 left-0 max-h-[50%] w-full overflow-hidden">
                  <div className="truncate pb-2 text-sm text-white">
                    {attachment?.originalFilename ?? attachment?.filename}
                  </div>
                  <Tags className="gap-x-1 gap-y-0.5">
                    {selectedKeywords?.map((keyword) => {
                      const isSelected = filterKeywords.includes(keyword.id);

                      return (
                        <Tag
                          key={keyword?.id}
                          variant="secondary"
                          className="px-2 py-0.5 text-sm"
                          isSelected={isSelected}
                        >
                          {keyword?.name}
                        </Tag>
                      );
                    })}
                  </Tags>
                </div>
              )}
            </ReadOnlyKeywordsBase>
          )}
        </div>
      </div>
    </>
  );
};

interface GalleryCardProps extends Pick<MediaGalleryProps, "rootRoute"> {
  attachment: any;
}

export const GalleryCard: FC<GalleryCardProps> = ({
  attachment,
  rootRoute,
}) => {
  const navigate = useNavigate();
  const selectedImageVariant = useAttachmentStore(
    (state) => state.selectedImageVariant,
  );
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const toggleSelectedAttachment = useAttachmentStore(
    (state) => state.toggleSelectedAttachment,
  );
  const setShiftSelectedAttachment = useAttachmentStore(
    (state) => state.setShiftSelectedAttachment,
  );
  const [isDragging, setDragging] = useState(false);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );

  const handleClick = () => {
    const path = `${rootRoute}/${attachment.id}`;

    setSelectedAttachment(attachment);
    navigate(path);
  };

  const handleSelect = () => {
    toggleSelectedAttachment(attachment.id);
  };

  const handleShiftSelect = () => {
    setShiftSelectedAttachment(attachment);
  };

  return (
    <>
      <DragInfoModal isDragging={isDragging} />
      <HoverSelector
        canSelect
        className="group relative cursor-pointer"
        variant={HoverVariants.Checkbox}
        id={attachment?.id}
        isSelected={selectedAttachments.includes(attachment?.id)}
        handleSelect={handleSelect}
        handleClick={handleClick}
        handleShiftSelect={handleShiftSelect}
      >
        <div className="relative">
          {mediaGeneralPermissions[GeneralPermissionTypes.Download] && (
            <GalleryDragHandle
              attachment={attachment}
              setDragging={setDragging}
            />
          )}
          <Image
            url={attachment.url}
            variant={selectedImageVariant}
            magicSize="gallery"
          >
            {() => <TagsAndName attachment={attachment} />}
          </Image>
        </div>
      </HoverSelector>
    </>
  );
};
