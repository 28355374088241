import { AcceptedShareIcon, DeleteModal } from "@amenda-components/Shared";
import {
  ActionBar,
  Button,
  IconButtonBase,
  SaveIndicator,
  Tooltip,
} from "@amenda-components/App";
import { AppRoutes, GeneralPermissionTypes } from "@amenda-types";
import {
  ArchiveIcon,
  BuildingIcon,
  PencilIcon,
  PrinterIcon,
  XIcon,
} from "lucide-react";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
  processGeneralAccessToResource,
} from "@amenda-components/Settings/common";
import {
  PermissionSharePath,
  ResourceSharingPermissionTypes,
} from "@amenda-components/Shared/common";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useAppStore,
  useDeleteProjects,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
  useUpdateProject,
  useUsersStore,
} from "@amenda-domains/mutations";

import { GeneralAccessTypes } from "@amenda-constants";
import { ProjectExpandFormsButton } from "./ProjectExpandFormsButton";
import { ShareModal } from "@amenda-components/Shared/ShareModal";
import { SingleCheckbox } from "@amenda-components/FormComponents";
import { addQueryToPath } from "./common";
import { getQueryParams } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useFormAutoSave } from "@amenda-components/PageBuilder";
import { useTranslation } from "react-i18next";

export const ProjectActionBar: FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId?: string }>();
  const isSavingProject = useProjectStore((state) => state.isSavingProject);
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [deleteRelatedResources, setDeleteRelatedResources] =
    useState<boolean>(false);
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues,
  );
  const { deleteProjects, loading } = useDeleteProjects();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const currentUser = useUsersStore((state) => state.currentUser);
  const { updateProject, updateProjectLoader } = useUpdateProject();
  const { handleSubmitManually } = useFormAutoSave();
  const { sidebarFilters } = useSidebarFiltersWithPath();

  const query = getQueryParams(search);
  const selectedShare = selectedProject?.share?.type;
  const { t } = useTranslation();
  const projectsGeneralPermissions = processGeneralAccessToResource({
    shareTo: selectedProject?.share?.users,
    shareType: selectedProject?.share?.type,
    ownerId: selectedProject?.ownerId,
    currentUserId: currentUser?.id,
    generalPermissions: getFromGeneralPermissions(
      permissions,
      GeneralPermissionKeys.Projects,
    ),
  });
  const isNewProject = isEmpty(projectId);

  const handleCheckBox = () =>
    setDeleteRelatedResources(!deleteRelatedResources);

  const handleDelete = async () => {
    await deleteProjects({
      deleteRelatedResources,
      ids: [selectedProject.id],
    });
    setOpenModal(false);
    navigate("/projekte");
    clearProjectValues();
  };

  const handleEdit = () => {
    navigate(
      addQueryToPath(`/projekte/${selectedProject.id}/bearbeiten`, query),
    );
  };

  const openPrintTab = () => {
    const url = addQueryToPath(
      `/projekte/${selectedProject.id}/drucken`,
      query,
    );
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleShare = async (type: GeneralAccessTypes, users: any[]) => {
    const share = {
      type,
      users: users.map((u) => ({
        id: u._id,
        role: u[PermissionSharePath] || ResourceSharingPermissionTypes.View,
      })),
    };

    await updateProject({
      input: {
        share,
        _id: selectedProject.id,
      },
    });
    setOpenShareModal(false);
  };

  const openDeleteModal = () => setOpenModal(true);
  const handleCloseShare = () => setOpenShareModal(false);

  const handleCloseProject = async () => {
    const path = selectedProject?.id
      ? generatePath(AppRoutes.ProjectView, {
          projectId: selectedProject?.id,
        })
      : AppRoutes.Projects;

    await handleSubmitManually?.();
    if (!isEmpty(sidebarFilters)) {
      setOpenSidebar(true);
    }
    navigate(addQueryToPath(path, query));
  };

  return (
    <div>
      <DeleteModal
        title="Archive Project"
        description="Are you sure you want to archive this project?"
        successLabel="Ins Archiv verschieben"
        openModal={openModal}
        loading={loading}
        handleDelete={handleDelete}
        handleCloseModal={() => setOpenModal(false)}
      >
        <div className="px-4 pb-4">
          <SingleCheckbox
            id="remove-attachments"
            label="Archive all files associated with the project"
            checked={deleteRelatedResources}
            onChange={handleCheckBox}
          />
        </div>
      </DeleteModal>
      <ShareModal
        title="Share the project with"
        loading={updateProjectLoader}
        openModal={openShareModal}
        ownerId={selectedProject?.ownerId}
        shareType={selectedProject?.share?.type}
        shareWith={selectedProject?.share?.users || []}
        onSubmit={handleShare}
        handleClose={handleCloseShare}
      />
      <ActionBar className="flex flex-row justify-end">
        {({ IconButton }) => (
          <>
            <ProjectExpandFormsButton />
            {isEditing ? (
              <>
                <SaveIndicator isSubmitting={isSavingProject} />
                <Tooltip id="back" message="Close Project">
                  <IconButtonBase
                    size="xs"
                    label="Close Project"
                    variant="default"
                    onClick={handleCloseProject}
                  >
                    <XIcon className="h-5 w-5" />
                  </IconButtonBase>
                </Tooltip>
              </>
            ) : (
              <>
                {projectsGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip
                    id="share"
                    message={
                      selectedShare === GeneralAccessTypes.Everyone
                        ? "Shared with Organization"
                        : "Share the project with"
                    }
                  >
                    <Button
                      variant="outline"
                      size="xs"
                      onClick={() => setOpenShareModal(true)}
                    >
                      <div className="flex items-center">
                        <AcceptedShareIcon value={selectedShare}>
                          <BuildingIcon className="h-5 w-5" />
                        </AcceptedShareIcon>
                        <span className="ml-1 text-sm">{t("Share")}</span>
                      </div>
                    </Button>
                  </Tooltip>
                )}
                {projectsGeneralPermissions[GeneralPermissionTypes.Edit] && (
                  <Tooltip id="edit" message="Edit Project">
                    <IconButtonBase
                      size="xs"
                      label="Edit Project"
                      variant="default"
                      onClick={handleEdit}
                      disabled={isEditing || isNewProject}
                    >
                      <PencilIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
                {projectsGeneralPermissions[GeneralPermissionTypes.Delete] && (
                  <Tooltip id="print" message="Print Project">
                    <IconButtonBase
                      size="xs"
                      label="Print Project"
                      variant="default"
                      onClick={openPrintTab}
                      disabled={isNewProject}
                    >
                      <PrinterIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
                {projectsGeneralPermissions[GeneralPermissionTypes.Delete] && (
                  <Tooltip id="archive" message="Archive Project">
                    <IconButtonBase
                      size="xs"
                      label="Archive Project"
                      variant="default"
                      onClick={openDeleteModal}
                      disabled={isNewProject}
                    >
                      <ArchiveIcon className="h-5 w-5" />
                    </IconButtonBase>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
      </ActionBar>
    </div>
  );
};
