import { gql } from "urql";

export const TENANT_FRAGMENT = gql`
  fragment TenantFragment on TenantRoot {
    tenantId
    displayName
    defaultRoleId
    taxId
    logoUrl
    billingEmailAddress
    technicalContactPerson
    responsibleContactPerson
    settings
    officeAddress {
      coordinates
      name
    }
    tenantConfig {
      samlConfig {
        callbackURL
        displayName
        emailDomain
        enabled
        idpEntityId
        providerId
        rpEntityId
        ssoURL
        webDomain
        x509Certificates
      }
    }
  }
`;
