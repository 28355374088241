import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { CollectionContactsShare } from "./CollectionContactsShare";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  ownerId: string;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  onBlur?: () => void;
}

export const CollectionContacts: FC<Props> = ({ id, control, ...rest }) => {
  const { t } = useTranslation();
  const isShared = useWatch({
    control,
    exact: true,
    name: "shareable",
  });

  if (!isShared) {
    return null;
  }
  return (
    <div className="w-full">
      <label htmlFor={id} className="amenda-component-label">
        {t("Share with Contacts")}
      </label>
      <CollectionContactsShare
        id={id}
        control={control}
        ignoreStylesOverride={true}
        {...rest}
      />
    </div>
  );
};
