import { gql, useClient } from "urql";

import { AvailableNotificationTypes } from "@amenda-types";
import { useAppStore } from "@amenda-domains/mutations";
import { useCallback, useState } from "react";

const GET_EMAIL_SIGN_IN_LINK = gql`
  query GetEmailSignLink($email: String!) {
    signInLink: getEmailSignInLink(email: $email)
  }
`;

export const useGetEmailSignInLink = () => {
  const showNotification = useAppStore((state) => state.showNotification);
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getEmailSignInLink = async (variables: Record<string, any>) => {
    setLoading(true);

    const response = await client
      .query(GET_EMAIL_SIGN_IN_LINK, variables)
      .toPromise()
      .catch((error) => {
        showNotification(AvailableNotificationTypes.Error, error?.message);
      });

    setLoading(false);

    return response?.data;
  };

  return {
    loading,
    getEmailSignInLink,
  };
};

const GET_CUSTOM_TOKEN = `
  query GetCustomToken($tenantId: String!, $uid: String!) {
    getCustomToken(tenantId: $tenantId, uid: $uid)
  }
`;

export const getApiCustomToken = async (tenantId: string, uid: string) => {
  const url = process.env.REACT_APP_BE_URL || "";

  const { data } = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: GET_CUSTOM_TOKEN,
      variables: {
        tenantId,
        uid,
      },
    }),
  }).then((res) => res.json());

  return data?.getCustomToken;
};

const GET_TENANT_DOMAIN = gql`
  query GetTenantDomain($email: String!) {
    tenant: getTenantDomain(email: $email) {
      id
      loginType
      webDomain
    }
  }
`;

export const useGetTenantDomain = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const showNotification = useAppStore((state) => state.showNotification);

  const getTenantDomain = useCallback(
    async (
      email: string,
    ): Promise<{
      id: string;
      webDomain: string;
      loginType: "Password" | "SAML" | "OIDC";
    }> => {
      setLoading(true);

      const response = await client
        .query(GET_TENANT_DOMAIN, {
          email,
        })
        .toPromise()
        .catch((error) => {
          showNotification(AvailableNotificationTypes.Error, error.message);
        });
      setLoading(false);

      return {
        id: response?.data?.tenant?.id,
        webDomain: response?.data?.tenant?.webDomain,
        loginType: response?.data?.tenant?.loginType,
      };
    },
    [client, showNotification],
  );

  return {
    loading,
    getTenantDomain,
  };
};
