import {
  CalendarIcon,
  CalendarRangeIcon,
  CircleCheckIcon,
  CircleDotIcon,
  ContactRoundIcon,
  GripIcon,
  GroupIcon,
  LetterTextIcon,
  ListTreeIcon,
  MapPinIcon,
  SearchIcon,
  SigmaIcon,
  TagsIcon,
  TextCursorInputIcon,
  ToggleRightIcon,
  UserRoundSearchIcon,
} from "lucide-react";
import { FC, useEffect, useState } from "react";

import { FormBuilderSectionHeader } from "./FormBuilderSectionsSidebar";
import { FormBuilderSortableItem } from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { FormComponentTypes } from "@amenda-constants/forms";
import { RectangleGroupIcon } from "@heroicons/react/24/outline";
import { availableLayouts } from "@amenda-types";
import clsx from "clsx";
import { groupBy } from "lodash";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

type GroupType = "data" | "structure";

export const componentGroups: Record<
  GroupType,
  { id: GroupType; label: string }
> = {
  data: {
    id: "data",
    label: "Data fields",
  },
  structure: {
    id: "structure",
    label: "Layouts",
  },
};

interface ComponentProps {
  group: GroupType;
  label: string;
  Icon: FC<any>;
  layout: availableLayouts;
  component?: FormComponentTypes;
}

export const sidebarLayoutComponents: Record<string, ComponentProps> = {
  [FormComponentTypes.Title]: {
    group: componentGroups.structure.id,
    label: "Title",
    Icon: LetterTextIcon,
    layout: availableLayouts.fullColumn,
    component: FormComponentTypes.Title,
  },
  [availableLayouts.twoThirdsColumnContainer]: {
    group: componentGroups.structure.id,
    label: "Two Thirds Column with Grid",
    Icon: RectangleGroupIcon,
    layout: availableLayouts.twoThirdsColumnContainer,
  },
  [availableLayouts.fullColumnContainer]: {
    group: componentGroups.structure.id,
    label: "Full Column with Grid",
    Icon: RectangleGroupIcon,
    layout: availableLayouts.fullColumnContainer,
  },
  [availableLayouts.nestedFormContainer]: {
    group: componentGroups.structure.id,
    label: "Nested form with Grid",
    Icon: GroupIcon,
    layout: availableLayouts.nestedFormContainer,
  },
};

export const sidebarComponents: Record<string, ComponentProps> = {
  [FormComponentTypes.AddressSearch]: {
    group: componentGroups.data.id,
    label: "Address Search",
    Icon: MapPinIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.AddressSearch,
  },
  [FormComponentTypes.Badges]: {
    group: componentGroups.data.id,
    label: "Badges",
    Icon: TagsIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.Badges,
  },
  [FormComponentTypes.CalculatorTextField]: {
    group: componentGroups.data.id,
    label: "Calculator Text Field",
    Icon: SigmaIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.CalculatorTextField,
  },
  [FormComponentTypes.Checkbox]: {
    group: componentGroups.data.id,
    label: "Checkbox",
    Icon: CircleCheckIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.Checkbox,
  },
  [FormComponentTypes.ColoredSelect]: {
    group: componentGroups.data.id,
    label: "Select with Colored Badges",
    Icon: ListTreeIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.ColoredSelect,
  },
  [FormComponentTypes.DatePicker]: {
    group: componentGroups.data.id,
    label: "Date Picker",
    Icon: CalendarIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.DatePicker,
  },
  [FormComponentTypes.DatePickerRange]: {
    group: componentGroups.data.id,
    label: "Date Picker Range",
    Icon: CalendarRangeIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.DatePickerRange,
  },
  [FormComponentTypes.Input]: {
    group: componentGroups.data.id,
    label: "Text Field",
    Icon: TextCursorInputIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.Input,
  },
  [FormComponentTypes.RadioButton]: {
    group: componentGroups.data.id,
    label: "Radio Button",
    Icon: CircleDotIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.RadioButton,
  },
  [FormComponentTypes.Select]: {
    group: componentGroups.data.id,
    label: "Select",
    Icon: ListTreeIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.Select,
  },
  [FormComponentTypes.Keyword]: {
    group: componentGroups.data.id,
    label: "Keyword",
    Icon: TagsIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.Keyword,
  },
  [FormComponentTypes.MultiSelect]: {
    group: componentGroups.data.id,
    label: "Multi Select",
    Icon: ListTreeIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.MultiSelect,
  },
  [FormComponentTypes.Switch]: {
    group: componentGroups.data.id,
    label: "Switch",
    Icon: ToggleRightIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.Switch,
  },
  [FormComponentTypes.Textarea]: {
    group: componentGroups.data.id,
    label: "Textarea",
    Icon: TextCursorInputIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.Textarea,
  },
  [FormComponentTypes.SearchAndSelect]: {
    group: componentGroups.data.id,
    label: "Contacts Search",
    Icon: UserRoundSearchIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.SearchAndSelect,
  },
  [FormComponentTypes.SearchAndSelectProjects]: {
    group: componentGroups.data.id,
    label: "Projects Search",
    Icon: SearchIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.SearchAndSelectProjects,
  },
  [FormComponentTypes.LabelledInput]: {
    group: componentGroups.data.id,
    label: "Labelled Input",
    Icon: TextCursorInputIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.LabelledInput,
  },
  [FormComponentTypes.LabelledContactInputs]: {
    group: componentGroups.data.id,
    label: "Contacts Labelled Input",
    Icon: ContactRoundIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.LabelledContactInputs,
  },
  [FormComponentTypes.MaterialCostInput]: {
    group: componentGroups.data.id,
    label: "Material Cost",
    Icon: GroupIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.MaterialCostInput,
  },
  [FormComponentTypes.MaterialPercentageValues]: {
    group: componentGroups.data.id,
    label: "Material Percentage Values",
    Icon: GroupIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.MaterialPercentageValues,
  },
  [FormComponentTypes.SelectCostGroups]: {
    group: componentGroups.data.id,
    label: "Search Cost Groups",
    Icon: ListTreeIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.SelectCostGroups,
  },
  [FormComponentTypes.RegionalSelect]: {
    group: componentGroups.data.id,
    label: "Regions Search",
    Icon: SearchIcon,
    layout: availableLayouts.halfColumn,
    component: FormComponentTypes.RegionalSelect,
  },
};

export const FormBuilderComponentsSidebar: FC = () => {
  const { t } = useTranslation();
  const groupedComponents = groupBy(
    {
      ...sidebarComponents,
      ...sidebarLayoutComponents,
    },
    (c) => c.group,
  );
  const isEditingComponent = useAppStore(
    (state) => state.formBuilderState.isEditingComponent,
  );
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (!isEditingComponent) {
      setKey((prev) => prev + 1);
    }
  }, [isEditingComponent]);

  return (
    <div
      key={key}
      className="h-full w-full overflow-y-auto overscroll-y-contain px-2 pb-4"
    >
      <FormBuilderSectionHeader />
      {Object.keys(groupedComponents).map((key) => {
        const label =
          componentGroups[key as keyof typeof componentGroups].label;
        const components = groupedComponents[key];

        return (
          <div key={key}>
            <span className="text-gray-900">{t(label)}</span>
            <div className="space-y-1 py-3">
              {components.map((c) => (
                <FormBuilderSortableItem
                  key={c.label}
                  id={c.label}
                  data={{
                    component: {
                      id: c.label,
                      parentId: "",
                      order: 0,
                      component: c.component,
                      layout: c.layout,
                      fromSidebar: true,
                    },
                  }}
                  disabled={isEditingComponent}
                >
                  {() => (
                    <div
                      className={clsx(
                        "flex cursor-grab select-none items-center space-x-2 truncate rounded-sm bg-white px-2 py-2 text-sm text-gray-900 hover:border-transparent hover:bg-gray-900 hover:text-white",
                        {
                          "cursor-not-allowed": isEditingComponent,
                        },
                      )}
                    >
                      <GripIcon className="h-4 min-h-4 w-4 min-w-4" />
                      {c.Icon && <c.Icon className="h-5 min-h-5 w-5 min-w-5" />}
                      <span className="leading-none">{t(c.label)}</span>
                    </div>
                  )}
                </FormBuilderSortableItem>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
