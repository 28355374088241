import * as Sentry from "@sentry/react";

import { AuthFormType, AvailableNotificationTypes } from "@amenda-types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { EnvelopeIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { LoaderWrapper, Spinner } from "@amenda-components/App";
import {
  signIn,
  useAppStore,
  useAuthStore,
  useSignInWithMicrosoft,
} from "@amenda-domains/mutations";

import { FC, useState } from "react";
import { EmailLookupSchema, LoginSchema } from "@amenda-constants";
import { TextField } from "@amenda-components/FormComponents";
import { useGetTenantDomain } from "@amenda-domains/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppRoutes } from "@amenda-types";
import { MicrosoftLoginButton } from "@amenda-components/Auth/MicrosoftLoginButton";

interface LoginInput {
  email: string;
  password: string;
}
interface EmailLookInput {
  email: string;
  password?: string;
}

type FormValues = EmailLookInput | LoginInput;

export const DefaultLoginForm: FC = () => {
  const [canUsePasswordLogin, setCanUsePasswordLogin] = useState(false);
  const [isLoginIn, setIsLoginIn] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getTenantDomain, loading: loadingDomain } = useGetTenantDomain();
  const { signInWithOIDC, loader } = useSignInWithMicrosoft();
  const toggleAuthFormType = useAuthStore((state) => state.toggleAuthFormType);
  const showNotification = useAppStore((state) => state.showNotification);

  const form = useForm<FormValues>({
    resolver: yupResolver(
      canUsePasswordLogin ? LoginSchema : EmailLookupSchema,
    ),
    mode: "onSubmit",
  });

  const handleLogin: SubmitHandler<FormValues> = async (data) => {
    try {
      const { webDomain, loginType, id } = await getTenantDomain(data.email);
      if (!id) {
        throw new Error(t("Tenant not found"));
      }

      if (loginType === "Password") {
        setCanUsePasswordLogin(true);
        if (canUsePasswordLogin) {
          setIsLoginIn(true);
          await signIn({ ...(data as LoginInput), tenantId: id }).then(() => {
            Sentry.setUser({ email: data.email });
            setIsLoginIn(false);
            navigate(AppRoutes.Projects);
          });
        }
      } else if (loginType === "SAML" && webDomain) {
        window.location.href = getSAMLLoginLink(webDomain);
      }
    } catch (error) {
      showNotification(
        AvailableNotificationTypes.Error,
        error instanceof Error ? error.message : t("Something went wrong"),
      );
    }
  };

  if (loader) {
    return <LoaderWrapper />;
  }

  return (
    <form onSubmit={form.handleSubmit(handleLogin)} className="space-y-6">
      <div className="space-y-4">
        <MicrosoftLoginButton onClick={signInWithOIDC} />
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="font-apercu bg-white px-2 text-gray-500">
              {t("or")}
            </span>
          </div>
        </div>
      </div>

      <Controller
        name="email"
        control={form.control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            id="email-password"
            placeholder={t("E-Mail Address")}
            error={error?.message}
            {...field}
            type="email"
            StartIcon={EnvelopeIcon}
          />
        )}
      />

      {canUsePasswordLogin && (
        <>
          <Controller
            name="password"
            control={form.control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="password"
                placeholder={t("Password")}
                error={error?.message}
                {...field}
                type="password"
                StartIcon={LockClosedIcon}
              />
            )}
          />
          <button
            type="submit"
            className="amenda-button w-full"
            disabled={isLoginIn}
          >
            {(loadingDomain || isLoginIn) && (
              <Spinner variant="primary" spinnerSize="xs" />
            )}
            {t("Login")}
          </button>
          <div className="text-right text-sm">
            <button
              type="button"
              onClick={() => toggleAuthFormType(AuthFormType.ResetPassword)}
              className="text-indigo-600 hover:text-indigo-500"
            >
              {t("Forgot your password?")}
            </button>
          </div>
        </>
      )}

      {!canUsePasswordLogin && (
        <button
          type="submit"
          className="amenda-button w-full"
          disabled={loadingDomain}
        >
          {loadingDomain && <Spinner variant="primary" spinnerSize="xs" />}
          {t("Next")}
        </button>
      )}
    </form>
  );
};

const getSAMLLoginLink = (webDomain: string) => {
  return `http://${webDomain}:3000` + AppRoutes.SAMLLogin;
};
