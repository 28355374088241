import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FC, ReactNode, useEffect, useState } from "react";
import { GeneralAccessTypes, formViewsSchema } from "@amenda-constants";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { getComponentsFromForms, groupComponentsByParent } from "@amenda-utils";
import { useAppStore, useProjectStore } from "@amenda-domains/mutations";

import { BoltIcon } from "lucide-react";
import { ComponentTreeRenderer } from "@amenda-components/PageBuilder";
import { DetectFormNavigation } from "@amenda-components/FormComponents";
import { FormBuilderDragOverlay } from "./FormBuilder";
import { FormBuilderFormViewLeftSidebar } from "./FormBuilderFormViewLeftSidebar";
import { FormBuilderFormViewRightSidebar } from "./FormBuilderFormViewRightSidebar";
import { HelperMessage } from "@amenda-components/App";
import { MainContainer } from "@amenda-components/Shared";
import { getFormViewComponents } from "@amenda-constants/template";
import { isDragValid } from "./FormBuilderDndComponents";
import { isEmpty } from "lodash";
import { transformFormView } from "@amenda-components/Projects/common";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormBuilderProps {
  components: any[];
  control: Control<any>;
  sections: any[];
  setValue: UseFormSetValue<any>;
}

const SectionLabel: FC<Pick<FormBuilderProps, "control" | "sections">> = ({
  control,
  sections,
}) => {
  const { t } = useTranslation();
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );

  const activeIndex = sections.findIndex((s: any) => s.id === selectedForm?.id);
  const fieldId = `sections.${activeIndex}.label`;

  return (
    <div key={fieldId} className="flex w-44 flex-row text-sm">
      <Controller
        control={control}
        name={fieldId}
        render={({ field: { value, onChange } }) => (
          <input
            type="text"
            placeholder={t("Title")}
            className="w-full border-gray-100 bg-gray-100 px-2 py-1 text-gray-900 hover:border-gray-100 focus:border-gray-100 focus:outline-none focus:ring-0"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};

const FormBuilder: FC<FormBuilderProps> = ({
  control,
  sections,
  components,
  setValue,
}) => {
  const formComponentTree = groupComponentsByParent(components);

  return (
    <div className="h-full overflow-y-auto overscroll-contain">
      <div className="sticky top-0 z-20 flex w-full items-center justify-between bg-white p-4">
        <SectionLabel control={control} sections={sections} />
      </div>
      <ComponentTreeRenderer
        config={formComponentTree}
        readOnly={true}
        isFormBuilder={true}
        globalProps={{
          data: {},
          isFormView: true,
          control,
          setValue,
        }}
      />
    </div>
  );
};

const FormBuilderWrapper: FC<FormBuilderProps> = ({
  sections = [],
  ...rest
}) => {
  const selectedFormViewSection = useProjectStore(
    (state) => state.selectedFormViewSection,
  );

  return (
    <MainContainer className="pr-0">
      {isEmpty(sections) ? (
        <HelperMessage
          iconClassName="stroke-[2] h-10 w-10 text-gray-800"
          message="Create section to get started"
          Icon={BoltIcon}
        />
      ) : (
        <FormBuilder
          key={selectedFormViewSection?.id + ".editor"}
          sections={sections}
          {...rest}
        />
      )}
    </MainContainer>
  );
};

interface FormBuilderDndWrapperProps {
  children: ReactNode;
  components: any[];
  control: Control;
  setValue: UseFormSetValue<any>;
}

const FormBuilderDndWrapper: FC<FormBuilderDndWrapperProps> = ({
  control,
  components,
  children,
  setValue,
}) => {
  const sections = useWatch({
    control,
    name: "sections",
    defaultValue: [],
  });
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [activeComponent, setActiveComponent] = useState<Record<string, any>>(
    {},
  );
  const upsertComponentDragNDrop = useAppStore(
    (state) => state.upsertComponentDragNDrop,
  );
  const upsertLayoutDragNDrop = useAppStore(
    (state) => state.upsertLayoutDragNDrop,
  );
  const handleNewFormViewComponentOnDragEnd = useAppStore(
    (state) => state.handleNewFormViewComponentOnDragEnd,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState.selectedForm,
  );
  const updateFormBuilderFormViewForm = useAppStore(
    (state) => state.updateFormBuilderFormViewForm,
  );
  const updateFormBuilderFormViewComponents = useAppStore(
    (state) => state.updateFormBuilderFormViewComponents,
  );

  const sectionComponents: any[] = selectedForm?.components ?? [];
  const formViewComponents = getFormViewComponents(
    components,
    sectionComponents,
  );

  const handleUpdate = (components: any[]) => {
    const activeIndex = sections.findIndex(
      (s: any) => s.id === selectedForm?.id,
    );
    if (activeIndex !== -1) {
      setValue(`sections.${activeIndex}.components`, components);
      updateFormBuilderFormViewComponents(components);
    }
  };

  const handleNewComponentDragEnd = async (
    active: Active,
    over: Over | null,
  ) => {
    const activeComponent = active.data.current?.component;
    const overComponent = over?.data?.current?.component;

    if (over && activeComponent.parentId === overComponent.parentId) {
      const componentIds: any[] = active.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const oldIndex = componentIds.indexOf(active.id);
      const updatedComponentIds = arrayMove(componentIds, oldIndex, newIndex);

      handleNewFormViewComponentOnDragEnd({
        component: activeComponent,
        componentIds: updatedComponentIds,
        callback: handleUpdate,
      });
    } else {
      handleNewFormViewComponentOnDragEnd({
        component: activeComponent,
        callback: handleUpdate,
      });
    }
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    const activeComponent = active.data.current?.component;
    const overComponent = over.data.current?.component;

    if (!activeComponent || !overComponent) return;
    if (activeComponent.parentId === overComponent.parentId) {
      const componentIds: any[] = active.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const oldIndex = componentIds.indexOf(active.id);
      const updatedComponentIds = arrayMove(componentIds, oldIndex, newIndex);
      const updatedComponents = sectionComponents.map((c) => {
        const index = updatedComponentIds.findIndex((id) => id === c.id);

        if (index === -1) return c;
        if (c?.order !== index) {
          return {
            ...c,
            order: index,
          };
        }
        return c;
      });

      handleUpdate(updatedComponents);
    } else if (activeComponent.component && overComponent.component) {
      const componentIds: any[] = over.data.current?.sortable?.items ?? [];
      const newIndex = componentIds.indexOf(over.id);
      const allComponentIds = [...componentIds, active.id];
      const updatedComponentIds = arrayMove(
        allComponentIds,
        allComponentIds.length - 1,
        newIndex,
      );
      const updatedComponents = sectionComponents
        .map((c) => {
          if (c.id === activeComponent?.id) {
            const parentId = !overComponent?.component
              ? overComponent?.id
              : overComponent?.parentId;

            return {
              ...c,
              parentId,
            };
          }
          return c;
        })
        .map((c) => {
          const index = updatedComponentIds.findIndex((id) => id === c.id);

          if (index === -1) return c;
          if (c?.order !== index) {
            return {
              ...c,
              order: index,
            };
          }
          return c;
        });

      handleUpdate(updatedComponents);
    }
  };

  useEffect(() => {
    if (!selectedForm?.id && sections.length > 0) {
      const section = sections[0];

      updateFormBuilderFormViewForm(section);
    }
  }, [sections, selectedForm?.id, updateFormBuilderFormViewForm]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        const { active } = event;

        setActiveComponent(active.data.current?.component ?? {});
      }}
      onDragOver={(e) => {
        const { over, active } = e;

        if (over?.data?.current?.component?.fromSidebar) {
          return;
        }
        if (
          active?.data?.current?.component?.component &&
          over?.data?.current?.component?.component
        ) {
          upsertComponentDragNDrop({
            component: active.data.current.component,
            order: over.data.current.component.order,
            parentId: over.data.current.component.parentId,
          });
        } else if (
          active?.data?.current?.component?.component &&
          over?.data?.current?.component?.layout
        ) {
          upsertComponentDragNDrop({
            component: active.data.current.component,
            parentId: over.data.current.component.id,
            order: over.data.current.component.order + 1,
          });
        } else if (
          active?.data?.current?.component?.layout &&
          over?.data?.current?.component?.layout
        ) {
          upsertLayoutDragNDrop(
            active.data.current.component,
            over.data.current.component,
          );
        }
      }}
      onDragEnd={(event) => {
        const { active, over } = event;

        setActiveComponent({});
        if (
          Boolean(active.data?.current?.component?.fromSidebar) ||
          Boolean(over?.data?.current?.component?.fromSidebar)
        ) {
          handleNewComponentDragEnd(active, over);
        } else if (over && isDragValid(active, over)) {
          handleDragEnd(active, over);
        }
      }}
    >
      <div className="relative flex h-screen w-full">
        {children}
        <FormBuilderWrapper
          control={control}
          sections={sections}
          components={formViewComponents}
          setValue={setValue}
        />
        <FormBuilderFormViewRightSidebar components={components} />
      </div>
      <DragOverlay>
        {activeComponent && (
          <FormBuilderDragOverlay component={activeComponent} />
        )}
      </DragOverlay>
    </DndContext>
  );
};

export const FormBuilderFormViewsEditor: FC = () => {
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const projectForms = useProjectStore((state) => state.projectForms);

  const defaultValues = {
    name: "",
    description: "",
    shareType: GeneralAccessTypes.Private,
    users: [],
    sections: [],
  };

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitted, isSubmitting },
  } = useForm<any>({
    defaultValues,
    values: selectedFormView ? transformFormView(selectedFormView) : undefined,
    resolver: yupResolver(formViewsSchema),
  });

  const components = getComponentsFromForms(projectForms);

  return (
    <FormBuilderDndWrapper
      control={control}
      setValue={setValue}
      components={components}
    >
      <DetectFormNavigation
        isFormDirty={!isSubmitting && !isSubmitted && !isEmpty(dirtyFields)}
      />
      <FormBuilderFormViewLeftSidebar
        control={control}
        reset={reset}
        setValue={setValue}
        handleSubmit={handleSubmit}
      />
    </FormBuilderDndWrapper>
  );
};
