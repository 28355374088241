import { AdminSettingsLayout, FormBuilder } from "@amenda-components/Settings";
import { FC, useEffect } from "react";

import { useAppStore } from "@amenda-domains/mutations/app";
import { useTranslation } from "react-i18next";

export const FormBuilderPage: FC = () => {
  const { t } = useTranslation();
  const clearFormBuilderState = useAppStore(
    (state) => state.clearFormBuilderState,
  );

  useEffect(() => {
    return () => {
      clearFormBuilderState();
    };
  }, [clearFormBuilderState]);

  return (
    <AdminSettingsLayout>
      <div className="sm:flex sm:items-center" style={{ height: "40px" }}>
        <div className="sm:flex-auto">
          <h1 className="amenda-settings-heading">{t("Form Builder")}</h1>
        </div>
      </div>
      <FormBuilder />
    </AdminSettingsLayout>
  );
};
