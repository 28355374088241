import { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface DragInfoModalProps {
  isDragging: boolean;
}
export const DragInfoModal: FC<DragInfoModalProps> = ({ isDragging }) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        "fixed left-1/2 top-12 z-50 w-96 -translate-x-1/2 transform border border-gray-300 bg-white p-4 shadow-lg transition-opacity",
        {
          "opacity-100": isDragging,
          "pointer-events-none opacity-0": !isDragging,
        },
      )}
    >
      <h3 className="mb-2 text-center text-lg font-semibold">
        {t("Drag & Drop")}
      </h3>
      <p className="text-center text-sm text-gray-600">
        {t("Drop this file into one of the following apps:")}
      </p>

      {/* Grid layout for app icons */}
      <div className="mt-4 grid grid-cols-3 gap-4 text-center">
        {dragApps.map(({ name, icon }) => (
          <div key={name} className="flex flex-col items-center">
            <img src={icon} alt={name} className="h-10 w-10" />
            <span className="text-sm">{name}</span>
          </div>
        ))}
      </div>

      <p className="mt-4 text-center text-xs text-gray-500">
        {t("Dragging to InDesign requires the Amenda InDesign Plugin.")}
      </p>
    </div>
  );
};

// ✅ App list with icons (store SVGs in /public/icons/)
const dragApps = [
  { name: "InDesign", icon: "/icons/indesign.svg" },
  { name: "Google Docs", icon: "/icons/google-docs.svg" },
  { name: "Google Slides", icon: "/icons/google-slides.svg" },
  { name: "Word", icon: "/icons/word.svg" },
  { name: "PowerPoint", icon: "/icons/powerpoint.svg" },
  { name: "Outlook", icon: "/icons/outlook.svg" },
  { name: "Affinity Designer", icon: "/icons/affinity-designer.svg" },
  { name: "Affinity Publisher", icon: "/icons/affinity-publisher.svg" },
  { name: "Affinity Photo", icon: "/icons/affinity-photo.svg" },
];

export default DragInfoModal;
