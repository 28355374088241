import { FC } from "react";
import { FormBuilderComponentSort } from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { FormBuilderFormViewComponentActionBar } from "@amenda-components/Settings/FormBuilder/FormBuilderFormViewComponentActionBar";
import { LayoutProps } from "./common";

export const FullColumn: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  config,
  globalProps,
}) => {
  return (
    <FormBuilderComponentSort
      className="group/full-col col-span-6"
      config={config}
      isFormBuilder={isFormBuilder}
    >
      {isFormBuilder && (
        <FormBuilderFormViewComponentActionBar
          config={config}
          globalProps={globalProps}
        />
      )}
      {children}
    </FormBuilderComponentSort>
  );
};
