import { FC, useEffect, useMemo, useState } from "react";
import {
  componentGroups,
  sidebarComponents,
  sidebarLayoutComponents,
} from "./FormBuilderComponentsSidebar";

import { FormBuilderSortableItem } from "./FormBuilderDndComponents";
import { FormComponentTypes } from "@amenda-constants/forms";
import Fuse from "fuse.js";
import { GripIcon } from "lucide-react";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { availableLayouts } from "@amenda-types";
import groupBy from "lodash/groupBy";
import { useAppStore } from "@amenda-domains/mutations";
import { useDndMonitor } from "@dnd-kit/core";
import { useTranslation } from "react-i18next";

const filterComponents = (components: any[], searchTerm: string) => {
  const fuse = new Fuse(components, {
    includeScore: true,
    threshold: 0.3,
    keys: ["label"],
  });

  const results = fuse.search(searchTerm);
  return results.map(({ item }) => item);
};

const sortComponents = (components: any[]) => {
  return components.sort((a, b) => a.label.localeCompare(b.label));
};

interface Props {
  components: any[];
}

export const FormBuilderFormViewComponents: FC<Props> = ({ components }) => {
  const { t } = useTranslation();
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const [key, setKey] = useState(0);
  const existingComponents = useMemo(
    () => selectedForm?.components ?? [],
    [selectedForm],
  );
  const layoutComponents = useMemo(() => {
    const components = { ...sidebarLayoutComponents };

    delete components[availableLayouts.nestedFormContainer];
    delete components[FormComponentTypes.Title];
    return components;
  }, []);

  useDndMonitor({
    onDragEnd() {
      setKey((prev) => prev + 1);
    },
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [availableComponents, setAvailableComponents] = useState<
    Record<string, any[]>
  >({});

  useEffect(() => {
    const fieldComponents = components.reduce((acc, c) => {
      if (
        c.component &&
        sidebarComponents[c.component] &&
        !existingComponents.some((ec) => ec.id === c.id)
      ) {
        const label = c?.properties?.label || c.id;

        acc[c.id] = {
          ...sidebarComponents[c.component],
          id: c.id,
          label,
          properties: {
            label,
          },
        };
      }
      return acc;
    }, {});

    if (searchTerm.length > 0) {
      const allComponents: Record<string, any> = {
        ...layoutComponents,
        ...fieldComponents,
      };
      const components = Object.keys(allComponents).reduce((acc, key) => {
        const component = allComponents[key];
        return [...acc, component];
      }, [] as any[]);
      const filteredComponents = filterComponents(components, searchTerm);
      const groupedComponents = groupBy(filteredComponents, (c) => c.group);

      setAvailableComponents(groupedComponents);
    } else {
      const groupedComponents = groupBy(
        { ...layoutComponents, ...fieldComponents },
        (c) => c.group,
      );

      setAvailableComponents(groupedComponents);
    }
  }, [searchTerm, components, existingComponents, layoutComponents]);

  return (
    <div key={key} className="w-full">
      <div className="sticky top-0 w-full bg-white pb-3 pt-4">
        <MiniSearchField
          value={searchTerm}
          placeholder="Suchen..."
          className="bg-gray-100"
          onChange={setSearchTerm}
          onClear={() => setSearchTerm("")}
        />
      </div>
      {Object.keys(availableComponents).map((key) => {
        const label =
          componentGroups[key as keyof typeof componentGroups].label;
        const components = sortComponents(availableComponents[key]);

        return (
          <div key={key}>
            <span className="text-gray-900">{t(label)}</span>
            <div className="space-y-1 py-3">
              {components.map((c) => {
                const id = c.id ?? c.label;

                return (
                  <FormBuilderSortableItem
                    key={id}
                    id={id}
                    data={{
                      component: {
                        id,
                        order: 0,
                        parentId: "",
                        fromSidebar: true,
                        layout: c.layout,
                        component: c.component,
                        properties: c.properties,
                      },
                    }}
                  >
                    {() => (
                      <div className="flex cursor-grab select-none items-center space-x-2 truncate rounded-sm bg-white px-2 py-2 text-sm text-gray-900 hover:border-transparent hover:bg-gray-900 hover:text-white">
                        <GripIcon className="h-4 min-h-4 w-4 min-w-4" />
                        {c.Icon && (
                          <c.Icon className="h-5 min-h-5 w-5 min-w-5" />
                        )}
                        <span className="truncate leading-none">
                          {!c.component ? t(c.label) : c.label}
                        </span>
                      </div>
                    )}
                  </FormBuilderSortableItem>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
