import { FC, ReactNode } from "react";

import { Modal } from "@amenda-components/App";

interface Props {
  description?: string;
  openModal: boolean;
  loading?: boolean;
  title: string;
  successLabel?: string;
  children?: ReactNode;
  handleDelete: () => void;
  handleCloseModal: () => void;
}

export const DeleteModal: FC<Props> = ({
  description,
  openModal,
  title,
  children,
  successLabel = "Delete",
  loading = false,
  handleDelete,
  handleCloseModal,
}) => {
  const handleClose = () => {
    handleCloseModal();
  };
  const handleSuccess = async () => {
    await handleDelete();
  };

  return (
    <Modal
      isOpen={openModal}
      withSuccess={true}
      title={title}
      message={description}
      loading={loading}
      successLabel={successLabel}
      isElevated={true}
      variant="danger"
      size="md"
      className="w-11/12 md:w-1/2 lg:w-4/12"
      onClose={handleClose}
      onSuccess={handleSuccess}
    >
      {children}
    </Modal>
  );
};
