import { FC } from "react";
import { FormBuilderFormViewComponents } from "./FormBuilderFormViewsComponents";
import { SidebarContainer } from "@amenda-components/Shared/Sidebar";

interface Props {
  components: any[];
}

export const FormBuilderFormViewRightSidebar: FC<Props> = ({ components }) => {
  return (
    <SidebarContainer className="relative w-72 border-l">
      <div className="w-full overflow-y-auto overscroll-y-contain px-4 pb-10">
        <FormBuilderFormViewComponents components={components} />
      </div>
    </SidebarContainer>
  );
};
