import { BoltIcon, PencilIcon } from "lucide-react";
import {
  ComponentTreeRenderer,
  ComponentsTreeView,
  ComponentsTreeViewRowProps,
} from "@amenda-components/PageBuilder";
import { HelperMessage, IconButtonBase } from "@amenda-components/App";
import {
  useAppStore,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { DeleteModal } from "@amenda-components/Shared";
import { FC } from "react";
import { FormBuilderActionBar } from "./FormBuilderActionBar";
import { FormBuilderFormDisplayConditions } from "./FormBuilderFormDisplayConditions";
import { groupComponentsByParent } from "@amenda-utils";

const CostGroupRowBody: FC<ComponentsTreeViewRowProps> = ({ component }) => {
  const updateFormBuilderSelectedComponent = useAppStore(
    (state) => state.updateFormBuilderSelectedComponent,
  );

  const handleEdit = () => {
    updateFormBuilderSelectedComponent(component, true);
  };

  return (
    <div className="invisible flex w-full items-center justify-end space-x-2 py-1 group-hover/treeRow:visible">
      <IconButtonBase
        size="sm"
        variant="clean"
        className="!bg-inherit p-1"
        onClick={handleEdit}
      >
        <PencilIcon className="h-4 w-4" />
      </IconButtonBase>
    </div>
  );
};

export const FormBuilderComponentEditor: FC = () => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const originalForms = useProjectStore((state) => state.originalForms);
  const selectedForm = useAppStore(
    (state) => state.formBuilderState.selectedForm,
  );
  const deleteForm = useAppStore((state) => state.formBuilderState.deleteForm);
  const { updateForm, loading } = useUpdateForm();

  const handleDeleteForm = async () => {
    const foundForm =
      selectedForm && originalForms.find((form) => form.id === selectedForm.id);
    if (foundForm?.id) {
      await updateForm({
        isDeleted: true,
        id: foundForm.id,
        components: foundForm.components,
      });
    }
    updateFormBuilderState("selectedFormComponent", undefined);
    updateFormBuilderState("selectedForm", undefined);
    updateFormBuilderState("deleteForm", false);
  };

  if (!selectedForm) {
    return (
      <HelperMessage
        iconClassName="stroke-[2] h-10 w-10 text-gray-800"
        message="Select a form"
        helpText="Select a form from the sidebar to edit"
        Icon={BoltIcon}
      />
    );
  }

  const formComponentTree = groupComponentsByParent(selectedForm?.components);

  return (
    <div className="relative h-full w-full overflow-y-auto overscroll-contain pb-8 lg:px-4 xl:px-8">
      <FormBuilderFormDisplayConditions />
      <DeleteModal
        title="Are you sure you want to delete this form?"
        description="Note that this action is irreversible."
        loading={loading}
        openModal={Boolean(deleteForm)}
        handleDelete={handleDeleteForm}
        handleCloseModal={() => updateFormBuilderState("deleteForm", false)}
      />
      <FormBuilderActionBar />
      {selectedForm?.category === AvailableForms.CostGroupsCost ? (
        <ComponentsTreeView
          readOnly={true}
          columnClassName="pt-1"
          components={formComponentTree.components as any[]}
          columns={[
            {
              id: "values",
              label: "",
            },
          ]}
          className="mt-2 max-h-[80vh]"
          rowClassName="group/treeRow hover:bg-gray-100 border-b border-transparent hover:border-gray-200 mb-2"
          searchPlaceholder="Kostengruppe filtern..."
          RowBody={CostGroupRowBody}
          globalData={{
            data: {},
          }}
        />
      ) : (
        <ComponentTreeRenderer
          config={formComponentTree}
          readOnly={true}
          isFormBuilder={true}
          globalProps={{
            data: {},
          }}
        />
      )}
    </div>
  );
};
