import {
  AllowedContactType,
  LogicalOperators,
  UserActivationState,
} from "@amenda-types";
import { BuildingIcon, UserCircleIcon, UserIcon } from "lucide-react";
import {
  ContactSearchProps,
  getContactRequestArgs,
  getGroupableComponentsAsOptions,
  hasNoUserSearchAndGrouping,
  isUserSearchFiltersEmpty,
} from "./common";
import {
  KeywordCollection,
  MultiSelectBase,
  SingleSelect,
} from "@amenda-components/FormComponents";
import {
  getOptionDescendantsWithTree,
  getOptionsTree,
} from "@amenda-components/FormComponents/common";
import {
  processSearchFilters,
  transformFilters,
} from "@amenda-components/Shared/common";
import {
  useFormStore,
  useProjectStore,
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";

import { Accordion } from "@amenda-components/Shared";
import { ContactDirectoryProps } from "./types";
import { FC } from "react";
import { FormComponentTypes } from "@amenda-constants";
import { Link } from "@amenda-components/App";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";

interface Props extends ContactDirectoryProps {
  isOpen: boolean;
  isCollection: boolean;
  handleSearch: (args: ContactSearchProps) => Promise<void>;
}

type FilterTagsProps = Pick<ContactDirectoryProps, "defaultContactType"> & {
  handleSearch: (args: ContactSearchProps) => Promise<void>;
};

const FilterTags: FC<FilterTagsProps> = ({
  defaultContactType,
  handleSearch,
}) => {
  const {
    searchFilters,
    groupingComponents,
    userComponentFilters,
    setUserComponentFilter,
  } = useUserSearchFiltersWithPath();

  const key =
    defaultContactType === AllowedContactType.office
      ? "userDetails"
      : "contactDetails";
  const componentsByContactType = groupBy(groupingComponents, "contactType");

  const handleFilters = async (filter: any) => {
    const componentFilters: any = {
      ...userComponentFilters,
      ...filter,
    };

    const match = transformFilters(componentFilters, key);

    await handleSearch({
      ...searchFilters,
      match,
    });
  };

  return (
    <div className="h-full w-full pb-3">
      {Object.keys(componentsByContactType).map((key) => (
        <div key={key}>
          <div className="w-full">
            {componentsByContactType[key].map((component) => {
              const filterValue = userComponentFilters[component.id] ?? {};
              const options = component?.properties?.options ?? [];
              const optionsTree = getOptionsTree(options);

              const handleChange = async (value: any) => {
                let other: any = {};
                let updatedValue = value;
                if (
                  Array.isArray(options) &&
                  Boolean(component?.properties?.isNested)
                ) {
                  other = {
                    operation: LogicalOperators.OR,
                  };
                  updatedValue = value[0]
                    ? [
                        ...value,
                        ...getOptionDescendantsWithTree(value[0], optionsTree),
                      ]
                    : value;
                }

                let filters = processSearchFilters({
                  component,
                  other,
                  value,
                });
                setUserComponentFilter(component.id, filters);
                filters = processSearchFilters({
                  component,
                  other,
                  value: updatedValue,
                });
                await handleFilters({
                  [component.id]: filters,
                });
              };

              return (
                <div
                  className="flex w-full items-center justify-between"
                  key={component.id}
                >
                  <div className="mb-2 mr-2 text-gray-900">
                    {component?.properties?.label}
                  </div>
                  <div className="w-56">
                    {[
                      FormComponentTypes.Select,
                      FormComponentTypes.MultiSelect,
                    ].includes(component.component) && (
                      <MultiSelectBase
                        id={component?.id}
                        isClearable={false}
                        hasMenuOverflow={true}
                        hideErrorMessage={true}
                        canSelectParents={true}
                        isNested={Boolean(component?.properties?.isNested)}
                        options={options}
                        values={filterValue.value}
                        onChange={(options) => {
                          let values = options?.map((o) => o?.value);

                          return handleChange(values);
                        }}
                      />
                    )}
                    {component.component === FormComponentTypes.Keyword && (
                      <KeywordCollection
                        id={component?.id}
                        isClearable={false}
                        isCreatable={false}
                        hasMenuOverflow={true}
                        onChange={handleChange}
                        values={filterValue.value}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export const ContactsFilterMenu: FC<Props> = ({
  isOpen,
  isCollection,
  collectionType,
  canFilterByType,
  defaultContactType,
  getAllContacts,
  handleSearch,
}) => {
  const { t } = useTranslation();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType,
  );
  const {
    userComponentFilters,
    searchFilters,
    setSearchFilters,
    clearSearchFilters,
    setGroupingComponents,
    clearUserComponentFilter,
  } = useUserSearchFiltersWithPath();
  const userActivationState = useUsersStore(
    (state) => state.userActivationState,
  );
  const setUserActivationState = useUsersStore(
    (state) => state.setUserActivationState,
  );

  const selectedCollection = selectedCollectionByType[collectionType];

  const onContactTypeChange = async (contactType: AllowedContactType) => {
    setSearchFilters("contactType", contactType);
    await handleSearch({ ...searchFilters, contactType });
  };

  const handleGrouping = async (groupByComponents: any[]) => {
    const groupByComponentIds = groupByComponents.map((c) => c.id);

    if (groupByComponentIds.length === 0) {
      clearUserComponentFilter();
    }
    setGroupingComponents(groupByComponents);
    setSearchFilters("groupByComponentIds", groupByComponentIds);
    await handleSearch({
      ...searchFilters,
      groupByComponentIds,
    });
  };

  const onUserActivationStateChange = async (value: UserActivationState) => {
    setUserActivationState(value);
    await handleSearch({
      ...searchFilters,
      userActivationState: value,
    });
  };

  const handleClearSearchFilters = async () => {
    const args = getContactRequestArgs({
      isCollection,
      userActivationState,
      defaultContactType,
      autoSelect: true,
      collectionResourceIds: selectedCollection?.resourceIds,
    });

    clearSearchFilters();
    await getAllContacts({
      ...args,
      context: {
        requestPolicy: "network-only",
      },
    });
  };

  return (
    <div className="relative w-full">
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="left-0 top-0 z-40 mt-2 max-h-[40vh] w-full overflow-y-auto overscroll-contain text-sm">
          {canFilterByType ? (
            <div className="flex w-full items-center justify-between">
              <div className="mb-2 mr-2 text-gray-900">{t("Type")}</div>
              <div className="w-56">
                <SingleSelect
                  id="type"
                  getOptionLabel={(option) => t(option.label)}
                  hasMenuOverflow={true}
                  hideErrorMessage={true}
                  placeholder="All"
                  options={[
                    { value: AllowedContactType.company, label: "Companies" },
                    { value: AllowedContactType.person, label: "Persons" },
                  ]}
                  onChange={onContactTypeChange}
                  value={searchFilters?.contactType}
                />
              </div>
            </div>
          ) : (
            <div className="flex w-full items-center justify-between">
              <div className="mb-2 mr-2 text-gray-900">{t("User state")}</div>
              <div className="w-56">
                <SingleSelect
                  id="userActivationState"
                  getOptionLabel={(option) => t(option.label)}
                  hasMenuOverflow={true}
                  hideErrorMessage={true}
                  isClearable={false}
                  options={[
                    { value: UserActivationState.Active, label: "Active" },
                    { value: UserActivationState.Inactive, label: "Inactive" },
                    { value: UserActivationState.All, label: "All" },
                  ]}
                  value={userActivationState}
                  onChange={onUserActivationStateChange}
                />
              </div>
            </div>
          )}
          <div className="flex w-full items-center justify-between">
            <div className="mb-2 mr-2 text-gray-900">{t("Grouping")}</div>
            <div className="w-56">
              <MultiSelectBase
                id="group-by"
                hasMenuOverflow={true}
                hideErrorMessage={true}
                isClearable={true}
                placeholder="Select group"
                options={getGroupableComponentsAsOptions(
                  formsByContactType,
                  searchFilters?.contactType ?? defaultContactType,
                )}
                formatGroupLabel={(group) => t(group.label)}
                getOptionValue={(component) => component.id}
                getOptionLabel={(component) => component.properties.label}
                onChange={handleGrouping}
                values={searchFilters?.groupByComponentIds ?? []}
                renderValueLabel={(props: any) => {
                  const { data } = props;
                  const Icon =
                    data.contactType === AllowedContactType.company
                      ? BuildingIcon
                      : data.contactType === AllowedContactType.person
                        ? UserIcon
                        : UserCircleIcon;

                  return (
                    <Icon
                      className="mr-1 h-4 min-h-4 w-4 min-w-4"
                      aria-hidden="true"
                    />
                  );
                }}
              />
            </div>
          </div>
          {searchFilters?.groupByComponentIds && (
            <div className="flex w-full flex-col">
              <>
                {searchFilters?.groupByComponentIds?.length > 3 ? (
                  <Accordion
                    panelClassName="pt-3"
                    title={t("Group Filter")}
                    defaultOpen={true}
                    hideBorder={true}
                  >
                    <FilterTags
                      defaultContactType={defaultContactType}
                      handleSearch={handleSearch}
                    />
                  </Accordion>
                ) : (
                  <FilterTags
                    defaultContactType={defaultContactType}
                    handleSearch={handleSearch}
                  />
                )}
              </>
            </div>
          )}
          <div className="flex w-full justify-end pb-1">
            <Link
              className={clsx("text-right transition delay-150 ease-in-out", {
                invisible:
                  hasNoUserSearchAndGrouping(searchFilters) &&
                  isUserSearchFiltersEmpty(userComponentFilters),
              })}
              onClick={handleClearSearchFilters}
            >
              {t("Clear search filters")}
            </Link>
          </div>
        </div>
      </Transition>
    </div>
  );
};
