import { ActionBar, SaveIndicator, Tooltip } from "@amenda-components/App";
import { AppRoutes, GeneralPermissionTypes } from "@amenda-types";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { MaterialsPageProps, isAddingNewMaterial } from "./common";
import { Share2Icon, XIcon } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMaterialsStore, useSettingsStore } from "@amenda-domains/mutations";

import { useTranslation } from "react-i18next";

interface Props extends MaterialsPageProps {
  materialId?: string;
  setOpenModal: (open: boolean) => void;
  setOpenShareModal: (open: boolean) => void;
}

export const MaterialActionBar: FC<Props> = ({
  collectionRoute,
  isCollection,
  materialId,
  setOpenModal,
  setOpenShareModal,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const isSubmitting = useMaterialsStore((state) => state.isSubmitting);
  const unitPriceGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.UnitPrice,
  );
  const isEditing =
    isAddingNewMaterial(pathname) ||
    (materialId && pathname.endsWith(`/${materialId}/bearbeiten`));
  const showActions = !isAddingNewMaterial(pathname) && !!materialId;
  const path = isCollection ? collectionRoute : AppRoutes.Materials;

  const { t } = useTranslation();

  const handleEdit = () => {
    navigate(`${path}/${materialId}/bearbeiten`);
  };

  const handleReadOnly = () => {
    navigate(`${path}/${materialId}`);
  };

  const handleDelete = () => setOpenModal(true);

  return (
    <ActionBar>
      {({ IconButton }) => {
        return (
          <>
            {isEditing ? (
              <>
                <SaveIndicator isSubmitting={isSubmitting} />
                <Tooltip id="back" message="Close">
                  <IconButton
                    Icon={XIcon}
                    label={t("Close")}
                    variant="default"
                    onClick={handleReadOnly}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                {unitPriceGeneralPermissions[GeneralPermissionTypes.Share] && (
                  <Tooltip id="share" message={t("Share")}>
                    <IconButton
                      Icon={Share2Icon}
                      label={t("Share")}
                      variant="default"
                      onClick={() => setOpenShareModal(true)}
                    />
                  </Tooltip>
                )}
                {unitPriceGeneralPermissions[GeneralPermissionTypes.Delete] &&
                  showActions && (
                    <IconButton
                      Icon={TrashIcon}
                      label={t("Delete material")}
                      variant="default"
                      onClick={handleDelete}
                    />
                  )}
                {unitPriceGeneralPermissions[GeneralPermissionTypes.Edit] &&
                  showActions && (
                    <IconButton
                      Icon={PencilIcon}
                      label={t("Edit material")}
                      variant="default"
                      onClick={handleEdit}
                    />
                  )}
              </>
            )}
          </>
        );
      }}
    </ActionBar>
  );
};
