import { BellIcon, LogOutIcon, MegaphoneIcon, SearchIcon } from "lucide-react";
import {
  Button,
  Link as CustomLink,
  IconButtonBase,
  SlideOver,
  Tooltip,
} from "@amenda-components/App";
import { FC, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useAppStore,
  useAttachmentStore,
  useTenantStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Modal } from "./Modal";
import { NavigationProps } from "@amenda-types";
import { SearchModal } from "@amenda-components/SearchComponents";
import { allBlue } from "@amenda-styles/theme";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { matchUrls } from "@amenda-utils";
import { useTranslation } from "react-i18next";
import { CannyProvider, useCannyContext } from "react-canny";

const APP_ID = "66d22b218f8386edef62c489";
const CANNY_URL = "https://feedback.amenda.app";

interface Props {
  className?: string;
}

interface CannyLinkProps {
  href: string;
  children: React.ReactNode;
}

const CannyLink: FC<CannyLinkProps> = ({ children, href }) => {
  const { canny, isLoaded } = useCannyContext();

  if (!isLoaded) {
    return null; // Placeholder while loading
  }

  return (
    <a
      href={canny.authenticateCannyLink(href)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export const Logo: FC = () => {
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  return (
    <img
      alt="amenda"
      src={
        primaryTenant?.logoUrl
          ? primaryTenant?.logoUrl
          : "/images/logoDefault.svg"
      }
      className="max-h-[45px] max-w-[140px] lg:max-w-[140px]"
    />
  );
};

const NotificationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const notificationMessages = useAppStore(
    (state) => state.notificationMessages,
  );
  const clearNotificationMessage = useAppStore(
    (state) => state.clearNotificationMessage,
  );

  const handleClick = (message: any) => () => {
    window.open(message.path);
    clearNotificationMessage(message);
  };

  return (
    <Modal
      isElevated
      title="Notifications"
      isOpen={isOpen}
      size="md"
      onClose={onClose}
    >
      {!isEmpty(notificationMessages) ? (
        <>
          {notificationMessages.map((message) => (
            <div key={message.path}>
              <CustomLink
                className={clsx(
                  allBlue.menus.panelItem.lg,
                  `hover:${allBlue.menus.panelItemActive.lg} w-full cursor-pointer py-1 hover:no-underline`,
                )}
                onClick={handleClick(message)}
              >
                {message.message}
              </CustomLink>
            </div>
          ))}
        </>
      ) : (
        <p className="w-full px-2 py-4 text-center text-sm text-gray-700">
          {t("No notifications found!")}
        </p>
      )}
    </Modal>
  );
};

export const SidebarFooter: FC<Props> = ({
  className = "pt-8 pb-2 bottom-0 absolute",
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  // const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const currentUser = useUsersStore((state) => state.currentUser);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      setLoading(false);
    }
  }, [currentUser]);

  if (loading) {
    return null; // or any other loading indicator
  }

  const USER = {
    id: currentUser?.id,
    name: currentUser?.fullName,
    email: currentUser?.email,
  };

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleLogout = () => navigate("/logout");

  return (
    <CannyProvider appId={APP_ID} user={USER}>
      <div className={className}>
        <NotificationModal isOpen={isOpen} onClose={handleClose} />
        <nav
          className={clsx("flex", {
            "flex-col-reverse": sidebarCollapsed,
            "w-full flex-1 flex-row px-2": !sidebarCollapsed,
          })}
        >
          <Tooltip
            id="logoutBtn"
            message="Logout"
            {...(sidebarCollapsed
              ? {
                  position: "top",
                  positionStrategy: "fixed",
                }
              : {})}
          >
            <IconButtonBase
              size="sm"
              variant="secondary"
              className="bg-inherit rounded-md hover:bg-gray-50 hover:text-gray-900"
              onClick={handleLogout}
            >
              <LogOutIcon className="h-6 w-6 shrink-0" />
            </IconButtonBase>
          </Tooltip>
          <Tooltip
            id="notificationsBtn"
            message="Notifications"
            {...(sidebarCollapsed
              ? {
                  position: "top",
                  positionStrategy: "fixed",
                }
              : {})}
          >
            <IconButtonBase
              size="sm"
              variant="secondary"
              className="bg-inherit rounded-md hover:bg-gray-50 hover:text-gray-900"
              onClick={handleOpen}
            >
              <BellIcon className="h-6 w-6 shrink-0" />
            </IconButtonBase>
          </Tooltip>
          <Tooltip
            id="feedback"
            message="Feedback"
            {...(sidebarCollapsed
              ? {
                  position: "top",
                  positionStrategy: "fixed",
                }
              : {})}
          >
            <IconButtonBase
              size="sm"
              variant="secondary"
              className="bg-inherit rounded-md hover:bg-gray-50 hover:text-gray-900"
            >
              <CannyLink href={CANNY_URL}>
                <MegaphoneIcon className="h-6 w-6 shrink-0" />
              </CannyLink>
            </IconButtonBase>
          </Tooltip>
        </nav>
      </div>
    </CannyProvider>
  );
};

interface SidebarProps {
  navigation: NavigationProps[];
  closeSlideOver?: () => void;
}

// export const PoweredBy: FC = () => {
//   return (
//     <div className="flex min-w-24 h-12">
//       <div className="flex ">
//         <div className="flex ">
//           <span className="text-xxs text-gray-500">
//             Powered by{" "}
//             <img
//               alt="amenda"
//               width="30px"
//               src="/images/logo.svg"
//               className="pl-2 pt-1 h-[15px] w-auto"
//             />
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

export const SidebarMenuItems: FC<SidebarProps> = ({
  navigation,
  closeSlideOver,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const setSidebarCollapsed = useAppStore((state) => state.setSidebarCollapsed);
  const setAttachmentProject = useAttachmentStore(
    (state) => state.setAttachmentProject,
  );

  const isSlideOver = Boolean(closeSlideOver);

  const handleSearch = () => toggleSearchModal(true);

  const handleOpenSidebar = () => {
    setSidebarCollapsed(false);
  };

  const handleClick = (navigation: any) => () => {
    closeSlideOver?.();
    setAttachmentProject({});
    navigate(navigation.path);
  };

  return (
    <>
      <SearchModal />
      <nav
        className={clsx("space-y-1", {
          "pl-4": !sidebarCollapsed,
        })}
      >
        {!isSlideOver && (
          <Button
            size="lg"
            className={clsx(
              "bg-inherit group h-12 rounded-bl-md rounded-tl-md py-2 text-sm text-gray-400",
              {
                "mt-4 w-full pl-1 hover:bg-white hover:text-gray-900":
                  !sidebarCollapsed,
                "hover:bg-inherit mt-7 flex w-full justify-center px-0 hover:text-gray-900":
                  sidebarCollapsed,
              },
            )}
            onClick={handleSearch}
          >
            <SearchIcon className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-gray-500" />
            {!sidebarCollapsed && (
              <span className="ml-3 opacity-0 duration-300 ease-in group-hover:opacity-100">
                {t("Search all")}
              </span>
            )}
          </Button>
        )}
        {sidebarCollapsed ? (
          <div className="flex-col items-center">
            <IconButtonBase
              size="sm"
              variant="secondary"
              className="bg-inherit rounded-md hover:bg-gray-50 hover:text-gray-900"
              onClick={handleOpenSidebar}
            >
              <img src="/images/sidebarExpand.svg" className="h-6 w-6" alt="" />
            </IconButtonBase>
          </div>
        ) : (
          navigation.map((navigation) => {
            const pathMatches = matchUrls(pathname, navigation);

            return (
              <Button
                key={navigation.path}
                size="lg"
                className={clsx(
                  "bg-inherit h-12 w-full rounded-bl-md rounded-tl-md pl-4 pr-2 text-sm text-gray-200 xl:h-16",
                  {
                    "bg-white text-gray-900": pathMatches,
                    "text-gray-500 hover:bg-gray-50 hover:text-gray-900":
                      !pathMatches,
                  },
                )}
                onClick={handleClick(navigation)}
              >
                <span>{t(navigation.label)}</span>
              </Button>
            );
          })
        )}
      </nav>
    </>
  );
};

export const SidebarMobileMenu: FC<SidebarProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleSearch = () => toggleSearchModal(true);

  return (
    <>
      <SlideOver show={isOpen} title="Menu" onClose={handleClose}>
        <div className="h-full w-full bg-[#F3F4F6] py-2">
          <SidebarMenuItems {...props} closeSlideOver={handleClose} />
          <SidebarFooter />
        </div>
      </SlideOver>
      <div className="mb-12 block lg:hidden">
        <div className="absolute z-[60] flex w-full justify-between border-b border-gray-200 bg-white px-1 pb-1 pt-0.5">
          <div className="flex items-center space-x-2">
            <IconButtonBase
              size="sm"
              variant="clean"
              className="hover:bg-white hover:text-gray-900"
              onClick={handleOpen}
            >
              <Bars3Icon className="h-8 w-8" />
            </IconButtonBase>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <IconButtonBase
            size="sm"
            variant="clean"
            className="hover:bg-white hover:text-gray-900"
            onClick={handleSearch}
          >
            <MagnifyingGlassIcon className="h-6 w-6" />
          </IconButtonBase>
        </div>
      </div>
    </>
  );
};
