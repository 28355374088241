import { ChevronDownIcon, GripIcon, XIcon } from "lucide-react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { FC } from "react";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "@amenda-components/PageLayouts/common";
import { availableLayouts } from "@amenda-types";
import { buttonTheme } from "@amenda-styles/theme";
import { getLayoutOptions } from "@amenda-components/Settings/common";
import { useAppStore } from "@amenda-domains/mutations/app";
import { useTranslation } from "react-i18next";

type ComponentActionBarProps = Pick<LayoutProps, "config" | "globalProps"> & {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
};

const ComponentActionBar: FC<ComponentActionBarProps> = ({
  control,
  setValue,
  config,
}) => {
  const { t } = useTranslation();
  const updateFormViewComponentLayout = useAppStore(
    (state) => state.updateFormViewComponentLayout,
  );
  const removeFormViewComponent = useAppStore(
    (state) => state.removeFormViewComponent,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState.selectedForm,
  );
  const sections = useWatch({
    control,
    name: "sections",
    defaultValue: [],
  });

  const activeIndex = sections.findIndex((s: any) => s.id === selectedForm?.id);

  const handleUpdateLayout = (layout: availableLayouts) => {
    if (config?.id && activeIndex !== -1) {
      updateFormViewComponentLayout({
        layout,
        componentId: config.id,
        callback: (components) => {
          setValue(`sections.${activeIndex}.components`, components);
        },
      });
    }
  };

  const handleDeleteComponent = () => {
    if (config?.id && activeIndex !== -1) {
      removeFormViewComponent(config.id, (components) => {
        setValue(`sections.${activeIndex}.components`, components);
      });
    }
  };

  return (
    <div className="flex w-full items-center justify-between">
      <Popover>
        <PopoverButton
          className={buttonTheme({
            size: "sm",
            variant: "outline",
            className:
              "flex h-6 items-center space-x-1 p-1 pl-2 text-xs leading-none",
          })}
        >
          {t("Layout")} <ChevronDownIcon className="h-4 w-4" />
        </PopoverButton>
        <PopoverPanel
          transition
          anchor="bottom start"
          className="fixed z-50 w-24 origin-top-left divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition duration-200 ease-in-out focus:outline-none"
        >
          {({ close }) => (
            <div className="w-full p-1">
              {getLayoutOptions().map((o) => (
                <div
                  key={o.value}
                  className="cursor-pointer rounded-sm px-2 py-1 text-sm text-gray-900 hover:bg-gray-900 hover:text-white"
                  onClick={() => {
                    handleUpdateLayout(o.value);
                    close();
                  }}
                >
                  {o.label}
                </div>
              ))}
            </div>
          )}
        </PopoverPanel>
      </Popover>
      <IconButtonBase
        size="xss"
        className="h-5 w-5"
        variant="primary"
        onClick={handleDeleteComponent}
      >
        <XIcon className="h-4 w-4" />
      </IconButtonBase>
    </div>
  );
};

type FormBuilderFormViewComponentActionBarProps = Pick<
  LayoutProps,
  "config" | "globalProps"
>;

export const FormBuilderFormViewComponentActionBar: FC<
  FormBuilderFormViewComponentActionBarProps
> = ({ config, globalProps }) => {
  const isEditingComponent = useAppStore(
    (state) => state.formBuilderState.isEditingComponent,
  );
  const isEditingForm = useAppStore(
    (state) => state.formBuilderState.isEditingForm,
  );

  const isFormView = Boolean(globalProps?.isFormView);

  if (!isEditingForm || isEditingComponent) {
    return null;
  }
  return (
    <div className="invisible flex items-center space-x-1 group-hover/full-col:visible group-hover/half-col:visible group-hover/one-third-col:visible group-hover/resizableLayout:visible group-hover/two-thirds-col:visible">
      <IconButtonBase
        size="xss"
        className="cursor-grab px-0.5 py-1"
        variant="clean"
      >
        <GripIcon className="h-4 w-4 stroke-[1.5]" />
      </IconButtonBase>
      {isFormView && globalProps?.control && globalProps?.setValue && (
        <ComponentActionBar
          config={config}
          control={globalProps?.control}
          setValue={globalProps?.setValue}
        />
      )}
    </div>
  );
};
