import {
  ChevronDownIcon,
  GlobeIcon,
  LockIcon,
  UsersRoundIcon,
} from "lucide-react";
import { FC, Fragment, ReactNode } from "react";
import { GeneralAccessTypes, projectShareOptions } from "@amenda-constants";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";

import { Option } from "@amenda-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  selectedOption?: string;
  className?: string;
  hasMenuOverflow?: boolean;
  labelClassName?: string;
  onChange: (option: string) => void;
}

interface AcceptedShareIconProps {
  value?: GeneralAccessTypes;
  children?: ReactNode;
}

export const AcceptedShareIcon: FC<AcceptedShareIconProps> = ({
  value,
  children,
}) => {
  switch (value) {
    case GeneralAccessTypes.Private:
      return <LockIcon className="h-5 w-5" />;
    case GeneralAccessTypes.Limited:
      return <UsersRoundIcon className="h-5 w-5" />;
    case GeneralAccessTypes.Everyone:
      return <GlobeIcon className="h-5 w-5" />;
    default:
      return (
        <Fragment>
          {children ? children : <UsersRoundIcon className="h-5 w-5" />}
        </Fragment>
      );
  }
};

const getCurrentOption = (selectedOption?: any) => {
  const defaultOption = projectShareOptions[0];

  if (!!selectedOption) {
    return (
      projectShareOptions.find((option) => option.value === selectedOption) ||
      defaultOption
    );
  }
  return defaultOption;
};

export const SharingPermissionsDropdown: FC<Props> = ({
  label,
  selectedOption,
  labelClassName,
  hasMenuOverflow = false,
  onChange,
}) => {
  const { t } = useTranslation();

  const selected = getCurrentOption(selectedOption);

  const handleChange = (option: Option) => {
    onChange(option.value);
  };

  return (
    <div className="w-full">
      <span className={clsx("mb-1 text-sm", labelClassName)}>{t(label)}</span>
      <Listbox value={selected} onChange={handleChange}>
        <div className="relative">
          <ListboxButton className="relative flex w-full cursor-pointer items-start rounded-lg bg-white py-2 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
            <div className="mr-2 rounded-full bg-gray-100 p-2 text-gray-500">
              <AcceptedShareIcon value={selected.value} />
            </div>
            <div>
              <div className="flex w-full items-center text-gray-800">
                <span className="block truncate">{t(selected.label)}</span>
                <span className="pointer-events-none pl-2">
                  <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                </span>
              </div>
              <p className="w-full text-left text-xs text-gray-500">
                {t(selected.description)}
              </p>
            </div>
          </ListboxButton>
          <Transition
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions
              className={clsx(
                "mt-1 max-h-60 w-64 overflow-auto bg-white py-0 text-base shadow-md sm:text-sm",
                {
                  fixed: hasMenuOverflow,
                },
              )}
            >
              {projectShareOptions.map((option) => (
                <ListboxOption
                  key={option.value}
                  value={option}
                  className={({ focus }) =>
                    clsx(
                      "relative flex cursor-default select-none items-center py-1.5 pl-3 pr-9",
                      {
                        "bg-gray-900 text-white": !!focus,
                        "text-gray-900": !focus,
                      },
                    )
                  }
                >
                  {({ selected }) => (
                    <span className="block truncate">{t(option.label)}</span>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
