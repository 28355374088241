import { FC, useEffect } from "react";

import { Button, HelperMessage } from "@amenda-components/App";
import { SAMLSchema } from "@amenda-constants";
import { InferType } from "yup";
import { TenantSAMLForm } from "@amenda-components/Tenant";
import { sanitizeData } from "@amenda-utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertTriangle } from "lucide-react";

type TenantInput = InferType<typeof SAMLSchema>;

export const TenantSAMLFormWrapper: FC<{
  tenant: any;
  loading: boolean;
  onFormSubmit: (args: any) => void;
}> = ({ tenant, loading, onFormSubmit }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm<TenantInput>({
    resolver: yupResolver(SAMLSchema),
  });

  const onSubmit = async (data: TenantInput) => {
    const dataForm = sanitizeData(data);
    await onFormSubmit(dataForm);
  };

  useEffect(() => {
    const getValues = (tenant: any = {}) => {
      return {
        ...tenant?.tenantConfig?.samlConfig,
        callbackURL:
          tenant?.callbackURL ||
          "https://azimio-5d437.firebaseapp.com/__/auth/handler",
      };
    };

    if (tenant) {
      reset(getValues(tenant));
    }
  }, [tenant, reset]);

  if (!tenant?.tenantConfig?.samlConfig?.webDomain) {
    return (
      <HelperMessage
        iconClassName="stroke-[2] h-10 w-10 text-gray-800"
        message="SAML Unavailable"
        helpText="The SAML configuration is not available for this tenant."
        Icon={AlertTriangle}
      />
    );
  }

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <TenantSAMLForm tenant={tenant} control={control} />
      <div className="flex justify-start">
        <Button type="submit" variant="secondary" size="md" loading={loading}>
          {t("Save")}
        </Button>
      </div>
    </form>
  );
};
