import { AvailableNotificationTypes, FormTab } from "@amenda-types";
import { Button, HelperMessage, Modal } from "@amenda-components/App";
import { CheckIcon, FileJson2Icon, FileSpreadsheetIcon } from "lucide-react";
import { FC, useMemo, useState } from "react";
import {
  SingleCheckbox,
  SingleSelect,
  TextField,
} from "@amenda-components/FormComponents";
import {
  getCategory,
  getComponentsFromConfig,
  getConfigFromExcel,
  getPossibleComponentTypes,
} from "../common";
import { readFileAsync, safeParse } from "@amenda-utils";
import {
  useAppStore,
  useCreateForm,
  useProjectStore,
} from "@amenda-domains/mutations";

import { FileUploaderDropZone } from "@amenda-components/FileUploads";
import { getTemplateComponents } from "@amenda-constants/template";
import { isEmpty } from "lodash";
import readXlsxFile from "read-excel-file";
import { useFormBuilderModifySystemRole } from "../hooks";
import { useTranslation } from "react-i18next";

const getForms = (forms: Record<string, FormTab[]>, formKeys: string[]) => {
  const availableForms: FormTab[] = [];

  formKeys.forEach((key) => {
    availableForms.push(...(forms[key] ?? []));
  });

  return availableForms;
};

export const FormBuilderCreateFormModal: FC = () => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>();
  const [files, setFiles] = useState<any[]>([]);
  const [dataRows, setDataRows] = useState<any[][]>([]);
  const [componentsConfig, setComponentsConfig] = useState<any>({});
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const storeForms = useProjectStore((state) => state.forms);
  const forms = useMemo(() => storeForms ?? {}, [storeForms]);
  const { createForm, loading } = useCreateForm();
  const showNotification = useAppStore((state) => state.showNotification);
  const { loading: isUpdatingPermissions, addFormToPermissions } =
    useFormBuilderModifySystemRole();

  const { createNewFormModal, selectedCategory } = formBuilderState ?? {};
  const formKeys = Object.keys(forms).filter(
    (key) => selectedCategory && key.startsWith(selectedCategory),
  );
  const source = createNewFormModal?.source;
  const availableForms = getForms(forms, formKeys);
  const isDisabled =
    source === "blankSlate"
      ? isEmpty(name)
      : source === "json"
        ? isEmpty(files)
        : isEmpty(name) || isEmpty(componentsConfig);

  const updateSelectedComponent = (id: string, value: any) => {
    setComponentsConfig({
      ...componentsConfig,
      [id]: {
        ...componentsConfig[id],
        selectedComponent: value,
      },
    });
  };

  const updateIsRequired = (id: string, value: boolean) => {
    setComponentsConfig({
      ...componentsConfig,
      [id]: {
        ...componentsConfig[id],
        isRequired: value,
      },
    });
  };

  const onDropExcel = async (acceptedFiles: any[]) => {
    try {
      const file = acceptedFiles[0];
      if (!file) return;

      const rows = await readXlsxFile(file);
      const config = getConfigFromExcel(rows);
      const componentsConfig = getPossibleComponentTypes(config);

      setDataRows(rows.slice(1));
      setComponentsConfig(componentsConfig);
    } catch (err) {
      showNotification(
        AvailableNotificationTypes.Error,
        "Something went wrong, upload failed!",
      );
    }
  };

  const createFormFromJSON = async () => {
    const file = files[0];
    if (!file) return;
    try {
      const fileContent = await readFileAsync(file);
      if (typeof fileContent === "string") {
        const values = safeParse(fileContent);
        const order = availableForms.length;
        const category = getCategory(availableForms, selectedCategory);

        const formInput = {
          order,
          category,
          ...values,
        };

        const form = await createForm(formInput);
        await addFormToPermissions(form);
      }
      setFiles([]);
      updateFormBuilderState("createNewFormModal", {
        isOpen: false,
      });
    } catch (err) {
      showNotification(
        AvailableNotificationTypes.Error,
        "Something went wrong, upload failed!",
      );
    }
  };

  const createTemplateFromScratch = async () => {
    const order = availableForms.length;
    const category = getCategory(availableForms, selectedCategory);

    const values = {
      name,
      order,
      category,
      components: getTemplateComponents(name),
    };

    const form = await createForm(values);
    await addFormToPermissions(form);
    setName(undefined);
    updateFormBuilderState("createNewFormModal", {
      isOpen: false,
    });
  };

  const createFormFromExcel = async () => {
    try {
      const order = availableForms.length;
      const category = getCategory(availableForms, selectedCategory);

      const values = {
        name,
        order,
        category,
        components: getComponentsFromConfig({
          componentsConfig,
          dataRows,
          formName: name,
          category: selectedCategory,
        }),
      };

      const form = await createForm(values);
      await addFormToPermissions(form);
      setName(undefined);
      setName(undefined);
      setDataRows([]);
      setComponentsConfig({});
      updateFormBuilderState("createNewFormModal", {
        isOpen: false,
      });
    } catch (err) {
      showNotification(
        AvailableNotificationTypes.Error,
        "Something went wrong, upload failed!",
      );
    }
  };

  const handleSubmit = () => {
    switch (source) {
      case "blankSlate":
        return createTemplateFromScratch();
      case "json":
        return createFormFromJSON();
      case "xlsx":
        return createFormFromExcel();
      default:
        return;
    }
  };

  const handleClose = () => {
    setName(undefined);
    setDataRows([]);
    setFiles([]);
    setComponentsConfig({});
    updateFormBuilderState("createNewFormModal", {
      isOpen: false,
    });
  };

  return (
    <Modal
      isOpen={Boolean(createNewFormModal?.isOpen)}
      loading={loading || isUpdatingPermissions}
      isElevated={true}
      closeModalFromTitle={true}
      withCancel={false}
      size="md"
      className="w-11/12 md:w-1/2 lg:w-4/12"
      title="Create a new form"
      onClose={handleClose}
      footerClassName="w-full"
      footerChildren={({ loading, onClose }) => {
        return (
          <div className="flex w-full justify-end bg-white">
            <Button type="button" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              loading={loading}
              variant="primary"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              {t("Save")}
            </Button>
          </div>
        );
      }}
    >
      {source && (
        <div className="flex flex-col items-center space-y-2">
          {source === "blankSlate" && (
            <div className="w-full pb-8">
              <TextField
                id="name"
                label="Form name"
                value={name}
                onChange={setName}
                hideErrorMessage={true}
              />
            </div>
          )}
          {source === "json" && (
            <FileUploaderDropZone
              acceptedFileTypes={{
                "application/json": [".json"],
              }}
              onDrop={setFiles}
              maxFiles={1}
            >
              {isEmpty(files) ? (
                <HelperMessage
                  message="Create form from JSON"
                  helpText="Drag 'n' drop a .json file here, or click to select"
                  Icon={FileJson2Icon}
                />
              ) : (
                <HelperMessage
                  message="File uploaded"
                  helpText="Click save to generate form"
                  Icon={CheckIcon}
                />
              )}
            </FileUploaderDropZone>
          )}
          {source === "xlsx" && (
            <FileUploaderDropZone
              acceptedFileTypes={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
              }}
              onDrop={onDropExcel}
              maxFiles={1}
              hideBorder={!isEmpty(componentsConfig)}
              disabled={!isEmpty(componentsConfig)}
            >
              {isEmpty(componentsConfig) ? (
                <HelperMessage
                  message="Create form from Excel"
                  helpText="Drag 'n' drop a .xlsx file here, or click to select"
                  Icon={FileSpreadsheetIcon}
                />
              ) : (
                <div className="divide-y-gray-300 max-h-[80vh] w-full space-y-1 divide-y overflow-y-auto overscroll-contain">
                  <div className="w-full pb-8">
                    <TextField
                      id="name"
                      label="Form name"
                      value={name}
                      onChange={setName}
                      hideErrorMessage={true}
                    />
                  </div>
                  {Object.keys(componentsConfig).map((key) => {
                    const componentConfig = componentsConfig[key];

                    return (
                      <div key={key} className="flex-col pt-2">
                        <p className="text-md">{componentConfig.label}</p>
                        <div className="flex items-center space-x-3 pt-2">
                          <div className="w-2/3">
                            <SingleSelect
                              id="component"
                              label="Component Typ"
                              isClearable={false}
                              hasMenuOverflow={true}
                              hideErrorMessage={true}
                              value={componentConfig.selectedComponent}
                              options={componentConfig.possibleComponents}
                              onChange={(value) =>
                                updateSelectedComponent(key, value)
                              }
                            />
                          </div>
                          <SingleCheckbox
                            id="isRequired"
                            label="Is required?"
                            checked={componentConfig.isRequired}
                            onChange={(checked) =>
                              updateIsRequired(key, Boolean(checked))
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </FileUploaderDropZone>
          )}
        </div>
      )}
    </Modal>
  );
};
