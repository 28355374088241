import { gql } from "urql";

export const PROJECT_DESIGN_FRAGMENT = gql`
  fragment ProjectDesignFragment on ProjectDesign {
    id: _id
    name
    previewUrl
    type
    projectId
    values
  }
`;
