import "@amenda-styles/index.css";
import "./i18n";

import * as Sentry from "@sentry/react";

import { FC, ReactNode, StrictMode, Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Notifications, Spinner } from "@amenda-components/App";

import { ErrorDetectionFallback } from "@amenda-components/App";
import { FirebaseProvider } from "@amenda-components/Auth";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "urql";
import { SubscriptionWrapper } from "@amenda-components/Shared";
import TagManager from "react-gtm-module";
import { Toaster } from "react-hot-toast";
import { client } from "@amenda-domains/client";
import { msalInstance } from "@amenda-domains/msGraphConfig";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TAG || "",
};

if (process.env.NODE_ENV === "production") {
  TagManager.initialize(tagManagerArgs);
}

interface Props {
  children: ReactNode;
}

const Providers: FC<Props> = ({ children }) => {
  return (
    <StrictMode>
      <FirebaseProvider>
        <Provider value={client}>
          <Sentry.ErrorBoundary
            fallback={({ resetError }) => (
              <ErrorDetectionFallback resetError={resetError} />
            )}
          >
            <Suspense
              fallback={
                <div className="flex h-screen items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <MsalProvider instance={msalInstance}>
                <HelmetProvider>
                  <SubscriptionWrapper>
                    <Helmet
                      titleTemplate="amenda - %s"
                      defaultTitle="amenda - Projektdatenbank"
                    />
                    {children}
                    <Toaster position="top-right" reverseOrder={true} />
                    <Notifications />
                  </SubscriptionWrapper>
                </HelmetProvider>
              </MsalProvider>
            </Suspense>
          </Sentry.ErrorBoundary>
        </Provider>
      </FirebaseProvider>
    </StrictMode>
  );
};

export default Providers;
