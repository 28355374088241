import { Button, HelperMessage, Modal, Spinner } from "@amenda-components/App";
import { Control, useController, useForm, useWatch } from "react-hook-form";
import { FC, ReactNode } from "react";
import { ResizeMode, createFileDownloadSchema } from "@amenda-constants";
import { devConsole, openUrlInNewTab } from "@amenda-utils";
import { isEmpty, isString } from "lodash";
import {
  preStripMetadata,
  showDownloadAttachmentForm,
} from "./DownloadAttachmentForms";
import {
  useAttachmentStore,
  useCreateAttachmentsZip,
} from "@amenda-domains/mutations";

import { CloudDownloadIcon } from "lucide-react";
import clsx from "clsx";
import { isOriginalDownloadFormat } from "./common";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface StepsProps {
  steps: {
    no: number;
    hasPassed?: boolean;
    label: string | ReactNode;
  }[];
  currentStep: number;
  children?: ReactNode;
  setStep: (i: number) => void;
}

const Steps: FC<StepsProps> = ({ steps, currentStep, children, setStep }) => {
  const { t } = useTranslation();

  return (
    <ol className="relative z-10 w-full bg-white text-sm text-gray-600">
      {steps.map((step, i) => (
        <li className="w-full" key={step.no}>
          <div
            className="flex cursor-pointer select-none items-center space-x-2"
            onClick={() => setStep(step.no)}
          >
            <span
              className={clsx(
                "h-6 min-h-6 w-6 min-w-6 rounded-full text-center text-[10px]/6",
                step.no === currentStep || Boolean(step.hasPassed)
                  ? "bg-blue-600 text-white"
                  : "bg-gray-100",
              )}
            >
              {step.no}
            </span>
            <span className="truncate">
              {isString(step.label) ? t(step.label) : step.label}
            </span>
          </div>
          <div className="flex h-full py-2">
            {i + 1 !== steps.length && step.no !== currentStep && (
              <div className="h-full min-h-6 w-3 min-w-3 border-r border-gray-300" />
            )}
            <div
              className={clsx("flex grow", {
                hidden: step.no !== currentStep,
              })}
            >
              <div className="h-full min-h-6 w-3 min-w-3 border-r border-gray-300" />
              {children}
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
};

interface FormWrapperProps {
  control: Control<any>;
}

const FormWrapper: FC<FormWrapperProps> = ({ control }) => {
  const { t } = useTranslation();
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: "currentStep",
    defaultValue: 1,
  });
  const [downloadFormat = [], filename] = useWatch({
    control,
    name: ["downloadFormat", "filename"],
  });

  return (
    <Steps
      currentStep={value}
      steps={[
        {
          no: 1,
          label: <span>{t("Select file sizes")}</span>,
          hasPassed: !!downloadFormat,
        },
        {
          no: 2,
          label: "Resize images",
          hasPassed: value > 2,
        },
        {
          no: 3,
          label: <span>{t("Custom file naming")}</span>,
          hasPassed: !!filename,
        },
        {
          no: 4,
          label: (
            <span>
              Metadata <b className="text-gray-500">Optional</b>
            </span>
          ),
        },
      ]}
      setStep={onChange}
    >
      <div className="w-full py-2 pl-4 transition-[height] duration-300 ease-in-out">
        {showDownloadAttachmentForm({
          control,
          currentStep: value,
        })}
      </div>
    </Steps>
  );
};

const DownloadIndicator = () => {
  return (
    <HelperMessage
      className="h-40"
      Icon={CloudDownloadIcon}
      message="Downloading files, this might take sometime..."
    >
      <div className="flex justify-center pt-4">
        <Spinner spinnerSize="sm" />
      </div>
    </HelperMessage>
  );
};

export const DownloadAttachmentModal: FC = () => {
  const { t } = useTranslation();
  const openDownloadModal = useAttachmentStore(
    (state) => state.modals.openDownloadModal,
  );
  const setOpenDownloadModal = useAttachmentStore(
    (state) => state.setOpenDownloadModal,
  );
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(createFileDownloadSchema),
  });
  const { createAttachmentsZip, loading } = useCreateAttachmentsZip();

  const formId = "downloadFile";

  const handleClose = () => {
    setOpenDownloadModal(false);
    reset({ currentStep: 1, metadata: [] });
  };

  const onSubmit = async (data: Record<string, any>) => {
    const {
      filename,
      downloadFormat,
      metadata = [],
      fit = ResizeMode.Cover,
    } = data;

    const formValues = metadata
      .filter((m: any) => m !== preStripMetadata)
      .reduce((acc: any, m: any) => {
        acc[m] = 1;
        return acc;
      }, {});

    let selectMetadataFields: any = {
      metadata: 1,
    };
    if (!isEmpty(formValues)) {
      selectMetadataFields.formValues = formValues;
    }
    if (metadata.includes(preStripMetadata)) {
      delete selectMetadataFields.metadata;
    }
    const input = {
      _ids: selectedAttachments,
      config: {
        filename,
        selectMetadataFields,
        dimension: {
          fit,
          name: downloadFormat.name,
          ...(downloadFormat.dimensions ?? {}),
        },
      },
    };
    if (isOriginalDownloadFormat(downloadFormat)) {
      delete input.config.dimension;
    }

    const response = await createAttachmentsZip({ input });
    if (response?.link) {
      openUrlInNewTab(response.link);
    }
    handleClose();
  };

  return (
    <Modal
      size="lg"
      isOpen={openDownloadModal}
      title="Create file download"
      message={t("DownloadWillContain", {
        selectedAttachments: selectedAttachments.length,
      })}
      loading={loading}
      className="md:1/2 w-11/12 lg:w-4/12"
      onClose={handleClose}
      closeModalFromTitle={true}
      footerChildren={({ loading, onClose }) => {
        if (loading) return null;
        return (
          <div className="flex w-full items-center justify-end space-x-2">
            <Button size="md" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              size="md"
              form={formId}
              variant="primary"
              loading={loading}
              type="submit"
              disabled={!isValid}
            >
              <CloudDownloadIcon className="h-5 w-5" />
              <span className="ml-1">{t("Start downloading")}</span>
            </Button>
          </div>
        );
      }}
    >
      <form
        id={formId}
        className="w-full"
        onSubmit={handleSubmit(onSubmit, (e) => {
          devConsole?.log("x error", e);
        })}
      >
        {loading ? <DownloadIndicator /> : <FormWrapper control={control} />}
      </form>
    </Modal>
  );
};
