import { CheckCircle2, CheckIcon } from "lucide-react";
import { FC, MouseEvent, ReactNode } from "react";

import { CheckMarkRadio } from "./CheckMarkRadio";
import { HoverVariants } from "@amenda-types";
import { IconButton } from "./IconButton";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { PhotoIcon as PhotoOutlineIcon } from "@heroicons/react/24/outline";
import { SingleCheckbox } from "@amenda-components/FormComponents";
import { SingleRadioButton } from "@amenda-components/FormComponents";
import clsx from "clsx";
import { isCmdOrCtrlOrShiftPressed } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface HoverSelectorBaseProps {
  children: ReactNode;
  className?: string;
  isListCard?: boolean;
  handleSelect?: () => void;
  handleClick?: () => void;
  handleShiftSelect?: () => void;
}
interface Props extends HoverSelectorBaseProps {
  id?: string;
  isSelected?: boolean;
  canSelect?: boolean;
  hoverMessage?: string;
  hoverIcon?: any;
  variant?: HoverVariants;
  hideSelector?: boolean;
}

interface HoverListSelectorProps extends HoverSelectorBaseProps {
  isSelected?: boolean;
}

export const HoverSelectorBase: FC<HoverSelectorBaseProps> = ({
  className,
  children,
  handleSelect,
  handleClick,
  handleShiftSelect,
}) => {
  const setHoverSelector = useAppStore((state) => state.setHoverSelector);

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    setHoverSelector(true);
  };

  const onPointerLeave = (e: MouseEvent<HTMLDivElement>) => {
    setHoverSelector(false);
  };

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    const isCommandPressed = e.metaKey || e.ctrlKey;
    const isShiftPressed = e.shiftKey;

    if (isCommandPressed) {
      handleSelect?.();
    } else if (isShiftPressed) {
      handleShiftSelect?.();
    } else {
      handleClick?.();
    }
  };

  return (
    <div
      className={clsx("group relative h-fit w-full select-none", className)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onPointerLeave={onPointerLeave}
    >
      {children}
    </div>
  );
};

export const HoverListSelector: FC<HoverListSelectorProps> = ({
  children,
  isSelected = false,
  handleSelect,
  ...rest
}) => {
  const pressedKey = useAppStore((state) => state.pressedKey);
  const hoverSelector = useAppStore((state) => state.hoverSelector);

  return (
    <HoverSelectorBase handleSelect={handleSelect} {...rest}>
      <div className="flex h-full w-full items-center overflow-x-hidden">
        <CheckMarkRadio
          className="block lg:hidden"
          isChecked={isSelected}
          onChange={handleSelect}
        />
        {children}
        <div className="absolute right-0 top-0 mr-2 hidden h-full items-center transition lg:flex">
          {!isSelected &&
            hoverSelector &&
            isCmdOrCtrlOrShiftPressed(pressedKey) && (
              <div
                className={`rounded-full p-1 text-gray-900 ${
                  isSelected
                    ? "bg-[#2286E0]"
                    : "hover:bg-[#2286E0] hover:text-white"
                }`}
              >
                <CheckIcon className="h-4 w-4" />
              </div>
            )}
          <CheckMarkRadio
            className={clsx({
              block: isSelected,
              "hidden group-hover:flex":
                !isSelected &&
                !(hoverSelector && isCmdOrCtrlOrShiftPressed(pressedKey)),
              "hidden group-hover:hidden":
                !isSelected &&
                hoverSelector &&
                isCmdOrCtrlOrShiftPressed(pressedKey),
            })}
            isChecked={isSelected}
            onChange={handleSelect}
          />
        </div>
      </div>
    </HoverSelectorBase>
  );
};

export const HoverSelector: FC<Props> = ({
  canSelect,
  children,
  hoverMessage,
  hoverIcon,
  id = "",
  isSelected = false,
  hideSelector = false,
  variant = HoverVariants.Default,
  handleSelect,
  ...rest
}) => {
  const { t } = useTranslation();
  const pressedKey = useAppStore((state) => state.pressedKey);
  const hoverSelector = useAppStore((state) => state.hoverSelector);

  return (
    <HoverSelectorBase handleSelect={handleSelect} {...rest}>
      {!hideSelector && (
        <div
          className={clsx("absolute z-20 m-1", {
            "left-1 top-1": [
              HoverVariants.Checkbox,
              HoverVariants.RadioButton,
            ].includes(variant),
            "right-0 top-0": ![
              HoverVariants.Checkbox,
              HoverVariants.RadioButton,
            ].includes(variant),
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {[HoverVariants.Default, HoverVariants.NoBorder].includes(variant) ? (
            <>
              {canSelect && (
                <div className="flex flex-row items-center">
                  <span
                    className={clsx("pr-2 text-xs", {
                      "hidden group-hover:block": !isSelected,
                    })}
                  >
                    {t(isSelected ? "Cover Image" : "Set as Cover Image")}
                  </span>
                  {isSelected ? (
                    <PhotoIcon className="h-5 w-5 text-yellow-500" />
                  ) : (
                    <PhotoOutlineIcon className="hidden h-5 w-5 text-gray-800 group-hover:block" />
                  )}
                </div>
              )}
            </>
          ) : variant === HoverVariants.Checkbox ? (
            !(hoverSelector && isCmdOrCtrlOrShiftPressed(pressedKey)) && (
              <SingleCheckbox
                id={id}
                checked={isSelected}
                className={clsx("h-5 w-5", {
                  "group-hover:block md:block": isSelected,
                  "group-hover:block md:hidden": !isSelected,
                })}
                onChange={handleSelect}
              />
            )
          ) : (
            <SingleRadioButton
              id={id}
              checked={isSelected}
              className={clsx({
                "group-hover:block md:hidden":
                  !isSelected &&
                  !(hoverSelector && isCmdOrCtrlOrShiftPressed(pressedKey)),
                "group-hover:hidden md:hidden":
                  !isSelected &&
                  hoverSelector &&
                  isCmdOrCtrlOrShiftPressed(pressedKey),
              })}
              onChange={handleSelect}
            />
          )}
        </div>
      )}
      {hoverMessage && hoverIcon && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <h4 className="lg:text-md text-sm text-gray-900">
            <div className="flex">
              <IconButton
                Icon={hoverIcon}
                label={t(hoverMessage)}
                iconSize={1}
              />
            </div>
          </h4>
        </div>
      )}
      {children}
      {canSelect && hoverSelector && isCmdOrCtrlOrShiftPressed(pressedKey) && (
        <div className="absolute inset-0 flex h-full w-full items-center justify-center">
          <div
            className={`rounded-full text-white ${
              isSelected ? "bg-[#2286E0]" : "hover:bg-[#2286E0]"
            }`}
          >
            {isSelected ? (
              <CheckIcon className="h-12 w-12 stroke-[3] lg:h-24 lg:w-24" />
            ) : (
              <CheckCircle2 className="h-12 w-12 stroke-[2] lg:h-24 lg:w-24" />
            )}
          </div>
        </div>
      )}
    </HoverSelectorBase>
  );
};
