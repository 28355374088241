import { SubmitHandler, useForm } from "react-hook-form";
import {
  useProjectStore,
  useSettingsStore,
  useTenantStore,
} from "@amenda-domains/mutations";

import { FC } from "react";
import { InferType } from "yup";
import { SimilaritySearchConfigForm } from "./SimilaritySearchConfigForm";
import { getSimilaritySearchConfigComponents } from "./common";
import { similaritySearchInputSchema } from "@amenda-constants";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  formId: string;
  handleSave: (data: any) => void;
}

type SimilaritySearchType = InferType<typeof similaritySearchInputSchema>;

export const SimilaritySearchConfigFormWrapper: FC<Props> = ({
  formId,
  handleSave,
}) => {
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const components = useProjectStore((state) => state.projectFormComponents);

  const values = primaryTenant?.settings?.similaritySearchConfig || {};
  const allowedComponents = getSimilaritySearchConfigComponents(
    permissions,
    components,
  );

  const { control, handleSubmit } = useForm<SimilaritySearchType>({
    values,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
    resolver: yupResolver(similaritySearchInputSchema),
  });

  const onSubmit: SubmitHandler<Record<string, any>> = async (
    similaritySearchConfig,
  ) => {
    handleSave({
      settings: {
        ...(primaryTenant?.settings || {}),
        similaritySearchConfig,
      },
    });
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-4 lg:pt-6">
        <SimilaritySearchConfigForm
          control={control}
          globalData={values}
          components={allowedComponents}
        />
      </div>
    </form>
  );
};
