import { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useGetAllProjectDesigns } from "@amenda-domains/queries/designs";
import { AllowedDesignType } from "@amenda-types";
import { ProjectDesignListView } from "@amenda-components/Projects/ProjectDesignListView";
import { useProjectDesignsStore } from "@amenda-domains/mutations/designs";

interface Props {
  projectId?: string;
}

export const ReadOnlyProjectTemplateDesigner: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const { getAllProjectDesigns, loading } = useGetAllProjectDesigns();
  const designs = useProjectDesignsStore((state) => state.designs);

  useEffect(() => {
    const getAllProjectDesignsAsync = async () => {
      if (projectId) {
        await getAllProjectDesigns({
          projectId,
          type: AllowedDesignType.custom,
        });
      }
    };

    getAllProjectDesignsAsync();
  }, [getAllProjectDesigns, projectId]);

  return (
    <div className="py-5">
      <h3 className="amenda-form-heading">{t("Project Designs")}</h3>
      <ProjectDesignListView loading={loading} designs={designs} />
    </div>
  );
};
