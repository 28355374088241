import { FC, useEffect, useRef } from "react";

interface GalleryDragHandleProps {
  attachment: any;
  setDragging: (dragging: boolean) => void;
}

const GalleryDragHandle: FC<GalleryDragHandleProps> = ({
  attachment,
  setDragging,
}) => {
  const thumbnailImgRef = useRef<HTMLImageElement | null>(null);

  // Preload thumbnail when component mounts
  useEffect(() => {
    const loadThumbnail = async () => {
      try {
        const img = await loadImage(`${attachment.url}/thumbnail`);
        thumbnailImgRef.current = img;
      } catch (error) {
        console.error("Error preloading thumbnail:", error);
      }
    };
    loadThumbnail();
  }, [attachment.url]);

  // Drag end cleanup
  useEffect(() => {
    const handleDragEnd = () => setDragging(false);
    document.addEventListener("dragend", handleDragEnd);
    return () => document.removeEventListener("dragend", handleDragEnd);
  }, [setDragging]);

  const handleDragStart = async (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setDragging(true);

    // Set drag image if thumbnail is loaded
    if (thumbnailImgRef.current) {
      event.dataTransfer.setDragImage(
        thumbnailImgRef.current,
        thumbnailImgRef.current.width / 12,
        thumbnailImgRef.current.height / 12,
      );
    }

    // Set drag data
    const highResUrl = `${attachment.url}/fullscreen`;
    event.dataTransfer.setData("text/html", `<img src="${highResUrl}">`);
    event.dataTransfer.setData("text/uri-list", highResUrl);
    event.dataTransfer.setData("text/plain", highResUrl);

    try {
      const response = await fetch(highResUrl);
      const blob = await response.blob();
      const fileName =
        attachment?.originalFilename ?? attachment?.filename ?? "download.jpg";
      const file = new File([blob], fileName, { type: "image/jpeg" });

      event.dataTransfer.items.clear();
      event.dataTransfer.items.add(file);
    } catch (error) {
      console.error("Error fetching image for drag:", error);
    }
  };

  return (
    <div
      className="absolute right-0 top-0 z-50 h-10 w-10 opacity-0 transition-opacity group-hover:opacity-100"
      draggable="true"
      onDragStart={handleDragStart}
      style={{ cursor: "grab" }}
    >
      {/*Orange triangle for dragging*/}
      <div className="absolute right-0 top-0 h-0 w-0 border-l-[50px] border-t-[50px] border-[#2286e0] border-l-transparent"></div>
      <svg
        className="absolute right-1 top-1 text-gray-900"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M3.5 12.75v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5H4a.5.5 0 0 0-.5.5m-1.5 7a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2zM9.25 10.75V9.667h1.5v1.083zm0-2.167V6.417h1.5v2.166zm0-3.25V4.25c0-.409.11-.794.302-1.126l1.298.751a.74.74 0 0 0-.1.375v1.083zm1.124-3.031A2.24 2.24 0 0 1 11.5 2h.889v1.5H11.5a.74.74 0 0 0-.374.1zm3.71 12.198H13V13h1.083zM13.277 2h1.778v1.5h-1.778zm4.055 12.5h-2.166V13h2.166zM15.944 2h1.778v1.5h-1.778zM19.5 14.5h-1.083V13H19.5a.74.74 0 0 0 .375-.1l.75 1.298a2.24 2.24 0 0 1-1.125.302M18.611 2h.889c.409 0 .794.11 1.126.302L19.875 3.6a.74.74 0 0 0-.375-.1h-.889zm3.139 10.25c0 .409-.11.794-.302 1.126l-1.298-.751q.098-.166.1-.375v-.889h1.5zm-.302-9.126c.192.332.302.717.302 1.126v.889h-1.5V4.25a.74.74 0 0 0-.1-.375zm.302 5.57v1.778h-1.5V8.694zm0-2.666v1.778h-1.5V6.028z"></path>
        <path d="m17.947 10.046-1.49-.017-.018-1.667-3.793 3.792-1.05-1.05 3.793-3.792-1.668-.02-.017-1.489 4.195.048z"></path>
      </svg>
    </div>
  );
};

async function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
}

export default GalleryDragHandle;
