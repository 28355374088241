import { Button, IconButtonBase } from "@amenda-components/App";
import { Control, UseFormSetValue, useForm } from "react-hook-form";
import { FC, useState } from "react";
import {
  ListOrdered,
  LockIcon,
  TrashIcon,
  ViewIcon,
  XIcon,
} from "lucide-react";
import { devConsole, sanitizeData } from "@amenda-utils/data";
import {
  getComponentIdsToRemove,
  getConditionComponents,
  transformFormBuilderComponent,
  transformFormBuilderComponents,
} from "../common";
import {
  useAppStore,
  useDeleteFormComponents,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { Accordion } from "@amenda-components/Shared";
import { FormBuilderComponentForm } from "./FormBuilderComponentForm";
import { FormBuilderComponentOptions } from "./FormBuilderComponentOptions";
import FormBuilderComponentRoles from "./FormBuilderComponentRoles";
import { FormBuilderCostGroupCostComponentForm } from "./FormBuilderCostGroupCostComponentForm";
import { FormBuilderDisplayConditions } from "./FormBuilderDisplayConditions";
import { FormBuilderValidationForm } from "./FormBuilderValidationForm";
import { FormComponentTypes } from "@amenda-constants/forms";
import { PageComponentProps } from "@amenda-types/app";
import { SidebarContainer } from "@amenda-components/Shared";
import { getFormBuilderSchema } from "@amenda-constants/validations";
import { useFormBuilderModifySystemRole } from "../hooks";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const DefaultComponentForm: FC<Props> = ({ control, setValue }) => {
  const { t } = useTranslation();
  const [editDisplayConditions, setEditDisplayConditions] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const [editOptions, setEditOptions] = useState(false);
  const component = useAppStore(
    (state) => state.formBuilderState.selectedFormComponent,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState.selectedForm,
  );

  return (
    <>
      <FormBuilderDisplayConditions
        isOpen={editDisplayConditions}
        control={control}
        setValue={setValue}
        components={getConditionComponents(selectedForm?.components, component)}
        onClose={() => setEditDisplayConditions(false)}
      />
      <FormBuilderComponentRoles
        isOpen={editPermissions}
        component={component}
        onClose={() => setEditPermissions(false)}
      />
      <FormBuilderComponentOptions
        isOpen={editOptions}
        control={control}
        component={component}
        onClose={() => setEditOptions(false)}
      />
      <div className="px-2 pt-4">
        <FormBuilderComponentForm control={control} />
        {component?.component && (
          <Accordion
            className="border-t"
            panelClassName="pt-3 pb-2"
            title={t("Validation")}
          >
            <FormBuilderValidationForm
              control={control}
              component={component}
            />
          </Accordion>
        )}
        {Boolean(component?.component) && !Boolean(component?.fromSidebar) && (
          <div className="space-y-1 pb-2 pt-4" title={t("Settings")}>
            <Button
              className="w-full"
              variant="card"
              onClick={() => setEditDisplayConditions(true)}
            >
              <div className="flex space-x-1">
                <ViewIcon className="h-5 w-5" />
                <span>{t("Display conditions")}</span>
              </div>
              <span className="text-xs">
                {t(
                  "Choose when to show or hide this component based on form values",
                )}
              </span>
            </Button>
            <Button
              className="w-full"
              variant="card"
              onClick={() => setEditPermissions(true)}
            >
              <div className="flex space-x-1">
                <LockIcon className="h-5 w-5" />
                <span>{t("Permissions")}</span>
              </div>
              <span className="text-xs">
                {t(
                  "Configure view and edit permissions for this component in each role",
                )}
              </span>
            </Button>
            {[
              FormComponentTypes.Keyword,
              FormComponentTypes.Select,
              FormComponentTypes.Badges,
              FormComponentTypes.MultiSelect,
              FormComponentTypes.LabelledInput,
              FormComponentTypes.RadioButton,
              FormComponentTypes.Checkbox,
              FormComponentTypes.SearchAndSelect,
              FormComponentTypes.LabelledContactInputs,
            ].includes(component?.component) && (
              <Button
                className="w-full"
                variant="card"
                onClick={() => setEditOptions(true)}
              >
                <div className="flex space-x-1">
                  <ListOrdered className="h-5 w-5" />
                  <span>{t("Options")}</span>
                </div>
                <span className="text-xs">
                  {t("Configure options for this component")}
                </span>
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const FormBuilderRightSidebar: FC = () => {
  const { t } = useTranslation();
  const isEditingComponent = useAppStore(
    (state) => state.formBuilderState.isEditingComponent,
  );
  const removeFormBuilderComponent = useAppStore(
    (state) => state.removeFormBuilderComponent,
  );
  const component = useAppStore(
    (state) => state.formBuilderState.selectedFormComponent,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState.selectedForm,
  );
  const originalForms = useProjectStore((state) => state.originalForms);
  const { loading: updatePermissionLoader, addComponentToPermissions } =
    useFormBuilderModifySystemRole();
  const closeFormBuilderComponent = useAppStore(
    (state) => state.closeFormBuilderComponent,
  );
  const { deleteFormComponents, loading: isDeletingComponents } =
    useDeleteFormComponents();
  const isCostGroupCostComponent = useAppStore(
    (state) => state.formBuilderState.isCostGroupCostComponent,
  );

  const defaultValues = transformFormBuilderComponents(
    component as PageComponentProps,
  );
  const { control, handleSubmit, setValue } = useForm<any>({
    values: defaultValues,
    resolver: yupResolver(
      getFormBuilderSchema(component as PageComponentProps),
    ),
  });
  const { updateForm, loading } = useUpdateForm();

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:project form error gat", errors);
  };

  const createComponent = async (form: any, component: any) => {
    await updateForm({
      id: form.id,
      components: [component],
    });
    await addComponentToPermissions(form, component);
    closeFormBuilderComponent();
  };

  const updateComponent = async (form: any, component: any) => {
    await updateForm({
      id: form.id,
      components: [component],
    });
    closeFormBuilderComponent();
  };

  const handleDeleteComponent = async () => {
    if (component?.id && selectedForm?.id) {
      const componentIds = getComponentIdsToRemove(component);

      await deleteFormComponents({
        componentIds,
        formId: selectedForm.id,
      });
      closeFormBuilderComponent();
    }
  };

  const onSubmit = async ({ ...data }: any) => {
    const form =
      selectedForm?.id &&
      originalForms.find((form) => form.id === selectedForm.id);
    const sanitizedData = sanitizeData(data);
    const existingComponent =
      form && form.components.find((c) => c.componentId === component?.id);
    const formComponent = transformFormBuilderComponent({
      values: sanitizedData,
      isNewComponent: Boolean(component?.fromSidebar),
      component: Boolean(component?.fromSidebar)
        ? {
            layout: component?.layout,
            order: component?.order,
            parentId: component?.parentId,
            component: component?.component,
          }
        : existingComponent,
    });

    if (!form) return;
    if (component?.fromSidebar) {
      await createComponent(form, formComponent);
    } else {
      await updateComponent(form, formComponent);
    }
  };

  if (!isEditingComponent) return null;
  return (
    <SidebarContainer className="w-72 border-l bg-gray-100 p-1">
      <form
        key={component?.id} // use id to keep the form updated when selecting another component
        className="h-full overflow-y-auto overscroll-y-contain bg-white pb-4"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="sticky top-0 z-20 flex items-center justify-end border-b border-gray-200 bg-white">
          <IconButtonBase
            className="bg-gray-800 p-1 text-white"
            label="Close Modal"
            onClick={() => removeFormBuilderComponent()}
          >
            <XIcon className="h-5 w-5" />
          </IconButtonBase>
        </div>
        {isCostGroupCostComponent ? (
          <FormBuilderCostGroupCostComponentForm control={control} />
        ) : (
          <DefaultComponentForm control={control} setValue={setValue} />
        )}
        <div className="space-y-1 px-2 pt-6">
          <Button
            className="w-full justify-center"
            type="submit"
            variant="primary"
            loading={loading || updatePermissionLoader}
          >
            {t("Save Changes")}
          </Button>
          {!Boolean(component?.isAppSpecific) &&
            !Boolean(component?.fromSidebar) && (
              <Button
                loading={isDeletingComponents}
                className="w-full items-center justify-center space-x-1 bg-white text-red-600"
                onClick={handleDeleteComponent}
              >
                <span>{t("Delete")}</span>
                <TrashIcon className="h-4 w-4" />
              </Button>
            )}
        </div>
      </form>
    </SidebarContainer>
  );
};
