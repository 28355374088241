import {
  AllowedContactType,
  AvailableNotificationTypes,
  ReactTableKeys,
} from "@amenda-types";
import {
  CONTACT_FRAGMENT,
  CONTACT_SUBSCRIPTION_FRAGMENT,
  USER_FRAGMENT,
  USER_SUBSCRIPTION_FRAGMENT,
} from "@amenda-domains/fragments/users";
import {
  generateColumnSorting,
  getSearchAggregation,
  handleGroupedUsers,
  handleTableGroupedUsers,
  sanitizeData,
} from "@amenda-utils";
import { gql, useClient, useSubscription } from "urql";
import { useCallback, useState } from "react";

import { SearchCollections } from "@amenda-constants";
import { flattenUserDetails } from "@amenda-components/Contacts/common";
import { isEmpty } from "lodash";
import { useAppStore } from "@amenda-domains/mutations/app";
import { useUsersStore } from "@amenda-domains/mutations/users";

const getSortByKey = (type?: AllowedContactType) => {
  return !type
    ? "fullName"
    : type === AllowedContactType.company
      ? "contactDetails.companyName"
      : "firstName"; //Todo: connect with user settings for default sort key
};

const getDefaultSortParams = ({
  type,
  prefix = "",
  isCaseInsensitive = true,
}: {
  type?: AllowedContactType;
  prefix?: string;
  isCaseInsensitive?: boolean;
}) => {
  let id = isCaseInsensitive ? "case_insensitive_" : "";
  id = id + prefix + getSortByKey(type);
  return { [id]: 1 };
};

const GET_TENANT_ID = gql`
  query GetTenantId($emailOrDomain: String!) {
    tenant: getTenantId(emailOrDomain: $emailOrDomain)
  }
`;

const GET_CURRENT_USER = gql`
  ${USER_FRAGMENT}
  query GetCurrentUser {
    getCurrentUser {
      ...UserFragment
    }
  }
`;

export const useGetTenantId = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const showNotification = useAppStore((state) => state.showNotification);

  const getTenantId = useCallback(
    async (emailOrDomain: string) => {
      setLoading(true);

      const response = await client
        .query(GET_TENANT_ID, {
          emailOrDomain,
        })
        .toPromise()
        .catch((error) => {
          showNotification(AvailableNotificationTypes.Error, error.message);
        });
      setLoading(false);

      return response?.data?.tenant;
    },
    [client, showNotification],
  );

  return {
    loading,
    getTenantId,
  };
};

export const useGetCurrentUser = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setDefaultLanguage = useAppStore((state) => state.setDefaultLanguage);
  const setCurrentUser = useUsersStore((state) => state.setCurrentUser);

  const getCurrentUser = useCallback(async () => {
    setLoading(true);
    const response = await client.query(GET_CURRENT_USER, {}).toPromise();
    const user = flattenUserDetails(response?.data?.getCurrentUser);

    if (user) {
      setCurrentUser(user);
      if (user?.language) {
        setDefaultLanguage(user.language);
      }
    }
    setLoading(false);
  }, [client, setCurrentUser, setDefaultLanguage]);

  return {
    getCurrentUser,
    getCurrentUserLoader: loading,
  };
};

export const GET_ALL_USERS = gql`
  ${USER_FRAGMENT}
  query GetAllUsers(
    $isActive: Boolean
    $isDeleted: Boolean
    $limit: Int
    $next: String
    $previous: String
    $userDetails: Object
    $sort: Object
    $ids: [ID!]
    $selectUserFields: Object
  ) {
    getAllUsers(
      isActive: $isActive
      isDeleted: $isDeleted
      limit: $limit
      next: $next
      previous: $previous
      sort: $sort
      userDetails: $userDetails
      ids: $ids
      selectUserFields: $selectUserFields
    ) {
      next
      previous
      hasNext
      hasPrevious
      docsCount
      filteredDocsCount
      users {
        ...UserFragment
      }
    }
  }
`;

interface GetAllContactsArgs {
  ids?: string[];
  autoSelect?: boolean;
  isDeleted?: boolean;
  type?: AllowedContactType;
  isActive?: boolean;
  userDetails?: Record<string, any>;
  contactDetails?: Record<string, any>;
  limit?: number;
  next?: string;
  previous?: string;
  context?: Record<string, any>;
  skipStorage?: boolean;
  isTableView?: boolean;
  columnSorting?: any;
  callback?: (contacts?: any[]) => void;
  paginationCallback?: (pagination?: any) => void;
}

export const useGetAllUsers = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setUsers = useUsersStore((state) => state.setUsers);
  const setContactsLoader = useUsersStore((state) => state.setContactsLoader);

  const getAllUsers = useCallback(
    async ({
      autoSelect,
      type,
      callback,
      paginationCallback,
      isTableView = false,
      skipStorage = false,
      context = {},
      columnSorting = {},
      ...rest
    }: GetAllContactsArgs) => {
      setLoading(true);
      setContactsLoader(true, rest);

      const sortProps = isTableView
        ? generateColumnSorting({
            ...columnSorting,
            defaultSorting: {
              sort: getDefaultSortParams({ type: AllowedContactType.office }),
            },
          })
        : {
            sort: getDefaultSortParams({ type: AllowedContactType.office }),
          };
      const args = sanitizeData({
        ...rest,
        ...sortProps,
        selectUserFields: {
          _id: 1,
          uid: 1,
          email: 1,
          fullName: 1,
          firstName: 1,
          lastName: 1,
          isActive: 1,
          isDeleted: 1,
          emailVerified: 1,
          photoURL: 1,
          isTenantAdmin: 1,
          ownerId: 1,
          share: 1,
          ...(isTableView
            ? {
                userDetails: 1,
              }
            : {
                userDetails: {
                  email: 1,
                  firstName: 1,
                  lastName: 1,
                  companyName: 1,
                },
              }),
        },
      });

      const { data } = await client
        .query(GET_ALL_USERS, args, context)
        .toPromise();
      if (data?.getAllUsers && !skipStorage) {
        callback
          ? callback(data?.getAllUsers?.users ?? [])
          : setUsers({
              type,
              autoSelect,
              sortBy: getSortByKey(AllowedContactType.office),
              ...data?.getAllUsers,
            });
        paginationCallback && paginationCallback(data?.getAllUsers);
      }

      setLoading(false);
      setContactsLoader(false, rest);
      return data?.getAllUsers?.users;
    },
    [client, setUsers, setContactsLoader],
  );

  return {
    loading,
    getAllUsers,
  };
};

export const GET_ALL_CONTACTS = gql`
  ${CONTACT_FRAGMENT}
  query GetAllContacts(
    $isDeleted: Boolean
    $limit: Int
    $next: String
    $previous: String
    $type: ContactType
    $contactDetails: Object
    $sort: Object
    $ids: [ID!]
    $selectContactFields: Object
  ) {
    getAllContacts(
      isDeleted: $isDeleted
      limit: $limit
      next: $next
      previous: $previous
      type: $type
      sort: $sort
      contactDetails: $contactDetails
      ids: $ids
      selectContactFields: $selectContactFields
    ) {
      next
      previous
      hasNext
      hasPrevious
      docsCount
      filteredDocsCount
      contacts {
        ...ContactFragment
      }
    }
  }
`;

export const useGetAllContacts = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setUsers = useUsersStore((state) => state.setUsers);
  const setContactsLoader = useUsersStore((state) => state.setContactsLoader);

  const getAllContacts = useCallback(
    async ({
      autoSelect,
      type,
      callback,
      paginationCallback,
      isTableView = false,
      skipStorage = false,
      context = {},
      columnSorting = {},
      ...rest
    }: GetAllContactsArgs) => {
      setLoading(true);
      setContactsLoader(true, rest);

      const sortProps = isTableView
        ? generateColumnSorting({
            ...columnSorting,
            defaultSorting: {
              sort: getDefaultSortParams({ type }),
            },
            getPrefix: (colId) => {
              return ["firstName", "lastName"].includes(colId)
                ? undefined
                : "contactDetails";
            },
          })
        : {
            sort: getDefaultSortParams({ type }),
          };
      const args = sanitizeData({
        ...rest,
        ...sortProps,
        type,
        selectContactFields: {
          _id: 1,
          email: 1,
          fullName: 1,
          firstName: 1,
          lastName: 1,
          isDeleted: 1,
          type: 1,
          photoURL: 1,
          share: 1,
          ...(isTableView
            ? { contactDetails: 1 }
            : {
                contactDetails: {
                  email: 1,
                  firstName: 1,
                  lastName: 1,
                  companyName: 1,
                },
              }),
        },
      });

      const { data } = await client
        .query(GET_ALL_CONTACTS, args, context)
        .toPromise();

      if (data?.getAllContacts && !skipStorage) {
        callback
          ? callback(data?.getAllContacts?.contacts ?? [])
          : setUsers({
              type,
              autoSelect,
              sortBy: getSortByKey(type),
              users: data?.getAllContacts?.contacts,
              ...data?.getAllContacts,
            });
        paginationCallback && paginationCallback(data?.getAllContacts);
      }

      setLoading(false);
      setContactsLoader(false, rest);
      return data?.getAllContacts?.contacts;
    },
    [client, setUsers, setContactsLoader],
  );

  return {
    loading,
    getAllContacts,
  };
};

const SEARCH_USERS_AND_CONTACTS = gql`
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    search(
      input: {
        limit: $limit
        filters: $filters
        searchTerm: $searchTerm
        collections: $collections
        aggregations: $aggregations
      }
    ) {
      users
      contacts
    }
  }
`;

const SEARCH_USERS = gql`
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    search(
      input: {
        limit: $limit
        filters: $filters
        searchTerm: $searchTerm
        collections: $collections
        aggregations: $aggregations
      }
    ) {
      users
    }
  }
`;

const SEARCH_CONTACTS = gql`
  query Search(
    $limit: Int
    $searchTerm: String
    $collections: [String!]
    $filters: Object
    $aggregations: Object
  ) {
    search(
      input: {
        limit: $limit
        filters: $filters
        searchTerm: $searchTerm
        collections: $collections
        aggregations: $aggregations
      }
    ) {
      contacts
    }
  }
`;

interface SearchUsersArgs {
  isActive?: boolean;
  type?: AllowedContactType;
  autoSelect?: boolean;
  searchTerm: string;
  match?: Record<string, any>;
  groupByComponentIds?: string[];
  isTableView?: boolean;
  componentFilters?: Record<string, any>;
  hasComponentFilters?: Record<string, any>;
  skipStorage?: boolean;
  columnSorting?: any;
  tableId?: ReactTableKeys;
  callback?: (users: any[]) => void;
}

export const useSearchUsers = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const updateUsers = useUsersStore((state) => state.updateUsers);
  const setIsSearchingContacts = useUsersStore(
    (state) => state.setIsSearchingContacts,
  );
  const setColumnSorting = useAppStore((state) => state.setColumnSorting);

  const searchUsers = useCallback(
    async ({
      isActive,
      autoSelect,
      searchTerm,
      columnSorting,
      groupByComponentIds,
      componentFilters,
      skipStorage = false,
      isTableView = false,
      hasComponentFilters,
      match: matchArgs = {},
      tableId = ReactTableKeys.ContactsFullScreenTable,
      callback,
    }: SearchUsersArgs) => {
      setLoading(true);
      if (!callback) {
        setIsSearchingContacts(true);
      }

      const defaultAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "firstName",
          },
        },
        {
          autocomplete: {
            query: searchTerm,
            path: "lastName",
          },
        },
      ];

      let match: any = { ...matchArgs, isDeleted: false };
      let autoComplete = defaultAutoComplete;
      let sortAggregations: any[] = [];
      const sorting = generateColumnSorting({
        ...columnSorting,
        useCaseInsensitiveSort: false,
        getPrefix: (colId) => {
          return ["firstName", "lastName"].includes(colId)
            ? undefined
            : "userDetails";
        },
      });

      if (isActive) {
        match = {
          ...match,
          isActive,
        };
      }
      if (!searchTerm) {
        sortAggregations = [
          {
            $sort: getDefaultSortParams({
              type: AllowedContactType.office,
              isCaseInsensitive: false,
            }),
          },
        ];
      }
      if (isTableView && sorting?.sort) {
        sortAggregations = [
          {
            $sort: sorting.sort,
          },
        ];
      }
      if (isTableView && !sorting?.sort) {
        setColumnSorting({
          tableId,
          columnSorting: {} as any,
        });
      }

      let searchAggregations: any[] = [];
      if (searchTerm) {
        searchAggregations = [
          {
            $search: {
              index: "autocomplete",
              compound: {
                should: [...autoComplete],
              },
            },
          },
          {
            $addFields: {
              score: {
                $meta: "searchScore",
              },
            },
          },
          {
            $setWindowFields: {
              output: {
                maxScore: {
                  $max: "$score",
                },
              },
            },
          },
          {
            $addFields: {
              normalizedScore: {
                $divide: ["$score", "$maxScore"],
              },
            },
          },
        ];
      }

      let allAggregations: any[] = [...searchAggregations];

      if (!isEmpty(groupByComponentIds)) {
        const groupIds = groupByComponentIds?.reduce<any>((acc, value) => {
          acc[value] = `$userDetails.${value}`;
          return acc;
        }, {});
        const groupIdsNotNull = groupByComponentIds?.reduce<any>(
          (acc, value) => {
            acc.push({ [`userDetails.${value}`]: { $exists: true } });
            return acc;
          },
          [],
        );

        if (!hasComponentFilters) {
          match = {
            ...match,
            $or: groupIdsNotNull,
          };
        }
        if (!searchTerm) {
          sortAggregations = [
            {
              $sort: {
                ...getDefaultSortParams({
                  prefix: "users.",
                  isCaseInsensitive: false,
                  type: AllowedContactType.office,
                }),
              },
            },
          ];
        }
        if (isTableView && columnSorting?.direction) {
          sortAggregations = [
            {
              $sort: generateColumnSorting({
                ...columnSorting,
                useCaseInsensitiveSort: false,
                getPrefix: () => "users.",
              }).sort,
            },
          ];
        }

        const groupAggregations = [
          {
            $match: match,
          },
          {
            $group: {
              _id: groupIds,
              users: { $push: "$$ROOT" },
              count: { $count: {} },
            },
          },
          {
            $match: {
              count: { $gte: 1 },
            },
          },
          {
            $unwind: "$users",
          },
        ];

        allAggregations = [...allAggregations, ...groupAggregations];
      } else {
        allAggregations = [
          ...allAggregations,
          {
            $match: match,
          },
        ];
      }

      allAggregations = [...allAggregations, ...sortAggregations];

      const response = await client
        .query(
          SEARCH_USERS,
          {
            collections: [SearchCollections.Users],
            aggregations: {
              [SearchCollections.Users]: allAggregations,
            },
          },
          {
            customKey: "searchUsers",
            requestPolicy: "network-only",
          },
        )
        .toPromise();

      const { data } = response;
      const isGrouped = !isEmpty(groupByComponentIds) && !isTableView;
      const isGroupedTableView = !isEmpty(groupByComponentIds) && isTableView;
      let users: any[] = data?.search?.users || [];

      if (isGrouped) {
        users = handleGroupedUsers(users, componentFilters);
      } else if (isGroupedTableView) {
        users = handleTableGroupedUsers(users, componentFilters);
      } else {
        users = users.map((u) => ({
          ...u,
          id: u._id,
        }));
      }

      if (!skipStorage) {
        callback
          ? callback(users)
          : updateUsers({
              autoSelect,
              users,
              isGrouped,
            });
      }
      setLoading(false);
      setIsSearchingContacts(false);
      return users;
    },
    [client, updateUsers, setColumnSorting, setIsSearchingContacts],
  );

  return {
    loading,
    searchUsers,
  };
};

export const useSearchContacts = () => {
  const client = useClient();
  const updateUsers = useUsersStore((state) => state.updateUsers);
  const [loading, setLoading] = useState(false);
  const setIsSearchingContacts = useUsersStore(
    (state) => state.setIsSearchingContacts,
  );
  const setColumnSorting = useAppStore((state) => state.setColumnSorting);

  const searchContacts = useCallback(
    async ({
      autoSelect,
      searchTerm,
      groupByComponentIds,
      type,
      columnSorting,
      hasComponentFilters,
      componentFilters,
      skipStorage = false,
      isTableView = false,
      match: matchArgs = {},
      tableId = ReactTableKeys.ContactsFullScreenTable,
      callback,
    }: SearchUsersArgs) => {
      setLoading(true);
      if (!callback) {
        setIsSearchingContacts(true);
      }

      const companyAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "contactDetails.companyName",
          },
        },
      ];
      const defaultAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "firstName",
          },
        },
        {
          autocomplete: {
            query: searchTerm,
            path: "lastName",
          },
        },
      ];

      let match: any = { ...matchArgs, isDeleted: false };
      let autoComplete = defaultAutoComplete;
      let sortAggregations: any[] = [];
      const sorting = generateColumnSorting({
        ...columnSorting,
        useCaseInsensitiveSort: false,
        getPrefix: (colId) => {
          return ["firstName", "lastName"].includes(colId)
            ? undefined
            : "contactDetails";
        },
      });

      if (!searchTerm) {
        sortAggregations = [
          {
            $sort: getDefaultSortParams({ type, isCaseInsensitive: false }),
          },
        ];
      }
      if (isTableView && sorting?.sort) {
        sortAggregations = [
          {
            $sort: sorting.sort,
          },
        ];
      }
      if (isTableView && !sorting?.sort) {
        setColumnSorting({
          tableId,
          columnSorting: {} as any,
        });
      }
      if (type) {
        match = {
          ...match,
          type,
        };

        if (type === AllowedContactType.company) {
          autoComplete = companyAutoComplete;
        }
      } else {
        autoComplete = defaultAutoComplete.concat(companyAutoComplete);
      }

      let searchAggregations: any[] = [];
      if (searchTerm) {
        searchAggregations = [
          {
            $search: {
              index: "autocomplete",
              compound: {
                should: [...autoComplete],
              },
            },
          },
          {
            $addFields: {
              score: {
                $meta: "searchScore",
              },
            },
          },
          {
            $setWindowFields: {
              output: {
                maxScore: {
                  $max: "$score",
                },
              },
            },
          },
          {
            $addFields: {
              normalizedScore: {
                $divide: ["$score", "$maxScore"],
              },
            },
          },
        ];
      }

      let allAggregations: any[] = [...searchAggregations];

      if (!isEmpty(groupByComponentIds)) {
        const groupIds = groupByComponentIds?.reduce<any>((acc, value) => {
          acc[value] = `$contactDetails.${value}`;
          return acc;
        }, {});
        const groupIdsNotNull = groupByComponentIds?.reduce<any>(
          (acc, value) => {
            acc.push({ [`contactDetails.${value}`]: { $exists: true } });
            return acc;
          },
          [],
        );

        if (!hasComponentFilters) {
          match = {
            ...match,
            $or: groupIdsNotNull,
          };
        }
        if (!searchTerm) {
          sortAggregations = [
            {
              $sort: {
                ...getDefaultSortParams({
                  type,
                  prefix: "contacts.",
                  isCaseInsensitive: false,
                }),
              },
            },
          ];
        }
        if (isTableView && columnSorting?.direction) {
          sortAggregations = [
            {
              $sort: generateColumnSorting({
                ...columnSorting,
                useCaseInsensitiveSort: false,
                getPrefix: () => "contacts.",
              }).sort,
            },
          ];
        }

        const groupAggregations = [
          {
            $match: match,
          },
          {
            $group: {
              _id: groupIds,
              contacts: { $push: "$$ROOT" },
              count: { $count: {} },
            },
          },
          {
            $match: {
              count: { $gte: 1 },
            },
          },
          {
            $unwind: "$contacts",
          },
        ];

        allAggregations = [...allAggregations, ...groupAggregations];
      } else {
        allAggregations = [
          ...allAggregations,
          {
            $match: match,
          },
        ];
      }

      allAggregations = [...allAggregations, ...sortAggregations];

      const response = await client
        .query(
          SEARCH_CONTACTS,
          {
            collections: [SearchCollections.Contacts],
            aggregations: {
              [SearchCollections.Contacts]: allAggregations,
            },
          },
          {
            customKey: "searchUsers",
            requestPolicy: "network-only",
          },
        )
        .toPromise();

      const { data } = response;
      const isGrouped = !isEmpty(groupByComponentIds) && !isTableView;
      const isGroupedTableView = !isEmpty(groupByComponentIds) && isTableView;
      let contacts: any[] = data?.search?.contacts || [];

      if (isGrouped) {
        contacts = handleGroupedUsers(contacts, componentFilters);
      } else if (isGroupedTableView) {
        contacts = handleTableGroupedUsers(contacts, componentFilters);
      } else {
        contacts = contacts.map((u) => ({
          ...u,
          id: u._id,
        }));
      }

      if (!skipStorage) {
        callback
          ? callback(contacts)
          : updateUsers({
              autoSelect,
              isGrouped,
              users: contacts,
            });
      }
      setLoading(false);
      setIsSearchingContacts(false);
      return contacts;
    },
    [client, updateUsers, setColumnSorting, setIsSearchingContacts],
  );

  return {
    loading,
    searchContacts,
  };
};

export const useSearchUsersAndContacts = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setIsSearchingContacts = useUsersStore(
    (state) => state.setIsSearchingContacts,
  );

  const searchUsersAndContacts = useCallback(
    async ({
      searchTerm,
      match: matchArgs = {},
      callback,
    }: SearchUsersArgs) => {
      setLoading(true);
      if (!callback) {
        setIsSearchingContacts(true);
      }

      const companyAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "contactDetails.companyName",
          },
        },
      ];
      const officeAndPersonsAutoComplete = [
        {
          autocomplete: {
            query: searchTerm,
            path: "firstName",
          },
        },
        {
          autocomplete: {
            query: searchTerm,
            path: "lastName",
          },
        },
      ];
      const officeMatch = {
        $match: {
          ...matchArgs,
          isDeleted: false,
          isActive: { $exists: true },
        },
      };
      const contactsMatch = {
        $match: {
          ...matchArgs,
          isDeleted: false,
        },
      };

      const response = await client
        .query(
          SEARCH_USERS_AND_CONTACTS,
          {
            collections: [SearchCollections.Contacts, SearchCollections.Users],
            aggregations: {
              [SearchCollections.Contacts]: getSearchAggregation(
                [...officeAndPersonsAutoComplete, ...companyAutoComplete],
                contactsMatch,
              ),
              [SearchCollections.Users]: getSearchAggregation(
                officeAndPersonsAutoComplete,
                officeMatch,
              ),
            },
          },
          {
            customKey: "searchUsers",
            requestPolicy: "network-only",
          },
        )
        .toPromise();

      const { data } = response;
      let contacts: any[] = [...(data?.search?.contacts || [])].concat(
        data?.search?.users || [],
      );

      callback && callback(contacts);
      setLoading(false);
      setIsSearchingContacts(false);
      return contacts;
    },
    [client, setIsSearchingContacts],
  );

  return {
    loading,
    searchUsersAndContacts,
  };
};

const GET_USERS_BY_IDS = gql`
  ${USER_FRAGMENT}
  query GetUsersByIds($selectUserFields: Object, $userIds: [String!]!) {
    getUsersByIds(selectUserFields: $selectUserFields, userIds: $userIds) {
      ...UserFragment
    }
  }
`;

export const useGetUsersById = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getUsersById = useCallback(
    async (userIds: string[], callback?: (users: any) => void) => {
      setLoading(true);

      const response = await client
        .query(GET_USERS_BY_IDS, {
          userIds,
          selectUserFields: {
            _id: 1,
            email: 1,
            firstName: 1,
            lastName: 1,
            isActive: 1,
            emailVerified: 1,
            photoURL: 1,
            userDetails: {
              address: 1,
              companyName: 1,
            },
          },
        })
        .toPromise();

      const { data } = response;

      if (data?.getUsersByIds && callback) {
        callback(data.getUsersByIds);
      }
      setLoading(false);

      return data?.getUsersByIds;
    },
    [client],
  );

  return {
    loading,
    getUsersById,
  };
};

const GET_CONTACTS_BY_IDS = gql`
  ${CONTACT_FRAGMENT}
  query GetContactsByIds(
    $contactIds: [String!]!
    $selectContactFields: Object
  ) {
    getContactsByIds(
      contactIds: $contactIds
      selectContactFields: $selectContactFields
    ) {
      ...ContactFragment
    }
  }
`;

export const useGetContactsById = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);

  const getContactsById = useCallback(
    async (contactIds: string[], callback?: (users: any) => void) => {
      setLoading(true);

      const response = await client
        .query(GET_CONTACTS_BY_IDS, {
          contactIds,
          selectContactFields: {
            _id: 1,
            email: 1,
            firstName: 1,
            fullName: 1,
            lastName: 1,
            type: 1,
            photoURL: 1,
            contactDetails: {
              address: 1,
              companyName: 1,
            },
          },
        })
        .toPromise();

      const { data } = response;

      if (data?.getContactsByIds && callback) {
        callback(data.getContactsByIds);
      }
      setLoading(false);

      return data?.getContactsByIds;
    },
    [client],
  );

  return {
    loading,
    getContactsById,
  };
};

const USER_CREATED_SUBSCRIPTION = gql`
  ${USER_SUBSCRIPTION_FRAGMENT}
  subscription UserCreated($ownerId: String!, $tenantId: String!) {
    userCreated(ownerId: $ownerId, tenantId: $tenantId) {
      ...UserSubsFragment
    }
  }
`;

const USER_UPDATED_SUBSCRIPTION = gql`
  ${USER_SUBSCRIPTION_FRAGMENT}
  subscription UserUpdated($ownerId: String!, $tenantId: String!) {
    userUpdated(ownerId: $ownerId, tenantId: $tenantId) {
      ...UserSubsFragment
    }
  }
`;

const USER_DELETED_SUBSCRIPTION = gql`
  subscription UserDeleted($ownerId: String!, $tenantId: String!) {
    userDeleted(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
      uid
      isDeleted
    }
  }
`;

const CONTACT_CREATED_SUBSCRIPTION = gql`
  ${CONTACT_SUBSCRIPTION_FRAGMENT}
  subscription ContactCreated($ownerId: String!, $tenantId: String!) {
    contactCreated(ownerId: $ownerId, tenantId: $tenantId) {
      ...ContactSubsFragment
    }
  }
`;

const CONTACT_UPDATED_SUBSCRIPTION = gql`
  ${CONTACT_SUBSCRIPTION_FRAGMENT}
  subscription ContactUpdated($ownerId: String!, $tenantId: String!) {
    contactUpdated(ownerId: $ownerId, tenantId: $tenantId) {
      ...ContactSubsFragment
    }
  }
`;

const CONTACT_DELETED_SUBSCRIPTION = gql`
  subscription ContactDeleted($ownerId: String!, $tenantId: String!) {
    contactDeleted(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
      isDeleted
      type
    }
  }
`;

export const useUserCreatedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  const setRefreshCount = useUsersStore((state) => state.setRefreshCount);

  useSubscription(
    {
      query: USER_CREATED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {
      setRefreshCount(AllowedContactType.office);
    },
  );
};

export const useUserUpdatedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  useSubscription(
    {
      query: USER_UPDATED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {},
  );
};

export const useUserDeletedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  const setRefreshCount = useUsersStore((state) => state.setRefreshCount);

  useSubscription(
    {
      query: USER_DELETED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {
      setRefreshCount(AllowedContactType.office);
    },
  );
};

export const useContactCreatedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  const setRefreshCount = useUsersStore((state) => state.setRefreshCount);

  useSubscription(
    {
      query: CONTACT_CREATED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {
      setRefreshCount();
    },
  );
};

export const useContactUpdatedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  useSubscription(
    {
      query: CONTACT_UPDATED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {},
  );
};

export const useContactDeletedSubscription = (
  ownerId: string,
  tenantId: string,
) => {
  const setRefreshCount = useUsersStore((state) => state.setRefreshCount);

  useSubscription(
    {
      query: CONTACT_DELETED_SUBSCRIPTION,
      variables: {
        ownerId,
        tenantId,
      },
    },
    () => {
      setRefreshCount();
    },
  );
};

const GET_USER = gql`
  ${USER_FRAGMENT}
  query GetUser($id: ID, $isActive: Boolean) {
    getUser(_id: $id, isActive: $isActive) {
      ...UserFragment
    }
  }
`;

interface GetUserProps {
  id: string;
  context?: Record<string, any>;
  skipLoading?: boolean;
  callback?: (user: any) => void;
}

export const useGetUser = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedUser = useUsersStore((state) => state.setSelectedUser);
  const setContactsLoader = useUsersStore((state) => state.setContactsLoader);

  const getUser = useCallback(
    async ({
      callback,
      skipLoading = false,
      context = {},
      ...variables
    }: GetUserProps) => {
      setLoading(true);
      if (!skipLoading) {
        setContactsLoader(true, { singleContact: true });
      }

      const response = await client
        .query(GET_USER, variables, context)
        .toPromise();

      const { data } = response;

      if (data?.getUser) {
        const cb = callback ? callback : setSelectedUser;
        cb(data.getUser);
      }
      setLoading(false);
      if (!skipLoading) {
        setContactsLoader(false, { singleContact: true });
      }
      return data?.getUser;
    },
    [client, setSelectedUser, setContactsLoader],
  );

  return {
    loading,
    getUser,
  };
};

const GET_CONTACT = gql`
  ${CONTACT_FRAGMENT}
  query GetContact($id: ID, $type: ContactType) {
    getContact(_id: $id, type: $type) {
      ...ContactFragment
    }
  }
`;

interface GetContactProps {
  id: string;
  context?: Record<string, any>;
  skipLoading?: boolean;
  callback?: (user: any) => void;
}

export const useGetContact = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setContactsLoader = useUsersStore((state) => state.setContactsLoader);
  const setSelectedUser = useUsersStore((state) => state.setSelectedUser);

  const getContact = useCallback(
    async ({
      callback,
      skipLoading = false,
      context = {},
      ...variables
    }: GetContactProps) => {
      setLoading(true);
      if (!skipLoading) {
        setContactsLoader(true, { singleContact: true });
      }

      const response = await client
        .query(GET_CONTACT, variables, context)
        .toPromise();

      const { data } = response;

      if (data?.getContact) {
        const cb = callback ? callback : setSelectedUser;
        cb(data.getContact);
      }
      setLoading(false);
      if (!skipLoading) {
        setContactsLoader(false, { singleContact: true });
      }
      return data?.getContact;
    },
    [client, setSelectedUser, setContactsLoader],
  );

  return {
    loading,
    getContact,
  };
};
