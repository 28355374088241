import { FC, useState } from "react";
import { GripIcon, PencilIcon } from "lucide-react";

import { FormBuilderComponentSort } from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { FormBuilderFormViewSectionHeader } from "@amenda-components/Settings/FormBuilder/FormBuilderFormViewSectionHeader";
import { GridWrapper } from "./GridWrapper";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import clsx from "clsx";
import { isChildrenEmpty } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";

export const TwoThirdsColumnContainerLayout: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  isPrintView,
  config,
  globalProps,
}) => {
  const updateFormBuilderSelectedComponent = useAppStore(
    (state) => state.updateFormBuilderSelectedComponent,
  );
  const isEditingComponent = useAppStore(
    (state) => state.formBuilderState.isEditingComponent,
  );
  const isEditingForm = useAppStore(
    (state) => state.formBuilderState.isEditingForm,
  );
  const [isEdit, setIsEdit] = useState(false);

  const { label, description } = config?.properties ?? {};

  const handleEdit = () => {
    if (Boolean(globalProps?.isFormView)) {
      setIsEdit(!isEdit);
    } else {
      updateFormBuilderSelectedComponent(config);
    }
  };

  return (
    <FormBuilderComponentSort
      className={clsx("group/two-col-full-width relative", {
        "py-5 md:grid md:grid-cols-3 md:gap-6": !isPrintView && !isFormBuilder,
        "border-b border-gray-300 pb-4": isPrintView,
      })}
      config={config}
      isFormBuilder={isFormBuilder}
      showBorder={isChildrenEmpty(children)}
    >
      <div className="md:col-span-1">
        {isFormBuilder && !isEditingComponent && isEditingForm && (
          <div
            className={clsx("flex items-center space-x-2 pb-2", {
              "invisible group-hover/two-col-full-width:visible":
                !isChildrenEmpty(children),
            })}
          >
            <IconButtonBase
              size="sm"
              className="cursor-grab p-1"
              variant="clean"
            >
              <GripIcon className="h-4 w-4 stroke-[1.5]" />
            </IconButtonBase>
            {!isEdit && (
              <IconButtonBase
                size="sm"
                className="p-1"
                variant="clean"
                onClick={handleEdit}
              >
                <PencilIcon className="h-4 w-4" />
              </IconButtonBase>
            )}
          </div>
        )}
        {isFormBuilder && (
          <FormBuilderFormViewSectionHeader
            isEdit={isEdit}
            config={config}
            globalProps={globalProps}
            setIsEdit={setIsEdit}
          />
        )}
        {label && !isEdit && (
          <h3
            className={clsx({
              "amenda-form-heading": !isPrintView && !isFormBuilder,
              "text-md": isFormBuilder,
              "text-lg": isPrintView,
            })}
          >
            {label}
          </h3>
        )}
        {description && !isEdit && (
          <p className="mt-1 text-sm text-gray-600">{description}</p>
        )}
      </div>
      <div
        className={clsx({
          "mt-5 md:col-span-2 md:mt-0": !isPrintView && !isFormBuilder,
          "mt-5": isPrintView || isFormBuilder,
        })}
      >
        <GridWrapper
          isFormBuilder={isFormBuilder}
          config={config}
          className={clsx("gap-4", {
            "min-h-32": isFormBuilder && isChildrenEmpty(children),
          })}
        >
          {children}
        </GridWrapper>
      </div>
    </FormBuilderComponentSort>
  );
};
