import { AvailablePermissions, permissionHeaders } from "@amenda-types";
import { FC, useEffect, useState } from "react";
import { LoaderWrapper, Modal } from "@amenda-components/App";
import {
  useSettingsStore,
  useUpsertSystemRole,
} from "@amenda-domains/mutations/settings";

import { SingleRadioButton } from "@amenda-components/FormComponents";
import { runAllAsync } from "@amenda-utils/data";
import { useGetAllSystemRoles } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  component?: Record<string, any>;
  onClose: () => void;
}

const groupSystemRoles = (systemRoles: any[]) => {
  return systemRoles.reduce((acc, s) => {
    acc[s.id] = s.permissions;
    return acc;
  }, {});
};

const FormBuilderComponentRoles: FC<Props> = ({
  isOpen,
  component,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const { upsertSystemRole } = useUpsertSystemRole();
  const { loading, getAllSystemRoles } = useGetAllSystemRoles();
  const systemRoles = useSettingsStore((state) => state.systemRoles);
  const [permissionValues, setPermissionValues] = useState<
    Record<string, AvailablePermissions>
  >({});

  const roleByPermissions = groupSystemRoles(systemRoles);

  useEffect(() => {
    getAllSystemRoles({
      context: {
        requestPolicy: "cache-and-network",
      },
    });
  }, [getAllSystemRoles]);

  const handlePermission = (
    roleId: string,
    permission: AvailablePermissions,
  ) => {
    setPermissionValues((prev) => ({
      ...prev,
      [roleId]: permission,
    }));
  };

  const handleClose = () => {
    setPermissionValues({});
    onClose();
  };

  const handleUpdatePermissions = async () => {
    const keys = Object.keys(permissionValues).filter((k) => {
      const rolePermission =
        roleByPermissions?.[k]?.[component?.formId]?.[component?.id];

      return rolePermission !== permissionValues[k];
    });

    if (keys.length > 0) {
      setIsUpdating(true);
      await runAllAsync(keys, async (key) => {
        const permission = permissionValues[key];
        const permissions = roleByPermissions[key] ?? {};
        const formId = component?.formId ?? "";
        const componentId = component?.id ?? "";

        const updatedPermission = {
          ...permissions,
          [formId]: {
            ...(permissions[formId] ?? {}),
            [componentId]: permission,
          },
        };

        await upsertSystemRole({
          skipCallback: true,
          input: {
            _id: key,
            permissions: updatedPermission,
          },
        });
      });
      setIsUpdating(false);
    }
    handleClose();
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      loading={isUpdating}
      withSuccess={true}
      closeModalFromTitle={true}
      title={t("Permissions")}
      onClose={handleClose}
      onSuccess={handleUpdatePermissions}
    >
      {loading ? (
        <LoaderWrapper />
      ) : (
        <div className="divide-y-200 max-h-[80vh] w-full divide-y overflow-y-auto overscroll-y-contain border border-gray-200">
          <div className="sticky grid w-full grid-cols-4 items-center bg-gray-50">
            <div className="amenda-table-heading">
              <span>{t("Role")}</span>
            </div>
            {permissionHeaders.map((p) => (
              <div key={p.value} className="amenda-table-heading">
                <span>{t(p.label)}</span>
              </div>
            ))}
          </div>
          {systemRoles.map((s) => (
            <div
              className="grid w-full grid-cols-4 items-center bg-white px-2 py-2.5 text-sm"
              key={s.id}
            >
              <div className="px-1">
                <span>{s.name}</span>
              </div>
              {permissionHeaders.map((p) => {
                const permission =
                  s.permissions?.[component?.formId]?.[component?.id];
                const formPermission = permissionValues[s.id];
                const isChecked = p.value === (formPermission ?? permission);

                return (
                  <div key={`${s.id}.${p.value}`} className="pl-4 pr-1">
                    <SingleRadioButton
                      id={`${s.id}.${p.value}`}
                      checked={isChecked}
                      className="h-4 w-4 text-gray-900"
                      onChange={() => handlePermission(s.id, p.value)}
                    />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default FormBuilderComponentRoles;
