import { FC, useEffect } from "react";
import { IconButtonBase, Image, SlideOver, Tag } from "@amenda-components/App";
import { useAppStore, useProjectStore } from "@amenda-domains/mutations";

import { FormTab } from "@amenda-types";
import { ProjectFallbackThumbnail } from "./ProjectFallbackThumbnail";
import { ProjectFormViews } from "./ProjectFormViews";
import { ProjectSectionSidebarContent } from "./ProjectSectionSidebarContent";
import { SidebarContainer } from "@amenda-components/Shared";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import { useGetFormView } from "@amenda-domains/queries";

interface Props {
  resourceId?: string;
  availableForms: FormTab[];
  projectsGeneralPermissions: any;
  showFormViews?: boolean;
}

export const ProjectSectionSidebar: FC<Props> = ({
  showFormViews,
  ...props
}) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const setSidebarCollapsed = useAppStore((state) => state.setSidebarCollapsed);
  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const { getFormView } = useGetFormView();

  useEffect(() => {
    if (selectedFormView?.id) {
      getFormView({
        id: selectedFormView.id,
        context: {
          requestPolicy: "cache-and-network",
        },
      });
    }
  }, [selectedFormView?.id, getFormView]);

  return (
    <SidebarContainer className="relative mr-4 h-screen border-r border-gray-200">
      <div className="h-full max-h-screen w-[300px] overflow-y-auto overscroll-y-contain py-4">
        {props?.resourceId && (
          <div className="flex w-full space-x-1 px-4">
            <div
              className={clsx("relative", {
                group: sidebarCollapsed,
              })}
            >
              <div
                className={clsx("transition", {
                  "group-hover:opacity-10": sidebarCollapsed,
                })}
              >
                {selectedProject?.galleryUrl ? (
                  <Image
                    url={selectedProject?.galleryUrl}
                    magicSize="thumbnail"
                    background="bg-white"
                    variant="contain"
                    size="h-[60px] min-h-[60px] w-[60px] min-w-[60px]"
                  />
                ) : (
                  <ProjectFallbackThumbnail
                    project={selectedProject}
                    textClassName="text-[10px]"
                    className="h-[60px] min-h-[60px] w-[60px] min-w-[60px]"
                  />
                )}
              </div>
              <div
                className={clsx(
                  "bg-gray-10 absolute inset-0 hidden items-center justify-center transition",
                  {
                    "group-hover:flex": sidebarCollapsed,
                  },
                )}
              >
                {sidebarCollapsed && (
                  <div className="flex flex-row items-center pr-1 pt-3">
                    <img
                      alt=""
                      className="mb-4 h-4 w-4 cursor-pointer"
                      onClick={toggleSidebar}
                      src={
                        sidebarCollapsed
                          ? "/images/sidebarExpand.svg"
                          : "/images/sidebarCollapse.svg"
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex grow flex-col pl-3">
              {selectedProject?.name && (
                <div className="line-clamp-2 text-sm leading-tight">
                  {selectedProject?.name}
                </div>
              )}
              {selectedProject?.number && (
                <div className="mt-auto pt-[1px]">
                  <Tag children={selectedProject.number} />
                </div>
              )}
            </div>
          </div>
        )}
        <ProjectSectionSidebarContent {...props} />
      </div>
      {Boolean(showFormViews) && <ProjectFormViews />}
    </SidebarContainer>
  );
};

export const ProjectSectionSidebarMobile: FC<Props> = ({
  showFormViews,
  ...props
}) => {
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const openProjectFormSection = useProjectStore(
    (state) => state.openProjectFormSection,
  );
  const setOpenProjectFormSection = useProjectStore(
    (state) => state.setOpenProjectFormSection,
  );

  const handleClose = () => {
    setOpenProjectFormSection(false);
  };

  return (
    <SlideOver show={openProjectFormSection} onClose={handleClose}>
      <div className="relative h-screen w-full overflow-y-auto p-2">
        <div className="flex w-full space-x-1">
          {selectedProject?.galleryUrl ? (
            <Image
              url={selectedProject?.galleryUrl}
              magicSize="thumbnail"
              background="bg-white"
              variant="contain"
              size="h-[80px] min-h-[80px] w-[80px] min-w-[80px]"
            />
          ) : (
            <ProjectFallbackThumbnail
              project={selectedProject}
              textClassName="text-[10px]"
              className="h-[80px] min-h-[80px] w-[80px] min-w-[80px]"
            />
          )}
          <div className="flex grow flex-col pl-3">
            <IconButtonBase
              className="ml-auto"
              variant="clean"
              label="Close"
              onClick={handleClose}
            >
              <XIcon className="h-6 w-6" />
            </IconButtonBase>
            <div className="line-clamp-2 text-sm leading-tight">
              {selectedProject?.name}
            </div>
            <div className="pt-[1px]">
              <Tag children={selectedProject?.number} />
            </div>
          </div>
        </div>
        <ProjectSectionSidebarContent {...props} />
      </div>
      {Boolean(showFormViews) && <ProjectFormViews />}
    </SlideOver>
  );
};
