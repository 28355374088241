import { AllowedContactType, ReactTableKeys } from "@amenda-types";
import { ChangeEvent, FC, memo } from "react";
import {
  FullScreenModal,
  HelperMessage,
  IconButton,
} from "@amenda-components/App";
import { UsersIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  flattenUserDetails,
  getContactRequestArgs,
  getDynamicContactColumns,
  getStaticContactColumns,
  hasNoUserSearchAndGrouping,
} from "./common";
import {
  useAppStore,
  useProjectStore,
  useUserSearchFiltersWithPath,
  useUsersStore,
} from "@amenda-domains/mutations";
import { useNavigate, useParams } from "react-router-dom";

import { ContactDirectoryProps } from "./types";
import { GroupByHeader } from "@amenda-components/Shared/GroupByHeader";
import { ReactTable } from "@amenda-components/Shared";
import { getLinkedContactDataColumns } from "./ContactTableLinkedData";
import { isEmpty } from "lodash";
import { useRunOnMountOnly } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
    ContactDirectoryProps,
    | "rootRoute"
    | "collectionRoute"
    | "defaultContactType"
    | "searchContacts"
    | "getAllContacts"
  > {
  users: any[];
  isCollection: boolean;
  collectionResourceIds?: string[];
}

interface FullScreenTableProps
  extends Pick<
    ContactDirectoryProps,
    "rootRoute" | "collectionRoute" | "defaultContactType"
  > {
  users: any[];
  isCollection: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleUsers: () => void;
  handleNextPage: () => Promise<void>;
  handleFilter: (columnSorting: any) => void;
  handleDownloadData: () => Promise<any[]>;
}

const FullScreenTable: FC<FullScreenTableProps> = ({
  users,
  isLoading,
  rootRoute,
  isCollection,
  collectionRoute,
  defaultContactType,
  handleClose,
  handleUsers,
  handleNextPage,
  handleFilter,
  handleDownloadData,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType,
  );
  const pagination = useUsersStore((state) => state.pagination);
  const { groupingComponents, searchFilters } = useUserSearchFiltersWithPath();
  const { collectionId } = useParams<{ collectionId?: string }>();

  const forms = defaultContactType
    ? formsByContactType[defaultContactType]
    : [
        ...formsByContactType[AllowedContactType.person],
        ...formsByContactType[AllowedContactType.company],
      ];
  const dynamicTableColumns = getDynamicContactColumns(forms);
  const transformedUsers = users.map((user) => flattenUserDetails(user));

  const handleClick = (user: any) => (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    let path = isCollection ? `${collectionRoute}/${collectionId}` : rootRoute;
    path = `${path}/${user.id}`;
    handleClose();
    navigate(path);
  };

  useRunOnMountOnly(handleUsers);

  if (isEmpty(users) && !isLoading) {
    return (
      <HelperMessage
        Icon={UsersIcon}
        message="No contacts found"
        actionText="Close table view"
        hideActionIcon={true}
        onClick={handleClose}
      />
    );
  }
  return (
    <div className="h-full w-full p-4">
      <ReactTable
        isFullWidth
        isConfigurable
        isResizable={true}
        showDefaultColumn={true}
        showGroupingColumn={!isEmpty(searchFilters.groupByComponentIds)}
        label="Contacts"
        maxEstimatedRowHeight={35}
        containerClass="h-[calc(100vh-5rem)] w-full"
        isLoading={isLoading}
        data={transformedUsers}
        pagination={pagination}
        tableId={ReactTableKeys.ContactsFullScreenTable}
        defaultPinnedColumns={{
          left: ["firstName", "companyName"],
        }}
        onRowClick={handleClick}
        tableConfigChildren={
          <IconButton
            label="Close table view"
            Icon={XMarkIcon}
            onClick={handleClose}
          />
        }
        groupingColumnChildren={(row) => {
          const { original } = row;
          return (
            <div className="ml-2 flex items-center space-x-1">
              <GroupByHeader
                componentId={original.componentId}
                componentValue={original.componentValue}
                components={groupingComponents}
              />
              <span className="text-gray-600">({original.count})</span>
            </div>
          );
        }}
        columns={[
          ...dynamicTableColumns,
          ...getStaticContactColumns(
            getLinkedContactDataColumns(t, defaultContactType),
          ),
        ]}
        fetchNextPage={handleNextPage}
        handleFilter={handleFilter}
        handleDownloadData={handleDownloadData}
      />
    </div>
  );
};

export const ContactsFullScreenTable: FC<Props> = memo(
  ({
    users,
    rootRoute,
    collectionRoute,
    isCollection,
    defaultContactType,
    collectionResourceIds,
    searchContacts,
    getAllContacts,
  }) => {
    const openUsersTableView = useUsersStore(
      (state) => state.openUsersTableView,
    );
    const setOpenUsersTableView = useUsersStore(
      (state) => state.setOpenUsersTableView,
    );
    const pagination = useUsersStore((state) => state.pagination);
    const isFetchingContacts = useUsersStore(
      (state) => state.isFetchingContacts,
    );
    const isSearchingContacts = useUsersStore(
      (state) => state.isSearchingContacts,
    );
    const { searchFilters } = useUserSearchFiltersWithPath();
    const userActivationState = useUsersStore(
      (state) => state.userActivationState,
    );
    const columnSorting = useAppStore(
      (state) =>
        state.tableState[ReactTableKeys.ContactsFullScreenTable].columnSorting,
    );

    const isLoading = Boolean(isFetchingContacts ?? isSearchingContacts);

    const handleUsers = async (isTableView = true) => {
      let args = getContactRequestArgs({
        ...searchFilters,
        isCollection,
        defaultContactType,
        userActivationState,
        collectionResourceIds,
        autoSelect: false,
      });
      args = {
        ...args,
        isTableView,
        columnSorting,
      };

      if (!hasNoUserSearchAndGrouping(searchFilters)) {
        await searchContacts(args);
      } else {
        await getAllContacts(args);
      }
    };

    const handleFilter = async (columnSorting: any) => {
      let args = getContactRequestArgs({
        ...searchFilters,
        isCollection,
        defaultContactType,
        userActivationState,
        collectionResourceIds,
        autoSelect: false,
      });
      args = {
        ...args,
        columnSorting,
        isTableView: true,
      };

      if (!hasNoUserSearchAndGrouping(searchFilters)) {
        await searchContacts(args);
      } else {
        await getAllContacts(args);
      }
    };

    const handleNextPage = async () => {
      if (pagination?.hasNext && hasNoUserSearchAndGrouping(searchFilters)) {
        const args = getContactRequestArgs({
          isCollection,
          defaultContactType,
          userActivationState,
          collectionResourceIds,
          autoSelect: false,
          contactType: searchFilters?.contactType,
        });

        await getAllContacts({
          ...args,
          columnSorting,
          isTableView: true,
          next: pagination?.next,
        });
      }
    };

    const handleDownloadData = async () => {
      const { limit, ...args } = getContactRequestArgs({
        isCollection,
        defaultContactType,
        userActivationState,
        collectionResourceIds,
        autoSelect: false,
        contactType: searchFilters?.contactType,
      });

      const contacts = await getAllContacts({
        ...args,
        ...(pagination?.docsCount
          ? {
              limit: pagination.docsCount,
            }
          : {}),
        columnSorting,
        isTableView: true,

        callback: () => {},
      });

      return contacts.map((c) => flattenUserDetails(c));
    };

    const handleClose = async () => {
      setOpenUsersTableView(false);
      await handleUsers(false);
    };

    return (
      <FullScreenModal isOpen={openUsersTableView} onClose={handleClose}>
        {openUsersTableView && (
          <FullScreenTable
            users={users}
            isLoading={isLoading}
            rootRoute={rootRoute}
            isCollection={isCollection}
            collectionRoute={collectionRoute}
            defaultContactType={
              searchFilters?.contactType ?? defaultContactType
            }
            handleClose={handleClose}
            handleUsers={handleUsers}
            handleFilter={handleFilter}
            handleNextPage={handleNextPage}
            handleDownloadData={handleDownloadData}
          />
        )}
      </FullScreenModal>
    );
  },
);
