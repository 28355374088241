import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useInviteUsers, useUsersStore } from "@amenda-domains/mutations";

import { AddUserSchema } from "@amenda-constants/validations";
import { FC } from "react";
import { InvitationType } from "@amenda-constants";
import { Modal } from "@amenda-components/App";
import { TextField } from "@amenda-components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";

interface AddOfficeUserModalInput {
  email: string;
}

export const AddOfficeUserModal: FC = () => {
  const { handleSubmit, control, reset } = useForm<AddOfficeUserModalInput>({
    resolver: yupResolver(AddUserSchema),
  });
  const modals = useUsersStore((state) => state.modals);
  const { inviteUsers, loading } = useInviteUsers();
  const setCreateOfficeUserModal = useUsersStore(
    (state) => state.setCreateOfficeUserModal,
  );

  const closeModal = () => {
    reset();
    setCreateOfficeUserModal(false);
  };

  const onSubmit: SubmitHandler<AddOfficeUserModalInput> = async (data) => {
    const { email } = data;
    await inviteUsers({
      input: {
        emails: [email],
        invitationType: InvitationType.Email,
      },
    });
    closeModal();
  };

  return (
    <Modal
      isOpen={modals.openCreateOfficeUserModal}
      onClose={closeModal}
      onSuccess={handleSubmit(onSubmit)}
      size="sm"
      title="Add New User"
      message="New Users will be invited to the Platform to get access to Projects and Portfolios"
      successLabel="Invite"
      withSuccess={true}
      loading={loading}
    >
      <div className="isolate -space-y-px rounded-md px-4 py-1">
        <form id="add-new-user">
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <Controller
              control={control}
              name="email"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    id="email"
                    type="email"
                    label="E-Mail Address"
                    onChange={onChange}
                    error={error?.message}
                    value={value}
                  />
                );
              }}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
