import { PaginationLimit } from "@amenda-constants";
import { PROJECT_DESIGN_FRAGMENT } from "@amenda-domains/fragments/designs";
import { useProjectDesignsStore } from "@amenda-domains/mutations/designs";
import { AllowedDesignType, PaginationQueryProps } from "@amenda-types";
import { useHandleNotFound } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { gql, useClient } from "urql";

export const GET_ALL_PROJECT_DESIGNS = gql`
  ${PROJECT_DESIGN_FRAGMENT}
  query GetAllProjectDesigns(
    $limit: Int
    $previous: String
    $next: String
    $selectFields: Object
    $type: AllowedDesignType!
    $projectId: String
    $sort: Object
  ) {
    getAllProjectDesigns(
      limit: $limit
      previous: $previous
      next: $next
      type: $type
      projectId: $projectId
      sort: $sort
      selectFields: $selectFields
    ) {
      docsCount
      filteredDocsCount
      hasNext
      hasPrevious
      previous
      next
      designs {
        ...ProjectDesignFragment
      }
    }
  }
`;
export const GET_PROJECT_DESIGN = gql`
  ${PROJECT_DESIGN_FRAGMENT}
  query GetProjectDesign($id: ID) {
    design: getProjectDesign(_id: $id) {
      ...ProjectDesignFragment
    }
  }
`;

export type GetAllProjectDesignsArgs = {
  projectId?: string;
  type: AllowedDesignType;
  context?: Record<string, any>;
  values?: Record<string, any>;
  columnSorting?: any;
  paginationProps?: PaginationQueryProps;
  selectFields?: Record<string, any>;
  callback?: (args: { designs: any[] }) => void;
};

export const useGetAllProjectDesigns = () => {
  const client = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const setDesigns = useProjectDesignsStore((state) => state.setProjectDesigns);

  const getAllProjectDesigns = useCallback(
    async (args: GetAllProjectDesignsArgs) => {
      const {
        selectFields = {
          _id: 1,
          projectId: 1,
          name: 1,
          type: 1,
          previewUrl: 1,
        },
        paginationProps = {
          limit: PaginationLimit.projectDesigns,
        },
        context = {},
        callback,
      } = args;
      const variables: any = {
        ...paginationProps,
        type: args.type,
        selectFields,
      };

      setLoading(true);

      const { data } = await client
        .query(GET_ALL_PROJECT_DESIGNS, variables, context)
        .toPromise();
      if (data?.getAllProjectDesigns) {
        callback
          ? callback(data.getAllProjectDesigns)
          : setDesigns(args?.type, data.getAllProjectDesigns);
      }
      setLoading(false);

      return data?.getAllProjectDesigns?.designs;
    },
    [client, setDesigns],
  );

  return {
    loading,
    getAllProjectDesigns,
  };
};

interface GetProjectDesignProps {
  id: string;
  callback?: (design: Record<string, any>) => void;
}

export const useGetProjectDesign = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedProjectDesign = useProjectDesignsStore(
    (state) => state.setSelectedProjectDesign,
  );
  const { handleNotFound } = useHandleNotFound();

  const getProjectDesign = useCallback(
    async ({ callback, ...variables }: GetProjectDesignProps) => {
      setLoading(true);
      const response = await client.query(GET_PROJECT_DESIGN, variables);
      const data = response?.data;

      callback
        ? callback(data?.design)
        : setSelectedProjectDesign(data?.design);
      if (isEmpty(data?.design)) {
        handleNotFound();
      }
      setLoading(false);
    },
    [client, setSelectedProjectDesign, handleNotFound],
  );
  const getProjectDesignAsync = useCallback(
    async ({ callback, ...variables }: GetProjectDesignProps) => {
      const response = await client.query(GET_PROJECT_DESIGN, variables);
      const data = response?.data;

      return data?.design;
    },
    [client],
  );

  return {
    loading,
    getProjectDesign,
    getProjectDesignAsync,
  };
};
