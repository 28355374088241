import {
  Button,
  IconButtonBase,
  SaveIndicator,
  Tooltip,
} from "@amenda-components/App";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { FC, useEffect, useRef, useState } from "react";
import { PencilIcon, TrashIcon } from "lucide-react";
import { useTenantStore, useUpdateTenant } from "@amenda-domains/mutations";

import { AdminSettingsLayout } from "@amenda-components/Settings";
import { PerformantFormAutoSaveBase } from "@amenda-components/PageBuilder";
import { PlusIcon } from "lucide-react";
import clsx from "clsx";
import { downloadFormatSchema } from "@amenda-constants";
import isEmpty from "lodash/isEmpty";
import { isOriginalDownloadFormat } from "@amenda-components/Gallery/common";
import { useTranslation } from "react-i18next";

interface DownloadFormatFieldProps {
  index: number;
  isEditing: boolean;
  isOriginal: boolean;
  control: Control<any>;
  handleDelete: () => void;
  toggleEditing: (value: boolean) => void;
}

const DownloadFormatField: FC<DownloadFormatFieldProps> = ({
  index,
  control,
  isEditing,
  isOriginal,
  handleDelete,
  toggleEditing,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const id = `downloadFormats.${index}`;

  const handlePointerLeave = () => {
    toggleEditing(false);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div
      className="grid h-9 w-full grid-cols-3 gap-4 pt-3"
      onPointerLeave={handlePointerLeave}
    >
      <div className="col-span-1">
        <Controller
          name={`${id}.name`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            if (isEditing) {
              return (
                <Tooltip
                  id={`${id}.name`}
                  hidden={!error?.message}
                  message={error?.message}
                >
                  <input
                    type="text"
                    ref={inputRef}
                    value={value}
                    placeholder={t("Name")}
                    className={clsx(
                      "amenda-input-editable border-b border-gray-300 px-0",
                      {
                        "border-red-300": !!error?.message,
                      },
                    )}
                    onChange={onChange}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Tooltip>
              );
            }
            return <span className="text-sm text-gray-900">{value}</span>;
          }}
        />
      </div>
      <div
        className={clsx("col-span-2 flex space-x-2 text-sm text-gray-800", {
          "items-center": !isEditing,
        })}
      >
        <Controller
          name={`${id}.dimensions.width`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            if (isEditing) {
              return (
                <Tooltip
                  id={`${id}.name`}
                  hidden={!error?.message}
                  message={error?.message}
                >
                  <input
                    type="number"
                    value={value}
                    placeholder={t("Width")}
                    className={clsx(
                      "amenda-input-editable w-14 border-b border-gray-300 px-0",
                      {
                        "border-red-300": !!error?.message,
                      },
                    )}
                    onChange={onChange}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Tooltip>
              );
            }
            if (isOriginal) {
              return <div />;
            }
            return <span>{value}</span>;
          }}
        />
        {!isOriginal && (
          <div className="h-5">
            <span>x</span>
          </div>
        )}
        <Controller
          name={`${id}.dimensions.height`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            if (isEditing) {
              return (
                <Tooltip
                  id={`${id}.dimensions.height`}
                  hidden={!error?.message}
                  message={error?.message}
                >
                  <input
                    type="number"
                    value={value}
                    placeholder={t("Height")}
                    className={clsx(
                      "amenda-input-editable w-14 border-b border-gray-300 px-0",
                      {
                        "border-red-300": !!error?.message,
                      },
                    )}
                    onChange={onChange}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Tooltip>
              );
            }
            if (isOriginal) {
              return <div />;
            }
            return <span>{value}</span>;
          }}
        />
        {!isEditing && !isOriginal && (
          <div className="!ml-auto flex items-center space-x-1">
            <IconButtonBase size="xss" onClick={() => toggleEditing(true)}>
              <PencilIcon className="h-4 w-4" />
            </IconButtonBase>
            <IconButtonBase size="xss" onClick={handleDelete}>
              <TrashIcon className="h-4 w-4" />
            </IconButtonBase>
          </div>
        )}
      </div>
    </div>
  );
};

interface DownloadFormatFieldsProps {
  control: Control<any>;
}

const DownloadFormatFields: FC<DownloadFormatFieldsProps> = ({ control }) => {
  const { t } = useTranslation();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "downloadFormats",
    keyName: "arrId",
  });
  const [isEditing, setIsEditing] = useState<Record<string, boolean>>({});

  const handleAdd = () => {
    const index = fields.length;
    setIsEditing((prev) => ({ ...prev, [String(index)]: true }));
    append({
      name: "",
      dimensions: {
        width: "",
        height: "",
      },
    });
  };

  return (
    <div className="w-full">
      <div className="grid w-full grid-cols-3 gap-4">
        <div className="amenda-component-label col-span-1">{t("Name")}</div>
        <div className="amenda-component-label col-start-auto">
          {t("Dimensions")}
        </div>
      </div>
      <div className="mb-4">
        {fields.map((field, index) => {
          const fieldId = (field as any)?.arrId ?? (field as any).id;

          return (
            <DownloadFormatField
              key={fieldId}
              control={control}
              index={index}
              isOriginal={isOriginalDownloadFormat(field)}
              isEditing={isEditing[String(index)]}
              handleDelete={() => remove(index)}
              toggleEditing={(value: boolean) =>
                setIsEditing((prev) => ({ ...prev, [String(index)]: value }))
              }
            />
          );
        })}
      </div>
      <Button
        variant="outline"
        className="flex items-center"
        size="xs"
        onClick={handleAdd}
      >
        <PlusIcon className="h-4 w-4" />
        <span>{t("Add download format")}</span>
      </Button>
    </div>
  );
};

export const DownloadFormats: FC = () => {
  const { t } = useTranslation();
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const { updateTenant, updateTenantLoader } = useUpdateTenant();

  const handleSave = async (downloadFormats: any[]) => {
    await updateTenant({
      input: {
        settings: {
          ...(primaryTenant?.settings || {}),
          downloadFormats,
        },
      },
    });
  };

  return (
    <AdminSettingsLayout>
      <div className="flex items-center" style={{ height: "40px" }}>
        <div className="flex-auto">
          <h1 className="amenda-settings-heading">{t("Download formats")}</h1>
        </div>
        <SaveIndicator isSubmitting={updateTenantLoader} />
      </div>
      <PerformantFormAutoSaveBase
        className="max-w-2xl py-4"
        resourceId="downloadFormat"
        inputSchema={downloadFormatSchema}
        values={{
          downloadFormats: primaryTenant?.settings?.downloadFormats,
        }}
        onSubmit={async ({ sanitizedData, sanitizedDirtyData }) => {
          if (!isEmpty(sanitizedDirtyData?.downloadFormats)) {
            handleSave(sanitizedData.downloadFormats);
          }
        }}
      >
        {({ control }) => {
          if (!control) return null;
          return <DownloadFormatFields control={control} />;
        }}
      </PerformantFormAutoSaveBase>
    </AdminSettingsLayout>
  );
};
