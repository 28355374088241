import {
  AllowedAttachmentType,
  AllowedCollectionType,
  AppRoutes,
  GeneralPermissionTypes,
} from "@amenda-types";
import {
  Button,
  IconButtonBase,
  Spinner,
  Tooltip,
} from "@amenda-components/App";
import { CollectionButton, DeleteModal } from "@amenda-components/Shared";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { LayoutGridIcon, PlusIcon, SheetIcon, TrashIcon } from "lucide-react";
import {
  useAttachmentStore,
  useDeleteAttachments,
  useFormStore,
  useSettingsStore,
  useUpdateCollection,
} from "@amenda-domains/mutations";

import { ConstructionDetailsProps } from "./types";
import { LayoutTypes } from "@amenda-components/Shared/common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
    ConstructionDetailsProps,
    | "canUpload"
    | "isCollection"
    | "collectionType"
    | "canCreateCollection"
    | "fetchAttachments"
  > {}

export const ConstructionDetailsActionBar: FC<Props> = ({
  canUpload,
  isCollection,
  collectionType,
  canCreateCollection,
  fetchAttachments,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { deleteAttachments } = useDeleteAttachments();
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );
  const { updateCollection, loading: updateCollectionLoader } =
    useUpdateCollection();
  const selectedCollection = useFormStore(
    (state) => state.selectedCollectionByType[collectionType],
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const layoutView = useAttachmentStore((state) => state.layoutView);
  const setLayoutView = useAttachmentStore((state) => state.setLayoutView);
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );

  const constructionDetailsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.ConstructionDetails,
  );
  const hasNoSelectedAttachments = selectedAttachments.length <= 0;

  const handleCreateNew = () => {
    setSelectedAttachment({});
    navigate(AppRoutes.ConstructionDetailsNew);
  };

  const handleArchiveAttachments = async () => {
    setLoader(true);
    await deleteAttachments({
      attachmentIds: selectedAttachments,
    });
    clearSelectedAttachments();
    setLoader(false);
    setOpenModal(false);
  };

  const handleRemoveFromCollection = async () => {
    if (selectedCollection?.id) {
      await updateCollection({
        input: {
          _id: selectedCollection.id,
          aggregation: {
            $pull: {
              resourceIds: {
                $in: selectedAttachments,
              },
            },
          },
        },
      });
      clearSelectedAttachments();
    }
  };

  const handleDeleteAttachments = async () => {
    if (isCollection) {
      await handleRemoveFromCollection();
    } else {
      setOpenModal(true);
    }
  };

  const toggleView = () => {
    const view =
      layoutView === LayoutTypes.Grid ? LayoutTypes.Table : LayoutTypes.Grid;
    setLayoutView(view);
  };

  const handleResourceIds = async (args: any) => {
    let attachmentIds: string[] = [];

    if (fetchAttachments) {
      const attachments = await fetchAttachments({
        formValues: args,
        attachmentType: AllowedAttachmentType.pdf,
        callback: (data: any) => {},
      });

      attachmentIds =
        attachments?.map((attachment: any) => attachment.id) ?? [];
    }
    return attachmentIds;
  };

  return (
    <>
      <DeleteModal
        title="Delete Attachments"
        description="Are you sure you want to delete these attachments?"
        loading={loader}
        openModal={openModal}
        handleDelete={handleArchiveAttachments}
        handleCloseModal={() => setOpenModal(false)}
      />
      <div className="flex w-full flex-col">
        <div className="flex items-center justify-end space-x-1">
          {hasNoSelectedAttachments && (
            <Button size="xs" onClick={toggleView}>
              {layoutView === LayoutTypes.Grid ? (
                <LayoutGridIcon className="h-5 w-5" />
              ) : (
                <SheetIcon className="h-5 w-5" />
              )}
            </Button>
          )}
          {!hasNoSelectedAttachments && (
            <>
              {!isCollection && Boolean(canCreateCollection) && (
                <CollectionButton
                  btnClassName="mr-2"
                  resourceIds={selectedAttachments}
                  collectionRoute={AppRoutes.ConstructionDetailsCollection}
                  collectionType={AllowedCollectionType.ConstructionDetails}
                  getResourceIds={handleResourceIds}
                  clearSelectedResources={clearSelectedAttachments}
                />
              )}
              {constructionDetailsGeneralPermissions[
                GeneralPermissionTypes.Delete
              ] && (
                <Tooltip
                  id="delete"
                  message={isCollection ? "Remove from collection" : "Delete"}
                >
                  {updateCollectionLoader ? (
                    <Spinner className="border-white" spinnerSize="xs" />
                  ) : (
                    <IconButtonBase
                      size="xs"
                      variant="danger"
                      onClick={handleDeleteAttachments}
                    >
                      <TrashIcon className="h-5 w-5" />
                    </IconButtonBase>
                  )}
                </Tooltip>
              )}
            </>
          )}
          {constructionDetailsGeneralPermissions[
            GeneralPermissionTypes.Create
          ] &&
            canUpload &&
            hasNoSelectedAttachments && (
              <Button onClick={handleCreateNew} variant="primary" size="xs">
                <span className="mr-2 text-nowrap">
                  {t("Add Construction Detail")}
                </span>
                <div className="rounded-full bg-white text-gray-800">
                  <PlusIcon className="h-5 w-5" />
                </div>
              </Button>
            )}
        </div>
      </div>
    </>
  );
};
