import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Button, IconButtonBase } from "@amenda-components/App";
import {
  Control,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { FC, useEffect, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
  isDragValid,
} from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { GripVerticalIcon, PlusIcon, TrashIcon, XIcon } from "lucide-react";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { availableLayouts } from "@amenda-types/app";
import clsx from "clsx";
import { generateReadableId } from "@amenda-utils";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

interface SectionLabelProps {
  id: string;
  control: Control<any>;
}

const SectionLabel: FC<SectionLabelProps> = ({ id, control }) => {
  const label = useWatch({
    control,
    name: id,
  });

  return (
    <span className="ml-2 mr-auto select-none truncate text-sm">{label}</span>
  );
};

export const ProjectFormSections: FC<Props> = ({ control, setValue }) => {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: "sections",
    keyName: "arrId",
  });
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const updateFormBuilderFormViewForm = useAppStore(
    (state) => state.updateFormBuilderFormViewForm,
  );
  const [newSectionIndex, setNewSectionIndex] = useState<number | null>(null);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const sectionIds = fields.map((s: any) => s.id);
  const activeSection: any = fields.find((s: any) => s.id === activeId);

  const handleDelete = (index: number) => {
    remove(index);
    if ((fields[index] as any).id === selectedForm?.id) {
      updateFormBuilderState("selectedForm", undefined);
    }
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    const oldIndex = sectionIds.indexOf(active.id);
    const newIndex = sectionIds.indexOf(over.id);

    if (oldIndex === -1 || newIndex === -1) {
      return;
    }
    move(oldIndex, newIndex);
  };

  const handleAddSection = () => {
    const parentId = generateReadableId("Initial");
    const componentId = generateReadableId("Two Thirds Column with Grid");
    const components = [
      {
        id: parentId,
        layout: availableLayouts.rootContainer,
      },
      {
        id: componentId,
        order: 0,
        parentId,
        layout: availableLayouts.twoThirdsColumnContainer,
        properties: {
          label: "",
          description: "",
        },
      },
    ];
    const index = fields.length;

    setNewSectionIndex(index);
    append({
      components,
      id: generateReadableId(),
      label: t("Section") + " " + Number(index).toString(),
    });
  };

  useEffect(() => {
    if (newSectionIndex && fields[newSectionIndex]) {
      setNewSectionIndex(null);
      updateFormBuilderFormViewForm(fields[newSectionIndex]);
    }
  }, [newSectionIndex, fields, updateFormBuilderFormViewForm]);

  return (
    <>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragStart={(event) => {
          const { active } = event;

          setActiveId(String(active.id));
        }}
        onDragEnd={(event) => {
          const { active, over } = event;
          setActiveId(null);
          if (over && isDragValid(active, over)) {
            handleDragEnd(active, over);
          }
        }}
      >
        <SortableContext
          id="formBuilderSidebar"
          items={sectionIds}
          strategy={verticalListSortingStrategy}
        >
          <FormBuilderSortDroppable className="h-full w-full py-4">
            <div
              className="flex w-full flex-col space-y-2"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              {fields.map((s: any, i) => (
                <FormBuilderSortableItem key={s.id} id={s.id}>
                  {(isDragging) => (
                    <div
                      className={clsx(
                        "group/sortableItem flex w-full cursor-pointer items-center rounded-sm p-1 pr-2 text-left outline-none",
                        {
                          invisible: isDragging,
                          "bg-gray-800 text-white": s.id === selectedForm?.id,
                          "bg-white text-gray-800": s.id !== selectedForm?.id,
                        },
                      )}
                      onClick={() => {
                        updateFormBuilderFormViewForm(s);
                      }}
                    >
                      <IconButtonBase
                        size="sm"
                        className="cursor-grab px-0.5 py-1"
                      >
                        <GripVerticalIcon className="h-5 w-5 stroke-[2]" />
                      </IconButtonBase>
                      <SectionLabel
                        id={`sections.${i}.label`}
                        control={control}
                      />
                      <IconButtonBase
                        size="xss"
                        className="h-4 w-4 rounded-full bg-white"
                        variant="default"
                        onClick={(e) => {
                          e?.stopPropagation();
                          handleDelete(i);
                        }}
                      >
                        <XIcon className="h-4 w-4" />
                      </IconButtonBase>
                    </div>
                  )}
                </FormBuilderSortableItem>
              ))}
              <Button
                size="sm"
                variant="outline"
                className="justify-center space-x-2"
                onClick={handleAddSection}
              >
                <span>{t("New section")}</span>
                <PlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </FormBuilderSortDroppable>
        </SortableContext>
        <DragOverlay modifiers={[restrictToVerticalAxis]}>
          {activeId && activeSection && (
            <FormBuilderDragOverlayItem>
              <div className="border-1 flex w-full items-center border border-gray-500">
                <div>
                  <IconButtonBase size="sm" className="cursor-grab px-0.5 py-1">
                    <GripVerticalIcon className="h-5 w-5 stroke-[2]" />
                  </IconButtonBase>
                </div>
                <span className="ml-2 truncate">{activeSection.label}</span>
              </div>
            </FormBuilderDragOverlayItem>
          )}
        </DragOverlay>
      </DndContext>
    </>
  );
};
