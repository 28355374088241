import * as Sentry from "@sentry/react";

import { AllMaterials, AllMaterialsCollection } from "@amenda-pages/Materials";
import {
  AllProjectsCollectionPage,
  AllProjectsPage,
  CreateOrEditProjectPage,
  NotFoundPage,
  PrintProjectAltPage,
  PrintProjectPage,
  PrintProjectTemplate,
  ProjectPage,
} from "@amenda-pages/Projects";
import {
  AttachmentPage,
  AttachmentsCollectionPage,
  AttachmentsPage,
} from "@amenda-pages/Gallery";
import {
  ConstructionDetailsCollectionPage,
  ConstructionDetailsPage,
  NewConstructionDetailsPage,
  ViewConstructionDetailsPage,
} from "@amenda-pages/Construction";
import {
  ContactsCollectionPage,
  ContactsPage,
  UsersCollectionPage,
  UsersPage,
} from "@amenda-pages/Contacts";
import {
  CreateOrEditRole,
  DownloadFormats,
  FormBuilderPage,
  IntegrationsPage,
  ManageUsers,
  RegionalFactors,
  Roles,
  SecurityPage,
  SimilaritySearch,
  Tenant,
} from "@amenda-pages/Administration";
import {
  DefaultLoginPage,
  LoginWithTokenPage,
  Logout,
  ProfileUpdatedSuccessPage,
  RegisterPage,
  RegistrationSuccessPage,
  ResetPassword,
  UpdateProfilePage,
} from "@amenda-pages/Auth";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import {
  PrivateRouteWrapper,
  PublicRouteWrapper,
} from "@amenda-components/Auth";

import { DashboardPage } from "@amenda-pages/Dashboard";
import { NotFoundRoute } from "@amenda-constants";
import PrimaryProviders from "./PrimaryProviders";
import { UserSettings } from "@amenda-pages/Settings";
import { SAMLConfigurationPage } from "@amenda-pages/Administration/SAMLConfiguration";
import { SAMLLoginPage } from "@amenda-pages/Auth/SAMLLogin";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const appRouter = sentryCreateBrowserRouter([
  {
    path: "/login/by-token",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <LoginWithTokenPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/saml-login",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <SAMLLoginPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/login",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <DefaultLoginPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/signup",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <RegisterPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/signup/*",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <Outlet />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "complete-registration/success",
        element: <ProfileUpdatedSuccessPage />,
      },
      {
        path: "complete-registration",
        element: <UpdateProfilePage />,
      },
      {
        path: "success",
        element: <RegistrationSuccessPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/planungsdetails",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <ConstructionDetailsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/planungsdetails/*",
    element: (
      <PrimaryProviders>
        <Outlet />
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId",
        element: (
          <PrivateRouteWrapper>
            <ConstructionDetailsCollectionPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: (
          <PrivateRouteWrapper>
            <ConstructionDetailsCollectionPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "sammlungen/:collectionId/:constructionDetailId/bearbeiten",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <ViewConstructionDetailsPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "sammlungen/:collectionId/:constructionDetailId",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <ViewConstructionDetailsPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "neu",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <NewConstructionDetailsPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: ":constructionDetailId/bearbeiten",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <ViewConstructionDetailsPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: ":constructionDetailId",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <ViewConstructionDetailsPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/einstellungen",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <UserSettings />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/einstellungen/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "security",
        element: <SecurityPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <ManageUsers />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/admin/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "rollen/neu",
        element: <CreateOrEditRole />,
      },
      {
        path: "rollen/:roleId",
        element: <CreateOrEditRole />,
      },
      {
        path: "rollen",
        element: <Roles />,
      },
      {
        path: "konto",
        element: <Tenant />,
      },
      {
        path: "regional-factors",
        element: <RegionalFactors />,
      },
      {
        path: "similarity-search",
        element: <SimilaritySearch />,
      },
      {
        path: "form-builder",
        element: <FormBuilderPage />,
      },
      {
        path: "saml-configuration",
        element: <SAMLConfigurationPage />,
      },
      {
        path: "integrations",
        element: <IntegrationsPage />,
      },
      {
        path: "download-formate",
        element: <DownloadFormats />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/bueroverzeichnis",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <UsersPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/bueroverzeichnis/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId",
        element: <UsersCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <UsersCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId/:userId/bearbeiten",
        element: <UsersCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId/:userId",
        element: <UsersCollectionPage />,
      },
      {
        path: ":userId/bearbeiten",
        element: <UsersPage />,
      },
      {
        path: ":userId",
        element: <UsersPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/kontaktverzeichnis",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <ContactsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/kontaktverzeichnis/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId",
        element: <ContactsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <ContactsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId/:userId/bearbeiten",
        element: <ContactsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId/:userId",
        element: <ContactsCollectionPage />,
      },
      {
        path: ":userId/bearbeiten",
        element: <ContactsPage />,
      },
      {
        path: ":userId",
        element: <ContactsPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/einheitspreise",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <AllMaterials />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/einheitspreise/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId",
        element: <AllMaterialsCollection />,
      },
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <AllMaterialsCollection />,
      },
      {
        path: "sammlungen/:collectionId/:materialId/bearbeiten",
        element: <AllMaterialsCollection />,
      },
      {
        path: "sammlungen/:collectionId/:materialId",
        element: <AllMaterialsCollection />,
      },
      {
        path: "neu",
        element: <AllMaterials />,
      },
      {
        path: ":materialId/bearbeiten",
        element: <AllMaterials />,
      },
      {
        path: ":materialId",
        element: <AllMaterials />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/medien",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <AttachmentsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/medien/*",
    element: (
      <PrimaryProviders>
        <Outlet />
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId",
        element: (
          <PrivateRouteWrapper>
            <AttachmentsCollectionPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: (
          <PrivateRouteWrapper>
            <AttachmentsCollectionPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "sammlungen/:collectionId/:attachmentId/bearbeiten",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <AttachmentPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "sammlungen/:collectionId/:attachmentId",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <AttachmentPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: ":attachmentId/bearbeiten",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <AttachmentPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: ":attachmentId",
        element: (
          <PrivateRouteWrapper hideSidebar={true}>
            <AttachmentPage />
          </PrivateRouteWrapper>
        ),
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/projekte/:projectId/template/:templateId/templateData/:templateDataId/generate-pdf",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <PrintProjectTemplate />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/projekte/:projectId/drucken",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper isPrintView={true}>
          <PrintProjectAltPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/projekte/:projectId/generate-pdf",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <PrintProjectPage />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/projekte",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <AllProjectsPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/projekte/*",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Outlet />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
    children: [
      {
        path: "sammlungen/:collectionId/bearbeiten",
        element: <AllProjectsCollectionPage />,
      },
      {
        path: "sammlungen/:collectionId",
        element: <AllProjectsCollectionPage />,
      },
      {
        path: "neu/allgemein",
        element: <CreateOrEditProjectPage />,
      },
      {
        path: ":projectId/bearbeiten",
        element: <CreateOrEditProjectPage />,
      },
      {
        path: ":projectId",
        element: <ProjectPage />,
      },
      {
        path: "*",
        element: <Navigate to={NotFoundRoute} />,
      },
    ],
  },
  {
    path: "/auth/reset-password",
    element: (
      <PrimaryProviders>
        <PublicRouteWrapper>
          <ResetPassword />
        </PublicRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/logout",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <Logout />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "/",
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <DashboardPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: NotFoundRoute,
    element: (
      <PrimaryProviders>
        <PrivateRouteWrapper>
          <NotFoundPage />
        </PrivateRouteWrapper>
      </PrimaryProviders>
    ),
  },
  {
    path: "*",
    element: <Navigate to={NotFoundRoute} />,
  },
]);
