import {
  CheckIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { CircleAlertIcon, XIcon } from "lucide-react";
import {
  FC,
  RefObject,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

import { Divider } from "@amenda-components/App";
import Fuse from "fuse.js";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { Option } from "@amenda-types";
import clsx from "clsx";
import { inputFieldTheme } from "@amenda-styles/theme";
import isEmpty from "lodash/isEmpty";
import { useRunOnMountOnly } from "@amenda-utils";
import { useTranslation } from "react-i18next";

interface Props {
  label?: string;
  selectedOption?: string | string[];
  options: Option[];
  secondaryOptions?: Option[];
  closeOnHover?: boolean;
  Icon?: FC<any>;
  withIcon?: boolean;
  disabled?: boolean;
  labelClassName?: string;
  menuItemsClassName?: string;
  hasMenuOverflow?: boolean;
  searchPlaceholder?: string;
  onChange: (option: Option) => void;
}

const getStyle = (
  hasMenuOverflow: boolean,
  parentRef?: RefObject<HTMLDivElement>,
) => {
  const parentRect = parentRef?.current?.getBoundingClientRect?.();

  if (hasMenuOverflow && parentRect) {
    return {
      top: parentRect.top,
    };
  }
  return {};
};

const sortSelectedOptions = (
  options: Option[],
  getSelectedOptions: (value: string) => boolean,
) => {
  const sortedOptions: Option[] = [];
  const unselectedOptions: Option[] = [];

  options.forEach((option) => {
    if (getSelectedOptions(option.value)) {
      sortedOptions.push(option);
    } else {
      unselectedOptions.push(option);
    }
  });

  return [...sortedOptions, ...unselectedOptions];
};

export const OperationsMenu: FC<Props> = ({
  label,
  selectedOption,
  options,
  secondaryOptions,
  disabled = false,
  closeOnHover = false,
  withIcon = false,
  hasMenuOverflow = false,
  searchPlaceholder = "Suche",
  menuItemsClassName = "origin-top-right right-0",
  labelClassName = "px-1 py-[1px] bg-white text-gray-900 hover:bg-gray-900 hover:text-white text-xxs focus:outline-none",
  Icon = EllipsisVerticalIcon,
  onChange,
}) => {
  const { t } = useTranslation();
  const [hoverAway, setHoverAway] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const hiddenMenuItemRef = useRef<HTMLDivElement>(null);
  const { searchInputCss } = inputFieldTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [availableOptions, setAvailableOptions] = useState<Option[]>([]);

  const isMulti = Array.isArray(selectedOption);

  const getLabel = () => {
    const defaultLabel = label || "No selected option";

    if (isMulti) {
      const foundOption = options.find(({ value }) =>
        selectedOption.includes(value),
      );

      return !foundOption
        ? defaultLabel
        : selectedOption.length > 1
          ? `${foundOption.label},  ...`
          : foundOption.label;
    }
    return (
      options.find(({ value }) => value === selectedOption)?.label ||
      defaultLabel
    );
  };

  const getSelectedOptions = useCallback(
    (value: string) => {
      if (isMulti) {
        return selectedOption.includes(value);
      }
      return Boolean(selectedOption && selectedOption === value);
    },
    [isMulti, selectedOption],
  );

  const handleClick =
    (option: Option, close: () => void) =>
    (e: SyntheticEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(option);
      close();
    };

  const handleMenuFocusAndBlur = (hoverAway: boolean) => () => {
    if (!disabled && closeOnHover) {
      setHoverAway(hoverAway);
      hiddenMenuItemRef?.current?.click();
    }
  };

  const handleSearch = (value: string) => {
    let sortedOptions: Option[] = [];

    setSearchTerm(value);
    if (value.length > 1) {
      const fuse = new Fuse(options, {
        includeScore: true,
        threshold: 0.3,
        keys: ["value", "label"],
      });

      sortedOptions = sortSelectedOptions(
        fuse.search(value).map((res) => res.item),
        getSelectedOptions,
      );
    } else {
      sortedOptions = sortSelectedOptions(options, getSelectedOptions);
    }
    setAvailableOptions(sortedOptions);
  };

  useRunOnMountOnly(() => {
    const sortedOptions = sortSelectedOptions(options, getSelectedOptions);
    setAvailableOptions(sortedOptions);
  }, JSON.stringify(options));

  return (
    <Menu
      ref={parentRef}
      as="div"
      className="relative"
      onPointerLeave={handleMenuFocusAndBlur(true)}
      onPointerEnter={handleMenuFocusAndBlur(false)}
    >
      {({ open }) => (
        <>
          {withIcon ? (
            <MenuButton
              disabled={disabled}
              className={clsx(
                "flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none",
                {
                  "cursor-not-allowed": disabled,
                },
              )}
            >
              <span className="sr-only">{t("Open options")}</span>
              <Icon className="h-4 w-4 text-gray-500" aria-hidden="true" />
            </MenuButton>
          ) : (
            <MenuButton
              disabled={disabled}
              className={clsx(
                "group inline-flex items-center",
                labelClassName,
                {
                  "cursor-not-allowed": disabled,
                },
              )}
            >
              <span>{t(getLabel())}</span>
              <Divider />
              <ChevronRightIcon
                className={clsx("ml-1 h-2 w-2", {
                  "rotate-90": open,
                })}
                aria-hidden="true"
              />
            </MenuButton>
          )}
          <Transition
            show={open && !hoverAway}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              static
              className={clsx(
                "z-50 max-h-48 w-36 divide-y divide-gray-100 overflow-y-auto overflow-x-hidden overscroll-contain bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                menuItemsClassName,
                {
                  fixed: hasMenuOverflow,
                  absolute: !hasMenuOverflow,
                },
              )}
              style={{
                ...getStyle(hasMenuOverflow, parentRef),
              }}
            >
              <MenuItem>
                {({ close }) => (
                  <div
                    className="hidden"
                    onClick={close}
                    ref={hiddenMenuItemRef}
                  />
                )}
              </MenuItem>
              <div>
                {options.length > 5 && (
                  <MiniSearchField
                    className={searchInputCss({
                      size: "xs",
                      class:
                        "w-full border-x-0 border-b border-t-0 border-gray-300",
                    })}
                    placeholder={t(searchPlaceholder) + "..."}
                    value={searchTerm}
                    onChange={handleSearch}
                    onClear={() => handleSearch("")}
                  />
                )}
                {availableOptions.map((option) => (
                  <MenuItem key={option.value}>
                    {({ focus, close }) => (
                      <div
                        onClick={handleClick(option, close)}
                        className={clsx(
                          "flex w-full cursor-pointer items-center justify-between py-1 hover:text-white",
                          {
                            "bg-gray-900 text-white": focus,
                            "text-gray-900": getSelectedOptions(option.value),
                            "text-gray-700": !focus,
                          },
                        )}
                      >
                        <span className="truncate px-2 text-sm">
                          {t(option.label)}
                        </span>
                        {getSelectedOptions(option.value) && (
                          <div className="flex w-6 justify-center">
                            {isMulti ? (
                              <XIcon aria-hidden="true" className="h-3 w-3" />
                            ) : (
                              <CheckIcon
                                aria-hidden="true"
                                className="h-3 w-3"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </MenuItem>
                ))}
                {isEmpty(availableOptions) && !isEmpty(searchTerm) && (
                  <div className="flex h-12 w-full flex-col items-center justify-center space-y-1">
                    <CircleAlertIcon className="h-4 w-4" />
                    <span className="text-center text-xs">
                      {t("No results found")}
                    </span>
                  </div>
                )}
                {secondaryOptions && !isEmpty(options) && (
                  <div className="border-t border-gray-300" />
                )}
                {secondaryOptions?.map((option) => (
                  <MenuItem key={option.value}>
                    {({ close }) => (
                      <div
                        onClick={handleClick(option, close)}
                        className="flex w-full cursor-pointer items-center justify-between py-1 hover:bg-gray-900 hover:text-white"
                      >
                        <div className="px-2 text-sm">{t(option.label)}</div>
                        {getSelectedOptions(option.value) && (
                          <div className="flex w-6 justify-center">
                            <CheckIcon aria-hidden="true" className="h-3 w-3" />
                          </div>
                        )}
                      </div>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};
