import { FC, useEffect, useState } from "react";
import {
  ProjectPrintView,
  ReadOnlyProjectHeader,
} from "@amenda-components/Projects";

import { Helmet } from "react-helmet-async";
import { MaxWidthLayout } from "@amenda-components/Shared";
import { useGetProject } from "@amenda-domains/queries";
import { useParams } from "react-router";
import { useProjectStore } from "@amenda-domains/mutations";

// Define dimensions and CSS page size values for each format.
const pageSizes = {
  A4: { width: "210mm", height: "297mm", pageSize: "210mm 297mm" },
  A3: { width: "297mm", height: "420mm", pageSize: "297mm 420mm" },
  A1: { width: "594mm", height: "841mm", pageSize: "594mm 841mm" },
  PowerPoint: {
    width: "338.7mm",
    height: "190.5mm",
    pageSize: "338.7mm 190.5mm",
  },
};

export const PrintProjectAltPage: FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const { getProject, loading } = useGetProject();
  const clearProjectValues = useProjectStore(
    (state) => state.clearProjectValues,
  );

  // State for the selected page format.
  const [pageFormat, setPageFormat] = useState<
    "A4" | "A3" | "A1" | "PowerPoint"
  >("A4");

  useEffect(() => {
    if (projectId) {
      getProject({
        id: projectId,
        redirectWhenEmpty: true,
        context: { requestPolicy: "cache-and-network" },
      });
    }
  }, [projectId, getProject]);

  useEffect(() => {
    return () => {
      clearProjectValues();
    };
  }, [clearProjectValues]);

  // Destructure the current format dimensions.
  const { width, height, pageSize } = pageSizes[pageFormat];

  return (
    <>
      <Helmet>
        <title>{"Druckansicht"}</title> {/* Should contain the project name */}
      </Helmet>

      {/* Print-only styles */}
      <style>{`
        @media print {
          @page {
            size: ${pageSize};
            margin: 0;
          }
          /* Hide all elements except the printable container */
          body * {
            visibility: hidden;
          }
          #printable, #printable * {
            visibility: visible;
          }
          #printable {
            position: absolute;
            left: 0;
            top: 0;
            overflow: visible !important;
            width: ${width};
            min-height: ${height};
            height: auto !important;
            box-shadow: none;
          }
          /* Hide scrollbars in print (for WebKit browsers) */
          ::-webkit-scrollbar {
            display: none;
          }
        }
      `}</style>

      {/* On-screen container */}
      <div className="flex min-h-screen flex-col items-center bg-gray-200 p-4">
        {/* Format selection buttons */}
        <div className="mb-4 flex space-x-2">
          {(["A4", "A3", "A1", "PowerPoint"] as const).map((format) => (
            <button
              key={format}
              onClick={() => setPageFormat(format)}
              className={`rounded border px-4 py-2 transition-colors duration-200 ${
                pageFormat === format
                  ? "border-gray-500 bg-gray-900 text-white"
                  : "border-gray-500 bg-white text-gray-500 hover:bg-gray-50"
              }`}
            >
              {format}
            </button>
          ))}
        </div>

        {/* Printable container */}
        <div
          id="printable"
          className="bg-white shadow-lg"
          style={{ width: width, minHeight: height }}
        >
          <MaxWidthLayout
            wrapperClassName="py-4 px-6 h-full"
            className="lg:max-w-[1200px]"
          >
            <ReadOnlyProjectHeader showPrintView={true} />
            <div className="w-full pt-4">
              <ProjectPrintView loading={loading} />
            </div>
          </MaxWidthLayout>
        </div>
      </div>

      {/* Floating Print Button centered at the bottom */}
      <div className="fixed bottom-4 left-1/2 z-50 -translate-x-1/2 transform">
        <button
          onClick={() => window.print()}
          className="rounded border bg-blue-500 px-4 py-2 text-white shadow-lg hover:bg-blue-600"
        >
          Drucken
        </button>
      </div>
    </>
  );
};
