import { Active, Over, useDndMonitor, useDroppable } from "@dnd-kit/core";
import { FC, ReactNode, useState } from "react";
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import { LayoutProps } from "@amenda-components/PageLayouts/common";
import { availableLayouts } from "@amenda-types";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";

interface FormBuilderSortableItemProps {
  children: (isDragging: boolean) => ReactNode;
  id: string;
  data?: Record<string, any>;
  className?: string;
  disabled?: boolean;
}

interface FormBuilderSortDroppableProps {
  children: ReactNode;
  className?: string;
  getClassName?: (isOver: boolean) => string;
}

interface FormBuilderDragOverlayItemProps {
  children: ReactNode;
  className?: string;
}

export const isDragValid = (active: Active, over: Over) => {
  return active.id !== over.id;
};

export const isFormBuilderDragValid = (
  activeComponent: any,
  overComponent: any,
) => {
  if (!activeComponent || !overComponent) return false;
  if (overComponent.layout === availableLayouts.rootContainer) {
    return [
      availableLayouts.fullColumnContainer,
      availableLayouts.nestedFormContainer,
      availableLayouts.twoThirdsColumnContainer,
    ].includes(activeComponent?.layout);
  }
  return Boolean(activeComponent?.component);
};

export const FormBuilderSortableItem: FC<FormBuilderSortableItemProps> = ({
  id,
  children,
  className,
  data = {},
  disabled = false,
}) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data,
    disabled,
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {children(isDragging)}
    </div>
  );
};

export const FormBuilderSortDroppable: FC<FormBuilderSortDroppableProps> = ({
  children,
  className,
  getClassName,
}) => {
  const [isOver, setOver] = useState(false);
  useDndMonitor({
    onDragStart() {
      setOver(true);
    },
    onDragEnd() {
      setOver(false);
    },
    onDragCancel() {
      setOver(false);
    },
  });

  return (
    <div
      className={clsx(className, getClassName?.(isOver), {
        "bg-gray-100": isOver,
      })}
    >
      {children}
    </div>
  );
};

export const FormBuilderDragOverlayItem: FC<
  FormBuilderDragOverlayItemProps
> = ({
  children,
  className = "shadow-lg w-full px-1 py-2 cursor-pointer text-sm rounded-sm outline-none bg-gray-800 text-white",
}) => {
  return <div className={className}>{children}</div>;
};

interface FormBuilderDndProps extends LayoutProps {
  className?: string;
  showBorder?: boolean;
}

interface DroppableProps {
  id: string;
  children: ReactNode;
  className: string;
  disabled?: boolean;
  data?: Record<string, any>;
}

const Droppable: FC<DroppableProps> = ({
  id,
  children,
  className,
  disabled,
  data = {},
}) => {
  const { setNodeRef } = useDroppable({
    id,
    disabled,
    data,
  });

  return (
    <div className={className} ref={setNodeRef}>
      {children}
    </div>
  );
};

const FormBuilderLayoutDnd: FC<FormBuilderDndProps> = ({
  children,
  className,
  config,
}) => {
  const [isOver, setOver] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const id = `${config?.layout}.${config?.id}`;
  const childComponentIds =
    config?.components?.map((component) => component.id) ?? [];

  useDndMonitor({
    onDragStart() {
      setOver(true);
    },
    onDragMove(event) {
      const { active } = event;
      const disabled = !isFormBuilderDragValid(
        active.data.current?.component,
        config,
      );

      setDisabled(disabled);
    },
    onDragEnd() {
      setOver(false);
      setDisabled(false);
    },
    onDragCancel() {
      setOver(false);
      setDisabled(false);
    },
  });

  return (
    <SortableContext
      id={id}
      items={childComponentIds}
      strategy={rectSortingStrategy}
      disabled={disabled}
    >
      <Droppable
        id={`droppable.${id}`}
        className={clsx("border border-dashed p-4", className, {
          "border-gray-300 bg-gray-50": isOver && !disabled,
          "border-gray-300 bg-white": isOver && disabled,
          "border-transparent": !isOver,
        })}
        data={{
          component: config,
        }}
        disabled={disabled}
      >
        {children}
      </Droppable>
    </SortableContext>
  );
};

export const FormBuilderLayoutSortDroppable: FC<FormBuilderDndProps> = ({
  children,
  className,
  isFormBuilder = false,
  ...rest
}) => {
  if (!isFormBuilder) {
    return <div className={className}>{children}</div>;
  }
  return (
    <FormBuilderLayoutDnd className={className} {...rest}>
      {children}
    </FormBuilderLayoutDnd>
  );
};

const FormBuilderComponentDnd: FC<FormBuilderDndProps> = ({
  config,
  children,
  className,
  showBorder = false,
}) => {
  const isEditingForm = useAppStore(
    (state) => state.formBuilderState.isEditingForm,
  );
  const {
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
    setNodeRef,
  } = useSortable({
    id: config?.id ?? "",
    data: {
      component: config,
    },
    disabled: !isEditingForm,
  });
  const isSortingComponent = useAppStore(
    (state) => state.formBuilderState?.isSortingComponent,
  );
  const isEditingComponent = useAppStore(
    (state) => state.formBuilderState?.isEditingComponent,
  );
  const component = useAppStore(
    (state) => state.formBuilderState?.selectedFormComponent,
  );

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      id={config?.id}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={clsx(
        className,
        "relative w-full cursor-default border border-dashed border-transparent p-2",
        {
          "h-fit": !config?.component,
          "hover:border-gray-300": !isEditingComponent,
          "opacity-30": isDragging,
          "!border-gray-300":
            showBorder || (isEditingComponent && component?.id === config?.id),
          "animate-pulse": Boolean(isSortingComponent),
        },
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export const FormBuilderComponentSort: FC<FormBuilderDndProps> = ({
  children,
  className,
  isFormBuilder = false,
  ...rest
}) => {
  if (!isFormBuilder) {
    return (
      <div id={rest?.config?.id} className={className}>
        {children}
      </div>
    );
  }
  return (
    <FormBuilderComponentDnd className={className} {...rest}>
      {children}
    </FormBuilderComponentDnd>
  );
};

export const FormBuilderSidebarComponentSort: FC<FormBuilderDndProps> = ({
  config,
  children,
  className,
}) => {
  const { listeners, attributes, setNodeRef } = useSortable({
    id: config?.id ?? "",
    data: {
      component: config,
    },
  });

  return (
    <div
      id={config?.id}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={className}
    >
      {children}
    </div>
  );
};
