import {
  AllowedAttachmentType,
  AllowedCollectionType,
  AppRoutes,
  AvailableImageVariants,
  GalleryView,
  GeneralPermissionTypes,
} from "@amenda-types";
import {
  ArrowsPointingInIcon,
  FolderArrowDownIcon,
  FunnelIcon,
  Squares2X2Icon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Button, Link, Spinner, Tooltip } from "@amenda-components/App";
import {
  CloudDownloadIcon,
  GalleryThumbnailsIcon,
  PencilIcon,
  PlusIcon,
  SheetIcon,
} from "lucide-react";
import {
  CollectionButton,
  CollectionDropdown,
  DeleteModal,
  ExpandableSearch,
  ResourceCounter,
} from "@amenda-components/Shared";
import { FC, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  useAppStore,
  useAttachmentStore,
  useDeleteAttachments,
  useFormStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
  useUpdateCollection,
} from "@amenda-domains/mutations";

import { ArrowsPointingOutIcon } from "@heroicons/react/24/solid";
import { DownloadAttachmentModal } from "./DownloadAttachmentModal";
import { MediaBulkEditorModal } from "@amenda-components/FileUploads";
import { MediaGalleryProps } from "./common";
import { MediaSliderModal } from "./MediaSliderModal";
import { isEmpty } from "lodash";
import { isSearchFilterEmpty } from "@amenda-components/Shared/common";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = Pick<
  MediaGalleryProps,
  | "label"
  | "attachments"
  | "canCreateCollection"
  | "canUpload"
  | "fetchAttachments"
  | "searchAttachments"
  | "canSetSlider"
  | "canAssignAttachments"
>;

export const AttachmentsActionBar: FC<Props> = ({
  attachments,
  canUpload,
  canSetSlider,
  canCreateCollection,
  canAssignAttachments,
  label = "Media Overview",
  fetchAttachments,
  searchAttachments,
}) => {
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const selectedImageVariant = useAttachmentStore(
    (state) => state.selectedImageVariant,
  );
  const setSelectedImageVariant = useAttachmentStore(
    (state) => state.setSelectedImageVariant,
  );
  const selectedGalleryView = useAttachmentStore(
    (state) => state.selectedGalleryView,
  );
  const setSelectedGalleryView = useAttachmentStore(
    (state) => state.setSelectedGalleryView,
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );
  const setSelectAllAttachments = useAttachmentStore(
    (state) => state.setSelectAllAttachments,
  );
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const toggleMoveAttachmentsModal = useAttachmentStore(
    (state) => state.toggleMoveAttachmentsModal,
  );
  const { deleteAttachments } = useDeleteAttachments();
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const toggleBulkEditorModal = useAttachmentStore(
    (state) => state.toggleBulkEditorModal,
  );
  const { updateCollection, loading: updateCollectionLoader } =
    useUpdateCollection();
  const selectedCollection = useFormStore(
    (state) =>
      state.selectedCollectionByType[AllowedCollectionType.Attachments],
  );
  const setOpenMediaSlider = useAttachmentStore(
    (state) => state.setOpenMediaSlider,
  );
  const { searchTerm, sidebarFilters, setSearchTerm } =
    useSidebarFiltersWithPath();
  const { filteredDocsCount, docsCount = 0 } = useAttachmentStore(
    (state) => state.pagination,
  );
  const setOpenDownloadModal = useAttachmentStore(
    (state) => state.setOpenDownloadModal,
  );

  const isCollection = pathname.includes(AppRoutes.AttachmentsCollection);
  const mediaGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Media,
  );
  const hasNoSelectedAttachments = selectedAttachments.length <= 0;

  const handleArchiveAttachments = async () => {
    setLoader(true);
    await deleteAttachments({
      attachmentIds: selectedAttachments,
    });
    clearSelectedAttachments();
    setLoader(false);
    setOpenModal(false);
  };

  const handleRemoveFromCollection = async () => {
    if (selectedCollection?.id) {
      await updateCollection({
        input: {
          _id: selectedCollection.id,
          aggregation: {
            $pull: {
              resourceIds: {
                $in: selectedAttachments,
              },
            },
          },
        },
      });
      clearSelectedAttachments();
    }
  };

  const handleDeleteProjects = async () => {
    if (isCollection) {
      await handleRemoveFromCollection();
    } else {
      setOpenModal(true);
    }
  };

  const handleOpenFilterSidebar = () => {
    setOpenSidebar(!sidebarOpen);
  };

  const handleMediaSlider = () => {
    setOpenMediaSlider(true);
  };

  const toggleGalleryView = () => {
    const galleryView =
      selectedGalleryView === GalleryView.Grid
        ? GalleryView.Table
        : GalleryView.Grid;
    setSelectedGalleryView(galleryView);
  };

  const toggleImageVariant = () => {
    const imageVariant =
      selectedImageVariant === AvailableImageVariants.cover
        ? AvailableImageVariants.contain
        : AvailableImageVariants.cover;
    setSelectedImageVariant(imageVariant);
  };

  const handleResourceIds = async (args: any) => {
    let attachmentIds: string[] = [];

    if (fetchAttachments) {
      const attachments = await fetchAttachments({
        formValues: args,
        attachmentType: AllowedAttachmentType.image,
        callback: (data: any) => {},
      });

      attachmentIds =
        attachments?.map((attachment: any) => attachment.id) ?? [];
    }
    return attachmentIds;
  };

  const handleOpenUploadAndEditModal = () => {
    toggleBulkEditorModal(true, !hasNoSelectedAttachments);
  };

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (searchAttachments) {
      await searchAttachments(searchTerm);
    }
  };

  return (
    <>
      <DeleteModal
        title="Delete Attachments"
        description="Are you sure you want to delete these attachments?"
        loading={loader}
        openModal={openModal}
        handleDelete={handleArchiveAttachments}
        handleCloseModal={() => setOpenModal(false)}
      />
      <MediaBulkEditorModal />
      <DownloadAttachmentModal />
      <MediaSliderModal attachments={attachments} />
      <div className="sticky top-0 z-30 w-full bg-white pt-6">
        <div className="flex w-full flex-row flex-wrap items-start justify-between pt-4 lg:pt-0">
          {Boolean(canCreateCollection) ? (
            <CollectionDropdown
              label={label}
              goBackLabel="All media"
              resourceIds={selectedAttachments}
              collectionType={AllowedCollectionType.Attachments}
              routes={{
                goBack: "/medien",
                collection: AppRoutes.AttachmentsCollection,
              }}
              getResourceIds={handleResourceIds}
              clearSelectedResources={clearSelectedAttachments}
            />
          ) : (
            <h3 className="amenda-form-heading">{t(label)}</h3>
          )}
          <div className="flex w-full justify-end md:w-auto">
            <ExpandableSearch
              className="mr-1"
              searchTerm={searchTerm}
              handleSearch={handleSearch}
            />
            {hasNoSelectedAttachments && (
              <Button onClick={toggleImageVariant} size="xs">
                <span className="sr-only">{t("Expand")}</span>
                {selectedImageVariant === AvailableImageVariants.contain ? (
                  <ArrowsPointingOutIcon className="h-5 w-5" />
                ) : (
                  <ArrowsPointingInIcon className="h-5 w-5" />
                )}
              </Button>
            )}
            {!hasNoSelectedAttachments && (
              <>
                {!isCollection && Boolean(canCreateCollection) && (
                  <CollectionButton
                    btnClassName="mr-2"
                    resourceIds={selectedAttachments}
                    collectionRoute={AppRoutes.AttachmentsCollection}
                    collectionType={AllowedCollectionType.Attachments}
                    getResourceIds={handleResourceIds}
                    clearSelectedResources={clearSelectedAttachments}
                  />
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Download] && (
                  <Tooltip
                    id="downloadAttachment"
                    message="Download attachments"
                  >
                    <Button
                      className="mr-2"
                      size="xs"
                      onClick={() => setOpenDownloadModal(true)}
                    >
                      <CloudDownloadIcon className="h-5 w-5" />
                    </Button>
                  </Tooltip>
                )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Move] &&
                  Boolean(canAssignAttachments) && (
                    <Tooltip id="moveToFolder" message="Assign to Project">
                      <Button
                        className="mr-2"
                        size="xs"
                        onClick={() => toggleMoveAttachmentsModal(true)}
                      >
                        <FolderArrowDownIcon className="h-5 w-5" />
                      </Button>
                    </Tooltip>
                  )}
                {mediaGeneralPermissions[GeneralPermissionTypes.Delete] && (
                  <Tooltip
                    id="delete"
                    message={isCollection ? "Remove from collection" : "Delete"}
                  >
                    <Button
                      className="mr-2"
                      size="xs"
                      variant={updateCollectionLoader ? "primary" : "default"}
                      onClick={handleDeleteProjects}
                    >
                      <TrashIcon className="h-5 w-5" />
                      {updateCollectionLoader && (
                        <Spinner className="border-white" spinnerSize="xs" />
                      )}
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
            {hasNoSelectedAttachments && (
              <Button size="xs" onClick={toggleGalleryView}>
                {selectedGalleryView === GalleryView.Grid ? (
                  <Squares2X2Icon className="h-5 w-5" />
                ) : (
                  <SheetIcon className="h-5 w-5" />
                )}
              </Button>
            )}
            {hasNoSelectedAttachments &&
              isCollection &&
              mediaGeneralPermissions[GeneralPermissionTypes.Download] && (
                <Tooltip id="downloadAttachment" message="Download attachments">
                  <Button
                    className="mr-2"
                    size="xs"
                    onClick={() => {
                      setSelectAllAttachments();
                      setOpenDownloadModal(true);
                    }}
                  >
                    <CloudDownloadIcon className="h-5 w-5" />
                  </Button>
                </Tooltip>
              )}
            {canSetSlider && (
              <Button
                size="xs"
                disabled={isEmpty(attachments)}
                onClick={handleMediaSlider}
              >
                <span className="sr-only">{t("Set Slider")}</span>
                <GalleryThumbnailsIcon className="h-5 w-5" />
              </Button>
            )}
            <Button
              className="mr-1"
              onClick={handleOpenFilterSidebar}
              variant={sidebarOpen ? "secondary" : "default"}
              size="xs"
              disabled={isEmpty(attachments)}
            >
              <span className="sr-only">{t("Filter")}</span>
              <FunnelIcon className="h-5 w-5" />
            </Button>
            {mediaGeneralPermissions[GeneralPermissionTypes.Create] &&
              Boolean(canUpload) && (
                <>
                  {hasNoSelectedAttachments ? (
                    <Button
                      size="xs"
                      variant="primary"
                      onClick={handleOpenUploadAndEditModal}
                    >
                      <span>{t("Add new attachments")}</span>
                      <PlusIcon className="ml-2 h-5 w-5" />
                    </Button>
                  ) : (
                    <Button
                      withGroup
                      className="mr-2"
                      size="xs"
                      variant="primary"
                      onClick={handleOpenUploadAndEditModal}
                    >
                      <span>{t("Edit attachment")}</span>
                      <PencilIcon className="ml-2 h-4 w-4" />
                    </Button>
                  )}
                </>
              )}
          </div>
        </div>
        <div className="flex h-6 w-full items-center space-x-3">
          <ResourceCounter
            currentCount={attachments.length}
            totalCount={filteredDocsCount ?? docsCount}
            hasSearch={
              !isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)
            }
          />
          {!hasNoSelectedAttachments && (
            <div className="flex flex-row items-center space-x-3">
              <Link className="group" onClick={clearSelectedAttachments}>
                <span className="amenda-component-label text-sm">
                  {t("Deselect All")} ({selectedAttachments.length.toString()})
                </span>
              </Link>
              <Link onClick={setSelectAllAttachments}>
                <span>{t("Select All")}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
