import { FC } from "react";
import { FormBuilderLayoutSortDroppable } from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { LayoutProps } from "./common";
import clsx from "clsx";

export const GridWrapper: FC<LayoutProps> = ({
  children,
  className = "gap-4",
  isFormBuilder,
  config,
}) => {
  return (
    <FormBuilderLayoutSortDroppable
      config={config}
      isFormBuilder={isFormBuilder}
      className={clsx("grid grid-cols-6", className, {
        "w-full": isFormBuilder,
      })}
    >
      {children}
    </FormBuilderLayoutSortDroppable>
  );
};
