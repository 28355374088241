import { Switch, TextArea, TextField } from "@amenda-components/FormComponents";

import { Controller } from "react-hook-form";
import { FC } from "react";
import { FullColumn } from "@amenda-components/PageLayouts";
import { useTranslation } from "react-i18next";

interface TenantSAMLFormProps {
  control: any;
  tenant: any;
}

export const TenantSAMLForm: FC<TenantSAMLFormProps> = ({
  control,
  tenant,
}) => {
  const { t } = useTranslation();

  return (
    <FullColumn>
      <div className="max-w-4xl">
        <div className="mb-8 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="amenda-settings-heading mt-2">
              {t("SAML Configuration")}
            </h1>
            <p className="amenda-settings-subline">
              {t("Configure your SAML authentication settings")}
            </p>
          </div>
        </div>

        <div className="space-y-8">
          {/* Basic Configuration */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-1">
              <Controller
                control={control}
                name="enabled"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Switch
                    label={t("Enable SAML")}
                    onChange={onChange}
                    checked={value}
                    error={error?.message}
                  />
                )}
              />
            </div>
            <div className="col-span-1">
              <Controller
                control={control}
                name="displayName"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="display-name"
                    placeholder="e.g., My SAML Provider"
                    label={t("Organization Display Name")}
                    onChange={onChange}
                    error={error?.message}
                    value={value}
                  />
                )}
              />
            </div>
          </div>

          {/* Domain Configuration */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <Controller
              control={control}
              name="webDomain"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="web-domain"
                  label={t("Organization Web Domain Name")}
                  placeholder="e.g., company-name.amenda.app"
                  onChange={onChange}
                  error={error?.message}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="emailDomain"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="email-domain"
                  placeholder="e.g., example.com"
                  label={t("Organization Email Domain Name")}
                  onChange={onChange}
                  error={error?.message}
                  value={value}
                />
              )}
            />
          </div>

          {/* Provider Configuration */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <Controller
              control={control}
              name="providerId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="provider-id"
                  label={t("Provider ID")}
                  placeholder="e.g., saml.myProvider"
                  onChange={onChange}
                  error={error?.message}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="idpEntityId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="idp-entity-id"
                  label={t("IDP Entity ID")}
                  placeholder="e.g., IDP_ENTITY_ID"
                  onChange={onChange}
                  error={error?.message}
                  value={value}
                />
              )}
            />
          </div>

          {/* SSO Configuration */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <Controller
              control={control}
              name="ssoURL"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="sso-url"
                  label={t("SSO URL")}
                  placeholder="e.g., https://example.com/saml/sso/1234/"
                  onChange={onChange}
                  error={error?.message}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="callbackURL"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="callback-url"
                  label={t("Callback URL")}
                  onChange={onChange}
                  error={error?.message}
                  value={value}
                />
              )}
            />
          </div>

          {/* Certificate and RP Configuration */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <Controller
              control={control}
              name="x509Certificates"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextArea
                  id="x509-certificates"
                  label={t("X.509 Certificates")}
                  onChange={(e) => onChange([e.target.value])}
                  error={error?.message}
                  value={value?.[0] || ""}
                  rows={4}
                />
              )}
            />
            <Controller
              control={control}
              name="rpEntityId"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="rp-entity-id"
                  label={t("RP Entity ID")}
                  onChange={onChange}
                  placeholder="e.g., RP_ENTITY_ID"
                  error={error?.message}
                  value={value}
                />
              )}
            />
          </div>
        </div>
      </div>
    </FullColumn>
  );
};
