import { FC, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import { AddToCollection } from "./AddToCollection";
import { CollectionProps } from "./common";
import { CreateCollectionModal } from "./CreateCollectionModal";
import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@amenda-components/App";
import { buttonTheme } from "@amenda-styles/theme";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props extends CollectionProps {
  label?: string;
  btnClassName?: string;
  collectionRoute: string;
}

export const CollectionButton: FC<Props> = ({
  label,
  btnClassName,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <div className="flex">
      <CreateCollectionModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        {...rest}
      />
      <AddToCollection
        isOpen={isEditOpen}
        handleClose={() => setIsEditOpen(false)}
        {...rest}
      />
      <Menu>
        {({ open }) => (
          <>
            <Tooltip
              id="collection-btn"
              position="bottom"
              message={label ? undefined : "Sort into Collection"}
            >
              <MenuButton
                className={buttonTheme({
                  variant: "default",
                  size: "xs",
                  className: clsx("flex items-center", btnClassName),
                })}
              >
                <RectangleStackIcon className="h-5 w-5" />
                {label && <span className="ml-1">{t(label)}</span>}
              </MenuButton>
            </Tooltip>
            <MenuItems
              className="z-50 w-fit divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              anchor="bottom start"
            >
              <MenuItem>
                {({ focus }) => (
                  <button
                    onClick={() => setIsOpen(true)}
                    className={clsx(
                      "group flex w-full items-center truncate px-2 py-2 text-sm",
                      {
                        "bg-white text-gray-800": !focus,
                        "bg-gray-900 text-white": focus,
                      },
                    )}
                  >
                    {t("New collection")}
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <button
                    onClick={() => setIsEditOpen(true)}
                    className={clsx(
                      "group flex w-full items-center truncate px-2 py-2 text-sm",
                      {
                        "bg-white text-gray-800": !focus,
                        "bg-gray-900 text-white": focus,
                      },
                    )}
                  >
                    {t("Existing collection")}
                  </button>
                )}
              </MenuItem>
            </MenuItems>
          </>
        )}
      </Menu>
    </div>
  );
};
