import { FC } from "react";
import { FormBuilderComponentSort } from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import { FormBuilderFormViewComponentActionBar } from "@amenda-components/Settings/FormBuilder/FormBuilderFormViewComponentActionBar";
import { LayoutProps } from "./common";

export const HalfColumn: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  config,
  globalProps,
}) => {
  return (
    <FormBuilderComponentSort
      className="group/half-col col-span-6 md:col-span-3"
      config={config}
      isFormBuilder={isFormBuilder}
    >
      {isFormBuilder && (
        <FormBuilderFormViewComponentActionBar
          config={config}
          globalProps={globalProps}
        />
      )}
      {children}
    </FormBuilderComponentSort>
  );
};
