import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Button, IconButtonBase, Modal } from "@amenda-components/App";
import { Control, Controller, useController, useForm } from "react-hook-form";
import { FC, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  isDragValid,
} from "./FormBuilderDndComponents";
import {
  FormBuilderNestedMenu,
  TextField,
} from "@amenda-components/FormComponents";
import {
  GripVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "lucide-react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  addOption,
  getAvailableOptions,
  getFlattenedOptions,
  getOptionsTree,
  getSearchFlatOptions,
  getUpdatedAvailableOptions,
  removeOption,
  separateOptionFromParent,
  updateOption,
} from "@amenda-components/FormComponents/common";

import { MiniSearchField } from "@amenda-components/SearchComponents";
import { Option } from "@amenda-types";
import { createPortal } from "react-dom";
import { inputFieldTheme } from "@amenda-styles/theme";
import isNil from "lodash/isNil";
import { optionFormSchema } from "@amenda-constants";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface OptionModalProps {
  isOpen: boolean;
  initialData?: { label: string };
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (data: { label: string }) => void;
}

const OptionModal: FC<OptionModalProps> = ({
  isOpen,
  initialData,
  onClose,
  onDelete,
  onSubmit,
}) => {
  const defaultData = { label: "" };
  const { t } = useTranslation();
  const { handleSubmit, reset, control } = useForm({
    values: initialData || defaultData,
    resolver: yupResolver(optionFormSchema),
  });

  const onSubmitForm = (data: { label: string }) => {
    onSubmit(data);
    reset(defaultData);
    onClose();
  };

  const handleDelete = () => {
    onDelete();
    reset(defaultData);
    onClose();
  };

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={() => {
        reset(defaultData);
        onClose();
      }}
      withCancel={false}
      closeModalFromTitle={true}
      title={initialData ? t("Edit option") : t("Add option")}
    >
      <div className="space-y-4">
        <Controller
          name="label"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              id="label"
              label="Label"
              value={field.value}
              onChange={field.onChange}
              error={error?.message}
            />
          )}
        />
        <div className="space-y-1 pt-6">
          <Button
            className="flex w-full justify-center"
            variant="primary"
            onClick={() => handleSubmit(onSubmitForm)()}
          >
            {initialData ? t("Edit") : t("Save")}
          </Button>
          {initialData && (
            <Button
              className="w-full items-center justify-center space-x-1 bg-white text-red-600"
              onClick={handleDelete}
            >
              <TrashIcon className="h-4 w-4" />
              <span>{t("Delete")}</span>
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  id: string;
  component: any;
  control: Control<any>;
}

export const FormBuilderNestedOptions: FC<Props> = ({
  id,
  control,
  component,
}) => {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedOption, setExpandedOption] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const {
    field: { value: formOptions, onChange: setFormOptions },
  } = useController({
    name: id,
    control,
    defaultValue: component?.properties?.options ?? [],
  });
  const [availableOptions, setAvailableOptions] = useState<any[]>(formOptions);

  const { searchInputCss } = inputFieldTheme();
  const items = availableOptions.map((o) => o.value);
  const optionsTree = getOptionsTree(formOptions);
  const allOptions = getFlattenedOptions(formOptions);
  const activeField: any = availableOptions.find((o) => o.value === activeId);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);

  const handleClearSearch = () => {
    setSearchTerm("");
    setAvailableOptions(formOptions);
    setExpandedOption({});
  };

  const handleOpenEditModal = (option: Option) => {
    setSelectedOption(option);
    setIsEditOpen(true);
  };

  const handleOpenAddModal = (option: Option | null) => {
    setSelectedOption(option);
    setIsAddOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedOption(null);
    setIsEditOpen(false);
  };

  const handleCloseAddModal = () => {
    setSelectedOption(null);
    setIsAddOpen(false);
  };

  const handleAddOption = ({ label }: { label: string }) => {
    const { updatedOptions, updatedExpandedOption } = addOption({
      optionsTree,
      expandedOption,
      options: formOptions,
      label,
      option: selectedOption,
    });
    const availableOptions = getUpdatedAvailableOptions(
      updatedOptions,
      updatedExpandedOption,
    );

    setFormOptions(updatedOptions);
    setExpandedOption(updatedExpandedOption);
    setAvailableOptions(availableOptions);
    handleCloseAddModal();
  };

  const handleEditOption = ({ label }: { label: string }) => {
    const { updatedOptions, updatedExpandedOption } = updateOption({
      optionsTree,
      expandedOption,
      options: formOptions,
      option: {
        ...selectedOption!,
        label,
      },
    });
    const availableOptions = getUpdatedAvailableOptions(
      updatedOptions,
      updatedExpandedOption,
    );

    setFormOptions(updatedOptions);
    setExpandedOption(updatedExpandedOption);
    setAvailableOptions(availableOptions);
    handleCloseEditModal();
  };

  const handleDeleteOption = () => {
    const updatedOptions = removeOption({
      optionsTree,
      options: formOptions,
      option: selectedOption!,
    });
    const updatedExpandedOption = {
      ...expandedOption,
    };
    delete updatedExpandedOption[selectedOption!.value];
    const availableOptions = getUpdatedAvailableOptions(
      updatedOptions,
      updatedExpandedOption,
    );

    setFormOptions(updatedOptions);
    setExpandedOption(updatedExpandedOption);
    setAvailableOptions(availableOptions);
    handleCloseEditModal();
  };

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (searchTerm.length > 0) {
      const optionIds = getSearchFlatOptions(allOptions, searchTerm).map(
        (v) => v.value,
      );
      const { availableOptions, expandedOptions } = getAvailableOptions(
        optionsTree,
        optionIds,
      );

      setAvailableOptions(availableOptions);
      setExpandedOption(expandedOptions);
    } else {
      setAvailableOptions(formOptions);
      setExpandedOption({});
    }
  };

  const sortParents = (active: Active, over: Over) => {
    const fieldIds: string[] = formOptions.map((f: any) => f.value);
    const oldIndex = fieldIds.indexOf(active.id as string);
    const newIndex = fieldIds.indexOf(over.id as string);
    const updatedFieldIds = arrayMove(fieldIds, oldIndex, newIndex);
    const updatedOptions = updatedFieldIds.map((id) => {
      return optionsTree[id].ogOption;
    });
    const availableOptions = getUpdatedAvailableOptions(
      updatedOptions,
      expandedOption,
    );

    setFormOptions(updatedOptions);
    setAvailableOptions(availableOptions);
  };

  const sortChildrenInSameParents = (active: Active, over: Over) => {
    const activeOption = optionsTree[active.id as string];
    const parentOption = optionsTree[activeOption.parentId!];
    const childrenIds = parentOption.childrenIds!;
    const oldIndex = childrenIds.indexOf(active.id as string);
    const newIndex = childrenIds.indexOf(over.id as string);
    const updatedChildrenIds = arrayMove(childrenIds, oldIndex, newIndex);
    const updatedChildren = updatedChildrenIds.map((id) => {
      return optionsTree[id].ogOption;
    });
    const { updatedOptions, updatedExpandedOption } = updateOption({
      optionsTree,
      expandedOption,
      options: formOptions,
      option: {
        ...parentOption.ogOption,
        children: updatedChildren,
      },
    });
    const availableOptions = getUpdatedAvailableOptions(
      updatedOptions,
      updatedExpandedOption,
    );

    setFormOptions(updatedOptions);
    setExpandedOption(updatedExpandedOption);
    setAvailableOptions(availableOptions);
  };

  const moveChildToParentPosition = (active: Active, over: Over) => {
    const activeOption = optionsTree[active.id as string];
    let options = removeOption({
      optionsTree,
      options: formOptions,
      option: activeOption.ogOption,
    });
    const updatedOptionsTree = separateOptionFromParent(
      options,
      activeOption.ogOption,
    );
    const overOption = updatedOptionsTree[over.id as string];
    let updatedExpandedOption = expandedOption;

    if (!overOption.parentId) {
      const fieldIds: string[] = [...options, activeOption.ogOption].map(
        (f: any) => f.value,
      );
      const oldIndex = fieldIds.indexOf(active.id as string);
      const newIndex = fieldIds.indexOf(over.id as string);
      const updatedFieldIds = arrayMove(fieldIds, oldIndex, newIndex);

      options = updatedFieldIds.map((id) => updatedOptionsTree[id].ogOption);
    } else {
      const childrenIds = [
        ...overOption.childrenIds!,
        activeOption.ogOption.value,
      ];
      const oldIndex = childrenIds.indexOf(active.id as string);
      const newIndex = childrenIds.indexOf(over.id as string);
      const updatedChildrenIds = arrayMove(childrenIds, oldIndex, newIndex);
      const updatedChildren = updatedChildrenIds.map((id) => {
        return updatedOptionsTree[id].ogOption;
      });
      const results = updateOption({
        options,
        expandedOption,
        optionsTree: updatedOptionsTree,
        option: {
          ...overOption.ogOption,
          children: updatedChildren,
        },
      });
      options = results.updatedOptions;
      updatedExpandedOption = results.updatedExpandedOption;
    }
    const availableOptions = getUpdatedAvailableOptions(
      options,
      updatedExpandedOption,
    );

    setFormOptions(options);
    setExpandedOption(updatedExpandedOption);
    setAvailableOptions(availableOptions);
  };

  const moveChildToDiffParent = (active: Active, over: Over) => {
    const activeOption = optionsTree[active.id as string];
    let options = removeOption({
      optionsTree,
      options: formOptions,
      option: activeOption.ogOption,
    });
    const updatedOptionsTree = separateOptionFromParent(
      options,
      activeOption.ogOption,
    );
    const parentId = updatedOptionsTree[over.id as string].parentId;
    const overOptionParent = updatedOptionsTree[parentId!];
    const childrenIds = [
      ...overOptionParent.childrenIds!,
      activeOption.ogOption.value,
    ];
    const oldIndex = childrenIds.indexOf(active.id as string);
    const newIndex = childrenIds.indexOf(over.id as string);
    const updatedChildrenIds = arrayMove(childrenIds, oldIndex, newIndex);
    const updatedChildren = updatedChildrenIds.map((id) => {
      return updatedOptionsTree[id].ogOption;
    });
    const { updatedOptions, updatedExpandedOption } = updateOption({
      options,
      expandedOption,
      optionsTree: updatedOptionsTree,
      option: {
        ...overOptionParent.ogOption,
        children: updatedChildren,
      },
    });
    const availableOptions = getUpdatedAvailableOptions(
      updatedOptions,
      updatedExpandedOption,
    );

    setFormOptions(updatedOptions);
    setExpandedOption(updatedExpandedOption);
    setAvailableOptions(availableOptions);
  };

  const moveParentToChildPosition = (active: Active, over: Over) => {
    const activeOption = optionsTree[active.id as string];
    let options = removeOption({
      optionsTree,
      options: formOptions,
      option: activeOption.ogOption,
    });
    const updatedOptionsTree = separateOptionFromParent(
      options,
      activeOption.ogOption,
    );
    const overOption = updatedOptionsTree[over.id as string];
    let result;

    if (overOption.childrenIds) {
      const childrenIds = [
        ...overOption.childrenIds!,
        activeOption.ogOption.value,
      ];
      const oldIndex = childrenIds.indexOf(active.id as string);
      const newIndex = childrenIds.indexOf(over.id as string);
      const updatedChildrenIds = arrayMove(childrenIds, oldIndex, newIndex);
      const updatedChildren = updatedChildrenIds.map((id) => {
        return updatedOptionsTree[id].ogOption;
      });
      result = updateOption({
        options,
        expandedOption,
        optionsTree: updatedOptionsTree,
        option: {
          ...overOption.ogOption,
          children: updatedChildren,
        },
      });
    } else {
      const overOptionParent = updatedOptionsTree[overOption.parentId!];
      const childrenIds = [
        ...overOptionParent.childrenIds!,
        activeOption.ogOption.value,
      ];
      const oldIndex = childrenIds.indexOf(active.id as string);
      const newIndex = childrenIds.indexOf(over.id as string);
      const updatedChildrenIds = arrayMove(childrenIds, oldIndex, newIndex);
      const updatedChildren = updatedChildrenIds.map((id) => {
        return updatedOptionsTree[id].ogOption;
      });
      result = updateOption({
        options,
        expandedOption,
        optionsTree: updatedOptionsTree,
        option: {
          ...overOptionParent.ogOption,
          children: updatedChildren,
        },
      });
    }

    const { updatedOptions, updatedExpandedOption } = result;
    const availableOptions = getUpdatedAvailableOptions(
      updatedOptions,
      updatedExpandedOption,
    );

    setFormOptions(updatedOptions);
    setExpandedOption(updatedExpandedOption);
    setAvailableOptions(availableOptions);
  };

  const handleDragEnd = (active: Active, over: Over) => {
    const activeOption = optionsTree[active.id as string];
    const overOption = optionsTree[over.id as string];

    if (isNil(activeOption.parentId) && !isNil(overOption.parentId)) {
      return moveParentToChildPosition(active, over);
    }
    if (isNil(activeOption.parentId) && isNil(overOption.parentId)) {
      return sortParents(active, over);
    }
    if (!isNil(activeOption.parentId) && isNil(overOption.parentId)) {
      return moveChildToParentPosition(active, over);
    }
    if (activeOption.parentId === overOption.parentId) {
      return sortChildrenInSameParents(active, over);
    }
    return moveChildToDiffParent(active, over);
  };

  return (
    <div
      className="w-full"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <OptionModal
        isOpen={isEditOpen || isAddOpen}
        initialData={
          selectedOption && isEditOpen
            ? { label: selectedOption.label }
            : undefined
        }
        onClose={isEditOpen ? handleCloseEditModal : handleCloseAddModal}
        onDelete={handleDeleteOption}
        onSubmit={isEditOpen ? handleEditOption : handleAddOption}
      />
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragStart={(event) => {
          const { active } = event;

          setActiveId(String(active.id));
        }}
        onDragEnd={(event) => {
          const { active, over } = event;
          setActiveId(null);
          if (over && isDragValid(active, over)) {
            handleDragEnd(active, over);
          }
        }}
      >
        <SortableContext
          id="formBuilderNestedOptions"
          items={items}
          strategy={verticalListSortingStrategy}
        >
          <div className="relative w-full bg-white">
            <MiniSearchField
              className={searchInputCss({
                size: "sm",
                class: "mb-2 w-full border-gray-100 bg-gray-50",
              })}
              placeholder={t("Search options") + "..."}
              value={searchTerm}
              onChange={handleSearch}
              onClear={handleClearSearch}
            />
            <FormBuilderNestedMenu
              options={formOptions}
              expandedOption={expandedOption}
              availableOptions={availableOptions}
              setAvailableOptions={setAvailableOptions}
              setExpandedOption={setExpandedOption}
              setSearchTerm={setSearchTerm}
            >
              {({ option }) => (
                <div className="flex w-full items-center truncate">
                  <span className="truncate">{option.label}</span>
                  <div className="invisible ml-auto flex items-center space-x-1 group-hover:visible">
                    <IconButtonBase
                      size="xss"
                      variant="clean"
                      onClick={() => handleOpenAddModal(option)}
                    >
                      <PlusIcon className="h-4 w-4" />
                    </IconButtonBase>
                    <IconButtonBase
                      size="xss"
                      variant="clean"
                      onClick={() => handleOpenEditModal(option)}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </IconButtonBase>
                  </div>
                </div>
              )}
            </FormBuilderNestedMenu>
          </div>
        </SortableContext>
        <>
          {createPortal(
            <DragOverlay modifiers={[restrictToVerticalAxis]}>
              {activeField && (
                <FormBuilderDragOverlayItem className="w-full cursor-pointer bg-gray-800 px-2 text-sm text-white shadow-lg outline-none">
                  <div className="flex w-full items-center">
                    <IconButtonBase size="xss" className="cursor-grab">
                      <GripVerticalIcon className="h-4 w-4" />
                    </IconButtonBase>
                    <span className="ml-2 leading-none">
                      {activeField.label}
                    </span>
                  </div>
                </FormBuilderDragOverlayItem>
              )}
            </DragOverlay>,
            document.body,
          )}
        </>
      </DndContext>
      <div className="mt-2">
        <Button
          size="sm"
          variant="outline"
          className="flex w-full items-center justify-center space-x-1"
          onClick={() => handleOpenAddModal(null)}
        >
          <span>{t("Add option")}</span>
          <PlusIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};
