import { AuthLayout } from "@amenda-components/Auth";
import { SAMLLoginForm } from "@amenda-components/Auth/SAMLLoginForm";
import { FC } from "react";

export const SAMLLoginPage: FC = () => {
  return (
    <AuthLayout label={"SAML Sign in"}>
      <SAMLLoginForm />
    </AuthLayout>
  );
};
