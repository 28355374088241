import { FormBuilderComponent, availableLayouts } from "@amenda-types";

import { generateReadableId } from "@amenda-utils";

export const getFormViewComponents = (
  projectComponents: any[],
  sectionComponents: any[] = [],
) => {
  return sectionComponents
    .map((c) => {
      const { label, description, id, ...rest } = c;

      if (
        [
          availableLayouts.rootContainer,
          availableLayouts.fullColumnContainer,
          availableLayouts.twoThirdsColumnContainer,
        ].includes(rest.layout)
      ) {
        return {
          id,
          ...rest,
          properties: {
            label,
            description,
          },
        };
      }
      const component = projectComponents.find((pc) => pc.id === id);

      if (component) {
        return {
          ...component,
          fromSidebar: rest.fromSidebar,
          order: rest.order,
          layout: rest.layout,
          parentId: rest.parentId,
        };
      }
      return null;
    })
    .filter(Boolean);
};

export const getTemplateComponents = (
  formName?: string,
): FormBuilderComponent[] => {
  const initialComponentId = generateReadableId("Initial Component");

  return [
    {
      componentId: initialComponentId,
      parentId: null,
      order: 0,
      layout: availableLayouts.rootContainer,
      properties: { label: formName ?? "Initial Info" },
    },
  ];
};
