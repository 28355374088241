import { AllowedTimelineTypes, FormTab, TabItem } from "@amenda-types";
import { FC, ReactNode, useMemo } from "react";
import {
  ReadOnlyProjectFormWrapper,
  ReadOnlyProjectMedia,
} from "@amenda-components/Projects";
import { getStaticProjectTabs, staticProjectLabels } from "./common";
import { isEmpty, isNil } from "lodash";
import { useLocation, useParams } from "react-router-dom";

import { ReadOnlyProjectConstructionDetails } from "./ReadOnlyProjectConstructionDetails";
import { ReadOnlyProjectCostGroups } from "@amenda-components/CostGroup/ReadOnlyProjectCostGroups";
import { ReadOnlyProjectTemplateDesigner } from "@amenda-components/Projects";
import { Timeline } from "@amenda-components/Timeline";
import { getFormViewComponents } from "@amenda-constants/template";
import { getQueryParams } from "@amenda-utils";
import { useProjectStore } from "@amenda-domains/mutations";

const showCurrentTab = ({
  tab,
  projectId,
  availableForms,
}: {
  tab: TabItem;
  availableForms: FormTab[];
  projectId: string;
}) => {
  const tabComponents: Record<string, ReactNode> = {
    [staticProjectLabels.constructionDetails.value]: (
      <ReadOnlyProjectConstructionDetails projectId={projectId} />
    ),
    [staticProjectLabels.attachments.value]: (
      <ReadOnlyProjectMedia projectId={projectId} />
    ),
    [staticProjectLabels.designs.value]: (
      <ReadOnlyProjectTemplateDesigner projectId={projectId} />
    ),
    [staticProjectLabels.costGroups.value]: (
      <ReadOnlyProjectCostGroups projectId={projectId} />
    ),
    [staticProjectLabels.activity.value]: (
      <Timeline type={AllowedTimelineTypes.Project} resourceId={projectId} />
    ),
  };

  availableForms?.forEach((form) => {
    tabComponents[form.id] = (
      <ReadOnlyProjectFormWrapper components={form.components} />
    );
  });
  return tabComponents[tab.value];
};

interface Props {
  availableForms: FormTab[];
  projectsGeneralPermissions: any;
}

export const ReadOnlyProjectTabs: FC<Props> = ({
  availableForms,
  projectsGeneralPermissions,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { search } = useLocation();
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const projectFormComponents = useProjectStore(
    (state) => state.projectFormComponents,
  );

  const projectTabs = useMemo(() => {
    const tabs: TabItem[] =
      availableForms?.map((form) => ({
        label: form.name,
        value: form.id,
      })) || [];

    tabs.push(...getStaticProjectTabs(projectsGeneralPermissions));

    return tabs;
  }, [availableForms, projectsGeneralPermissions]);

  const formViewTabs = useMemo(() => {
    const tabs: TabItem[] =
      selectedFormView?.sections?.map((s: any) => ({
        value: s.id,
        label: s.label,
      })) || [];

    return tabs;
  }, [selectedFormView]);

  const formViewForms = (selectedFormView?.sections ?? []).map((s: any) => ({
    id: s.id,
    name: s.label,
    order: s.order,
    components: getFormViewComponents(projectFormComponents, s.components),
  }));
  const availableTabs = isEmpty(selectedFormView) ? projectTabs : formViewTabs;
  const forms = isEmpty(selectedFormView) ? availableForms : formViewForms;
  const foundIndex = availableTabs.findIndex(
    (tab) => tab.value === getQueryParams(search)?.tab,
  );
  const defaultTabIndex = foundIndex >= 0 ? foundIndex : 0;

  if (isEmpty(forms) || isNil(projectId)) {
    return null;
  }
  return (
    <div className="pt-4">
      {showCurrentTab({
        projectId,
        availableForms: forms,
        tab: availableTabs[defaultTabIndex],
      })}
    </div>
  );
};
