import { Button, Modal } from "@amenda-components/App";
import { devConsole, getComponentsFromForms } from "@amenda-utils";
import { useAppStore, useUpdateForm } from "@amenda-domains/mutations";

import { FC } from "react";
import { FormBuilderConditionsForm } from "./FormBuilderDisplayConditions";
import { getConditionComponents } from "../common";
import { useForm } from "react-hook-form";
import { useGetFormsByCategory } from "../hooks";
import { useTranslation } from "react-i18next";

export const FormBuilderFormDisplayConditions: FC = () => {
  const { t } = useTranslation();
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    setValue,
    reset,
  } = useForm<any>({
    values: {
      displayConditions: selectedForm?.properties?.display ?? [],
    },
  });
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const openFormDisplayConditions = useAppStore(
    (state) => state.formBuilderState?.openFormDisplayConditions,
  );

  const { availableForms } = useGetFormsByCategory();
  const { updateForm, loading } = useUpdateForm();

  const availableComponents = getComponentsFromForms(availableForms);
  const components = getConditionComponents(availableComponents);

  const handleClose = () => {
    updateFormBuilderState("openFormDisplayConditions", false);
  };

  const onSubmit = async (data: any) => {
    if (isDirty && selectedForm) {
      await updateForm({
        id: selectedForm.id,
        properties: {
          ...(selectedForm?.properties ?? {}),
          display: data.displayConditions,
        },
      });
    }
    reset({
      displayConditions: [],
    });
    updateFormBuilderState("openFormDisplayConditions", false);
  };

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:project form error gat", errors);
  };

  return (
    <Modal
      size="md"
      isOpen={Boolean(openFormDisplayConditions)}
      onClose={handleClose}
      withCancel={false}
      closeModalFromTitle={true}
      title="Display conditions"
    >
      <div className="max-h-[80vh] w-full overflow-y-auto overscroll-y-contain">
        <FormBuilderConditionsForm
          control={control}
          setValue={setValue}
          components={components}
        />
        <div className="pt-6">
          <Button
            loading={loading}
            className="flex w-full justify-center"
            variant="primary"
            onClick={() => handleSubmit(onSubmit, onError)()}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
