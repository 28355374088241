import { FC, ReactNode, useEffect } from "react";
import {
  useGetAllForms,
  useGetCurrentTenant,
  useGetCurrentUser,
} from "@amenda-domains/queries";
import {
  useSettingsStore,
  useTenantStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { LoaderWrapper } from "@amenda-components/App";
import { devConsole } from "@amenda-utils";
import { useGetSystemRole } from "@amenda-domains/queries/settings";

interface Props {
  children: ReactNode;
}

export const FormsEarlyLoader: FC<Props> = ({ children }) => {
  const { getSystemRole } = useGetSystemRole();
  const { loading, getAllForms } = useGetAllForms();
  const setCurrentUserSystemRole = useSettingsStore(
    (state) => state.setCurrentUserSystemRole,
  );
  const { getCurrentUser } = useGetCurrentUser();
  const { getCurrentTenant } = useGetCurrentTenant();
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    getCurrentTenant();
  }, [getCurrentTenant]);

  useEffect(() => {
    if (currentUser.id) {
      getSystemRole({
        usersIds: [currentUser.id],
        callback: setCurrentUserSystemRole,
      });
    }
  }, [
    currentUser.email,
    currentUser.firstName,
    currentUser.id,
    currentUser.lastName,
    currentUser.tenantId,
    getSystemRole,
    primaryTenant.displayName,
    primaryTenant.logoUrl,
    primaryTenant.tenantId,
    setCurrentUserSystemRole,
  ]);

  useEffect(() => {
    devConsole?.info("amenda:FormsEarlyLoader: fetching forms");
    getAllForms();
  }, [getAllForms]);

  if (loading) return <LoaderWrapper />;
  return <>{children}</>;
};
