import {
  Button,
  HelperMessage,
  IconButtonBase,
  LoaderWrapper,
  Tooltip,
} from "@amenda-components/App";
import { ChevronsUpDownIcon, PencilIcon } from "lucide-react";
import { FC, MouseEvent, useEffect, useState } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  ResourceSharingPermissionTypes,
  checkCollectionPermissions,
  searchInCollections,
} from "@amenda-components/Shared/common";
import { buttonTheme, inputFieldTheme } from "@amenda-styles/theme";
import {
  useAppStore,
  useProjectStore,
  useUsersStore,
} from "@amenda-domains/mutations";

import { MiniSearchField } from "@amenda-components/SearchComponents";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { isEmpty } from "lodash";
import { useGetAllFormViews } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface FormViewsWrapperProps {
  close: () => void;
}

const FormViewsWrapper: FC<FormViewsWrapperProps> = ({ close }) => {
  const { t } = useTranslation();
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const [searchTerm, setSearchTerm] = useState("");
  const formViews = useProjectStore((state) => state.formViews);
  const { getAllFormViews, loading } = useGetAllFormViews();

  const { searchInputCss } = inputFieldTheme();
  const availableFormViews = searchInCollections(formViews, searchTerm);
  const setSelectedFormView = useProjectStore(
    (state) => state.setSelectedFormView,
  );

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleSelect = (onClose: () => void, formView: Record<string, any>) => {
    setSelectedFormView(formView);
    onClose();
  };

  useEffect(() => {
    getAllFormViews({
      isDeleted: false,
    });
  }, [getAllFormViews]);

  return (
    <div className="max-h-80 min-h-[10rem] overflow-auto">
      <div className="border-1 sticky bottom-0 bg-white text-xs text-gray-500">
        {(!isEmpty(formViews) || Boolean(searchTerm)) && (
          <MiniSearchField
            value={searchTerm}
            className={searchInputCss({
              size: "sm",
              class: "w-full border-gray-100 bg-gray-50",
            })}
            placeholder={t("Browse views") + "..."}
            onChange={handleSearch}
          />
        )}
      </div>
      <div className="my-2 flex flex-col space-y-1">
        {loading ? (
          <LoaderWrapper className="h-24" spinnerSize="sm" />
        ) : (
          <>
            {isEmpty(availableFormViews) ? (
              <div className="pt-6">
                <HelperMessage
                  className="h-24"
                  iconClassName="h-8 w-8 text-gray-800"
                  message="No views found. Create a new one and share with your team."
                />
              </div>
            ) : (
              <>
                {selectedFormView?.id && (
                  <button
                    className={buttonTheme({
                      size: "sm",
                      variant: "default",
                      className: "rounded-sm",
                    })}
                    onClick={() => {
                      handleSelect(close, {});
                    }}
                  >
                    <span>{t("Default view")}</span>
                  </button>
                )}
                {availableFormViews.map((formView) => (
                  <button
                    key={formView.id}
                    className={buttonTheme({
                      size: "sm",
                      className: "rounded-sm",
                      variant:
                        selectedFormView?.id === formView?.id
                          ? "primary"
                          : "default",
                    })}
                    onClick={() => handleSelect(close, formView)}
                  >
                    <span>{formView.name}</span>
                  </button>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const ProjectFormViews: FC = () => {
  const { t } = useTranslation();
  const selectedFormView = useProjectStore((state) => state.selectedFormView);
  const setSelectedFormView = useProjectStore(
    (state) => state.setSelectedFormView,
  );
  const setFormViewStatus = useProjectStore((state) => state.setFormViewStatus);

  const currentUser = useUsersStore((state) => state.currentUser);
  const updateFormBuilderFormViewForm = useAppStore(
    (state) => state.updateFormBuilderFormViewForm,
  );

  const handleClick = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();

    const section = selectedFormView?.sections?.[0];

    setFormViewStatus("edit");
    if (section) {
      updateFormBuilderFormViewForm(section);
    }
  };

  const handleCreate = (onClose: () => void) => {
    setSelectedFormView({});
    setFormViewStatus("create");
    onClose();
  };

  return (
    <div className="absolute bottom-0 left-0 w-full bg-white pb-2">
      {selectedFormView?.description && (
        <div className="px-4 py-2 text-base text-gray-900">
          {selectedFormView.description}
        </div>
      )}
      <Popover className="w-full">
        <PopoverButton
          as="div"
          className={buttonTheme({
            size: "lg",
            variant: "default",
            className:
              "flex w-full cursor-pointer items-center justify-between !bg-white !py-2 text-gray-500 hover:!text-gray-900 focus:outline-none",
          })}
        >
          <div className="flex items-center space-x-3">
            <span>{selectedFormView?.name ?? t("Views")}</span>
            <ChevronsUpDownIcon className="h-4 w-4" />
          </div>
          <div className="ml-auto flex items-center">
            {selectedFormView?.name &&
            (checkCollectionPermissions({
              currentUser,
              collection: selectedFormView,
              permission: ResourceSharingPermissionTypes.Admin,
            }) ||
              checkCollectionPermissions({
                currentUser,
                collection: selectedFormView,
                permission: ResourceSharingPermissionTypes.Edit,
              })) ? (
              <Tooltip id="editFormView" message="Edit Form View">
                <IconButtonBase
                  size="xs"
                  label="Edit Form View"
                  variant="default"
                  onClick={handleClick}
                >
                  <PencilIcon className="h-4 w-4" />
                </IconButtonBase>
              </Tooltip>
            ) : (
              <div className="h-9 w-9" />
            )}
          </div>
        </PopoverButton>
        <PopoverPanel
          transition
          anchor="bottom"
          className="z-50 w-64 rounded-sm bg-white shadow-md transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
        >
          {({ close }) => (
            <div className="w-full px-2 py-2">
              <FormViewsWrapper close={close} />
              <div className="border-t border-gray-100 py-1" />
              <Button
                variant="default"
                className="flex w-full justify-center"
                onClick={() => handleCreate(close)}
              >
                <span className="pr-2 pt-1">{t("Create new view")}</span>
                <PlusCircleIcon className="h-6 w-6" />
              </Button>
            </div>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};
