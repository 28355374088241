import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Button, Modal } from "@amenda-components/App";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { FC, useEffect, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
  isDragValid,
} from "./FormBuilderDndComponents";
import { FormComponentTypes, optionFormSchema } from "@amenda-constants";
import {
  GripVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "lucide-react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { TextArea, TextField } from "@amenda-components/FormComponents";

import { FormBuilderNestedOptions } from "./FormBuilderNestedOptions";
import { IconButtonBase } from "@amenda-components/App";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { snakeCase } from "lodash";
import { useForm } from "react-hook-form";
import { useGetKeywords } from "@amenda-domains/queries/app";
import { useTranslation } from "react-i18next";
import { useUpsertKeywords } from "@amenda-domains/mutations";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  id: string;
  control: Control<any>;
  component: any;
  isLoading: boolean;
  keywords: any[];
  updateKeywordsState: (keyword: any, action?: "update" | "delete") => void;
}

type OptionsWrapperProps = Pick<Props, "id" | "control"> & {
  hasDescriptions?: boolean;
  label?: string;
};

interface DropdownItemProps {
  label: string;
  description?: string;
  hasDescriptions?: boolean;
  isDraggable?: boolean;
  isDragging?: boolean;
  level?: number;
  onEdit?: () => void;
}

const DropdownItem: FC<DropdownItemProps> = ({
  isDraggable,
  isDragging,
  description,
  label,
  level = 0,
  hasDescriptions,
  onEdit,
}) => {
  return (
    <div
      className={clsx(
        "group/option flex w-full cursor-pointer items-center text-sm outline-none",
        {
          invisible: isDragging,
        },
      )}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col space-y-0.5 truncate py-0.5">
          <div className="flex w-full items-center">
            {isDraggable && (
              <div>
                <IconButtonBase
                  size="xss"
                  className="mr-1 cursor-grab px-0.5 py-1"
                >
                  <GripVerticalIcon className="h-4 w-4" />
                </IconButtonBase>
              </div>
            )}
            <span
              className="mr-1"
              style={{
                paddingLeft: `${20 * level}px`,
              }}
            >
              {label}
            </span>
          </div>
          {Boolean(hasDescriptions) && (
            <span className="mr-1 text-xs italic">{description}</span>
          )}
        </div>
        <div className="invisible flex items-center space-x-1 group-hover/option:visible">
          <IconButtonBase size="xss" variant="clean" onClick={onEdit}>
            <PencilIcon className="min-4 h-4 min-h-4 w-4" />
          </IconButtonBase>
        </div>
      </div>
    </div>
  );
};

interface OptionModalProps {
  isOpen: boolean;
  hasDescriptions?: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (data: { label: string; description?: string }) => void;
  initialData?: { label: string; description?: string };
  loading?: boolean;
}

const OptionModal: FC<OptionModalProps> = ({
  isOpen,
  initialData,
  loading = false,
  hasDescriptions = false,
  onClose,
  onDelete,
  onSubmit,
}) => {
  const defaultData = { label: "", description: "" };
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const { handleSubmit, reset, control } = useForm({
    values: initialData || defaultData,
    resolver: yupResolver(optionFormSchema),
  });

  const onSubmitForm = (data: { label: string; description?: string }) => {
    onSubmit(data);
    reset(defaultData);
    onClose();
  };

  const handleDelete = () => {
    setIsDeleting(true);
    onDelete();
    reset(defaultData);
    onClose();
  };

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={() => {
        reset(defaultData);
        onClose();
      }}
      withCancel={false}
      closeModalFromTitle={true}
      title={initialData ? "Edit option" : "Add option"}
    >
      <div className="space-y-4">
        <div className="space-y-2">
          <Controller
            name="label"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="label"
                label="Label"
                value={field.value}
                onChange={field.onChange}
                error={error?.message}
              />
            )}
          />
          {hasDescriptions && (
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextArea
                  id="description"
                  label="Description"
                  value={field.value || ""}
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          )}
        </div>
        <div className="space-y-1 pt-6">
          <Button
            className="flex w-full justify-center"
            variant="primary"
            loading={loading && !isDeleting}
            onClick={() => handleSubmit(onSubmitForm)()}
          >
            {t("Save")}
          </Button>
          <Button
            loading={loading && isDeleting}
            className="w-full items-center justify-center space-x-1 bg-white text-red-600"
            onClick={handleDelete}
          >
            <TrashIcon className="h-4 w-4" />
            <span>{t("Delete")}</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const OptionsWrapper: FC<OptionsWrapperProps> = ({
  id,
  label,
  control,
  hasDescriptions,
}) => {
  const { t } = useTranslation();
  const { fields, remove, update, append, replace } = useFieldArray<
    any,
    any,
    any
  >({
    control,
    name: id,
    keyName: "arrId",
  });
  const [activeId, setActiveId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingOption, setEditingOption] = useState<{
    index: number;
    data: { label: string; description?: string };
  } | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const activeField = fields.find((field) => field.value === activeId);

  const handleAdd = ({
    label,
    description,
  }: {
    label: string;
    description?: string;
  }) => {
    if (hasDescriptions) {
      append({
        label,
        description,
        value: snakeCase(label),
      });
    } else {
      append({
        label,
        value: snakeCase(label),
      });
    }
  };

  const handleUpdate = (
    index: number,
    { label, description }: { label: string; description?: string },
  ) => {
    if (hasDescriptions) {
      update(index, {
        label,
        description,
        value: fields[index].value,
      });
    } else {
      update(index, {
        label,
        value: fields[index].value,
      });
    }
  };

  const shuffleParents = (active: Active, over: Over) => {
    const fieldIds = fields.map((f) => f.value);
    const oldIndex = fieldIds.indexOf(active.id as string);
    const newIndex = fieldIds.indexOf(over.id as string);
    const updatedFieldIds = arrayMove(fieldIds, oldIndex, newIndex);
    const values = updatedFieldIds.map((id) => {
      const value = fields.find((f) => f.value === id);
      return value;
    });

    replace(values.map(({ arrId, ...rest }: any) => rest));
  };

  const handleDragEnd = async (active: Active, over: Over) => {
    shuffleParents(active, over);
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        const { active } = event;

        setActiveId(String(active.id));
      }}
      onDragEnd={(event) => {
        const { active, over } = event;
        setActiveId(null);
        if (over && isDragValid(active, over)) {
          handleDragEnd(active, over);
        }
      }}
    >
      <SortableContext
        id="formBuilderOptions"
        items={fields.map((field) => field.value)}
        strategy={verticalListSortingStrategy}
      >
        <div className="relative w-full bg-white pb-4">
          <div className="max-h-96 overflow-y-auto overscroll-contain">
            {label && (
              <label className="amenda-component-label">{t(label)}</label>
            )}
            <FormBuilderSortDroppable className="space-y-1">
              {fields.map((field, i) => (
                <FormBuilderSortableItem key={field.value} id={field.value}>
                  {(isDragging) => (
                    <DropdownItem
                      key={field.value}
                      isDraggable={true}
                      isDragging={isDragging}
                      hasDescriptions={hasDescriptions}
                      label={field.label}
                      description={field?.description}
                      onEdit={() => {
                        setEditingOption({
                          index: i,
                          data: {
                            label: field.label,
                            description: field.description,
                          },
                        });
                        setModalOpen(true);
                      }}
                    />
                  )}
                </FormBuilderSortableItem>
              ))}
            </FormBuilderSortDroppable>
            <div className="mt-2">
              <Button
                size="sm"
                variant="outline"
                className="flex w-full items-center justify-center space-x-1"
                onClick={() => {
                  setEditingOption(null);
                  setModalOpen(true);
                }}
              >
                <span>{t("Add option")}</span>
                <PlusIcon className="h-4 w-4" />
              </Button>
            </div>
            <OptionModal
              isOpen={modalOpen}
              hasDescriptions={hasDescriptions}
              onClose={() => {
                setModalOpen(false);
                setEditingOption(null);
              }}
              onSubmit={(data) => {
                if (editingOption) {
                  handleUpdate(editingOption.index, data);
                } else {
                  handleAdd(data);
                }
              }}
              onDelete={() => {
                if (editingOption) {
                  remove(editingOption.index);
                }
              }}
              initialData={editingOption?.data}
            />
          </div>
        </div>
      </SortableContext>
      <>
        {createPortal(
          <DragOverlay modifiers={[restrictToVerticalAxis]}>
            {activeField && (
              <FormBuilderDragOverlayItem>
                <div className="flex w-full items-center">
                  <div>
                    <IconButtonBase
                      size="xss"
                      className="cursor-grab px-0.5 py-1"
                    >
                      <GripVerticalIcon className="h-4 w-4" />
                    </IconButtonBase>
                  </div>
                  <span className="ml-2 truncate">{activeField.label}</span>
                </div>
              </FormBuilderDragOverlayItem>
            )}
          </DragOverlay>,
          document.body,
        )}
      </>
    </DndContext>
  );
};

const KeywordWrapper: FC<Props> = ({
  component,
  isLoading,
  keywords,
  updateKeywordsState,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [editKeyword, setEditKeyword] = useState<Record<string, any> | null>(
    null,
  );
  const { upsertKeywords, loading } = useUpsertKeywords();

  const title = t("Tags") + (Boolean(isLoading) ? "..." : "");

  const handleUpdate = async (
    keyword: Record<string, any>,
    { label }: { label: string },
  ) => {
    await upsertKeywords({
      id: keyword.id,
      name: label,
      componentId: keyword.componentId,
      callback: (keyword) => updateKeywordsState(keyword, "update"),
    });
  };

  const handleDelete = async (keyword: Record<string, any>) => {
    await upsertKeywords({
      id: keyword.id,
      name: keyword.name,
      componentId: keyword.componentId,
      isDeleted: true,
      callback: (keyword) => updateKeywordsState(keyword, "delete"),
    });
  };

  const handleAdd = async ({ label }: { label: string }) => {
    await upsertKeywords({
      name: label,
      componentId: component.id,
      callback: (keyword) => updateKeywordsState(keyword),
    });
  };

  return (
    <div className="relative w-full bg-white pb-4">
      <div className="max-h-96 overflow-y-auto overscroll-contain">
        <label className="amenda-component-label">{title}</label>
        {keywords.map((keyword) => (
          <DropdownItem
            key={keyword.id}
            label={keyword.name}
            onEdit={() => {
              setEditKeyword(keyword);
              setModalOpen(true);
            }}
          />
        ))}
      </div>
      <div className="mt-2">
        <Button
          size="sm"
          variant="outline"
          className="flex w-full items-center justify-center space-x-1"
          onClick={() => {
            setEditKeyword(null);
            setModalOpen(true);
          }}
        >
          <span>{t("Add option")}</span>
          <PlusIcon className="h-4 w-4" />
        </Button>
      </div>
      <OptionModal
        loading={loading}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setEditKeyword(null);
        }}
        onSubmit={(data) => {
          if (editKeyword) {
            handleUpdate(editKeyword, data);
          } else {
            handleAdd(data);
          }
        }}
        initialData={{
          label: editKeyword?.name,
        }}
        onDelete={() => {
          if (editKeyword) {
            handleDelete(editKeyword);
          }
        }}
      />
    </div>
  );
};

interface ComponentOptionsWrapperProps {
  component: any;
  control: Control<any>;
}

const ComponentOptionsWrapper: FC<ComponentOptionsWrapperProps> = ({
  control,
  component,
}) => {
  const [keywords, setKeywords] = useState<any[]>([]);
  const { getKeywords, loading } = useGetKeywords();

  useEffect(() => {
    if (
      component?.id &&
      [
        FormComponentTypes.Keyword,
        FormComponentTypes.LabelledContactInputs,
      ].includes(component?.component)
    ) {
      getKeywords({
        componentIds: [component.id],
        setKeywords,
      });
    }
  }, [component?.id, component?.component, getKeywords]);

  const updateKeywordsState = (
    keyword: Record<string, any>,
    action?: string,
  ) => {
    setKeywords((prev) => {
      if (action === "delete") {
        return prev.filter((k) => k.id !== keyword.id);
      } else if (action === "update") {
        return prev.map((k) => (k.id === keyword.id ? keyword : k));
      }
      return [...prev, keyword];
    });
  };

  switch (component.component) {
    case FormComponentTypes.Keyword:
      return (
        <KeywordWrapper
          id="options"
          isLoading={loading}
          keywords={keywords}
          control={control}
          component={component}
          updateKeywordsState={updateKeywordsState}
        />
      );
    case FormComponentTypes.Select:
    case FormComponentTypes.Badges:
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.LabelledInput:
    case FormComponentTypes.RadioButton:
      if (Boolean(component?.properties?.isNested)) {
        return (
          <FormBuilderNestedOptions
            id="options"
            control={control}
            component={component}
          />
        );
      }
      return <OptionsWrapper id="options" control={control} />;
    case FormComponentTypes.Checkbox:
      return (
        <OptionsWrapper id="options" control={control} hasDescriptions={true} />
      );
    case FormComponentTypes.SearchAndSelect:
      return <OptionsWrapper id="roles" control={control} />;
    case FormComponentTypes.LabelledContactInputs:
      return (
        <div className="flex w-full flex-col space-y-2 bg-white pb-4">
          <KeywordWrapper
            id="options"
            isLoading={loading}
            keywords={keywords}
            control={control}
            component={component}
            updateKeywordsState={updateKeywordsState}
          />
          <OptionsWrapper id="roles" label="Roles" control={control} />
        </div>
      );
    default:
      return null;
  }
};

interface FormBuilderComponentOptionsProps
  extends ComponentOptionsWrapperProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FormBuilderComponentOptions: FC<
  FormBuilderComponentOptionsProps
> = ({ isOpen, control, component, onClose }) => {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      withCancel={false}
      closeModalFromTitle={true}
      title="Configure Options"
    >
      <div className="max-h-[80vh] w-full overflow-y-auto overscroll-y-contain">
        <ComponentOptionsWrapper control={control} component={component} />
      </div>
    </Modal>
  );
};
