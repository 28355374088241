import {
  FullColumn,
  FullColumnContainerLayout,
  HalfColumn,
  NestedFormContainerLayout,
  OneThirdColumn,
  RootContainerLayout,
  TwoThirdsColumn,
  TwoThirdsColumnContainerLayout,
} from "@amenda-components/PageLayouts";

import { FC } from "react";
import { availableLayouts } from "@amenda-types";

export * from "./FormAutoSaveWrapper";
export * from "./FormAutoSaveWrapperBase";
export * from "./FormAutoSaveWithConditions";
export * from "./FormAutoSaveProvider";
export * from "./ComponentTreeRenderer";
export * from "./FormWrapper";
export * from "./ComponentsTreeView";
export * from "./ReadOnlyHeader";
export * from "./FormBuilderProviders";

export const getLayoutComponents = (
  customLayoutComponents: Record<string, FC<any>> = {},
) => {
  let layoutComponents = {
    [availableLayouts.rootContainer]: RootContainerLayout,
    [availableLayouts.fullColumn]: FullColumn,
    [availableLayouts.halfColumn]: HalfColumn,
    [availableLayouts.oneThirdColumn]: OneThirdColumn,
    [availableLayouts.fullColumnContainer]: FullColumnContainerLayout,
    [availableLayouts.twoThirdColumn]: TwoThirdsColumn,
    [availableLayouts.twoThirdsColumnContainer]: TwoThirdsColumnContainerLayout,
    [availableLayouts.nestedFormContainer]: NestedFormContainerLayout,
  };
  return {
    ...layoutComponents,
    ...customLayoutComponents,
  };
};
